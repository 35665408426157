import React, { useCallback, useContext, useState } from "react";
import { TenantContext } from "../contexts/ContextWrapper";
import { GetAllUserData, GetUserData } from "../helpers/GetFromRestApi";
import { AuthContext } from "../contexts/AuthContext";
import { UserInfoExtended } from "../helpers/SettingsCompany";
import { uploadUserdetails, deleteUserdetails } from "../helpers/PostToRestApi";
import { getProfileDataExtended } from "../helpers/GetFromGraph";

export const useUserData = () => {
    const [isLoadingUserData, setIsLoadingUserData] = useState<boolean>(false)
    const [userDataItems, setUserDataItems] = useState<UserInfoExtended[]>([])
    const [allUserDataItems, setAllUserDataItems] = useState<UserInfoExtended[]>([])

    const { accessToken } = useContext(AuthContext)
    const { tenantContext } = useContext(TenantContext)

    React.useEffect(() => {
        FetchUserData()
        FetchAllUserData()
    }, [accessToken, tenantContext.tenantName, tenantContext.api])

    const FetchUserData = () => {
        setIsLoadingUserData(true)
        GetUserData(
            accessToken!,
            tenantContext.tenantName,
            tenantContext.api,
        )
            .then(async (data) => {
                const graphProfile = await getProfileDataExtended(accessToken!, data!?.map((item) => item.item))
                console.log(graphProfile)
                const newItems: UserInfoExtended[] = data!?.map((subItem) => {
                    return {
                        ...subItem,
                        key: subItem.field,
                        labelEng: subItem.label[0],
                        labelSwe: subItem.label[1],
                        value: subItem.subItem ? graphProfile[subItem.item][subItem.subItem] : graphProfile[subItem.item]
                    } as UserInfoExtended
                })
                setUserDataItems(newItems)
            })
            .catch(() => { setUserDataItems([]) })
            .finally(() => {
                setIsLoadingUserData(false)
            })
    }

    const FetchAllUserData = () => {
        setIsLoadingUserData(true)
        GetAllUserData(
            accessToken!,
            tenantContext.tenantName,
            tenantContext.api,
        )
            .then((data) => {
                const newItems: UserInfoExtended[] = data!?.map((subItem) => {
                    return {
                        ...subItem,
                        key: subItem.field,
                        labelEng: subItem.label[0],
                        labelSwe: subItem.label[1],
                    } as UserInfoExtended
                })
                setAllUserDataItems(newItems)
            })
            .catch(() => { setAllUserDataItems([]) })
            .finally(() => {
                setIsLoadingUserData(false)
            })
    }

    const PostItems = () => {
        uploadUserdetails(
            accessToken!,
            tenantContext.tenantName,
            tenantContext.api,
            userDataItems.map((item) => {
                return {
                    ...item,
                    label: [item.labelEng, item.labelSwe],
                    value: undefined
                }
            }),
        ).then(() => {
            FetchUserData()
            FetchAllUserData()
        })
    }

    const RestoreUserData = useCallback(() => {
        deleteUserdetails(
            accessToken!,
            tenantContext.tenantName,
            tenantContext.api,
        ).then(() => {

        })
    }, [accessToken, tenantContext.tenantName, tenantContext.api])

    return { FetchUserData, FetchAllUserData, PostItems, RestoreUserData, userDataItems, setUserDataItems, allUserDataItems, setAllUserDataItems, isLoadingUserData }
}