import React, { useMemo } from 'react';
import { CommandBar, IColumn, ICommandBarItemProps, ShimmeredDetailsList, Selection, Stack, DetailsListLayoutMode, SelectionMode, CheckboxVisibility, FontIcon } from '@fluentui/react';
import { DialogDelete } from '../dialogs/DialogDelete';
import { GroupedData } from '../../interfaces/GroupedData';

export interface CombinedDataListProps {
	items: GroupedData[]
	setItems: (items: GroupedData[]) => void
	selectedItem: GroupedData
	setSelectedItem: (item: GroupedData) => void
	deleteItem: (id: string) => void
}

const CombinedDataList: React.FC<CombinedDataListProps> = (props) => {

	// const [selectedItem, setSelectedItem] = React.useState<GroupedData | null>(null);
	const [selectionCount, setSelectedCount] = React.useState<number>(0);

	const [hideDeleteDialog, setHideDeleteDialog] = React.useState<boolean>(true);


	let selection: Selection = new Selection({
		onSelectionChanged: () => {
			props.setSelectedItem(selection.getSelection()[0] as GroupedData)
			setSelectedCount(selection.getSelectedCount())
		}
	});

	React.useEffect(() => {
		selection.selectToKey(props.selectedItem!?.ID!)
	}, [props.selectedItem])

	console.log(props.items)

	const commandItems = (): ICommandBarItemProps[] => {
		console.log(selectionCount)
		return [
			{
				key: 'new',
				text: 'New',
				iconProps: { iconName: 'Add' },
				disabled: props.items!?.length > 0 && props.items![props.items!?.length - 1]?.ID === '',
				onClick: () => {
					props.setItems([...props.items, { ID: '', GroupedDataName: '', GroupedDataTag: '', Delimiter: '', HTML: false, DataItems: [] } as GroupedData])
					props.setSelectedItem({ ID: '', GroupedDataName: '', GroupedDataTag: '', Delimiter: '', HTML: false, DataItems: [] } as GroupedData)
					// selection.selectToKey(props.selectedItem!?.ID!)
					// selection.selectToIndex(props.items.length)
				}
			} as ICommandBarItemProps,
			{
				key: 'delete',
				text: 'Delete',
				iconProps: { iconName: 'Delete' },
				disabled: selectionCount === 0 || props.selectedItem!?.ID === '',
				onClick: () => {
					setHideDeleteDialog(false)
				}
			} as ICommandBarItemProps,
		]
	} 

	const onRenderDelimiter = (item: GroupedData) => {
		return (
			<>
				<span style={{ color: 'darkgray' }}>'</span>
				<span>{item.Delimiter === '\v'? '\u21b5' : item.Delimiter}</span>
				<span style={{ color: 'darkgray' }}>'</span>
			</>
		)
	}

	const columns: IColumn[] = useMemo(() => [
		{ key: 'column1', name: 'Name', fieldName: 'GroupedDataName', minWidth: 150, maxWidth: 300, isResizable: true },
		{ key: 'column3', name: 'Delimiter', fieldName: 'Delimiter', minWidth: 70, maxWidth: 200, isResizable: true, onRender: onRenderDelimiter },
		{ key: 'column4', name: 'HTML', fieldName: 'HTML', minWidth: 50, maxWidth: 50, isResizable: true, onRender: (item: GroupedData) => { return <span>{item.HTML ? <FontIcon iconName="CheckMark" /> : null}</span> } },
		{ key: 'column5', name: 'ID', fieldName: 'ID', minWidth: 250, maxWidth: 250, isResizable: true },
	], [props.items])

	return (
		<Stack className='Width100'>
			<span className='Indent20'><h3>Combined Data</h3></span>
			<CommandBar
				items={commandItems()}
				ariaLabel="Use left and right arrow keys to navigate between commands"
			/>
			<ShimmeredDetailsList
				items={props.items}
				columns={columns}
				enableShimmer={!props.items}
				setKey="set"
				layoutMode={DetailsListLayoutMode.justified}
				selectionPreservedOnEmptyClick={true}
				selectionMode={SelectionMode.single}
				selection={selection}
				ariaLabelForSelectionColumn="Toggle selection"
				ariaLabelForSelectAllCheckbox="Toggle selection for all items"
				checkButtonAriaLabel="Row checkbox"
				checkboxVisibility={CheckboxVisibility.always}
				// onActiveItemChanged={(item) => {
				// 	console.log(item)
				// }}
			/>
			<DialogDelete
				hidden={hideDeleteDialog}
				callbackDelete={() => {
					props.deleteItem(props.selectedItem.ID)
					setHideDeleteDialog(true)
				}}
				callbackCancel={() => { 
					setHideDeleteDialog(true)
				 }}
				title='Delete'
				description={`This will affect all templates using the Combined Data Field ${props.selectedItem!?.GroupedDataName}! Are you sure you want to remove it?`}
			/>
		</Stack>
	);
};

export default CombinedDataList;
