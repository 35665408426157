import * as React from 'react';
import { ActionButton, Image, ImageFit, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
// import { exampleFolderItem, exampleTemplateList, GroupDetails, GroupInfo, TemplateApiHeaders, TemplateFolderItem, TemplateListItem } from '../../helpers/MiscItems';
import { GetTemplatesFolders, GetTemplatesList } from '../../helpers/GetFromRestApi';
import TemplatesDetails from './TemplatesDetails';
import Axios, { CancelTokenSource } from 'axios';
import TemplatesFolders from './TemplatesFolders';
import SettingsCompany from '../../helpers/SettingsCompany';
import { GroupDetails } from '../../interfaces/GroupDetails';
import { GroupInfo } from '../../interfaces/GroupInfo';
import { exampleFolderItem, TemplateFolderItem } from '../../interfaces/TemplateFolderItem';
import { exampleTemplateList, TemplateListItem } from '../../interfaces/TemplateListItem';
import { TemplateApiHeaders } from '../../interfaces/TemplateApiHeaders';
import { VersionSelector } from '../common/VersionSelector';
import { ContentFrame } from '../common/ContentFrame';
import { getTooltipFromString } from '../../helpers/Descriptions';
import { MessageContextType } from '../../interfaces/ContextType';

export interface TemplatesProps {
	setMessage: (x: MessageContextType) => void;
	accessToken: string
	// apiUri: string
	domain: string
	api: string
	groupIds: GroupInfo[]
	companyGroups: GroupDetails[]
	settings: SettingsCompany;
}

export interface TemplatesState {
	folderItems: TemplateFolderItem[]
	templateItems: TemplateListItem[]
	accessToken: string
	// apiUri: string
	path: string
	library: string
	templateVersion: string
}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class Templates extends React.Component<TemplatesProps, TemplatesState> {

	constructor(props: TemplatesProps) {
		super(props);
		this.state = {
			folderItems: [],
			templateItems: [],
			accessToken: this.props.accessToken,
			path: '\\Templates\\Test',
			library: '\\',
			templateVersion: 'TEST',
		};
		this.getTemplates = this.getTemplates.bind(this)
	}

	getFolders = async (version: string) => {
		ourRequest = Axios.CancelToken.source()
		let allFolders: TemplateFolderItem[] = await GetTemplatesFolders(this.props.accessToken!, this.props.domain, this.props.api, ourRequest.token)
		if (!allFolders) { allFolders = exampleFolderItem }
		else {
			if (version === 'TEST') {
				allFolders = [allFolders[0]].concat(allFolders[0]?.Folders).filter(value => value !== null)
			}
			else {
				allFolders = [allFolders[1]].concat(allFolders[1]?.Folders).filter(value => value !== null)
			}
		}
		this.getTemplates('\\Templates\\' + version[0] + version.substr(1).toLowerCase(), '\\', version)
		this.setState({
			folderItems: allFolders,
			accessToken: this.props.accessToken,
			path: '\\Templates\\' + version[0] + version.substr(1).toLowerCase() + this.state.path.substr(this.state.path.length - 36),
			templateVersion: version,
		})
	}

	getTemplates = async (path: string, library?: string, version?: string) => {
		if (!library) { library = this.state.folderItems.find((item) => { return item.Path === path })?.Library || '\\' }
		if (!version) { version = this.state.templateVersion }
		
		ourRequest = Axios.CancelToken.source()
		const templateHeaders: TemplateApiHeaders = {
			templateVersion: version,
			templateEnviron: '',
			templateType: '',
			myGroups: '',
			groupSelection: 'ALL-TEMPLATES',
		}
		let allTemplates: TemplateListItem[] = await GetTemplatesList(this.props.accessToken!, this.props.domain, this.props.api, templateHeaders, ourRequest.token)
		if (!allTemplates) { allTemplates = exampleTemplateList }
		else {
			allTemplates = allTemplates.filter((value: TemplateListItem) => value.Root?.split('\\').pop() === path.split('\\').pop())
		}
		if (!allTemplates) { allTemplates = exampleTemplateList }
		this.setState({
			templateItems: allTemplates,
			accessToken: this.props.accessToken,
			path: path,
			library: library,
			templateVersion: version,
		})
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	componentDidUpdate(prevProps: Readonly<TemplatesProps>, _prevState: Readonly<TemplatesState>, _snapshot?: any): void {
		if (!this.state.folderItems || prevProps.domain !== this.props.domain || prevProps.api !== this.props.api || prevProps.accessToken !== this.props.accessToken) {
			this.getFolders(this.state.templateVersion)
		}
	}

	componentDidMount(): void {
		this.getFolders(this.state.templateVersion)
	}

	render() {
		const settingGuideImage = require('../../assets/settings-guide.png');
		return (
			<Stack >
				<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
					<Text variant="xxLarge">
						Templates
					</Text>
				</Stack.Item>
				<VersionSelector
					onVersionChange={this.getFolders}
					version={this.state.templateVersion}
					testDescription={<>
						You're now in test mode. You can upload, download, delete and rename templates and folders, but they will not be available to all users before you publish them.<br />
						To publish templates use the<ActionButton iconProps={{ iconName: 'PublishContent' }} >Publish All</ActionButton>button in the <b>Templates Folders</b> section.<br />
						Users with an account in this portal are able to switch to Test version in the 365TemplateExtender addin. To reach the <b>Template Version</b> setting, click the bottom right menu and select <b>Settings</b> {getTooltipFromString(<><Image src={settingGuideImage} imageFit={ImageFit.contain} alt="" /></>)}
					</>}
					pubDescription={<>
						You're now in published mode. All templates are available for all users.
					</>}
				/>
				{!this.state.folderItems.length && <Spinner className='spinner' size={SpinnerSize.large} label='Loading templates...' />}
				{['TEST', 'PUB'].map((version) => {
					if (this.state.folderItems.length) {
						return (
							<ContentFrame key={version} hidden={version !== this.state.templateVersion}>
								<TemplatesFolders
									setMessage={this.props.setMessage}
									getFolders={this.getFolders}
									getTemplates={this.getTemplates}
									accessToken={this.props.accessToken}
									domain={this.props.domain}
									api={this.props.api}
									folderItems={this.state.folderItems}
									groupIds={this.props.groupIds}
									companyGroups={this.props.companyGroups}
									settings={this.props.settings}
									templateVersion={version}
								/>
							</ContentFrame>
						)
					}
					else { return null }
				})}
				{this.state.folderItems && this.state.folderItems!.map((folder: TemplateFolderItem) => {
					if (this.state.folderItems.length) {
						return (
							<ContentFrame key={folder.Path} hidden={folder.Path !== this.state.path}>
								<TemplatesDetails
									setMessage={this.props.setMessage}
									getTemplates={this.getTemplates}
									path={folder.Path}
									library={this.state.library}
									templateVersion={this.state.templateVersion}
									accessToken={this.props.accessToken}
									domain={this.props.domain}
									api={this.props.api}
									templateItems={this.state.templateItems}
									folderItems={this.state.folderItems}
									groupIds={this.props.groupIds}
									settings={this.props.settings}
									folderGroupIds={(this.state.folderItems) ? this.state.folderItems!.find((item) => { return item.Library === this.state.library })?.templateGroups : undefined}
									companyGroups={this.props.companyGroups}
								/>
							</ContentFrame>
						)
					}
					else { return null }
				})}
			</Stack>
		)
	}
}
