
import { IIconProps } from '@fluentui/react';

export function GetProductColor(host: string): string {
  switch (host) {
      case 'Excel': {
          return '#217346';
      }
      case 'PowerPoint': {
          return '#b7472a';
      }
      case 'Word': {
          return '#2b579a';
      }
      default: {
          return '#d83b01';
      }
  }
}
  
export function GetProductIcon(host: string): IIconProps {
    switch (host) {
        case 'Excel': {
            return { iconName: 'ExcelDocument' };
        }
        case 'PowerPoint': {
            return { iconName: 'PowerPointDocument' };
        }
        case 'Word': {
            return { iconName: 'WordDocument' };
        }
        default: {
            return { iconName: 'TextDocument' };
        }
    }
}
  
// export function GetTemplateRestUri(host: string): string {
//     switch (host) {
//         case 'Excel': {
//             return GetRestApiHref() + 'templates?FileType=ExcelWorkbook';
//         }
//         case 'PowerPoint': {
//             return GetRestApiHref() + 'templates?FileType=PowerPointPresentation';
//         }
//         case 'Word': {
//             return GetRestApiHref() + 'templates?FileType=WordDocument';
//         }
//         default: {
//             return '';
//         }
//     }
// }

export function GetHostHref(): string {
    return window.location.protocol + '//' + window.location.host;
}

export const apiUris=(api: string) => {
    switch (api.toLowerCase()) {
        case 'dev': {
            // return 'https://rehnrestapidev-newauthorization.azurewebsites.net/api/'
            return 'https://rehnrestapidev.azurewebsites.net/api/';
        }
        case 'test': {
            return 'https://rehnrestapitest.azurewebsites.net/api/';
        }   
        case 'prod': 
        default: {  
            return 'https://rehnrestapi.azurewebsites.net/api/';
        }        
    }        
}

export const adminApiUris=() => {
    // return 'https://rehnadminrestapi-newauthorization.azurewebsites.net/api/'
    return 'https://rehnadminrestapi.azurewebsites.net/api/'
}

// export function GetRestApiHref(): string {
//     return 'https://rehnrestapitest.azurewebsites.net/api/'
    // switch (location.host) {
    //     case '365templateextender.azurewebsites.net': {
    //         return 'https://rehnrestapi.azurewebsites.net/api/'
    //     }
    //     default: {
    //         return 'https://rehnrestapitest.azurewebsites.net/api/'
    //     } 
    // }
// }