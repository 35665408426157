/* eslint-disable react/prop-types */
import * as React from "react"
import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IDropdownOption, IPersonaProps, Label, PrimaryButton, Text, Toggle } from "@fluentui/react";
import { TemplateSettings } from "../../interfaces/TemplateSettings";
import { GroupDetails } from "../../interfaces/GroupDetails";
import { PeoplePickerGroups } from "../../helpers/PeoplePicker";
import { GroupID } from "../../interfaces/GroupID";
import { TemplateFolderItem } from "../../interfaces/TemplateFolderItem";
import { TemplateListItem } from "../../interfaces/TemplateListItem";

const getDropdownOption = (item: TemplateFolderItem): IDropdownOption => {
	return ({
		key: typeof item.Root === 'undefined' ? item.Path : '\\Templates\\Test\\' + item.Path,
		text: typeof item.Root === 'undefined' ? '\\' : item.Library!,
		data: item
	})
}

export interface DialogEditTemplateSettingsProps {
	callbackOk: (settings: TemplateSettings) => void,
	callbackAbort: () => void,
	files: TemplateListItem[],
	hidden: boolean,
	defaultSettings: TemplateSettings
	showGroups: boolean,
	companyGroups: GroupDetails[];
	folders: TemplateFolderItem[],
}


export const DialogEditTemplateSettings: React.FunctionComponent<DialogEditTemplateSettingsProps> = (props): JSX.Element => {

	const [settings, setSettings] = React.useState<TemplateSettings>(props.defaultSettings);
	const [selectedRoot, setSelectedRoot] = React.useState<IDropdownOption>(props.folders.map((item) => { return getDropdownOption(item) }).find((item) => item.key === props.defaultSettings.Root)!);


	React.useEffect(() => {
		setSettings(props.defaultSettings)
	}, [props.defaultSettings])

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: 'Edit Selected Templates',
		subText: '',
	};

	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 450 } },
	};

	if (!settings) { return (<></>) }

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.callbackAbort()}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			{props.files.length > 0 ? <><Label>Selected file(s):</Label>{props.files.map((item) => { return <Text key={item.id}>{item.Path}<br /></Text> })}<br /></> : null}
			<Toggle
				label="Hide online"
				onText="On"
				offText="Off"
				inlineLabel
				checked={settings.hideOnline! || false}
				onChange={(_event, checked) => { setSettings({ ...settings, hideOnline: checked! }) }}
			/>
			<Toggle
				label="Hide desktop"
				onText="On"
				offText="Off"
				inlineLabel
				checked={settings.hideDesktop! || false}
				onChange={(_event, checked) => { setSettings({ ...settings, hideDesktop: checked! }) }}
			/>
			<Dropdown
				placeholder="Select folder"
				label="Move to folder"
				options={props.folders.map((item) => getDropdownOption(item))}
				selectedKey={selectedRoot ? selectedRoot.key : props.defaultSettings.Root}
				onChange={(_event, option) => setSelectedRoot(option!)}
			/>
			{props.showGroups ?
				props.companyGroups!?.length ?
					<>
						<Label>Groups</Label>
						<PeoplePickerGroups
							setSelectedGroups={(groups) => { setSettings({ ...settings, templateGroups: props.companyGroups?.filter((item) => { return groups?.find((group) => { return group.id === item.id }) }).map((item) => { return { groupID: item.id } as GroupID }) }) }}
							companyGroups={props.companyGroups!?.filter(v => v).map((item: GroupDetails) => {
								return ({
									id: item.id,
									text: item.displayName,
									secondaryText: item.description
								} as IPersonaProps)
							})}
							selectedGroups={settings.templateGroups!?.map((item: GroupID) => {
								return ({
									id: item.groupID,
									text: props.companyGroups?.find((group) => { return group.id === item.groupID })?.displayName,
									secondaryText: props.companyGroups?.find((group) => { return group.id === item.groupID })?.description
								} as IPersonaProps)
							}
							)}
						/>
					</>
					: <>Groups not loaded!</>
				: null}
			<Toggle
				label="Apply updates to published templates"
				onText="On"
				offText="Off"
				inlineLabel
				disabled={!settings.publishDate}
				checked={settings.updatePub!}
				onChange={(_event, checked) => { setSettings({ ...settings, updatePub: checked! }) }}
			/>
			<DialogFooter>
				<PrimaryButton onClick={() => props.callbackOk({ ...settings, Root: selectedRoot!?.key.toString() || '' })} text="Ok" />
				<DefaultButton onClick={() => props.callbackAbort()} text="Abort" />
			</DialogFooter>
		</Dialog>
	);
};