import * as React from "react"
import { Checkbox, Dropdown, IDropdownOption } from "@fluentui/react"

export const EditFieldDropdown: React.FunctionComponent<{
    onChange: (option: IDropdownOption<any>) => void
    options: string[],
    value: string,
    placeholder: string,
}> = (props) => {
    return (
        <Dropdown
            aria-label={props.placeholder}
            placeholder={props.placeholder}
            options={props.options.map((val) => { return { key: val.toLowerCase().replace(' ', ''), text: val } })}
            selectedKey={props.value.toLowerCase().replace(' ', '')}
            onChange={(_ev, option) => props.onChange(option!)}
        />
    )
}

// export const EditFieldTextfield: React.FunctionComponent<{
//     onChange: (newValue: string) => void
//     value: string,
//     placeholder: string,
//     errorMessage?: string, 
// }> = (props) => {
//     return (
//         <TextField
//             aria-label={props.placeholder}
//             placeholder={props.placeholder}
//             errorMessage={props.errorMessage}
//             value={props.value}
//             onChange={(_ev, newValue) => props.onChange(newValue!)}
//         />
//     )
// }

export const EditFieldCheckbox: React.FunctionComponent<{
    onChange: (checked: boolean) => void
    value: boolean,
    placeholder?: string,
}> = (props) => {
    return (
        <Checkbox
            aria-label={props.placeholder}
            checked={props.value}
            onChange={(_ev, checked) => props.onChange(checked!)}
        />
    )
}