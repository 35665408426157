import * as React from 'react';
import {
	Stack,
	ProgressIndicator,
} from '@fluentui/react';
import { CancelTokenSource } from 'axios';
import ReactDOMServer from 'react-dom/server';
import { ContentItem } from '../../interfaces/ContentItem';
import WordContentCard from './WordContentCard';

export interface WordContentDetailsProps {
	onButtonClick(key: string, selectedItem: ContentItem): void
	accessToken: string
	// apiUri: string
	domain:string
	api:string
	contentItems: ContentItem[]
	path: string
	templateVersion: string
}

export interface WordContentDetailsState {

}

let infoMessage: HTMLDivElement | null = null;

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class WordContentDetails extends React.Component<WordContentDetailsProps, WordContentDetailsState> {

	constructor(props: WordContentDetailsProps) {
		super(props);
		this.state = {

		};
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	render(): JSX.Element {
		let i: number = 0;

		if (!this.props.contentItems.length) {
			if (infoMessage) { infoMessage.innerHTML = ReactDOMServer.renderToString(<ProgressIndicator label="Fetching slides" description={`Updating slide gallery`} />) }
		}

		if (this.props.contentItems.length) {
			if (infoMessage) { infoMessage.innerHTML = '' }
			return (
				<Stack className='Width100'>
					<span className='Indent20'>
						<h3>{`${this.props.templateVersion === 'TEST' ? 'Test' : 'Published'} Contents ${this.props.path !== '\\' ? ` in ${this.props.path}` : ''}`}</h3>
					</span>
					<Stack style={{ paddingLeft: '20px', paddingRight: '20px' }}>
						<div ref={element => { infoMessage = element }}></div>
					</Stack>
					<Stack horizontal wrap tokens={{ childrenGap: 15 }} styles={{ root: { margin: '20px', maxWidth: '100%' } }}>
						{this.props.contentItems?.map((item) => {
							return (
								<Stack.Item styles={{ root: { padding: '5px', border: '1px', borderStyle: 'solid', borderColor: '#e1dfdd', } }} key={i++}>
									<WordContentCard
										onButtonClick={this.props.onButtonClick}
										accessToken={this.props.accessToken}
										domain={this.props.domain}
										api={this.props.api}
										id={item.ContentID}
										templateVersion={this.props.templateVersion}
									/>
								</Stack.Item>
							)
						})}
					</Stack>
				</Stack>
			);
		}
		else {
			return <></>
		}


	}
}
