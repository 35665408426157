import * as React from "react"
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField } from "@fluentui/react"
import { Toggle } from "office-ui-fabric-react";
import { EmailSignature } from "../../interfaces/EmailSignature";

export interface DialogEditSignatureProps {
	callbackOk: (signature: EmailSignature) => void,
	callbackAbort: () => void,
	// setParentState: (x: any) => void, 
	// settings: SettingsCompany, //?????
	hideState: string,
	confirmState: string,
	newSignatureItem: string,
	hidden: boolean,
	selectedItem: EmailSignature,
	isNew: boolean
}

export const DialogEditSignature: React.FunctionComponent<DialogEditSignatureProps> = (props) => {

	// const [hasItems, setHasItems] = React.useState(false);
	const [signatureName, setSignatureName] = React.useState<string>('');
	const [isDefault, setIsDefault] = React.useState<boolean>(false);
	const [isReply, setIsReply] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (props.isNew) {
			setSignatureName('');
			setIsDefault(false)
			setIsReply(false)
		}
		else {
			setSignatureName(props.selectedItem.SignatureName);
			setIsDefault(props.selectedItem.DefaultSignature)
			setIsReply(props.selectedItem.ReplySignature)
		}		
	}, [props])

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: props.isNew ? 'Create new signature' : 'Edit selected signature',
		// subText: props.isNew ? '' : '',
	};

	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 600 } },
	};

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.callbackAbort()}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<TextField
				label='Signature name'
				value={signatureName}
				disabled={!props.isNew}
				onChange={(_event, value) => setSignatureName(value!)}
			/>
			<Toggle
				label="Default signature"
				id="default"
				onText="On"
				offText="Off"
				inlineLabel
				checked={isDefault}
				onChange={(_ev, checked) => { setIsDefault(checked!) }}
			/>
			<Toggle
				label="Reply signature"
				id="reply"
				onText="On"
				offText="Off"
				inlineLabel
				checked={isReply}
				onChange={(_ev, checked) => { setIsReply(checked!) }}
			/>
			<DialogFooter>
				<PrimaryButton onClick={() => props.callbackOk({ ...props.selectedItem, SignatureName: signatureName, DefaultSignature: isDefault, ReplySignature: isReply })} text={props.isNew ? "Create" : "Update"} />
				<DefaultButton onClick={() => props.callbackAbort()} text="Cancel" />
			</DialogFooter>
		</Dialog>
	);
};
