import * as React from "react"
import { Pivot, PivotItem } from "@fluentui/react"
import { ContentFrame } from "./ContentFrame"
// import { getTooltipFromString } from "../../helpers/Descriptions"

export interface VersionSelectorProps {
	onVersionChange: (version: string) => void
	version: string
	testDescription?: JSX.Element
	pubDescription?: JSX.Element
	testHeader?: string
	pubHeader?: string
}

export const VersionSelector: React.FunctionComponent<VersionSelectorProps> = (props) => {
	// const [version, setVersion] = React.useState()
	// const fontIconClass = mergeStyles({ FontSize: '32px', height: 32, width:32 })
	return (
		<ContentFrame >
			<>
				<Pivot
					className='Indent20'
					aria-label="Version Selector"
					onLinkClick={(item) => { props.onVersionChange(item!.props.itemKey!) }}
					selectedKey={props.version}
				>
					<PivotItem key='TEST' itemKey='TEST' headerText={props.testHeader || 'Test'} />
					<PivotItem key='PUB' itemKey='PUB' headerText={props.pubHeader || 'Published'} />
				</Pivot>
				<div className='Indent20' >
					{/* 'GlobeFavorite' 'Rocket' 'Globe' */}
					{/* <Icon iconName={version === 'TEST' ? 'DeveloperTools' : 'Globe'} style={{ fontSize: '24px' }} /> */}
					{/* getTooltipFromString */}
					{props.version === 'TEST' ?
						!props.testDescription ?
							<div style={{ fontSize: '14px', padding: '10px' }}>You're now in test mode. Here you can upload new items, but they will not be available to all users before you publish them.</div>
							:
							<div style={{ fontSize: '14px', padding: '10px' }}>{props.testDescription}</div>
						:
						!props.pubDescription ?
							<div style={{ fontSize: '14px', padding: '10px' }}>You're now in published mode. All items are available for all users.</div>
							:
							<div style={{ fontSize: '14px', padding: '10px' }}>{props.pubDescription}</div>
					}
				</div>
			</>
		</ContentFrame>
	)
}