import { UserAdminItem } from "../interfaces/UserAdminItem"
import SettingsCompany from "./SettingsCompany"

export const GetMyMenuItems = (userData: UserAdminItem, settings: SettingsCompany, adminMode: boolean): string[] => {

    const extensions = settings.Extensions || []
    const functions = settings.Functions || []

    if (!userData) return []

    return [
        'home',
        ...(((userData.isTemplateAdmin || userData.isContributorAdmin || userData.isSettingsAdmin) && (extensions?.find((val) => val.ExtensionName === 'Templates'))) ? ['templates']: []) ,
        ...((((userData.isIconAdmin || userData.isContributorAdmin || userData.isSettingsAdmin) || userData.isTemplateAdmin) && (extensions?.find((val) => val.ExtensionName === 'Icons'))) ? ['icons']: []) ,
        ...(((userData.isPictureAdmin || userData.isContributorAdmin || userData.isSettingsAdmin) && (extensions?.find((val) => val.ExtensionName === 'Pictures'))) ? ['pictures']: []) ,
        ...(((userData.isSlideAdmin || userData.isContributorAdmin || userData.isSettingsAdmin) && (extensions?.find((val) => val.ExtensionName === 'Slides'))) ? ['slides']: []) ,
        ...(((userData.isContentAdmin || userData.isContributorAdmin || userData.isSettingsAdmin) && extensions?.find((val) => val.ExtensionName === 'Contents')) ? ['wordcontents']: []) ,
        ...(((userData.isSectionsAdmin || userData.isContributorAdmin || userData.isSettingsAdmin) && extensions?.find((val) => val.ExtensionName === 'Sections')) ? ['sections']: []) ,
        ...(((userData.isContributorAdmin || userData.isSettingsAdmin) && extensions?.find((val) => val.ExtensionName === 'Email Signatures')) ? ['emailsignatures']: []) ,
        ...(((userData.isContributorAdmin || userData.isSettingsAdmin) && functions?.find((val) => val.FunctionName === 'Custom Data')?.ActivateFunction) ? ['customdata']: []) ,
        ...((adminMode && userData.isAdmin && (extensions?.find((val) => val.ExtensionName === 'Icons'))) ? ['iconsadmin']: []) ,
        ...((userData.isSettingsAdmin && settings) ? ['settings']: []) ,
        ...((((userData.isContributorAdmin || userData.isSettingsAdmin) && functions?.find((val) => val.FunctionName === 'User Information')?.ActivateFunction)) ? ['userdata']: []) ,
        ...((userData.isSettingsAdmin) ? ['accounts']: []) ,
        ...((userData.isSettingsAdmin && functions.find((val) => val.FunctionName === 'Microsoft 365 Groups')?.ActivateFunction) ? ['groups']: []) ,
        ...((userData.isSettingsAdmin) ? ['statistics']: []) ,
        ...((adminMode && userData.isAdmin) ? ['domains']: []) ,
        ...((adminMode && userData.isAdmin) ? ['news']: []) ,
        ...((adminMode && userData.isRehngruppen) ? ['companies']: []) ,
        ...((adminMode && userData.isRehngruppen) ? ['academy']: []) ,
        
    ]    
}


export const AllMenuItems = (userData: UserAdminItem, settings: SettingsCompany, adminMode: boolean) => {

    const extensions = settings.Extensions || []
    const functions = settings.Functions || []

    if (!userData) return []

    const homeMenuItem = () => {
        return [{
            key: 'home',
            name: 'Home',
            url: '',
            data: 'Home',
        }]
    }

    const templatesMenuItem = () => {
        return (((userData.isTemplateAdmin || userData.isContributorAdmin || userData.isSettingsAdmin) && (extensions?.find((val) => val.ExtensionName === 'Templates'))) ? [{
            key: 'templates',
            name: 'Templates',
            url: '',
            data: 'Documentation',
        }] : [])
    }

    const iconsMenuItem = () => {
        return ((((userData.isIconAdmin || userData.isContributorAdmin || userData.isSettingsAdmin) || userData.isTemplateAdmin) && (extensions?.find((val) => val.ExtensionName === 'Icons'))) ? [{
            key: 'icons',
            name: 'Icons',
            url: '',
            data: 'PictureTile',
        }] : [])
    }

    const picturesMenuItem = () => {
        return (((userData.isPictureAdmin || userData.isContributorAdmin || userData.isSettingsAdmin) && (extensions?.find((val) => val.ExtensionName === 'Pictures'))) ? [{
            key: 'pictures',
            name: 'Pictures',
            url: '',
            data: 'PictureCenter',
        }] : [])
    }

    const slideMenuItem = () => {
        return (((userData.isSlideAdmin || userData.isContributorAdmin || userData.isSettingsAdmin) && (extensions?.find((val) => val.ExtensionName === 'Slides'))) ? [{
            key: 'slides',
            name: 'Slides',
            url: '',
            data: 'Slideshow'
        }] : [])
    }

    const wordContentMenuItem = () => {
        return (((userData.isContentAdmin || userData.isContributorAdmin || userData.isSettingsAdmin) && extensions?.find((val) => val.ExtensionName === 'Contents')) ? [{
            key: 'wordcontents',
            name: 'Contents',
            url: '',
            data: 'WordLogo',
        }] : [])
    }

    const sectionsMenuItem = () => {
        return (((userData.isSectionsAdmin || userData.isContributorAdmin || userData.isSettingsAdmin) && extensions?.find((val) => val.ExtensionName === 'Sections')) ? [{
            key: 'sections',
            name: 'Sections',
            url: '',
            data: 'WordLogo',
        }] : [])
    }

    const emailsignaturesMenuItem = () => {
        return (((userData.isContributorAdmin || userData.isSettingsAdmin) && extensions?.find((val) => val.ExtensionName === 'Email Signatures')) ? [{
            key: 'emailsignatures',
            name: 'Email Signatures',
            url: '',
            data: 'InsertSignatureLine',
        }] : [])
    }

    const customDataMenuItem = () => {
        return (((userData.isContributorAdmin || userData.isSettingsAdmin) && functions?.find((val) => val.FunctionName === 'Custom Data')?.ActivateFunction) ? [{
            key: 'customdata',
            name: 'Custom Data',
            url: '',
            data: 'EMI',
        }] : [])
    }

    const iconsAdminMenuItem = () => {
        return ((adminMode && userData.isAdmin && (extensions?.find((val) => val.ExtensionName === 'Icons'))) ? [{
            key: 'iconsadmin',
            name: 'Icons Admin',
            url: '',
            data: 'FileCode',
        }] : [])
    }

    const settingsMenuItem = () => {
        return ((userData.isSettingsAdmin && settings) ? [{
            key: 'settings',
            name: 'Settings',
            url: '',
            data: 'Settings',
        }] : [])
    }

    const userDataMenuItem = () => {
        return (((userData.isContributorAdmin || userData.isSettingsAdmin) && functions?.find((val) => val.FunctionName === 'User Information')?.ActivateFunction) ? [{
            key: 'userdata',
            name: 'User Data',
            url: '',
            data: 'ContactInfo',
        }] : [])
    }

    const usersMenuItem = () => {
        return ((userData.isSettingsAdmin) ? [{
            key: 'accounts',
            name: 'Accounts',
            url: '',
            data: 'Contact',
        }] : [])
    }

    const groupsMenuItem = () => {
        return ((userData.isSettingsAdmin && functions.find((val) => val.FunctionName === 'Microsoft 365 Groups')?.ActivateFunction) ? [{
            key: 'groups',
            name: 'Groups',
            url: '',
            data: 'Group',
        }] : [])
    }

    const statisticsMenuItem = () => {
        return ((userData.isSettingsAdmin) ? [{
            key: 'statistics',
            name: 'Statistics',
            url: '',
            data: 'BarChart4',
        }] : [])
    }

    const domainsMenuItem = () => {
        return ((adminMode && userData.isAdmin) ? [{
            key: 'domains',
            name: 'Domains',
            url: '',
            data: 'ServerEnviroment',
        }] : [])
    }

    const newsMenuItem = () => {
        return ((adminMode && userData.isAdmin) ? [{
            key: 'news',
            name: 'News',
            url: '',
            data: 'News',
        }] : [])
    }

    const companiesMenuItem = () => {
        return ((adminMode && userData.isRehngruppen) ? [{
            key: 'companies',
            name: 'Export customer data',
            url: '',
            data: 'Companies',
        }] : [])
    }
    
    const academyMenuItem = () => {
        return ((adminMode && userData.isRehngruppen) ? [{
            key: 'academy',
            name: 'RehnAcademy',
            url: '',
            data: 'Academy',
        }] : [])
    }

    return [
        ...homeMenuItem(),
        ...templatesMenuItem(),
        ...iconsMenuItem(),
        ...iconsAdminMenuItem(),
        ...picturesMenuItem(),
        ...slideMenuItem(),
        ...wordContentMenuItem(),
        ...sectionsMenuItem(),
        ...settingsMenuItem(),
        ...userDataMenuItem(),
        ...customDataMenuItem(),
        ...usersMenuItem(),
        ...groupsMenuItem(),
        ...statisticsMenuItem(),
        ...domainsMenuItem(),
        ...emailsignaturesMenuItem(),
        ...newsMenuItem(),
        ...companiesMenuItem(),
        ...academyMenuItem(),
    ]
}