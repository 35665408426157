import * as React from 'react';
import { FileInfo } from '../../interfaces/FileInfo';
import {
	CheckboxVisibility,
	CommandBar,
	IColumn,
	ICommandBarItemProps,
	Icon,
	MessageBarType,
	SelectionMode,
	ShimmeredDetailsList,
	Stack,
} from '@fluentui/react';
import { DetailsListLayoutMode, Selection, } from 'office-ui-fabric-react/lib/DetailsList';
import { sortArray } from '../../helpers/MiscFunctions';
import { GetIconsFile } from '../../helpers/GetFromRestApi';
import { deleteIconsFile, publishIconsFiles, uploadIconsFile } from '../../helpers/PostToRestApi';
import { UploadFile } from '../../interfaces/UploadFile';
import { DialogDelete } from '../dialogs/OldDialogDelete';
import { DialogUploadJson } from '../dialogs/DialogUploadJson';
import { DialogOkAbort } from '../dialogs/OldDialogOkAbort';
import { DialogOkOnly } from '../dialogs/OldDialogOkOnly';
import Axios, { CancelTokenSource } from 'axios';
import { MessageContextType } from '../../interfaces/ContextType';

export interface IconsAdminFilesProps {
	setMessage: (x: MessageContextType) => void;
	getIcons: () => void
	accessToken: string
	// apiUri: string
	domain:string
	api:string
	templateVersion: string
	items: FileInfo[]
}

export interface IconsAdminFilesState {
	items: FileInfo[]
	columns: IColumn[]
	accessToken?: string,
	apiUri?: string,
	selectedItem: FileInfo
	selection: Selection
	hideDeleteDialog: boolean
	hideUploadDialog: boolean
	hideNoSelectDialog: boolean
	hideFileExist: boolean
	hidePublishDialog: boolean
	deleteConfirmed: boolean
	uploadConfirmed: boolean
	publishConfirmed: boolean
	uploading: boolean
	deleting: boolean
	publishing: boolean
	uploadItems: UploadFile[]
	publishFilename: string,

}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;
export default class IconsAdminFiles extends React.Component<IconsAdminFilesProps, IconsAdminFilesState> {
	private _selection: Selection;

	constructor(props: IconsAdminFilesProps) {
		super(props);
		this._selection = new Selection({
			onSelectionChanged: () => this.setState({ selection: this._selection }),
		});
		this.state = {
			items: [],
			columns: [{ key: 'column1', name: '', minWidth: 16, }],
			selectedItem: {} as FileInfo,
			selection: this._selection,
			hideDeleteDialog: true,
			hideUploadDialog: true,
			hideNoSelectDialog: true,
			hideFileExist: true,
			hidePublishDialog: true,
			deleteConfirmed: false,
			uploadConfirmed: false,
			publishConfirmed: false,
			uploading: false,
			deleting: false,
			publishing: false,
			uploadItems: [],
			publishFilename: '',

		}
		this.boundSetState = this.setState.bind(this)
	}

	boundSetState = (x: any) => { this.setState(x) }

	getColums(): IColumn[] {
		return [
			{
				key: 'column0',
				name: 'Icon',
				fieldName: 'icon',
				// iconName: 'FabricFolder',				
				isIconOnly: true,
				minWidth: 16,
				maxWidth: 16,
				isResizable: false,
				onRender: () => { return <Icon styles={{root: {fontSize: '16px'}}} iconName={'FileCode'}/>; },
			},
			{ key: 'column1', name: 'Filename', fieldName: 'fileName', minWidth: 150, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick, },
		]
	}

	commandItems = (): ICommandBarItemProps[] => {
		return [
			...(this.props.templateVersion === 'TEST' ? [{
				key: 'upload',
				text: 'Upload',
				iconProps: { iconName: 'Upload' },
				onClick: () => this._uploadPrompt(),
			}] : []),
			{
				key: 'download',
				text: 'Download',
				iconProps: { iconName: 'Download' },
				onClick: () => this._downloadFile(),
			},
			{
				key: 'delete',
				text: 'Delete',
				iconProps: { iconName: 'Delete' },
				onClick: () => this._deletePrompt(),
			},
			...(this.props.templateVersion === 'TEST' ? [{
				key: 'publish',
				text: 'Publish',
				iconProps: { iconName: 'PublishContent' },
				onClick: () => this._publishPrompt(this.state.selectedItem.fileName! || ''),
			}] : []),
			...(this.props.templateVersion === 'TEST' ? [{
				key: 'publishAll',
				text: 'Publish All',
				iconProps: { iconName: 'PublishContent' },
				onClick: () => this._publishPrompt('ALL'),
			}] : []),
		]
	}


	componentDidUpdate(prevProps: Readonly<IconsAdminFilesProps>, _prevState: Readonly<IconsAdminFilesState>, _snapshot?: any): void {
		if (prevProps.items !== this.props.items) {
			this.setState({
				columns: this.getColums(),
				items: this.props.items,
			})
		}
		if (this.state.publishConfirmed && !this.state.publishing && this.state.publishFilename) {
			this._publishIcons(this.state.publishFilename)
		}
		if (this.state.deleteConfirmed && !this.state.deleting) {
			this._deleteSelectedFiles()
		}
		if (this.state.uploadConfirmed && !this.state.uploading) {
			console.log(this.state.uploadItems)
			this._uploadFile()
		}
	}

	componentDidMount(): void {
		this.setState({
			columns: this.getColums(),
			items: this.props.items,
		})
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	render(): JSX.Element {

		return (
			<Stack className='Width100'>
				<span className='Indent20'>
					<h3>{this.props.templateVersion === 'TEST' ? 'Icon files in Test' : 'Published Icons'}</h3>
				</span>
				<CommandBar
					items={this.commandItems()}
					ariaLabel="Use left and right arrow keys to navigate between commands"
				/>
				{this.state.items[0]?.fileName !== 'No files' &&
					<ShimmeredDetailsList
						items={this.state.items}
						columns={this.state.columns}
						enableShimmer={this.state.items?.length === 0}
						setKey="set"
						layoutMode={DetailsListLayoutMode.justified}
						selectionPreservedOnEmptyClick={true}
						selectionMode={SelectionMode.single}
						selection={(this._selection)}
						ariaLabelForSelectionColumn="Toggle selection"
						ariaLabelForSelectAllCheckbox="Toggle selection for all items"
						checkButtonAriaLabel="Row checkbox"
						checkboxVisibility={CheckboxVisibility.always}
						onActiveItemChanged={this._onActiveItemChanged}
					/>}
				<DialogDelete setParentState={this.boundSetState} hidden={this.state.hideDeleteDialog} hideState='hideDeleteDialog' confirmState='deleteConfirmed' filename={this._selection.count === 1 ? ` file ${this.state.selectedItem.fileName}` : ` ${this._selection.count} files`} />
				<DialogUploadJson setParentState={this.boundSetState} hidden={this.state.hideUploadDialog} hideState='hideUploadDialog' confirmState='uploadConfirmed' uploadItemState='uploadItems' />
				<DialogOkAbort setParentState={this.boundSetState} hidden={this.state.hidePublishDialog} hideState='hidePublishDialog' confirmState='publishConfirmed' title='Publish icons' text={this.state.publishFilename === 'ALL' ? 'Are you sure you want to replace all icons in Published with the icons from Test?' : `Are you sure you want to publish icon file ${this.state.publishFilename}?`} />
				<DialogOkOnly setParentState={this.boundSetState} hidden={this.state.hideNoSelectDialog} hideState='hideNoSelectDialog' title='Nothing selected' text='You have to select a file to use this option.' />
				<DialogOkOnly setParentState={this.boundSetState} hidden={this.state.hideFileExist} hideState='hideFileExist' title='File exist' text='This filename already exist.' />
			</Stack>
		);
	}



	private _onActiveItemChanged = (item?: FileInfo): void => {
		// console.log(this._selection.count)
		this.setState({ selectedItem: item! })
	}

	private _uploadPrompt() {
		this.setState({ hideUploadDialog: false })
	}

	private _uploadFile(): void {
		this.setState({ uploading: true, uploadConfirmed: false })
		//this.setState({uploadItem: {filename: '123', base64: '123'}})
		this.state.uploadItems.forEach((value, _index, _array) => {
			uploadIconsFile(
				this.props.accessToken,
				this.props.domain,
				this.props.api,
				value.filename,
				value.data
			)
				.then((response) => {
					if (response.status === 200) {
						setTimeout(() => {
							this.setState({
								items: [],
								uploadConfirmed: false,
								uploading: false,
								hideUploadDialog: true,
								uploadItems: [],
							})
							// this.getIconFiles(this.state.templateVersion)
							this.props.getIcons()
							this.props.setMessage({ message: `${value.filename} uploaded!`, messageType: MessageBarType.success, visible: true })
						}, 500)
					}
					else if (response.status === 405) {
						this.setState({
							hideFileExist: false,
							uploadConfirmed: false,
							hideUploadDialog: true,
							uploading: false,
							uploadItems: [],
						})
						this.props.setMessage({ message: typeof response.data ==='string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
					}
					else {
						this.setState({
							uploadConfirmed: false,
							hideUploadDialog: true,
							uploading: false,
							uploadItems: [],
						})
						this.props.setMessage({ message: typeof response.data ==='string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })						
					}
				})
				.finally(() => {


				});
		})
		this.setState({
			uploading: false,
		})
	}

	private _deletePrompt() {
		if (this._selection.count > 0) {
			this.setState({ hideDeleteDialog: false })
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}
	}

	private _deleteSelectedFiles() {
		this.setState({ deleting: true, deleteConfirmed: false })
		if (true) {
			let i: number;
			for (i = 0; i < this._selection.getSelection().length; i++) {
				let file: FileInfo = this._selection.getSelection()[i] as FileInfo
				//TODO templates-version
				deleteIconsFile(this.props.accessToken, this.props.domain,this.props.api, file.fileName, this.props.templateVersion)
			}
			setTimeout(() => {
				this.setState({
					items: [],
					selectedItem: {} as FileInfo,
					hideDeleteDialog: true,
					deleting: false,
				})
				// this.getIconFiles(this.state.templateVersion)
				this.props.getIcons()
				this.props.setMessage({ message: this._selection.count === 1 ? `Removed 1 file.` : `Removed ${this._selection.count} files.`, messageType: MessageBarType.success, visible: true })
			}, 500)
		}

	}

	private _publishPrompt(fileName: string) {
		if (this._selection.count === 0 && fileName !== 'ALL') {
			this.setState({ hideNoSelectDialog: false })
		}
		else {
			this.setState({ hidePublishDialog: false, publishFilename: fileName })
		}
	}

	private _publishIcons(fileName: string): void {
		this.setState({ publishing: true, publishConfirmed: false })
		publishIconsFiles(
			this.props.accessToken,
			this.props.domain,
			this.props.api,
			fileName
		)
			.then(() => {
				setTimeout(() => {
					// this.getIconFiles(this.state.templateVersion)
					this.props.getIcons()
				}, 500)
			})
			.finally(() => {
				this.setState({ publishing: false, publishFilename: '' })
			})
	}

	private async _downloadFile() {
		if (typeof this._selection.getSelection()[0] !== 'undefined') {
			ourRequest = Axios.CancelToken.source()
			let selectedFile: FileInfo = this._selection.getSelection()[0] as FileInfo
			let fileJson = await GetIconsFile(this.props.accessToken, this.props.domain,this.props.api,  selectedFile.fileName, this.props.templateVersion, ourRequest.token)
			let anchor: HTMLAnchorElement = document.createElement("a");
			anchor.href = 'data:application/json,' + (JSON.parse(fileJson.replace(/#/g, '%23').replace(/ /g, '%20')));
			anchor.download = selectedFile.fileName;
			anchor.click();
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}

	}

	private _onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
		const { columns, items } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
				// this.setState({
				//   announcedMessage: `${currColumn.name} is sorted ${
				// 	currColumn.isSortedDescending ? 'descending' : 'ascending'
				//   }`,
				// });
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		const newItems = sortArray(items, currColumn.fieldName!, currColumn.isSortedDescending);
		this.setState({
			columns: newColumns,
			items: newItems,
		});
	};
}
