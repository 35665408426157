import React, { useState } from 'react';
import { exampleSlidesList, SlideListItem } from '../../interfaces/SlideListItem';
import {
	IconButton,
	Stack,
	Image,
	ICommandBarItemProps,
	ProgressIndicator,
	CommandBar,
} from '@fluentui/react';
import { updatePresentationPreviews } from '../../helpers/PostToRestApi';
import { AuthContext } from '../../contexts/AuthContext';
import { TenantContext } from '../../contexts/ContextWrapper';

interface SlidesSlidesProps {
	refreshSlides: () => void;
	isLoadingSlides: boolean;
	slideItems: SlideListItem[];
	templateVersion: string;
}

const SlidesSlides: React.FC<SlidesSlidesProps> = (props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { accessToken } = React.useContext(AuthContext)
	const { tenantContext } = React.useContext(TenantContext)

	const commandItems = (): ICommandBarItemProps[] => {
		return [
			{
				key: 'renewPreviews',
				text: 'Renew Previews',
				iconProps: { iconName: 'Processing' },
				disabled: isLoading,
				onClick: updatePreviews,
			}
		]
	};

	const updatePreviews = () => {
		if (!accessToken || !tenantContext.tenantName || !tenantContext.api) { return }
		setIsLoading(true)
		updatePresentationPreviews(accessToken!, tenantContext.tenantName, tenantContext.api, props.slideItems[0].presentationName, props.templateVersion)
			.then(() => {
				props.refreshSlides()
				setIsLoading(false)
			})
	}

	if (!props.slideItems) { return <></> }

	return (
		<>
			{props.slideItems.length && props.slideItems !== exampleSlidesList && (
				<Stack className='Width100'>
					<span className='Indent20'>
						<h3>Slides in {props.slideItems[0].presentationName}</h3>
					</span>
					<CommandBar
						items={commandItems()}
						ariaLabel="Use left and right arrow keys to navigate between commands"
					/>
					{(isLoading || props.isLoadingSlides) && <ProgressIndicator
						styles={{ root: { padding: '20px' } }}
						label="Rendering slide previews..."
						description=''
					/>}
					{(!isLoading && !props.isLoadingSlides )&& <Stack horizontal wrap tokens={{ childrenGap: 15 }} styles={{ root: { margin: '20px', maxWidth: '100%' } }}>
						{props.slideItems?.map((item, index) => (
							<Stack.Item styles={{ root: { padding: '5px', border: '1px', borderStyle: 'solid', borderColor: '#e1dfdd' } }} key={index}>
								<IconButton
									ariaLabel={item.title}
									style={{ width: 120 * item.Width / item.Height, height: 120 }}
								>
									<Image
										style={{ maxHeight: 120, maxWidth: 120 * item.Width / item.Height }}
										src={'data:image/png;base64,' + item.base64}
										alt={item.searchText}
									/>
								</IconButton>
							</Stack.Item>
						))}
					</Stack>}
				</Stack>
			)}
		</>
	);
};

export default SlidesSlides;
