import React, { useEffect, useState } from 'react';
import { Stack, Text } from '@fluentui/react';
import Axios, { CancelTokenSource } from 'axios';
import { StatisticMonthLogInItem } from '../../interfaces/Statisticd';
import { GetLoginStatistics } from '../../helpers/GetFromRestApi';
import StatisticsBarChart from './StatisticsBarChart';
import { ContentFrame } from '../common/ContentFrame';
import { TenantContext } from '../../contexts/ContextWrapper';
import { checkValidToken } from '../../helpers/LogginHelper';
import { AuthContext } from '../../contexts/AuthContext';

let ourRequest: CancelTokenSource | undefined;

// interface FileStatistics {
// 	file: string;
// 	plot: { x: string, y: string }[];
// }

const Statistics: React.FC = () => {
	const [loginItems, setLoginItems] = useState<StatisticMonthLogInItem[]>([]);
	// const [slideItems, setSlideItems] = useState<StatisticSlideItem[]>([]);
	// const [templateItems, setTemplateItems] = useState<StatisticTemplatesItem[]>([]);

	const { accessToken, refreshToken } = React.useContext(AuthContext);
	const { tenantContext } = React.useContext(TenantContext);

	const getStatistics = async () => {
		if (ourRequest) {
			ourRequest.cancel();
		}

		ourRequest = Axios.CancelToken.source();

		try {
			GetLoginStatistics(accessToken!, tenantContext.tenantName, tenantContext.api, ourRequest.token)
				.then((stats) => {
					setLoginItems(stats);
				})
			// GetSlidesStatistics(accessToken!, tenantContext.tenantName, tenantContext.api, ourRequest.token)
			// 	.then((stats) => {
			// 		setSlideItems(stats);
			// 	})

			// GetTemplatesStatistics(accessToken!, tenantContext.tenantName, tenantContext.api, ourRequest.token)
			// 	.then((stats) => {
			// 		setTemplateItems(stats);
			// 	})
		} catch (error) {
			// Handle the error appropriately
		}
	};

	useEffect(() => {
		getStatistics();
	}, [accessToken, tenantContext.tenantName, tenantContext.api]);

	// const transformTemplateItems = (templateItems: StatisticTemplatesItem[]): FileStatistics[] => {
	// 	const groupedByFile = templateItems.reduce((acc, item) => {
	// 		if (!acc[item.File]) {
	// 			acc[item.File] = [];
	// 		}
	// 		acc[item.File].push({
	// 			x: item.month + ' ' + item.year,
	// 			y: item.NoAccess
	// 		});
	// 		return acc;
	// 	}, {});

	// 	return Object.keys(groupedByFile).map(file => ({
	// 		file: file,
	// 		plot: groupedByFile[file]
	// 	}));
	// }

	if (!checkValidToken(accessToken!)) { refreshToken() }

	return (
		<Stack>
			<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
				<Text variant="xxLarge">Statistics</Text>
			</Stack.Item>
			<ContentFrame>
				<Stack horizontal>
					<Stack.Item styles={{ root: { width: 350, minHeight: 400 } }}>
						{loginItems!?.length > 0 && <StatisticsBarChart
							title="Monthly unique logins"
							plotItems={loginItems
								.slice()
								.reverse()
								.map((item) => ({
									x: item.Month + ' ' + item.Year,
									y: item.UniqueLogIn,
								}))}
							horizontal={true}
						/>}
					</Stack.Item>
					{/* <Stack.Item styles={{ root: { width: 350, minHeight: 400 } }}>
						<StatisticsLineChart
							title="Templates used"
							lines={transformTemplateItems(templateItems)
								.slice()
								.reverse()
								.map((item) => ({
									x: item.month + ' ' + item.year,
									y: item.NoAccess,
								}))}
							horizontal={true}
						/>
					</Stack.Item>
					<Stack.Item styles={{ root: { width: 350, minHeight: 400 } }}>
						<StatisticsBarChart
							title="Slides used"
							plotItems={slideItems
								.slice()
								.reverse()
								.map((item) => ({
									x: item.month + ' ' + item.year,
									y: item.NoAccess,
								}))}
							horizontal={true}
						/>
					</Stack.Item> */}

				</Stack>
			</ContentFrame>
		</Stack>
	);
};

export default Statistics;
