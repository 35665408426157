import { Icon, Stack, TooltipHost, getTheme } from '@fluentui/react';
import * as React from 'react';

interface FloatingButtonProps {
    hidden: boolean
    iconName?: string
    tooltip?: string
    right?: string
    onClick: () => void
}

const theme = getTheme();

const FloatingButton: React.FC<FloatingButtonProps> = ({ hidden, onClick, iconName = 'Save', tooltip = 'Save', right = '40px' }) => {
    if (hidden) return null;
    return (
        <TooltipHost
            content={tooltip}
        >
            <Stack
                horizontalAlign='center'
                verticalAlign='center'
                styles={{
                    root: {
                        width: '64px',
                        height: '64px',
                        borderRadius: '50%',
                        right: right,
                        top: '185px',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: theme.palette.themePrimary,
                        position: 'fixed',
                        zIndex: 1000,
                        opacity: 0.3,
                        selectors: { ':hover': { opacity: 1 } },
                        cursor: 'pointer',
                    }
                }}
                onClick={onClick}
            >

                <Icon
                    styles={{ root: { 
                        fontSize: '32px', 
                        color: 'white', 
                        cursor: 'pointer',
                    } }}
                    iconName={iconName}
                    onClick={onClick}
                />
            </Stack>
        </TooltipHost>
    )
}

export default FloatingButton;