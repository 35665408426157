import * as React from 'react';
import { CheckboxVisibility, CommandBar, DetailsListLayoutMode, IColumn, ICommandBarItemProps, MessageBarType, Selection, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import { RedirectItem } from '../../interfaces/RedirectItem';
import { GetRedirects } from '../../helpers/GetFromRestApi';
import { AuthContext } from '../../contexts/AuthContext';
import { MessageContext, TenantContext } from '../../contexts/ContextWrapper';
import { DialogOkAbort } from '../dialogs/DialogOkAbort';
import { deleteRedirect, postRedirect } from '../../helpers/PostToAdminApi';
import { DialogNewRedirect } from '../dialogs/DialogNewRedirect';

interface SettingsRedirectProps {
}

const SettingsRedirect: React.FC<SettingsRedirectProps> = () => {

    const [redirectItems, setRedirectItems] = React.useState<RedirectItem[]>([])
    const [selectedItem, setSelectedItem] = React.useState<RedirectItem | null>(null)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [showConfirmDelete, setShowConfirmDelete] = React.useState<boolean>(false)
    const [showDialogNew, setShowDialogNew] = React.useState<boolean>(false)

    let selection: Selection = new Selection({
        onSelectionChanged: () => setSelectedItem(selection.getSelectedCount() > 0 ? selection.getSelection()[0] as RedirectItem : null)
    });

    const { accessToken } = React.useContext(AuthContext)
    const { tenantContext } = React.useContext(TenantContext)
    const { messageContext, setMessageContext } = React.useContext(MessageContext)



    React.useEffect(() => {
        getRedirects()
    }, [accessToken, tenantContext.tenantName, tenantContext.api])

    const getRedirects = async () => {
        if (!accessToken || !tenantContext.tenantName || !tenantContext.api) { return }
        setIsLoading(true)
        const redirects = await GetRedirects(accessToken!, tenantContext.tenantName, tenantContext.api)
        setRedirectItems(redirects)
        setIsLoading(false)
    }

    const columns: IColumn[] = React.useMemo(() =>
        [
            {
                key: 'column1',
                name: 'DomainName',
                fieldName: 'DomainName',
                minWidth: 150,
                maxWidth: 300,
                isResizable: true,
            },
            {
                key: 'column2',
                name: 'TargetDomainName',
                fieldName: 'TargetDomainName',
                minWidth: 150,
                maxWidth: 300,
                isResizable: true,
            },
        ], [])

    const commandItems: ICommandBarItemProps[] = React.useMemo(() =>
        [
            {
                key: 'newSize',
                text: 'New',
                iconProps: { iconName: 'Add' },
                onClick: () => setShowDialogNew(true),
            },
            {
                key: 'delete',
                text: 'Delete',
                iconProps: { iconName: 'Delete' },
                disabled: !selectedItem,
                onClick: () => setShowConfirmDelete(true),
            }
        ], [selectedItem])

    return (
        <Stack tokens={{ childrenGap: '20px' }}>
            <CommandBar
                items={commandItems}
                ariaLabel="Use left and right arrow keys to navigate between commands"
            />
            {redirectItems &&
                <ShimmeredDetailsList
                    items={redirectItems.map((item) => { return { ...item, key: item.DomainName } })}
                    columns={columns}
                    enableShimmer={isLoading}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={SelectionMode.single}
                    selection={selection}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                    checkboxVisibility={CheckboxVisibility.always}
                    onActiveItemChanged={(item) => setSelectedItem(item as RedirectItem)}
                />
            }
            <DialogOkAbort
                title='Confirm delete'
                description={`Are you sure you want to delete the redirect from "${selectedItem!?.DomainName}"?`}
                callbackOk={() => {
                    deleteRedirect(accessToken!, tenantContext.api, selectedItem!?.DomainName)
                        .then((res) => {
                            setShowConfirmDelete(false)
                            setMessageContext({ ...messageContext, message: res.data, messageType: res.status === 200 ? MessageBarType.success : MessageBarType.error, visible: true })
                            getRedirects()
                        })
                }}
                callbackAbort={() => { setShowConfirmDelete(false) }}
                hidden={!showConfirmDelete}
            />
            <DialogNewRedirect
                callbackOk={(doaminName) => {
                    postRedirect(accessToken!, tenantContext.api, doaminName, tenantContext.tenantName)
                        .then((res) => {
                            setShowDialogNew(false)
                            setMessageContext({ ...messageContext, message: res.data, messageType: res.status === 200 ? MessageBarType.success : MessageBarType.error, visible: true })
                            getRedirects()
                        })
                }}
                callbackAbort={() => { setShowDialogNew(false) }}
                hidden={!showDialogNew}
            />
        </Stack>
    );
}

export default SettingsRedirect;