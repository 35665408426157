import * as React from 'react';
import { CommandBar, ICommandBarItemProps, Stack } from '@fluentui/react';
import { CustomDataDefItem } from '../../interfaces/CustomDataDefItem';
import FloatingButton from '../common/FloatingButton';

interface CustomDataOptionsProps {
	// settings: SettingsCompany
	definitionMode: boolean;
	editMode: boolean;
	CustomDataDefItem: CustomDataDefItem;
	CustomDataDefEdited: CustomDataDefItem;
	onChangeDef: (newDef: CustomDataDefItem) => void;
	onSaveDef: (newDef: CustomDataDefItem) => void;
	onChangeEdit: (editMode: boolean) => void;
}

const CustomDataOptions: React.FC<CustomDataOptionsProps> = ({
	definitionMode,
	editMode,
	CustomDataDefItem,
	CustomDataDefEdited,
	onChangeDef,
	onSaveDef,
	onChangeEdit,
}) => {

	const saveDisabled = JSON.stringify(CustomDataDefItem) === JSON.stringify(CustomDataDefEdited) || CustomDataDefEdited.Level1?.CustomDataDefs.filter((v, i, a) => a.indexOf(v) === i).length !== CustomDataDefEdited.Level1?.CustomDataDefs.length
	const revertDisabled = JSON.stringify(CustomDataDefItem) === JSON.stringify(CustomDataDefEdited)

	const getCommandItems = (): ICommandBarItemProps[] => {
		return [
			{
				key: 'edit',
				text: 'Edit Mode',
				iconProps: { iconName: 'Edit' },
				onClick: () => onChangeEdit(!editMode),
				checked: editMode,
			},
			...(definitionMode ? [
				{
					key: 'save',
					text: 'Save',
					disabled: saveDisabled,
					iconProps: { iconName: 'Save' },
					onClick: () => onSaveDef(JSON.parse(JSON.stringify(CustomDataDefEdited))),
				},
				{
					key: 'revert',
					text: 'Revert',
					disabled: revertDisabled,
					iconProps: { iconName: 'RevToggleKey' },
					onClick: () => onChangeDef(JSON.parse(JSON.stringify(CustomDataDefItem))),
				},
			] : []),
		];
	};

	console.log(CustomDataDefEdited);

	return (
		<Stack className='Width100'>
			<span className='Indent20'>
				<h3>Custom data options</h3>
			</span>
			{definitionMode && (
				<CommandBar
					items={getCommandItems()}
					ariaLabel="Use left and right arrow keys to navigate between commands"
				/>
			)}
			{!saveDisabled &&
				<FloatingButton
					hidden={false}
					onClick={() => onSaveDef(JSON.parse(JSON.stringify(CustomDataDefEdited)))}
				/>
			}
		</Stack>
	);
};

export default CustomDataOptions;
