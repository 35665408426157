import React, { useState, useEffect } from 'react';
import { ActionButton, Image, ImageFit, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import Axios, { CancelTokenSource } from 'axios';
import { GetSectionsList } from '../../helpers/GetFromRestApi';
import { ContentFrame } from '../common/ContentFrame';
import { VersionSelector } from '../common/VersionSelector';
import { getTooltipFromString } from '../../helpers/Descriptions';
import { TenantContext } from '../../contexts/ContextWrapper';
import { checkValidToken } from '../../helpers/LogginHelper';
import { AuthContext } from '../../contexts/AuthContext';
import { SectionsDocument } from '../../interfaces/SectionsDocument';
import SectionsFiles from './SectionsFiles';
import SectionsDetails from './SectionsDetails';
import { updateSectionsDocument } from '../../helpers/PostToRestApi';


let ourRequest: CancelTokenSource = {} as CancelTokenSource;

const Sections: React.FC = () => {

	const [sectionsItems, setSectionsItems] = useState<SectionsDocument[]>([]);
	const [selectedItem, setSelectedItem] = React.useState<SectionsDocument | null>(null)
	const [templateVersion, setTemplateVersion] = useState<string>('TEST');
	const [isLoadingDocs, setIsLoadingDocs] = useState<boolean>(false);
	const [isLoadingSelected, setIsLoadingSelected] = useState<boolean>(false);

	const { tenantContext } = React.useContext(TenantContext);
	const { accessToken, refreshToken } = React.useContext(AuthContext);

	const getSectionsDocs = async (version: string) => {
		ourRequest = Axios.CancelToken.source();
		// const selectedFile: string = selectedItem ? selectedItem.DocName : '';
		setSelectedItem(null);
		setIsLoadingDocs(true);
		setTemplateVersion(version);
		GetSectionsList(
			accessToken!,
			tenantContext.tenantName,
			tenantContext.api,
			version,
			ourRequest.token
		)
			.then((documents) => {
				setSectionsItems(documents);
				setSelectedItem(documents[0])
			})
			.catch(() => {
				setSectionsItems([]);
				setSelectedItem(null);
			})
			.finally(() => {
				setIsLoadingDocs(false);
			});
	};

	useEffect(() => {
		getSectionsDocs(templateVersion);
	}, [accessToken!, tenantContext.tenantName, tenantContext.api, templateVersion]);

	useEffect(() => {
		getSectionsDocs(templateVersion);
		return () => { try { ourRequest.cancel(); } catch (error) { } }
	}, []);

	const settingGuideImage = require('../../assets/settings-guide.png');

	const onSave = (item: SectionsDocument) => {
		setIsLoadingSelected(true)
		updateSectionsDocument(
			accessToken!,
			tenantContext.tenantName,
			tenantContext.api,
			templateVersion,
			item
		)
			.then((item: SectionsDocument) => {
				if (item) {
					setSectionsItems(
						sectionsItems.map((doc) => {
							if (doc.DocName === item.DocName) { return item; }
							return doc;
						})
					);
					setSelectedItem(item);
				}
			})
			.finally(() => { setIsLoadingSelected(false) })
	}

	if (!checkValidToken(accessToken!)) { refreshToken() }

	return (
		<Stack>
			<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
				<Text variant="xxLarge">Sections</Text>
			</Stack.Item>
			<VersionSelector
				onVersionChange={(version) => { getSectionsDocs(version); setSelectedItem(null) }}
				version={templateVersion}
				testDescription={
					<>
						<p style={{ fontSize: '14px' }}>You're now in test mode. You can upload, download and delete documents, but they will not be available to all users before you publish them.</p>
						<p style={{ fontSize: '14px' }}>Sections divides the document into parts that consists of a heading and the following content. When you upload a document you can select which outline levels (e.g. Heading 1, Heading 2) that will be available. After upload, only the outline levels that exist in the document will be shown. By selecting <b>Check all</b> all sections will be selected by default. By selecting <b>Hide index</b> the automatic index will be hidden (useful if you already have numbered headings). By selecting <b>Ignore hierarchy</b> you can insert a single section without automatically inserting it's parents.<br/>
						To publish all documents use the<ActionButton iconProps={{ iconName: 'PublishContent' }} >Publish All</ActionButton>button. To publish a single document use the<ActionButton iconProps={{ iconName: 'PublishContent' }} >Publish Document</ActionButton>button.<br/>
						Users with an account in this portal are able to switch to Test version in the 365TemplateExtender addin. To reach the <b>Template Version</b> setting, click the bottom right menu and select <b>Settings</b> {getTooltipFromString(<><Image src={settingGuideImage} imageFit={ImageFit.contain} alt="" /></>)}</p>
					</>
				}
				pubDescription={
					<>
						You're now in published mode. All documents are available for all users.
					</>
				}
			/>
			{isLoadingDocs && <Spinner className='spinner' size={SpinnerSize.large} label='Loading documents...' />}
			<ContentFrame hidden={isLoadingDocs}>
				<SectionsFiles
					items={sectionsItems}
					reloadItems={() => getSectionsDocs(templateVersion)}
					selectedItem={selectedItem!}
					setSelectedItem={setSelectedItem}
					templateVersion={templateVersion}
				/>
			</ContentFrame>
			{isLoadingSelected && <Spinner className='spinner' size={SpinnerSize.large} label='Loading document...' />}
			{selectedItem && !isLoadingSelected && <ContentFrame hidden={isLoadingDocs}>
				<SectionsDetails
					selectedItem={selectedItem!}
					onSave={onSave}
				/>
			</ContentFrame>}
		</Stack>
	);
};

export default Sections;
