import * as React from 'react';
import { ContentFrame } from '../common/ContentFrame';
import CombinedDataList from './CombinedDataList';
import CombinedDataEdit from './CombinedDataEdit';
import { GetAllCombinedData } from '../../helpers/GetFromRestApi';
import { AuthContext } from '../../contexts/AuthContext';
import { TenantContext } from '../../contexts/ContextWrapper';
import { GroupedData } from '../../interfaces/GroupedData';
import { deleteCombinedDataById, uploadCombinedData } from '../../helpers/PostToRestApi';

export const CombinedDataWrapper: React.FunctionComponent = () => {
    const [items, setItems] = React.useState<GroupedData[]>([]);
    const [selectedItem, setSelectedItem] = React.useState<GroupedData | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const { accessToken } = React.useContext(AuthContext)
    const { tenantContext } = React.useContext(TenantContext)


    React.useEffect(() => {
        getItems()
    }, [])

    const getItems = React.useCallback(() => {
        setIsLoading(true)
        GetAllCombinedData(
            accessToken!,
            tenantContext.tenantName,
            tenantContext.api,
        )
            .then((data) => {
                setItems(data || [])
                if (selectedItem!?.ID === '') {setSelectedItem(null)}
            })
            .catch(() => {
                setItems([])
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [accessToken, tenantContext.tenantName, tenantContext.api])

    const deleteItem = React.useCallback((id: string) => {
        setIsLoading(true)
        deleteCombinedDataById(
            accessToken!,
            id,
            tenantContext.tenantName,
            tenantContext.api,
        )
            .finally(() => {
                getItems()
                setIsLoading(false)
            })
    }, [accessToken, tenantContext.tenantName, tenantContext.api])

    const updateItem = React.useCallback((item: GroupedData) => {
        setIsLoading(true)
        uploadCombinedData(accessToken!, tenantContext.tenantName, tenantContext.api, item)
            .then((newItem) => {
                setSelectedItem(newItem.data as GroupedData)
                getItems()
            })
            .catch(() => {
                getItems()
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [accessToken, tenantContext.tenantName, tenantContext.api])

    return (
        <>
            <ContentFrame>
                <CombinedDataList
                    items={items}
                    setItems={setItems}
                    selectedItem={selectedItem!}
                    setSelectedItem={setSelectedItem}
                    deleteItem={deleteItem}
                />
            </ContentFrame>
            {!!selectedItem && <ContentFrame>
                <CombinedDataEdit
                    selectedItem={selectedItem!}
                    updateItem={updateItem}
                    getItems={getItems}
                    isLoading={isLoading}
                />
            </ContentFrame>}
        </>
    );
}

export default CombinedDataWrapper;