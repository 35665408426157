import * as React from 'react';
import { Stack, TextField, Toggle, } from '@fluentui/react';
import SettingsCompany from '../../helpers/SettingsCompany';
import { FunctionItem } from '../../interfaces/FunctionItem';
import { ExtraField } from '../../interfaces/ExtraField';
import { UserAdminItem } from '../../interfaces/UserAdminItem';
import { getTooltipFromString } from '../../helpers/Descriptions';

export interface SettingsFunctionsProps {
	setEditedSettings: (x: SettingsCompany) => void;
	editedSettings: SettingsCompany,
	userData: UserAdminItem,
}

export interface SettingsFunctionsState {

}

export default class SettingsFunctions extends React.Component<SettingsFunctionsProps, SettingsFunctionsState> {
	constructor(props: SettingsFunctionsProps) {
		super(props);
		this.state = {
		};
	}

	onChangeFunction = (item: FunctionItem): void => {
		let functions: FunctionItem[] = this.props.editedSettings.Functions
		functions[functions.findIndex(val => val.FunctionName === item.FunctionName)] = item
		this.props.setEditedSettings(Object.assign(this.props.editedSettings, { Functions: functions }))
	}

	onChangeFunctionField = (item: FunctionItem, field: ExtraField): void => {
		let fields: ExtraField[] = item.ExtraFields
		fields[fields.findIndex(val => val.Title === field.Title)] = field

		let functions: FunctionItem[] = this.props.editedSettings.Functions
		functions[functions.findIndex(val => val.FunctionName === item.FunctionName)] = Object.assign(item, { ExtraFields: fields })
		this.props.setEditedSettings(Object.assign(this.props.editedSettings, { Functions: functions }))
	}

	render() {
		return (
			this.props.editedSettings.Extensions.length > 0 ?
				<Stack styles={{ root: { maxWidth: '400px' } }}>
					<table className='SettingsTable'>
						<thead>
						</thead>
						<tbody>
							{this.props.editedSettings.Functions
								.map((item: FunctionItem, idx: number, _arr: FunctionItem[]) => {
									return (
										item.ActivateFunction ?
											<React.Fragment key={item.FunctionName}>
												<tr key={idx}>
													<td>{item.FunctionName} {getTooltipFromString(item.Description)}</td>
													<td>
														<Toggle
															// className={item.AnimationMotion}
															aria-label={`Enable ${item.FunctionName}`}
															styles={{ root: { justifyContent: 'center', marginBottom: '0px' } }}
															checked={item.EnableFunction}
															disabled={!item.ActivateFunction}
															onChange={(_ev, checked) => this.onChangeFunction(Object.assign(item, item.EnableFunction = checked!))}
														/>
													</td>
												</tr>
												{item.ExtraFields?.length > 0 && item.ExtraFields.filter(value => value.Visible).length > 0 ?
													<tr>
														<td></td>
														<td>
															{item.ExtraFields.map((field: ExtraField) => {
																if (field.Visible) {
																	const passwordfields = field.HiddenInput ?
																		{
																			type: "password"
																		} : null
																	const passwordreveal = this.props.userData.isAdmin ?
																		{
																			canRevealPassword: true,
																			revealPasswordAriaLabel: "Show password"
																		} : null
																	console.log(passwordfields)
																	if (field.Value === 'TRUE' || field.Value === 'FALSE') {
																		return (
																			<Toggle
																				key={field.Title}
																				defaultChecked={field.Value === 'TRUE'}
																				label={(
																					<Stack horizontal verticalAlign='center' tokens={{ childrenGap: '3px' }}>
																						{field.Title}
																						{getTooltipFromString(field.Description)}
																					</Stack>
																				)}
																				inlineLabel
																				disabled={!item.ActivateFunction}
																				onChange={(_ev, newValue) => { this.onChangeFunctionField(item, Object.assign(field, field.Value = newValue! ? 'TRUE' : 'FALSE')) }}
																			/>
																		)
																	}
																	else {
																		return (
																			<TextField
																				key={field.Title}
																				styles={{ fieldGroup: { maxWidth: 400 } }}
																				label={field.Title}
																				defaultValue={field.Value?.toString()}
																				disabled={!item.ActivateFunction}
																				onRenderLabel={(props, defaultRender) => {
																					return (<>{defaultRender!(props!)} {getTooltipFromString(item.Description)}</>)
																				}}
																				onChange={(_ev, newValue) => { this.onChangeFunctionField(item, Object.assign(field, field.Value = newValue!)) }}
																				{...passwordfields}
																				{...passwordreveal}
																			/>
																		)
																	}
																}
																else { return null }
															})}
														</td>
													</tr>
													: null}
											</React.Fragment>
											: null
									)
								})}
						</tbody>
					</table>
				</Stack>
				: null
		)
	}
}
