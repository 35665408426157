import * as React from 'react';
import {
	IconButton,
	Stack,
} from '@fluentui/react';
import { hasValues } from '../../helpers/MiscFunctions';
import { GetIconsList } from '../../helpers/GetFromRestApi';
import Axios, { CancelTokenSource } from 'axios';
import { exampleIconList, IconListItem } from '../../interfaces/IconListItem';
import { GroupDetails } from '../../interfaces/GroupDetails';
import EncapsulatedSVG from '../common/EncapsultedSVG';

export interface GroupIconsProps {
	templateVersion: string,
	accessToken: string,
	// apiUri: string,
	domain: string,
	api: string,
	selectedGroup: GroupDetails,
}

export interface GroupIconsState {
	items: IconListItem[],
	selectedGroup: GroupDetails,
	templateVersion: string,
}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class GroupIcons extends React.Component<GroupIconsProps, GroupIconsState> {

	constructor(props: GroupIconsProps) {
		super(props);
		this.state = {
			items: {} as IconListItem[],
			selectedGroup: {} as GroupDetails,
			templateVersion: 'TEST'
		};
	}

	getIcons = async () => {
		ourRequest = Axios.CancelToken.source()
		let icons: IconListItem[] = await GetIconsList(
			this.props.accessToken!,
			this.props.domain,
			this.props.api,
			this.props.templateVersion,
			JSON.stringify([{ groupID: this.props.selectedGroup.id }]),
			'ONLY-GROUP',
			ourRequest.token
		)
		// console.log(icons)
		// console.log(hasValues(icons[0]?.libraryName))
		if (!hasValues(icons) || !hasValues(icons[0]?.libraryName)) { icons = exampleIconList }
		this.setState({
			items: icons,
		})
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	componentDidUpdate(_prevProps: Readonly<GroupIconsProps>, _prevState: Readonly<GroupIconsState>, _snapshot?: any): void {
		if (_prevProps.selectedGroup !== this.props.selectedGroup || _prevProps.templateVersion !== this.props.templateVersion) {
			this.getIcons()
		}
	}

	componentDidMount(): void {
		this.getIcons()
	}

	render(): JSX.Element {


		if (!this.state.items) {
			return <></>
		}

		console.log(this.state.items !== exampleIconList)

		if (hasValues(this.state.items) && this.state.items !== exampleIconList) {
			return (
				<Stack className='Width100' style={{ marginTop: 50 }}>
					<span className='Indent20'><h4>Group Icons</h4></span>
					<Stack horizontal wrap tokens={{ childrenGap: 15 }} styles={{ root: { margin: '20px', maxWidth: '400px' } }}>
						{this.state.items?.map((item) => {
							return (
								<Stack.Item styles={{ root: { padding: '5px' } }}>
									<IconButton
										ariaLabel={item.iconName}
										styles={{ flexContainer: { alignItems: 'normal' } }}
										style={{ width: this.state.items[0].previewWidth, height: this.state.items[0].previewHeight, }}
									>
										<EncapsulatedSVG>
											<img width='100%' height='100%' src={`data:image/svg+xml,${encodeURIComponent(item.ImageString.substring(1, item.ImageString.length - 1))}`} alt={item.iconName} />
										</EncapsulatedSVG>
									</IconButton>

								</Stack.Item>
							)
						})}
					</Stack>
				</Stack>
			);
		}
		else {
			return <></>
		}


	}
}
