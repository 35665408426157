import * as React from "react"
import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IDropdownOption, PrimaryButton, Text } from "@fluentui/react"
import { hasValues } from "../../helpers/MiscFunctions";
import { UploadFile } from "../../interfaces/UploadFile";
import { PictureLibrary } from "../../interfaces/PictureLibrary";
import { toBase64 } from "../../helpers/Base64";

export const DialogUploadPictures: React.FunctionComponent<{setParentState: (x: any) => void, hideState: string, confirmState: string, uploadItemState: string, hidden: boolean, libraries?: PictureLibrary[]}> = (props) => {

	const [noInput, setNoInput] = React.useState<boolean>(false);
	const [emptyLibraries, setEmptyLibraries] = React.useState<boolean>(false);
	const [selectedLibrary, setSelectedLibrary] = React.useState<IDropdownOption>();
	const [uploadFiles, setUploadFiles] = React.useState<UploadFile[]>([]);

	if (props.hidden) {
		if (noInput) {setNoInput(false)}
		if (uploadFiles.length > 0) {setUploadFiles([])}
		return (<></>)
	}

	const acceptExtensions: string = ".png, .jpeg, .jpg" 

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: `Upload picture`,
		subText: `Browse the picture(s) you want to upload. As default, filename will show as Alt text title and comments will show as Alt text description.`,
	};
	
	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 450 } },
	};
	
	const libraryOptions: IDropdownOption[] = props.libraries ? props.libraries.map((item) => {
		return ({
			key: item.Path!, text: item.LibraryName
		})
	}) : []
	
	const onChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {

		await Promise.all(Array.from(event.target.files!).map(async (item) => {
			try {
				let res = (await toBase64(item))
				let data = ''
				if (typeof res === 'string') {data = (res.split(',')[1])}
				return({filename: item.name, data: data, library: selectedLibrary?.key.toString() || (props.libraries && props.libraries![0].Path!) || ''})
			} catch (error) {
				return({filename: '', data: '', library: selectedLibrary?.key.toString() || (props.libraries && props.libraries![0].Path!) || ''})
			}
		})).then((res: UploadFile[]) => {
			console.log(res)
			setUploadFiles(res)
			setNoInput(false)
		})	
		.catch(() => 
			setUploadFiles([])
		)	
		//console.log(uploadfiles)
	}
	
	const onChangeLibrary = (item: IDropdownOption): void => {
		setSelectedLibrary(item)
		if (hasValues(uploadFiles)) {
			setUploadFiles(uploadFiles?.map((file) => {
				return {
					filename: file.filename,
					data: file.data,
					library: item.key.toString(),
				}
			}))
		}	
		else {setNoInput(true)}
		console.log(uploadFiles)		
	};
	

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.setParentState({[props.hideState]: true})}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<p>Max filesize: 5 MB / picture</p> 
			<input title="Browse..." type="file" multiple name="file" accept={acceptExtensions} onChange={(event) => {onChangeHandler(event)}} />
			{noInput ? <p><Text variant='small' style={{color: '#a4262c'}}>Nothing selected!</Text></p> : null}
			{props.libraries && <Dropdown
				placeholder="Select library"
				label="Select library"
				options={libraryOptions}
				selectedKey={selectedLibrary ? selectedLibrary.key : props.libraries[0].Path}
				onChange={(_event, option) => onChangeLibrary(option!)}				
				errorMessage={emptyLibraries ? 'Must select a library' : ''}
			/>}
			<DialogFooter>
				<PrimaryButton onClick={() => {
					setEmptyLibraries(!!props.libraries && selectedLibrary?.key === ''); 
					setNoInput(uploadFiles.length < 1)
					if ((!props.libraries || selectedLibrary?.key !== '') && uploadFiles.length > 0){
						setEmptyLibraries(false)
						props.setParentState({
							[props.confirmState]: true, 
							[props.hideState]: true, 
							[props.uploadItemState]: uploadFiles 
						})
					}				
				}} 
				text="Upload" />
				<DefaultButton onClick={() => {props.setParentState({[props.hideState]: true})}} text="Cancel" />
			</DialogFooter>
		</Dialog>
	);
};