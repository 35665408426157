import { DefaultButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { CenterComponent } from './CenterComponent';

interface LoadingSessionProps {
    logout: () => void;
}

const LoadingSession: React.FC<LoadingSessionProps> = (props) => {

    const [showAbortButton, setShowAbortButton] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowAbortButton(true);
        }, 5000);
        return () => {
            clearTimeout(timeoutId)
        };
    }, []);

    return (
        <CenterComponent>
            <Stack tokens={{ childrenGap: '20px' }}>
                <Spinner className='spinner' size={SpinnerSize.large} label='Loading session...' />
                {showAbortButton && <DefaultButton onClick={props.logout}>Abort</DefaultButton>}
            </Stack>
        </CenterComponent>
    )
}

export default LoadingSession;