import * as React from 'react';
//import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { IconButton, Image, Modal, Panel, PanelType, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { PictureListItem } from '../../interfaces/PictureListItem';
import { GetPicture } from '../../helpers/GetFromRestApi';
import Axios, { CancelTokenSource } from 'axios';
import { hasValues } from '../../helpers/MiscFunctions';

export interface PicturePreviewPanelProps {
	onDismiss: () => void;
	//changeTextBox: (myTag: string, myValue: string) => void;
	pictureItem: PictureListItem;
	accessToken: string,
	// apiUri: string,
	domain: string
	api: string
}

export interface PicturePreviewPanelState {
	// pictureItem: PictureListItem;
	showPreviewModal: boolean;
	smallPreview: string,
	bigPreview: string,
	fullSize: string,
	loaded: string,
}

const stackTokens = { childrenGap: 8 };

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class PicturePreviewPanel extends React.Component<PicturePreviewPanelProps, PicturePreviewPanelState> {
	constructor(props: PicturePreviewPanelProps) {
		super(props);
		this.state = {
			// pictureItem: {} as PictureListItem,
			showPreviewModal: false,
			smallPreview: '',
			bigPreview: '',
			fullSize: '',
			loaded: 'notLoaded',
		}
	}

	getImages = async () => {
		ourRequest = Axios.CancelToken.source()
		this.setState({ loaded: 'loadingInProgress' })
		let smallPreview = 'data:image/' + (this.props.pictureItem.imgType === '.png' ? 'png' : 'jpeg') + ';base64,' + this.props.pictureItem.previewBase64
		let bigPreview = 'data:image/' + (this.props.pictureItem.imgType === '.png' ? 'png' : 'jpeg') + ';base64,' + await GetPicture(this.props.accessToken, this.props.domain, this.props.api, this.props.pictureItem.id, ourRequest.token, 'big')
		let fullSize = 'data:image/' + (this.props.pictureItem.imgType === '.png' ? 'png' : 'jpeg') + ';base64,' + await GetPicture(this.props.accessToken, this.props.domain, this.props.api, this.props.pictureItem.id, ourRequest.token)
		this.setState({
			smallPreview: smallPreview,
			bigPreview: bigPreview,
			fullSize: fullSize,
			loaded: 'loaded'
		})
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
			this.setState({ loaded: 'notLoaded' })
		} catch (error) {

		}
	}

	componentDidUpdate(_prevProps: Readonly<PicturePreviewPanelProps>, _prevState: Readonly<PicturePreviewPanelState>, _snapshot?: any): void {
		if (_prevProps.pictureItem !== this.props.pictureItem && this.props.pictureItem.id) {
			if (hasValues(ourRequest)) {
				ourRequest.cancel()
				this.setState({ loaded: 'notLoaded' })
			}
			this.getImages()
		}
	}

	componentDidMount(): void {
		if (this.props.pictureItem.id) {
			this.getImages()
		}
	}

	render() {

		return (
			<>
				<Panel
					headerText={this.props.pictureItem.imgName}
					isBlocking={false}
					isLightDismiss={true}
					allowTouchBodyScroll={true}
					isOpen={true}
					onDismiss={this.props.onDismiss}
					onChange={() => console.log('panelchange')}
					type={PanelType.medium}
					// You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
					closeButtonAriaLabel='Close'
				>
					<p>{this.props.pictureItem.imgDescription}</p>
					{(this.state.loaded === 'loaded') &&
						<Stack tokens={stackTokens} style={{ paddingLeft: 15, paddingRight: 15 }}>
							<Stack.Item>
								<h3>Preview Small</h3>

								<Image src={this.state.smallPreview} alt={this.props.pictureItem.imgDescription} />
							</Stack.Item>
							<Stack.Item>
								<h3>Preview Big</h3>
								<Image src={this.state.bigPreview} alt={this.props.pictureItem.imgDescription} />
							</Stack.Item>
							<Stack.Item>
								<h3>Full size ( {this.props.pictureItem.imageWidth} x {this.props.pictureItem.imageHeight} )</h3>
								<Image src={this.state.fullSize} alt={this.props.pictureItem.imgDescription} onClick={(_event) => { this.setState({ showPreviewModal: true }) }} />
							</Stack.Item>
						</Stack>
					}
					{(this.state.loaded === 'loadingInProgress') &&
						<Spinner className='spinner' size={SpinnerSize.large} label='Loading previews...' />
					}
				</Panel>

				<Modal
					titleAriaId='Image preview'
					isOpen={this.state.showPreviewModal}
					onDismiss={(_event) => this.setState({ showPreviewModal: false })}
					isBlocking={false}
					allowTouchBodyScroll={true}
				>
					<Stack grow horizontal styles={{ root: { padding: '12px 12px 14px 24px' } }}>
						<Stack.Item grow align='stretch'>
							<Stack>
								<span style={{ fontSize: 24, fontWeight: 'bold', marginBottom: '10px' }}>{this.props.pictureItem.imgName}</span>
								<span>{this.props.pictureItem.imgDescription}</span>
							</Stack>
						</Stack.Item>
						<Stack.Item align='start'>
							<IconButton
								iconProps={{ iconName: 'Cancel' }}
								styles={{ root: { marginLeft: 'auto', marginRight: '2px', marginTop: '4px' } }}
								ariaLabel="Close popup modal"
								onClick={() => this.setState({ showPreviewModal: false })}
							/>
						</Stack.Item>
					</Stack>
					<div style={{ padding: '20px' }}>
						<Image src={this.state.fullSize} width='100%' height='100%' alt={this.props.pictureItem.imgDescription} onClick={(_event) => { this.setState({ showPreviewModal: false }) }} />
					</div>
				</Modal>
			</>

		);
	}
}