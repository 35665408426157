import * as React from 'react';
import { Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import { GetEmailSignaturePictures, GetEmailSignatures } from '../../helpers/GetFromRestApi';
import Axios from 'axios';
import { EmailSignature, exampleEmailSignature } from '../../interfaces/EmailSignature';
import { EmbeddedPictureItem } from '../../interfaces/EmbeddedPictureItem';
import { deleteEmailsignature, uploadEmailsignature } from '../../helpers/PostToRestApi';
import EmailSignaturesList from './EmailSignaturesList';
import EmailSignaturesPreview from './EmailSignaturesPreview';
import EmailSignaturesPictures from './EmailSignaturesPictures';
import { ContentFrame } from '../common/ContentFrame';
import { UploadItem } from '../../interfaces/UploadItem';
import { DialogOkAbort } from '../dialogs/DialogOkAbort';
import { DialogEditSignature } from '../dialogs/DialogEditSignature';
import { AuthContext } from '../../contexts/AuthContext';
import { TenantContext } from '../../contexts/ContextWrapper';

export interface EmailSignaturesProps {
}

const EmailSignatures: React.FC<EmailSignaturesProps> = () => {
	// Define the state using the React.useState hook
	// const [customDataItem, setCustomDataItem] = React.useState<CustomDataPlainListItem[]>([]);
	const [signatureItems, setSignatureItems] = React.useState<EmailSignature[]>([]);
	const [selectedSignature, setSelectedSignature] = React.useState<EmailSignature>(exampleEmailSignature);
	const [embeddedPictureItems, setEmbeddedPictureItems] = React.useState<EmbeddedPictureItem[]>([]);
	const [uploadSignature, setUploadSignature] = React.useState<UploadItem>({ inProgress: false, id: '', name: '', data: '' });
	const [deletedSignature, setDeletedSignature] = React.useState<UploadItem>({ inProgress: false, id: '', name: '' });
	// const [uploadPicture, setUploadPicture] = React.useState<UploadItem>({ inProgress: false, id: '', name: '', data: '' });
	// const [deletePicture, setDeletePicture] = React.useState<UploadItem>({ inProgress: false, id: '', name: '' });
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const { accessToken } = React.useContext(AuthContext)
	const { tenantContext } = React.useContext(TenantContext)

	let ourRequest = Axios.CancelToken.source();

	const getSignatures = async () => {
		ourRequest = Axios.CancelToken.source()
		let signatures: EmailSignature[] = await GetEmailSignatures(accessToken!, tenantContext.tenantName, tenantContext.api, ourRequest.token)
		if (!signatures) { signatures = [exampleEmailSignature] }
		setSignatureItems(signatures)
		setSelectedSignature(signatures![0])
	}

	//signature: EmailSignature
	const saveSignature = (item: UploadItem) => {
		ourRequest = Axios.CancelToken.source()
		console.log(item)
		uploadEmailsignature(accessToken!, tenantContext.tenantName, tenantContext.api, item.data!, ourRequest.token)
			.then((res) => {
				console.log(res.data)
				if (!res.data) { res.data = [exampleEmailSignature] }
				setUploadSignature({ inProgress: false, id: '', name: '', data: '' })
				setSignatureItems(res.data)
			})
	}

	const deleteSignature = (item: UploadItem) => {
		ourRequest = Axios.CancelToken.source()
		deleteEmailsignature(accessToken!, tenantContext.tenantName, tenantContext.api, item.name, ourRequest.token)
			.then(() => {
				setUploadSignature({ inProgress: false, id: '', name: '' })
				getSignatures()
			})
	}

	const getEmbeddedPictures = async () => {
		ourRequest = Axios.CancelToken.source()
		const pictures: EmbeddedPictureItem[] = await GetEmailSignaturePictures(accessToken!, tenantContext.tenantName, tenantContext.api, ourRequest.token)
		setEmbeddedPictureItems(pictures)
	}

	// const getCustomData = async () => {
	// 	ourRequest = Axios.CancelToken.source()
	// 	// let CustomDataDef: CustomDataDefItem = await GetCustomDataDef(this.props.accessToken!, this.props.domain,this.props.api, JSON.stringify([{ groupID: '123' }]), 'ALL-ICONS', ourRequest.token)
	// 	let CustomData: CustomDataPlainListItem[] = await GetCustomPlainList(accessToken!, tenantContext.tenantName, tenantContext.api, ourRequest.token)
	// 	console.log(CustomData)
	// 	if (!!CustomData) {
	// 		setCustomDataItem(JSON.parse(JSON.stringify(CustomData)))
	// 	}
	// }

	const updateSignatureItem = (key: string, selectedItem: EmailSignature) => {
		switch (key) {
			case 'new':
				setSelectedSignature(selectedItem)
				setUploadSignature({ ...uploadSignature, id: '', name: '', data: '', inProgress: true })
				break;
			case 'edit':
				setSelectedSignature(selectedItem)
				setUploadSignature({ ...uploadSignature, id: selectedItem.SignatureName, name: selectedItem.SignatureName, data: JSON.stringify(selectedItem)!, inProgress: true })
				break;
			case 'upload':
				setSelectedSignature(selectedItem)
				setUploadSignature({ ...uploadSignature, id: selectedItem.SignatureName, name: selectedItem.SignatureName, data: JSON.stringify(selectedItem)!, inProgress: true })
				break;
			case 'delete':
				setSelectedSignature(selectedItem)
				setDeletedSignature({ ...deletedSignature, id: selectedItem.SignatureName, name: selectedItem.SignatureName, inProgress: true })
				break;
			default:
				break;
		}
	}

	// const updatePictureItem = (key: string, selectedItem: EmbeddedPictureItem) => {
	// 	switch (key) {
	// 		case 'upload':
	// 			setUploadPicture({ ...uploadPicture, id: selectedItem.PictureName, name: selectedItem.PictureName, data: selectedItem.Base64, inProgress: true })
	// 			break;
	// 		case 'delete':
	// 			setUploadPicture({ ...uploadPicture, id: selectedItem.PictureName, name: selectedItem.PictureName, inProgress: true })
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// }


	const getAllData = () => {
		setIsLoading(true)
		// this.setState({ isLoading: true })
		Promise.all([
			// getCustomData(),
			getSignatures(),
			getEmbeddedPictures(),
		])
			.finally(() => {
				setIsLoading(false)
			})
	}

	// componentDidUpdate(prevProps: EmailSignaturesProps, _prevState: EmailSignaturesState) {
	// 	if (prevProps.accessToken !== this.props.accessToken ||
	// 		prevProps.domain !== this.props.domain ||
	// 		prevProps.api !== this.props.api
	// 	) {
	// 		this.getAllData()
	// 	}
	// }

	// componentDidMount() {
	// 	this.getAllData()
	// }

	React.useEffect(() => {
		return () => {
			ourRequest.cancel()
		}
	}, [])

	React.useEffect(() => {
		getAllData()
	}, [accessToken, tenantContext.tenantName, tenantContext.api])

	if (isLoading) {
		return (
			<Stack horizontalAlign='center' verticalAlign='center' styles={{ root: { margin: '30px', height: '100%' } }}>
				<Spinner className='spinner' size={SpinnerSize.large} label='Loading email signatures...' />
			</Stack>
		)
	}

	return (
		<Stack >
			<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
				<Text variant="xxLarge">
					Email Signatures
				</Text>
			</Stack.Item>
			<ContentFrame>
				<EmailSignaturesList
					// setSelectedItem={(signature) => this.setState({ selectedSignature: signature })}
					setSelectedItem={(signature) => setSelectedSignature(signature)}
					onCommandItemClick={updateSignatureItem}
					selectedItem={selectedSignature}
					settings={tenantContext.settings}
					signatureItems={signatureItems!}
				/>
			</ContentFrame>
			<ContentFrame>
				<EmailSignaturesPreview
					// saveSignatures={(signature) => this.updateSignatureItem('upload', signature)}
					saveSignatures={(signature) => updateSignatureItem('upload', signature)}
					signature={selectedSignature!}
					embeddedPictureItems={embeddedPictureItems!}
				/>
			</ContentFrame>
			<ContentFrame>
				<EmailSignaturesPictures
					getEmbeddedPictures={getEmbeddedPictures}
					embeddedPictureItems={embeddedPictureItems!}
					accessToken={accessToken!}
					domain={tenantContext.tenantName}
					api={tenantContext.api}
				/>
			</ContentFrame>
			<DialogOkAbort
				callbackOk={() => { deleteSignature(deletedSignature) }}
				callbackAbort={() => { setDeletedSignature({ ...deletedSignature, inProgress: false, name: '', id: '' }) }}
				title='Delete Content Folder?'
				description={`Do you want to delete the signature ${deletedSignature.name}?`}
				hidden={!deletedSignature.inProgress}
			/>
			<DialogEditSignature
				callbackOk={(signature) => { saveSignature({ ...uploadSignature, id: signature.SignatureName, name: signature.SignatureName, data: JSON.stringify(signature) }) }}
				callbackAbort={() => { setUploadSignature({ ...uploadSignature, inProgress: false, name: '', id: '', data: '' }) }}
				hidden={!uploadSignature.inProgress}
				hideState='hideNewDialog'
				confirmState='newConfirmed'
				newSignatureItem='newSignatureItem'
				selectedItem={selectedSignature}
				isNew={!uploadSignature.id}
			/>
		</Stack >
	)
}

export default EmailSignatures;