import * as React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface HTMLEditorProps {
    html: string,
    setHtml: (html: string) => void
}

const HTMLEditor: React.FC<HTMLEditorProps> = (props) => {

    const modules = {
        toolbar: [
            [{ 'header': [false, 2, 3] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }],
            ['link'],
            ['clean']
        ],
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    return (
        <ReactQuill
            defaultValue={props.html}
            modules={modules}
            formats={formats}
            onChange={(_content, _delta, _source, _editor) => {
                // console.log(_content, _delta, _source, _editor)
                props.setHtml(_editor.getHTML().replace(/<p><br><\/p>/g, '').replace(/<p><\/p>/g, ''))
            }
            }
        />
    );
}

export default HTMLEditor;