import React from 'react';
import { Stack, TextField, Toggle, Text } from '@fluentui/react';
import SettingsCompany from '../../helpers/SettingsCompany';
import HeaderPreview from './SettingsHeaderPreview';

export interface SettingsBrandingProps {
	//   setParentState: (x: SettingsState) => void;
	setEditedSettings: (x: SettingsCompany) => void;
	editedSettings: SettingsCompany;
	companyLogo: string;
}

export default function SettingsBranding(props: SettingsBrandingProps) {

	const onChangeSettings = (settings: SettingsCompany): void => {
		// props.setParentState({ editedSettings: settings });
		props.setEditedSettings(settings);
	};

	return (
		<Stack horizontal wrap tokens={{ childrenGap: '20px' }}>
			{typeof props.editedSettings.Caption !== 'undefined' ? (
				<Stack >
					<Stack horizontal className="SettingsStack">
						<Stack.Item align="center" className="SettingsStackCaption">English caption</Stack.Item>
						<Stack.Item align="center">
							<TextField
								aria-label="English caption"
								value={props.editedSettings.Caption[0]}
								styles={{ root: { maxWidth: '400px' } }}
								// onChange={(_event, value) => {
								// 	props.editedSettings.Caption[0] = value;
								// 	setState({ editedSettings: props.editedSettings });
								// }}
								onChange={(_ev, value) => { onChangeSettings(Object.assign(props.editedSettings, { Caption: [value, props.editedSettings.Caption[1]] })) }}
							/>
						</Stack.Item>
					</Stack>
					<Stack horizontal className="SettingsStack">
						<Stack.Item align="center" className="SettingsStackCaption">Swedish caption</Stack.Item>
						<Stack.Item align="center">
							<TextField
								aria-label="Swedish caption"
								value={props.editedSettings.Caption[1]}
								styles={{ root: { maxWidth: '400px' } }}
								// onChange={(_event, value) => {
								// 	props.editedSettings.Caption[1] = value!;
								// 	setState({ editedSettings: props.editedSettings });
								// }}
								onChange={(_ev, value) => { onChangeSettings(Object.assign(props.editedSettings, { Caption: [props.editedSettings.Caption[0], value] })) }}
							/>
						</Stack.Item>
					</Stack>
					<Stack horizontal className="SettingsStack">
						<Stack.Item align="center" className="SettingsStackCaption">Show Logo</Stack.Item>
						<Stack.Item align="center">
							<Toggle
								aria-label="Show Logo"
								id="showLogo"
								onText="On"
								offText="Off"
								checked={!props.editedSettings.hideLogo}
								onChange={(_ev, checked) => { onChangeSettings(Object.assign(props.editedSettings, { hideLogo: !checked })) }}
							/>
						</Stack.Item>
					</Stack>
					<Stack horizontal className="SettingsStack">
						<Stack.Item align="center" className="SettingsStackCaption">Show Caption</Stack.Item>
						<Stack.Item align="center">
							<Toggle
								aria-label="Show Caption"
								id="showCaption"
								onText="On"
								offText="Off"
								checked={!props.editedSettings.hideCaption}
								onChange={(_ev, checked) => { onChangeSettings(Object.assign(props.editedSettings, { hideCaption: !checked })) }}
							/>
						</Stack.Item>
					</Stack>
				</Stack>
			) : null}
			{props.editedSettings.Caption ? (
				<Stack tokens={{ childrenGap: 10 }}>
					<HeaderPreview
						settings={props.editedSettings}
						logo={props.companyLogo}
						title={props.editedSettings.Caption[0]}
					/>
					{props.editedSettings.hideCaption ? (
						<Text variant='mediumPlus'>Preview Addin Header</Text>
					) : (
						<>
							<Text variant='mediumPlus'>Preview Addin Header (English)</Text>
							<HeaderPreview
								settings={props.editedSettings}
								logo={props.companyLogo}
								title={props.editedSettings.Caption[1]}
							/>
							<Text variant='mediumPlus'>Preview Addin Header (Swedish)</Text>
						</>
					)}
				</Stack>
			) : null}
		</Stack>
	);
}
