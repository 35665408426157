import * as React from 'react';
import {
	CheckboxVisibility,
	CommandBar,
	DetailsListLayoutMode,
	Facepile,
	IButtonProps,
	IColumn,
	ICommandBarItemProps,
	Icon,
	IconButton,
	IFacepilePersona,
	mergeStyleSets,
	Modal,
	OverflowButtonType,
	Persona,
	PersonaSize,
	ShimmeredDetailsList,
	Stack,
	TooltipHost,
} from '@fluentui/react';
import SettingsCompany, { GetUseGroups } from '../../helpers/SettingsCompany';
import { sortArray } from '../../helpers/MiscFunctions';
import { IconListItem } from '../../interfaces/IconListItem';
import { GroupInfo } from '../../interfaces/GroupInfo';
import { GroupID } from '../../interfaces/GroupID';
import { useEffect, useState } from 'react';
import EncapsulatedSVG from '../common/EncapsultedSVG';

export interface IconsDetailsProps {
	iconItems: IconListItem[];
	iconWidth: number,
	iconHeight: number,
	selectedCategory: string;
	settings: SettingsCompany;
	groupIds: GroupInfo[];
}

const classNames = mergeStyleSets({
	fileIconHeaderIcon: {
		padding: 0,
		fontSize: '16px',
	},
	fileIconCell: {
		textAlign: 'center',
		selectors: {
			'&:before': {
				content: '.',
				display: 'inline-block',
				verticalAlign: 'middle',
				height: '100%',
				width: '0px',
				visibility: 'hidden',
			},
		},
	},
	fileIconImg: {
		verticalAlign: 'middle',
		maxHeight: '16px',
		maxWidth: '16px',
	},
	controlWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	exampleToggle: {
		display: 'inline-block',
		marginBottom: '10px',
		marginRight: '30px',
	},
	selectionDetails: {
		marginBottom: '20px',
	},
});


const IconsDetails: React.FC<IconsDetailsProps> = (props) => {
	const [iconItems, setIconItems] = useState<IconListItem[]>([]);
	const [columns, setColumns] = useState<IColumn[]>([{ key: 'column1', name: '', minWidth: 16 }]);
	const [selectedIcon, setSelectedIcon] = useState<IconListItem | null>(null);
	const [showList, setShowList] = useState(false);
	const [showPreviewModal, setShowPreviewModal] = useState(false);

	const commandItems = (): ICommandBarItemProps[] => {
		return [
			{
				key: 'view',
				text: showList ? 'List view' : 'Preview',
				ariaLabel: showList ? 'List view' : 'Preview',
				iconProps: { iconName: showList ? 'GroupedList' : 'Tiles' },
				subMenuProps: {
					items: [
						{
							key: 'list',
							text: 'List view',
							label: 'List view',
							iconProps: { iconName: 'GroupedList' },
							onClick: () => setShowList(true),
						},
						{
							key: 'tile',
							text: 'Preview',
							label: 'Preview',
							iconProps: { iconName: 'Tiles' },
							onClick: () => setShowList(false),
						},
					]
				}
			},
		]
	};

	const getColumns = (): IColumn[] => {
		return [
			{
				key: 'column1',
				name: 'Icon',
				fieldName: 'id',
				className: classNames.fileIconCell,
				iconClassName: classNames.fileIconHeaderIcon,
				ariaLabel: 'Column operations for File type, Press to sort on File type',
				iconName: 'FileImage',
				isIconOnly: true,
				minWidth: 16,
				maxWidth: 16,
				isResizable: false,
				onColumnClick: onColumnClick,
				onRender: (item: IconListItem) => {
					return (<Stack style={{ height: '16px' }} ><img width='100%' height='100%' src={`data:image/svg+xml,${encodeURIComponent(item.ImageString.substring(1, item.ImageString.length - 1))}`} alt={item.iconName + ' file icon'} /></Stack>)
				},
			},
			{ key: 'column2', name: 'Name', fieldName: 'iconName', minWidth: 200, maxWidth: 300, isResizable: true, onColumnClick: onColumnClick, },
			{ key: 'column3', name: 'Description', fieldName: 'iconDescription', minWidth: 250, maxWidth: 300, isResizable: true, onColumnClick: onColumnClick, },
			...(GetUseGroups(props.settings) || (props.iconItems.find((icon) => icon.libraryName === props.selectedCategory)?.iconGroups!?.length > 0)) ? [{
				key: 'column4',
				name: 'Groups',
				fieldName: 'iconGroups',
				minWidth: 150,
				maxWidth: 300,
				isResizable: true,
				onColumnClick: onColumnClick,
				onRender: (item: IconListItem) => {
					if (item.iconGroups && !!props.groupIds) {
						const myGroups: GroupInfo[] = props.groupIds.filter((group: GroupInfo) => {
							return item.iconGroups?.filter((icongroup: GroupID) => {
								return group?.groupID === icongroup.groupID
							}).length! > 0
						})
						// const groups: IFacepilePersona[] = item.templateGroups?.map((groupItem: GroupID) => {return {personaName: groupItem.groupID, label: '',  }});
						const groups: IFacepilePersona[] = myGroups.map((groupItem: GroupInfo) => { return { personaName: groupItem.groupName, label: '', } });
						if (GetUseGroups(props.settings)) {
							return (
								<Facepile
									personas={groups}
									personaSize={PersonaSize.size24}
									maxDisplayablePersonas={3}
									overflowButtonType={OverflowButtonType.descriptive}
									overflowButtonProps={{ ariaLabel: 'More' } as IButtonProps}
									ariaDescription={'To move through the items use left and right arrow keys.'}
									ariaLabel={'List of folder´s groups'}
									onRenderPersona={(item: IFacepilePersona | undefined) => {
										return (
											<Persona key={item?.personaName} text={item?.personaName} size={PersonaSize.size24} hidePersonaDetails={true} />
										)
									}}
								/>
							)
						}
						else if (groups.length > 0) {
							return (
								<TooltipHost
									content={<>This library is connected to a group. <b>Microsoft 365 Groups</b> needs to be enabled by an <b>Administrator</b> in <b>Settings</b>. The icons in this category is not accessible for any user.</>}
								>
									<Icon styles={{ root: { color: '#a80000' } }} iconName='BlockedSolid' />
								</TooltipHost>

							)
						}
						else { return null }
					}
					else { return null }
				},
			}] : [],
		]
	};

	useEffect(() => {
		setColumns(getColumns());
		setIconItems(props.iconItems.filter((obj) => obj.libraryName === props.selectedCategory));
	}, [props.selectedCategory, props.iconItems, props.groupIds]);

	const onItemInvoked = (item?: IconListItem): void => {
		if (item) {
			setShowPreviewModal(true);
			setSelectedIcon(item);
		}
	};

	const onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
		const newColumns: IColumn[] = [...columns];
		const currColumn: IColumn = newColumns.find((col) => col.key === column.key)!;
		currColumn.isSorted = !currColumn.isSorted;
		const newItems = sortArray(iconItems, currColumn.fieldName!, currColumn.isSortedDescending);
		setColumns(newColumns);
		setIconItems(newItems);
	};

	if (props.iconItems[0] === null) {
		return (<></>)
	}

	let IconsRender: JSX.Element = (<></>)

	if (showList) {
		IconsRender = (
			<ShimmeredDetailsList
				items={iconItems}
				columns={columns}
				enableShimmer={!iconItems}
				setKey="set"
				layoutMode={DetailsListLayoutMode.justified}
				selectionPreservedOnEmptyClick={true}
				ariaLabelForSelectionColumn="Toggle selection"
				ariaLabelForSelectAllCheckbox="Toggle selection for all items"
				checkButtonAriaLabel="Row checkbox"
				checkboxVisibility={CheckboxVisibility.always}
				onItemInvoked={onItemInvoked}
			/>
		)
	}
	else {
		IconsRender = (
			<Stack horizontal wrap tokens={{ childrenGap: 15 }} styles={{ root: { margin: '20px' } }}>
				{iconItems.map((item) => {
					return (
						<Stack.Item key={item.id} styles={{ root: { padding: '5px' } }}>
							<IconButton
								ariaLabel={item.iconName}
								styles={{ flexContainer: { alignItems: 'normal' } }}
								style={{ width: props.iconWidth, height: props.iconHeight, }}
								onClick={() => {
									setShowPreviewModal(true);
									setSelectedIcon(item);
								}}
							>
								<EncapsulatedSVG>
									<img width='100%' height='100%' src={`data:image/svg+xml,${encodeURIComponent(item.ImageString.substring(1, item.ImageString.length - 1))}`} alt={item.iconName} />
								</EncapsulatedSVG>
							</IconButton>

						</Stack.Item>
					)
				})}
			</Stack>
		)
	}


	return (
		<Stack className='Width100'>
			<span className='Indent20'><h3>Icon Details</h3></span>
			<CommandBar
				items={commandItems()}
				ariaLabel="Use left and right arrow keys to navigate between commands"
			/>
			{IconsRender}
			{showPreviewModal && selectedIcon ?
				<Modal
					titleAriaId='Image preview'
					isOpen={showPreviewModal}
					onDismiss={(_event) => setShowPreviewModal(false)}
					isBlocking={false}
					allowTouchBodyScroll={true}
					styles={{ root: { padding: 20 } }}
				>
					<Stack grow horizontal styles={{ root: { padding: '12px 12px 14px 24px' } }}>
						<Stack.Item grow align='stretch'>
							<Stack>
								<Stack.Item style={{ fontSize: 24, fontWeight: 'bold', marginBottom: '10px' }}>{selectedIcon.iconName}</Stack.Item>
								<Stack.Item>{selectedIcon!?.iconDescription}</Stack.Item>
							</Stack>
						</Stack.Item>
						<Stack.Item align='start'>
							<IconButton
								iconProps={{ iconName: 'Cancel' }}
								styles={{ root: { marginLeft: 'auto', marginRight: '2px', marginTop: '4px' } }}
								ariaLabel="Close popup modal"
								onClick={() => setShowPreviewModal(false)}
							/>
						</Stack.Item>
					</Stack>
					<Stack style={{ padding: '20px' }}>
						<EncapsulatedSVG>
							<img width='100%' height='100%' src={`data:image/svg+xml,${encodeURIComponent(selectedIcon.ImageString.substring(1, selectedIcon.ImageString.length - 1))}`} alt={selectedIcon.iconName} />
						</EncapsulatedSVG>
					</Stack>
				</Modal>
				: null}
		</Stack>
	);
}

export default IconsDetails;
