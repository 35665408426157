import { MotionAnimations, MotionDurations } from "@fluentui/react"

export function getAnimStyleFrameUp(updating: boolean): any {
    if (updating) {
        return { root: { margin: '30px', animation: MotionAnimations.fadeOut, animationDuration: MotionDurations.duration3 } }
    }
    else {
        return { root: { margin: '30px', animation: MotionAnimations.slideUpIn, animationDuration: MotionDurations.duration3 } }
    }
}

export function getAnimStyleNavRight(updating: boolean): any {
    if (updating) {
        return { root: { animation: MotionAnimations.slideLeftOut, animationDuration: MotionDurations.duration3 } }
    }
    else {
        return { root: { animation: MotionAnimations.slideRightIn, animationDuration: MotionDurations.duration3 } }
    }
}