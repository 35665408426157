import React from "react";
import { ScrollablePane, Stack } from "@fluentui/react"
import NavigationButton from "./NavigationButton"
import NavigationPane from "./NavigationPane"

interface NavigationWrapperProps {
    setSelectedPage: (x: string) => void;
    selectedPage: string;
}

const NavigationWrapper: React.FC<NavigationWrapperProps> = (props) => {

    return (
        <Stack styles={{ root: { height: '100%' } }}>
            {/* Visible on small screens */}
            <Stack.Item className="ms-bgColor-white ms-hiddenXlUp" styles={{ root: { padding: '10px' } }}>
                <NavigationButton
                    selectPage={props.setSelectedPage}
                />
            </Stack.Item>
            <Stack.Item grow>

                <Stack horizontal styles={{ root: { height: '100%' } }}>
                    {/* Visible on large screens */}
                    <Stack.Item className="ms-Grid-col ms-xl2 ms-bgColor-white ms-hiddenLgDown" styles={{ root: { padding: '30px 0px', minWidth: '250px' } }}>
                        <ScrollablePane>
                            <NavigationPane
                                selectPage={props.setSelectedPage}
                                selectedPage={props.selectedPage!}
                            />
                        </ScrollablePane>
                    </Stack.Item>
                    <Stack.Item className="ms-Grid-col ms-sm12 ms-xl10 ms-bgColor-gray10"  >
                        <ScrollablePane>
                            {props.children}
                        </ScrollablePane>
                    </Stack.Item>
                </Stack>

            </Stack.Item >
        </Stack >
    )
}

export default NavigationWrapper