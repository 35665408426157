/* eslint-disable react/prop-types */
import * as React from "react"
import { ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, IComboBoxOption, PrimaryButton, Stack, TextField } from "@fluentui/react";

export interface DialogEditFolderProps {
	callbackOk: (name: string, pathId: string, rootPathId: string) => void
	callbackAbort: () => void
	hidden: boolean
	pathOptions: IComboBoxOption[]
	title?: string
	description?: string
	defaultName: string
	defaultId: string
	defaultParentId: string
	isFolder: boolean
}


export const DialogEditFolder: React.FunctionComponent<DialogEditFolderProps> = (props): JSX.Element => {

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: props.title,
		subText: props.description,
	};

	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 450 } },
	};

	const [name, setName] = React.useState<string>('')
	const [parentId, setParentId] = React.useState<string>('')
	const [illegalName, setIllegalName] = React.useState<boolean>(false)
	const [illegalFolder, setIllegalFolder] = React.useState<boolean>(false)

	React.useEffect(() => {
		setName(props.defaultName)
		setParentId(props.defaultParentId)
		setIllegalFolder(false)
		setIllegalName(false)
	}, [props])

	const changeName = (name: string ) =>  {
		setIllegalName(props.pathOptions.filter((val) => val.text.toLowerCase() === (props.pathOptions.find((val) => val.key === props.defaultParentId)?.text + '\\' + name).toLowerCase()).length > 0)
		setName(name) 
	}

	const changeOption = (option: IComboBoxOption ) =>  {
		setIllegalFolder(option.key.toString() === props.defaultId || option.text.startsWith(props.pathOptions.find((val) => val.key === props.defaultId)?.text + '\\'))		
		setParentId(option.key.toString())
	}

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.callbackAbort()}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<Stack tokens={{ childrenGap: '5px' }}>
				<TextField
					label={props.isFolder ? 'Folder name' : 'Name'}
					value={name}
					onChange={(_ev, value) => { changeName(value!) }}
					errorMessage={illegalName ? 'Not allowed' : ''}
				/>
				<ComboBox
					defaultSelectedKey={parentId}
					label={props.isFolder ?'Parent folder' :'Folder'}
					options={props.pathOptions}
					onChange={(_ev, option) => { changeOption(option!)}}
					errorMessage={illegalFolder ? 'Not allowed' : ''}
				// calloutProps={{ doNotLayer: true }}
				/>
			</Stack>
			<DialogFooter>
				<PrimaryButton disabled={illegalFolder || illegalName} onClick={() => props.callbackOk(name, props.defaultId, parentId)} text="Ok" />
				<DefaultButton onClick={() => props.callbackAbort()} text="Abort" />
			</DialogFooter>
		</Dialog>
	);
};