export interface PictureListItem {
    id: string;
    imgName: string;
    imgDescription?: string;
    imgPath: string;
    imgSrc: string;
    imgType: string;
    fileSize: string;
    fileSizeNum: number;
    libraryName: string;
    modifiedDate: string;
    previewBase64: string;
    previewWidth: number;
    previewHeight: number; 
    imageWidth: number;
    imageHeight: number;  
}

export const examplePictureList: PictureListItem[] = [{
	id: '-1',
	imgName: 'No items',
	imgPath: '',
	imgSrc: '',
	imgType: '',
	fileSize: '',
	fileSizeNum: 0,
	libraryName: '',
	modifiedDate: '',
	previewBase64: '',
	previewWidth: 0,
	previewHeight: 0, 
	imageWidth: 0,
	imageHeight: 0,  
}]