import { DefaultButton, Label, Separator, Stack, TextField } from '@fluentui/react';
import React from 'react';
import { NewsItem } from '../../interfaces/NewsItem';
import { htmlMenuProps } from '../../helpers/HtmlStyling';

interface NewsEditorProps {
    reset: () => void
    save: () => void
    editedItem: NewsItem
    setEditedItem: (editedItem: NewsItem) => void
    image: string
    setImage: (image: string) => void
}

const NewsEditor: React.FC<NewsEditorProps> = (props) => {

    // const [html, setHtml] = React.useState<string>(props.selectedItem?.Content!)
    const [cursorPosition, setCursorPosition] = React.useState({ start: 0, end: 0 })

    const setHtml = (html: string) => { props.setEditedItem({ ...props.editedItem!, Content: html }) }

    const insertTextAtSelection = (startText: string, endText?: string) => {
        setHtml(`${props.editedItem?.Content.substring(0, cursorPosition.start)}${startText}${props.editedItem?.Content.substring(cursorPosition.start, cursorPosition.end)}${endText ? endText : ''}${props.editedItem?.Content.substring(cursorPosition.end, props.editedItem?.Content.length)}`)
        setCursorPosition({ start: cursorPosition.start + startText.length, end: cursorPosition.end + startText.length })
    }

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                props.setImage(reader.result!?.toString())
            };
            reader.readAsDataURL(file); // Convert the image to Base64
        }
    };

    const uploadImageClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/jpeg, image/png, image/gif';
        fileInput.onchange = handleFileChange;
        fileInput.click();
    };

    return (
        <Stack className='Width100' styles={{ root: { paddingLeft: '20px', paddingRight: '20px' } }} tokens={{ childrenGap: '5px' }}>
            <span><h3>HTML</h3></span>
            <TextField
                label='Title'
                styles={{ root: { width: '100%' } }}
                value={props.editedItem?.Title}
                onChange={(_event, value) => props.setEditedItem({ ...props.editedItem!, Title: value! })}
            />
            <TextField
                label='Link'
                styles={{ root: { width: '100%' } }}
                prefix='https://'
                value={props.editedItem!?.Link!?.replace('https://', '')}
                onChange={(_event, value) => props.setEditedItem({ ...props.editedItem!, Link: `${value!}` })}
            />
            <TextField
                label='Body'
                styles={{ root: { width: '100%' }, field: { fontFamily: 'Courier New' } }}
                value={props.editedItem?.Content}
                onChange={(event, value) => {
                    setHtml(value!)
                    setCursorPosition({ start: event.currentTarget.selectionStart!, end: event.currentTarget.selectionEnd! })
                }}
                onClick={(event) => {
                    setCursorPosition({ start: event.currentTarget.selectionStart!, end: event.currentTarget.selectionEnd! })
                }}
                onKeyUp={(event) => {
                    setCursorPosition({ start: event.currentTarget.selectionStart!, end: event.currentTarget.selectionEnd! })
                }}
                multiline
                rows={10}
            />
            <Stack horizontal wrap tokens={{ childrenGap: '5px' }}>
                <DefaultButton
                    primary
                    text='Save'
                    onClick={() => props.save()}
                />
                <DefaultButton
                    text='Reset'
                    // onClick={() => this.setState({ html: pretty(this.props.signature.HTML, { ocd: true }) })}
                    onClick={() => props.reset()}
                />
                <DefaultButton
                    text='Insert HTML'
                    menuProps={htmlMenuProps(insertTextAtSelection)}
                />
            </Stack>
            <Label>Image</Label>
            <Stack horizontal wrap tokens={{ childrenGap: '5px' }}>
                <DefaultButton
                    text='Upload Image'
                    // onClick={() => this.setState({ html: pretty(this.props.signature.HTML, { ocd: true }) })}
                    onClick={() => uploadImageClick()}
                />
                <DefaultButton
                    text='Remove Image'
                    disabled={!props.image}
                    onClick={() => props.setImage('')}
                />
            </Stack>
            {/* &nbsp; */}
            <Separator key='sepPreview' />
        </Stack>
    )
}

export default NewsEditor;