
import * as React from  'react';
import { Stack, Text } from '@fluentui/react';
import AccountsList from './AccountsList';
import { UserAdminItem } from '../../interfaces/UserAdminItem';
import { GetAllUsers, GetUser } from '../../helpers/GetFromRestApi';
import Axios, { CancelTokenSource } from 'axios';
import SettingsCompany from '../../helpers/SettingsCompany';
import { ContentFrame } from '../common/ContentFrame';
import { MessageContextType } from '../../interfaces/ContextType';

export interface AccountsProps {
    setMessage: (x: MessageContextType) => void;
    setUserData: (x: UserAdminItem) => void;
    userData: UserAdminItem;
    accessToken: string;
    domain: string;
    api: string;
    canChangeTenant: boolean;
    settings: SettingsCompany;
}

const Accounts: React.FC<AccountsProps> = ({
    setMessage,
    setUserData,
    userData,
    accessToken,
    domain,
    api,
    canChangeTenant,
    settings,
}) => {
    const [users, setUsers] = React.useState<UserAdminItem[]>([]);
    const ourRequest = React.useRef<CancelTokenSource>(Axios.CancelToken.source());

    const updateUsers = async () => {
        ourRequest.current = Axios.CancelToken.source();
        const allUsers = await GetAllUsers(accessToken,false, ourRequest.current.token);
        setUsers(allUsers);
        const user = await GetUser(accessToken, '', ourRequest.current.token);
        setUserData(user);
    };

    React.useEffect(() => {
        if (accessToken) {
            updateUsers();
        }
        return () => {
            try {
                ourRequest.current.cancel();
            } catch (error) {
                // Handle cancellation error if necessary
            }
        };
    }, [accessToken]);

    React.useEffect(() => {
        if (userData?.changeTenantDomain) {
            updateUsers();
        }
    }, [userData?.changeTenantDomain]);

    return (
        <Stack>
            <Stack.Item className="" styles={{ root: { margin: '30px' } }}>
                <Text variant="xxLarge">Accounts</Text>
            </Stack.Item>
            <ContentFrame>
                <AccountsList
                    setMessage={setMessage}
                    updateUsers={updateUsers}
                    accesstoken={accessToken}
                    userData={userData}
                    users={users}
                    domain={domain}
                    api={api}
                    canChangeTenant={canChangeTenant}
                    settings={settings}
                />
            </ContentFrame>
        </Stack>
    );
};

export default Accounts;
