import * as React from 'react';
import ReactDOM from 'react-dom';

const EncapsulatedSVG: React.FC = ({ children }) => {
    const containerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
    if (containerRef.current) {
        let shadowRoot = containerRef.current.shadowRoot;
        if (!shadowRoot) {
            shadowRoot = containerRef.current.attachShadow({ mode: 'open' });
        } else {
            while (shadowRoot.firstChild) {
                shadowRoot.removeChild(shadowRoot.firstChild);
            }
        }
        const shadowContainer = document.createElement('div');
        const style = document.createElement('style');
        style.textContent = `
            div {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: normal;
            }
            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        `;
        shadowRoot.appendChild(style);
        shadowRoot.appendChild(shadowContainer);
        ReactDOM.render(<>{children}</>, shadowContainer);
    }
}, [children]);

    return <div ref={containerRef}></div>;
};

export default EncapsulatedSVG;
