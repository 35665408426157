import * as React from "react"
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react"

export const DialogDelete: React.FunctionComponent<{setParentState: (x: any) => void, hideState: string, confirmState: string, hidden: boolean, filename: string, isPublished?: boolean}> = (props) => {
  
	if (props.hidden) {
		return (<></>)
	}

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: 'Delete' + (props.isPublished ? ' (Published)' : ''),
		subText: `Are you sure you want to delete the selected${props.filename}?`,
	};
	
	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 450 } },
	};

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.setParentState({[props.hideState]: true})}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<DialogFooter>
				<PrimaryButton onClick={() => props.setParentState({[props.confirmState]: true, [props.hideState]: true})} text="Delete" />
				<DefaultButton onClick={() => props.setParentState({[props.hideState]: true})} text="Cancel" />
			</DialogFooter>
		</Dialog>
	);
};