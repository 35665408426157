import { GroupID } from "./GroupID";

export interface TemplateFolderItem {
	Root?: string;
	Path: string;
	primaryText: string;
	Publish?: boolean;
	Library?: string,
	ModifiedDate: string;
	Templates: any;
	Folders: TemplateFolderItem[];
	hideOnline?: boolean;
	hideDesktop?: boolean;
	templateGroups?: GroupID[] | undefined;  
}

export const exampleFolderItem: TemplateFolderItem[] = [{
	primaryText: 'No items',
	Path: '',
	Publish: true,
	ModifiedDate: '',
	Templates: null,
	Folders: [],
}]