import * as React from 'react';
import pretty from 'pretty';
import {
	DefaultButton,
	IconButton,
	IContextualMenuItem,
	IContextualMenuProps,
	Image,
	Separator,
	Stack,
	Text,
	TextField,
} from '@fluentui/react';
import * as DOMPurify from 'dompurify';
import { EmailSignature } from '../../interfaces/EmailSignature';
import { EmbeddedPictureItem } from '../../interfaces/EmbeddedPictureItem';
import { GetUseCustomData, GetUseUserInformation } from '../../helpers/SettingsCompany';
import { DialogOkAbort } from '../dialogs/DialogOkAbort';
import { aadFieldsMenuProps, buttonStyles, customFieldsMenuProps } from '../../helpers/HtmlStyling';
import { useCustomData } from '../../hooks/useCustomData';
import { useReplace } from '../../hooks/useReplace';
import { useUserData } from '../../hooks/useUserData';
import HtmlButtons from '../common/HtmlButtons';
import { TenantContext } from '../../contexts/ContextWrapper';

export interface EmailSignaturesPreviewProps {
	saveSignatures: (signature: EmailSignature) => void
	signature: EmailSignature
	embeddedPictureItems: EmbeddedPictureItem[]
}

// export interface EmailSignaturesPreviewState {
// 	signature: EmailSignature
// 	html: string
// 	cursorPositionStart: number
// 	cursorPositionEnd: number
// 	// hideSaveDialog: boolean
// 	hideResetDialog: boolean
// 	// saveConfirmed: boolean
// 	// resetConfirmed: boolean
// }

// export default class EmailSignaturesPreview extends React.Component<EmailSignaturesPreviewProps, EmailSignaturesPreviewState> {

// constructor(props: EmailSignaturesPreviewProps) {
// 	super(props);
// 	this.state = {
// 		signature: exampleEmailSignature,
// 		html: '',
// 		cursorPositionStart: 0,
// 		cursorPositionEnd: 0,
// 		// hideSaveDialog: true,
// 		hideResetDialog: true,
// 		// saveConfirmed: false,
// 		// resetConfirmed: false,
// 	};
// 	this.boundSetState = this.setState.bind(this)
// }

// boundSetState = (x: any) => { this.setState(x) }

const EmailSignaturesPreview: React.FC<EmailSignaturesPreviewProps> = ({ saveSignatures, signature, embeddedPictureItems }) => {
	// const [localSignature, setLocalSignature] = React.useState<EmailSignature>(exampleEmailSignature);
	const [html, setHtml] = React.useState<string>('');
	const [cursorPositionStart, setCursorPositionStart] = React.useState<number>(0);
	const [cursorPositionEnd, setCursorPositionEnd] = React.useState<number>(0);
	const [hideResetDialog, setHideResetDialog] = React.useState<boolean>(true);

	const { tenantContext } = React.useContext(TenantContext)

	const { userDataItems } = useUserData()
	const { customDataItem } = useCustomData()
	const { ReplaceInText, isLoadingUserData, isLoadingCustomData } = useReplace()

	React.useEffect(() => {
		// setLocalSignature(signature);
		setHtml(pretty(signature.HTML, { ocd: true }));
	}, [signature]);

	// componentDidUpdate(_prevProps: EmailSignaturesPreviewProps, _prevState: EmailSignaturesPreviewState) {
	// 	if (_prevProps.signature !== signature) {
	// 		this.setState({
	// 			signature: signature,
	// 			html: pretty(signature.HTML, { ocd: true })
	// 		});
	// 	}
	// }

	// componentDidMount() {
	// 	this.setState({
	// 		signature: signature,
	// 		html: pretty(signature.HTML, { ocd: true })
	// 	});
	// }

	const insertTextAtSelection = (startText: string, endText?: string) => {
		setHtml(`${html.substring(0, cursorPositionStart)}${startText}${html.substring(cursorPositionStart, cursorPositionEnd)}${endText ? endText : ''}${html.substring(cursorPositionEnd, html.length)}`)
		// this.setState({ html: `${html.substring(0, cursorPositionStart)}${startText}${html.substring(cursorPositionStart, cursorPositionEnd)}${endText ? endText : ''}${html.substring(cursorPositionEnd, html.length)}` })
	}

	// const FindReplace = (): FindReplace[] => {
	//     let findReplace: FindReplace[] = []
	//     if (GetUseUserInformation(settings)) { findReplace = FindReplaceFromUserData(userDataItems) }
	//     if (GetUseCustomData(settings)) { findReplace = [...findReplace, ...FindReplaceFromCustomData(customDataItem)] }
	//     return findReplace
	// }

	const SignaturePreview = (): string => {
		let signature = html
		try {
			//replace pictures with base64
			signature = signature.replace(/src=['"]cid:([^"']*)["']/ig, (_match, p1) => {
				const pic: EmbeddedPictureItem = embeddedPictureItems.find((val) => val!?.PictureName === p1!)!
				return `src='data:image/${pic!?.PictureName.split('.').pop()?.toLowerCase() === 'png' ? 'png' : 'jpeg'};base64,${pic!?.Base64!}'`
			})
			const groupFields: string[] = signature.match(/\$grp[^$]*\$/g)!?.map(val => { return val })
			// console.log({ groupFields: groupFields })

			if (groupFields && groupFields!.length > 0) {
				//TODO
				// signature = ReplaceGroupfields(signature, groupFields, userInfo, customData)
			}
			signature = ReplaceInText(signature, false)

			// signature = ReplaceAADfields(signature, userInfo![0].items)
			//TODO
			// signature = GetCustomFieldValue(GetCustomDataListItems(customData), signature)


		} catch (error) {
			console.log(error)
		}
		return signature
	}

	const setCursor = (event: any) => {
		setCursorPositionStart(event.currentTarget.selectionStart!)
		setCursorPositionEnd(event.currentTarget.selectionEnd!)
	}

	const pictureMenuProps = (): IContextualMenuProps | undefined => {
		if (embeddedPictureItems) {
			return {
				items: embeddedPictureItems.map((pic) => {
					return {
						key: pic.PictureName,
						text: pic.PictureName,
						data: pic.Base64,
						onRender: (item: IContextualMenuItem) => {
							return (
								<IconButton
									key={item.text!}
									ariaLabel={item.text!}
									styles={buttonStyles}
									style={{ width: '100%', height: 'auto', maxHeight: 104, padding: 2, border: '' }}
									onClick={() => { insertTextAtSelection(`<img src="cid:${item.text!}">`) }}
								>
									<Stack>
										<Image
											src={`data:image/${item.text!.split('.').pop()?.toLowerCase() === 'jpg' ? 'jpeg' : item.text!.split('.').pop()?.toLowerCase()};base64,${item.data!}`}
											alt={item.text!}
											styles={{ image: { objectFit: 'contain', height: 'auto', width: 'auto', maxHeight: 86, maxWidth: 100 } }}
										/>
										<Text variant='xSmall'>{item.text!}</Text>
									</Stack>
								</IconButton>
							)
						}
					} as IContextualMenuItem
				})
			}
		}
		else { return { items: [] } }
	}

	return (
		<Stack className='Width100' styles={{ root: { paddingLeft: '20px', paddingRight: '20px' } }} tokens={{ childrenGap: '5px' }}>
			<span><h3>HTML</h3></span>
			<TextField
				styles={{ root: { width: '100%' }, field: { fontFamily: 'Courier New' } }}
				value={html}
				onChange={(_event, value) => {
					setHtml(value!);
					// setCursorPositionStart(event.currentTarget.selectionStart!);
					// setCursorPositionEnd(event.currentTarget.selectionEnd!);
				}}
				onClick={(event) => {
					setCursor(event)
				}}
				onKeyUp={(event) => {
					setCursor(event)
				}}
				onSelectCapture={(event) => {
					setCursor(event)
				}}
				multiline
				rows={10}
			/>
			<Stack horizontal wrap tokens={{ childrenGap: '5px' }}>
				<DefaultButton
					primary
					text='Save'
					onClick={() => {
						saveSignatures(
							{
								...signature,
								HTML: html,
								EmbeddedPictures: html.match(/src=['"]cid:([^"']*)["']/ig)!?.map((name) => {
									return { PictureName: name.replace(/src=['"]cid:([^"']*)["']/ig, '$1') } as EmbeddedPictureItem
								})
							})
					}}
				/>
				<DefaultButton
					text='Reset'
					// onClick={() => this.setState({ html: pretty(signature.HTML, { ocd: true }) })}
					onClick={() => setHideResetDialog(false)}
				/>
				<HtmlButtons
					InsertTextAtSelection={insertTextAtSelection}
				/>
				{!isLoadingUserData && GetUseUserInformation(tenantContext.settings) &&
					<DefaultButton
						text='User Data'
						menuProps={aadFieldsMenuProps(userDataItems, insertTextAtSelection)}
					/>
				}
				{!isLoadingCustomData && GetUseCustomData(tenantContext.settings) && customDataItem &&
					<DefaultButton
						text='Custom Data'
						menuProps={customFieldsMenuProps(customDataItem, insertTextAtSelection)}
					/>
				}
				<DefaultButton
					text='Insert Picture'
					menuProps={pictureMenuProps()}
				/>
			</Stack>
			{/* &nbsp; */}
			<Separator key='sepPreview' />
			{/* <span><h3>Preview</h3></span> */}
			{/* <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(SignaturePreview(html, embeddedPictureItems, settings.UserInformation)) }} /> */}
			<div dangerouslySetInnerHTML={{
				__html: DOMPurify.sanitize(
					// SignaturePreview(html, embeddedPictureItems, settings.UserInformation!, customDataItem)
					SignaturePreview()
				)
			}} />
			<DialogOkAbort
				callbackOk={() => {
					setHtml(pretty(signature.HTML, { ocd: true }));
					setHideResetDialog(true);
					// this.setState({ html: pretty(signature.HTML, { ocd: true }), hideResetDialog: true })
				}}
				callbackAbort={() => {
					setHideResetDialog(true);
					//  this.setState({ hideResetDialog: true })
				}}
				title='Reset signature'
				description={`Do you want to reset the signature ${signature.SignatureName}? All changes will be lost!`}
				hidden={hideResetDialog!}
			/>
		</Stack>
	);
}

export default EmailSignaturesPreview;