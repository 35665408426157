export const GetTime = (date: Date): string => {
    try {
        return date.toLocaleTimeString().split(':').splice(0, 2).join(':')
    } catch (error) {
        return date.toString().split('T')[1].split(':').splice(0, 2).join(':');
    }
}

export const GetDate = (date: Date): string => {
    try {
        return date.toLocaleDateString()
    } catch (error) {
        return date.toString().split('T')[0];
    }
}