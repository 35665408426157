import * as React from 'react';
import { Pivot, PivotItem, Stack, } from '@fluentui/react';
import { CancelTokenSource } from 'axios';
import { UserAdminItem } from '../../interfaces/UserAdminItem';
import SettingsCompany from '../../helpers/SettingsCompany';

export interface SettingsMenuProps {
	// setParentState: (x: SettingsState) => void;
	setSelectedPivotItem: (x: string) => void;
	selectedPivotItem: string,
	userData: UserAdminItem,
	settings: SettingsCompany
}

export interface SettingsMenuState {

}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class SettingsMenu extends React.Component<SettingsMenuProps, SettingsMenuState> {
	constructor(props: SettingsMenuProps) {
		super(props);
		this.state = {
		};
	}

	// const pivotItems = [
	// 	{ key: 'imagevault', order: 0, visible: localStorage.getItem('ImageVault') },
	// 	{ key: 'templates', order: GetPivotOrder(Office.context.host, 'templates', this.props.companySettings), visible: GetUseTemplates(Office.context.host, Office.context.platform, this.props.companySettings) },
	// 	{ key: 'icons', order: GetPivotOrder(Office.context.host, 'icons', this.props.companySettings), visible: GetUseIcons(Office.context.host, Office.context.platform, this.props.companySettings) },
	// 	{ key: 'pictures', order: GetPivotOrder(Office.context.host, 'pictures', this.props.companySettings), visible: GetUsePictures(Office.context.host, Office.context.platform, this.props.companySettings) },
	// 	{ key: 'slides', order: GetPivotOrder(Office.context.host, 'slides', this.props.companySettings), visible: GetUseSlides(Office.context.host, Office.context.platform, this.props.companySettings) },
	// ]

	LinkClickEvent(item: PivotItem) {
		// this.props.setParentState({ selectedPivotItem: item.props.itemKey! })
		this.props.setSelectedPivotItem(item.props.itemKey!)
		// switch (item.props.itemKey) {
		//     case 'templates': {
		//         this.props.setParentState({ selectedPivotItem: item.props.itemKey });
		//         break;
		//     }
		//     default: {
		//         this.props.setParentState({ selectedPivotItem: item.props.itemKey })
		//     }
		// }
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	pivotItems = () => {
		return [
			{ key: 'admin', text: 'Admin', visible: this.props.userData.isAdmin },
			{ key: 'branding', text: 'Branding', visible: true },
			{ key: 'manifest', text: 'Manifest', visible: true },
			{ key: 'extensions', text: 'Extensions', visible: true },
			{ key: 'functions', text: 'Functions', visible: true },
			{ key: 'redirect', text: 'Redirect', visible: this.props.settings.enableRedirect},
			{ key: 'more', text: 'More', visible: true }
		]
	}

	render() {

		return (
			<Stack >
				<Pivot
					selectedKey={this.props.selectedPivotItem}
					onLinkClick={(item) => { this.LinkClickEvent(item!) }}
					overflowBehavior={'menu'}
					overflowAriaLabel={'more tabs'}
				>
					{this.pivotItems().map((item) => {
						if (item.visible) {
							return (<PivotItem key={item.key} headerText={item.text} itemKey={item.key} />)
						}
						else { return null }
					})}
				</Pivot>
			</Stack>
		)
	}
}