import React, { useMemo } from 'react';
import { CommandBar, IColumn, ICommandBarItemProps, ShimmeredDetailsList, Selection, Stack, DetailsListLayoutMode, SelectionMode, CheckboxVisibility, IContextualMenuProps, IContextualMenuItem, TextField, Checkbox, IconButton } from '@fluentui/react';
import { UserInfoExtended } from '../../helpers/SettingsCompany';
import { DialogDelete } from '../dialogs/DialogDelete';
import { useUserData } from '../../hooks/useUserData';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';

export interface UserDataListProps {

}

const UserDataList: React.FC<UserDataListProps> = () => {
	// const [items, setUserDataItems] = React.useState<UserInfoExtended[]>([]);
	// const [allUserDataItems, setAllUserDataItems] = React.useState<UserInfoExtended[]>([]);
	const [selectedItem, setSelectedItem] = React.useState<UserInfoExtended | null>(null);
	const [selectionCount, setSelectedCount] = React.useState<number>(0);

	const [editMode, setEditMode] = React.useState<boolean>(false);
	const [hideRestoreDialog, setRestoreDeleteDialog] = React.useState<boolean>(true);

	const { userDataItems, setUserDataItems, allUserDataItems, setAllUserDataItems, FetchUserData, FetchAllUserData, PostItems, RestoreUserData, isLoadingUserData } = useUserData()

	let selection: Selection = new Selection({
		onSelectionChanged: () => {
			console.log(selection)
			setSelectedItem(selection.getSelection()[0] as UserInfoExtended)
			setSelectedCount(selection.getSelectedCount())
		}
	});

	React.useEffect(() => {
		FetchUserData()
		FetchAllUserData()
	}, [])

	React.useEffect(() => {
		selection.selectToKey(selectedItem!?.key.toString()!)
	}, [selectedItem])

	const menuProps: IContextualMenuProps = useMemo(() => {
		return {
			items: allUserDataItems!?.map((item) => {
				return {
					key: item.field,
					text: `${item.labelEng} (${item.subItem ? item.subItem : item.item})`,
					onClick: () => {
						setUserDataItems(userDataItems.concat(item))
					}
				} as IContextualMenuItem
			})
		} as IContextualMenuProps
	}, [allUserDataItems, userDataItems])

	const commandItems: ICommandBarItemProps[] = useMemo(() => {
		console.log(selectionCount)
		return [
			{
				key: 'newField',
				text: 'New Field',
				iconProps: { iconName: 'Add' },
				subMenuProps: menuProps,
			} as ICommandBarItemProps,
			{
				key: 'edit',
				text: 'Edit Mode',
				checked: editMode,
				iconProps: { iconName: 'Edit' },
				onClick: () => { setEditMode(!editMode) },
			} as ICommandBarItemProps,
			{
				key: 'save',
				text: 'Save',
				iconProps: { iconName: 'Save' },
				onClick: () => { PostItems() },
			},
			{
				key: 'restore',
				text: 'Restore',
				disabled: selectionCount === 0,
				iconProps: { iconName: 'Refresh' },
				onClick: () => { setRestoreDeleteDialog(false) },
			},
		]
	}, [userDataItems, allUserDataItems, selectionCount, editMode])

	const onRenderLabel = (item: UserInfoExtended, index: number, column: IColumn) => {
		return editMode ?
			<TextField
				value={item[column.fieldName as keyof UserInfoExtended] as string}
				onChange={(_event, newValue) => {
					const newItems = [...userDataItems]
					newItems[index] = {
						...item,
						[column?.fieldName! as keyof UserInfoExtended]: newValue
					}
					setUserDataItems(newItems)
				}}
			/>
			: item[column.fieldName as keyof UserInfoExtended]
	}

	const onRenderHidden = (item: UserInfoExtended, index: number, column: IColumn) => {
		return editMode ?
			<Checkbox
				checked={item.hidden}
				onChange={(_event, newValue) => {
					const newItems = [...userDataItems]
					newItems[index] = {
						...item,
						[column?.fieldName! as keyof UserInfoExtended]: newValue
					}
					setUserDataItems(newItems)
				}}
			/>
			: item.hidden ? 'True' : 'False'
	}

	const onRenderItem = (item: UserInfoExtended) => {
		return item.subItem ? `${item.item}.${item.subItem}` : item.item
	}

	const onRenderValue = (item: UserInfoExtended) => {
		return <span style={{color: 'darkgray'}}>{item.value}</span>
	}

	const onRenderField = (item: UserInfoExtended) => {
		return editMode ?
			<Stack horizontal verticalAlign='center'>
				<Stack.Item>{item.field}</Stack.Item>
				<IconButton iconProps={{ iconName: 'Delete' }} onClick={() => {
					const newItems = [...userDataItems]
					newItems.splice(newItems.indexOf(item), 1)
					setUserDataItems(newItems)
					setAllUserDataItems(allUserDataItems.concat(item))
				}} />
			</Stack>
			: item.field
	}

	const columns: IColumn[] = useMemo(() => [
		// { key: 'column1', name: 'Id', fieldName: 'key', minWidth: 16, maxWidth: 16, },
		{ key: 'column2', name: 'Field', fieldName: 'field', minWidth: 100, maxWidth: 300, isResizable: true, onRender: (item) => onRenderField(item) },
		{ key: 'column3', name: 'Label Eng', fieldName: 'labelEng', minWidth: 100, maxWidth: 300, isResizable: true, onRender: (item, index, column) => onRenderLabel(item, index!, column!) },
		{ key: 'column4', name: 'Label Swe', fieldName: 'labelSwe', minWidth: 100, maxWidth: 300, isResizable: true, onRender: (item, index, column) => onRenderLabel(item, index!, column!) },
		// { key: 'column5', name: 'Category Eng', fieldName: 'categoryEng', minWidth: 100, maxWidth: 300, isResizable: true, },
		// { key: 'column6', name: 'Category Swe', fieldName: 'categorySwe', minWidth: 100, maxWidth: 300, isResizable: true, },
		// { key: 'column7', name: 'Store', fieldName: 'store', minWidth: 100, maxWidth: 300, isResizable: true, },
		{ key: 'column8', name: 'Item', fieldName: 'item', minWidth: 100, maxWidth: 300, isResizable: true, onRender: (item) => onRenderItem(item) },
		
		{ key: 'column10', name: 'Hidden', fieldName: 'hidden', minWidth: 100, maxWidth: 300, isResizable: true, onRender: (item, index, column) => onRenderHidden(item, index!, column!) },
		{ key: 'column11', name: 'My Data', fieldName: 'value', minWidth: 100, maxWidth: 300, isResizable: true, onRender: (item) => onRenderValue(item)},
		// { key: 'column11', name: 'Mask', fieldName: 'mask', minWidth: 100, maxWidth: 300, isResizable: true, },
		// { key: 'column12', name: '', fieldName: 'delete', minWidth: 16, maxWidth: 16, onRender: (item) => onRenderDelete(item) },
	], [userDataItems, editMode])

	// const getItems = useCallback(() => {
	// 	GetUserData(
	// 		accessToken!,
	// 		tenantContext.tenantName,
	// 		tenantContext.api,
	// 	).then((data) => {
	// 		const newItems: UserInfoExtended[] = data!?.map((subItem) => {
	// 			return {
	// 				...subItem,
	// 				key: subItem.field,
	// 				labelEng: subItem.label[0],
	// 				labelSwe: subItem.label[1],
	// 			} as UserInfoExtended
	// 		})
	// 		setUserDataItems(newItems)
	// 	})
	// 		.catch(() => { setUserDataItems([]) })
	// }, [])

	// const getallUserDataItems = useCallback(() => {
	// 	GetAllUserData(
	// 		accessToken!,
	// 		tenantContext.tenantName,
	// 		tenantContext.api,
	// 	).then((data) => {
	// 		const newItems: UserInfoExtended[] = data!?.map((subItem) => {
	// 			return {
	// 				...subItem,
	// 				key: subItem.field,
	// 				labelEng: subItem.label[0],
	// 				labelSwe: subItem.label[1],
	// 			} as UserInfoExtended
	// 		})
	// 		setAllUserDataItems(newItems)
	// 	})
	// }, [])

	// const postItem = useCallback(() => {
	// 	uploadUserdetails(
	// 		accessToken!,
	// 		tenantContext.tenantName,
	// 		tenantContext.api,
	// 		items.map((item) => {
	// 			return {
	// 				...item,
	// 				label: [item.labelEng, item.labelSwe]
	// 			}
	// 		}),
	// 	).then(() => {
	// 		getItems()
	// 		getallUserDataItems()
	// 	})
	// }, [items])

	// const restore = useCallback(() => {
	// 	deleteUserdetails(
	// 		accessToken!,
	// 		tenantContext.tenantName,
	// 		tenantContext.api,
	// 	).then(() => {

	// 	})
	// }, [accessToken, tenantContext.tenantName, tenantContext.api])


	if (isLoadingUserData) return <Spinner size={SpinnerSize.large} label='Loading User Data...' />
	
	return (
		<Stack className='Width100'>
			<span className='Indent20'><h3>User Data (Microsoft Entra ID)</h3></span>
			<CommandBar
				items={commandItems}
				ariaLabel="Use left and right arrow keys to navigate between commands"
			/>
			<ShimmeredDetailsList
				items={userDataItems}
				columns={columns}
				enableShimmer={!userDataItems}
				setKey="set"
				layoutMode={DetailsListLayoutMode.justified}
				selectionPreservedOnEmptyClick={true}
				selectionMode={SelectionMode.none}
				selection={selection}
				ariaLabelForSelectionColumn="Toggle selection"
				ariaLabelForSelectAllCheckbox="Toggle selection for all items"
				checkButtonAriaLabel="Row checkbox"
				checkboxVisibility={CheckboxVisibility.always}
				onActiveItemChanged={(item) => {
					console.log(item)
					// setSelectedItem(item as UserInfoExtended)
					console.log(selection.count)
				}}
			/>
			<DialogDelete
				hidden={hideRestoreDialog}
				callbackDelete={() => {
					setRestoreDeleteDialog(true)
					RestoreUserData()
				}}
				callbackCancel={() => { setRestoreDeleteDialog(true) }}
				title='Restore'
				description={`Are you sure you want to restore User Data to the default list?`}
			/>
		</Stack>
	);
};

export default UserDataList;
