import React from 'react';
import { NewsItem } from '../../interfaces/NewsItem';
import { GetAllNews } from '../../helpers/GetFromRestApi';
import { DocumentCard, DocumentCardDetails, DocumentCardPreview, DocumentCardTitle, DocumentCardType, FontWeights, IButtonStyles, IDocumentCardPreviewImage, IDocumentCardStyles, IconButton, Image, ImageFit, Link, Modal, Spinner, SpinnerSize, Stack, getTheme, mergeStyleSets } from '@fluentui/react';
import { CenterComponent } from '../common/CenterComponent';
import Parser from 'html-react-parser';
import { TenantContext } from '../../contexts/ContextWrapper';
import { AuthContext } from '../../contexts/AuthContext';

const NewsHome: React.FC = () => {

    const [news, setNews] = React.useState<NewsItem[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [selectedNews, setSelectedNews] = React.useState<NewsItem | undefined>(undefined);

    const { accessToken } = React.useContext(AuthContext);
    const { tenantContext } = React.useContext(TenantContext)

    const theme = getTheme();
    const { palette } = theme;
    const cardStyles: IDocumentCardStyles = {
        root: { display: 'inline-block', marginRight: 20, width: 320, maxWidth: '80%', selectors: { ':hover': { backgroundColor: palette.themeLighterAlt } } }
    };

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        heading: {
            color: theme.palette.neutralPrimary,
            fontWeight: FontWeights.semibold,
            fontSize: 'inherit',
            margin: '0',
        },
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };

    React.useEffect(() => {
        let mounted = true;
        const getNews = async () => {
            if (accessToken) {
                setIsLoading(true);
                GetAllNews(accessToken, tenantContext.api.toLowerCase() === 'dev' ? 'TEST' : 'PROD')
                    .then((result: NewsItem[]) => {
                        if (mounted) {
                            setNews(result); // Ensure we're still mounted before setting state
                        }
                    })
                    .catch(error => console.error("Failed to fetch news:", error))
                    .finally(() => {
                        if (mounted) {
                            setIsLoading(false); // Ensure we're still mounted before setting state
                        }
                    });
            }
        };
        getNews();
        return () => {
            mounted = false; // Set mounted to false when the component unmounts
        };
    }, [accessToken, tenantContext.api]);
    

    if (isLoading) {
        return (
            <CenterComponent>
                <Spinner className='spinner' size={SpinnerSize.large} label='Loading news...' />
            </CenterComponent>
        )
    }

    if (!news || news!?.length === 0) { return <></> }

    return (
        <Stack wrap horizontal tokens={{ childrenGap: '10px' }} styles={{ root: { paddingTop: '10px' } }} >
            {news.map((newsItem) => {
                const previewPart = newsItem.Image ?
                    {
                        previewImageSrc: newsItem.Image,
                        imageFit: ImageFit.cover,
                        height: 120,
                    } as IDocumentCardPreviewImage
                    : undefined;

                return (
                    <DocumentCard
                        key={newsItem.ID}
                        type={DocumentCardType.normal}
                        styles={cardStyles}
                        // onClickHref={'templates'}
                        onClick={() => {
                            setSelectedNews(newsItem);
                            setShowModal(true);
                        }}
                    // onClick={() => window.open(newsItem.Link, "_blank")}
                    // onClick={() => window.open(newsItem.Link)}
                    >

                        <DocumentCardDetails                        >
                            <div style={{ padding: '8px 16px', fontWeight: 'bold', backgroundColor: palette.neutralLighterAlt }}>
                                {newsItem.PublishDate?.toString().split('T')[0]!}
                            </div>
                            <DocumentCardPreview
                                previewImages={[{
                                    name: newsItem.Title,
                                    // linkProps: {
                                    //     href: newsItem.Link,
                                    //     target: '_blank',
                                    // },
                                    ...previewPart,
                                }]}
                                styles={{ previewIcon: { backgroundColor: palette.white } }}
                            />
                            <DocumentCardTitle
                                title={newsItem.Title}
                            />
                            <div style={{ padding: '0px 16px 16px 16px' }}>
                                {Parser(newsItem.Content!?.split('<br').length > 1 ? newsItem.Content!?.split('<br')[0] + '...<br/>&nbsp;<br/>(click to see more)' : newsItem.Content!?.split('<br')[0] || '')}
                            </div>
                        </DocumentCardDetails>
                    </DocumentCard>
                    // <Stack.Item key={newsItem.ID}>
                    //     <div>{newsItem.Title}</div>
                    //     <div>{newsItem.Content}</div>
                    //     <div>{newsItem.PublishDate}</div>
                    // </Stack.Item>
                )
            })}
            <Modal
                isOpen={showModal && selectedNews !== undefined}
                onDismiss={() => {
                    setSelectedNews(undefined);
                    setShowModal(false)
                }}
                containerClassName={contentStyles.container}
            >
                <div className={contentStyles.header}>
                    <h2 className={contentStyles.heading} id={selectedNews!?.ID}>
                        {selectedNews!?.Title}
                    </h2>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={() => setShowModal(false)}
                    />
                </div>
                <div className={contentStyles.body}>
                    <Stack tokens={{ childrenGap: '5px' }}>
                        <p>{selectedNews!?.PublishDate?.toString().split('T')[0]!}</p>
                        <div style={{ maxWidth: '600px' }}>
                            {Parser(selectedNews!?.Content || '')}
                        </div>
                        <Image
                            styles={{ root: { paddingTop: '20px' } }}
                            src={selectedNews!?.Image!}
                        />
                        <Link href={selectedNews!?.Link}>{selectedNews!?.Link}</Link>
                    </Stack>
                </div>
            </Modal>
        </Stack >
    )
}

export default NewsHome;