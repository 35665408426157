import * as React from "react"
import { Stack } from "@fluentui/react"
import { getAnimStyleFrameUp } from "../../helpers/Animations"

export interface ContentFrameProps {
    hidden?: boolean
}

export const ContentFrame: React.FunctionComponent<ContentFrameProps> = (props) => {

    if (!props.children) { return null }
    if (props?.hidden!) { return null }

    return (
        <Stack.Item className="ms-depth-8 ms-bgColor-white" styles={getAnimStyleFrameUp(props?.hidden!)}>
            {props.children}
        </Stack.Item>
    )
}