import * as React from "react";

import { CustomDataPlainListItem } from "../interfaces/CustomDataPlainList";
import { AuthContext } from "../contexts/AuthContext";
import { TenantContext } from "../contexts/ContextWrapper";
import { GetCustomPlainList } from "../helpers/GetFromRestApi";
import { GetUseCustomData } from "../helpers/SettingsCompany";

export const useCustomData = () => {
    const [isLoadingCustomData, setIsLoadingCustomData] = React.useState<boolean>(false)
    const [customDataItem, setCustomDataItem] = React.useState<CustomDataPlainListItem[]>([])

    const { accessToken } = React.useContext(AuthContext)
    const { tenantContext } = React.useContext(TenantContext)

    React.useEffect(() => {
        setIsLoadingCustomData(true)
        getCustomData()
            .finally(() => { setIsLoadingCustomData(false) })
    }, [accessToken, tenantContext.tenantName, tenantContext.api])

    const getCustomData = async () => {
        if (GetUseCustomData(tenantContext.settings)) {
            GetCustomPlainList(accessToken!, tenantContext.tenantName, tenantContext.api).then((data) => {
                if (data) { setCustomDataItem(JSON.parse(JSON.stringify(data))) }
            })
        }
    }

    return { customDataItem ,isLoadingCustomData}
}