import * as React from 'react';
import {
	CheckboxVisibility,
	CommandBar,
	FontIcon,
	IColumn,
	ICommandBarItemProps,
	SelectionMode,
	ShimmeredDetailsList,
	Stack,
} from '@fluentui/react';
import { DetailsListLayoutMode, Selection, } from 'office-ui-fabric-react/lib/DetailsList';
import SettingsCompany from '../../helpers/SettingsCompany';
import { EmailSignature, exampleEmailSignature } from '../../interfaces/EmailSignature';

export interface EmailSignaturesListProps {
	setSelectedItem(selectedItem: EmailSignature): void
	onCommandItemClick(key: string, selectedItem: EmailSignature): void
	signatureItems: EmailSignature[];
	selectedItem: EmailSignature
	settings: SettingsCompany;
}

export interface EmailSignaturesListState {
	items: EmailSignature[]
	columns: IColumn[]
	selection: Selection
	// selectedItem: EmailSignature
	// newSignatureItem: EmailSignature
}

export default class EmailSignaturesList extends React.Component<EmailSignaturesListProps, EmailSignaturesListState> {
	private _selection: Selection;

	constructor(props: EmailSignaturesListProps) {
		super(props);
		this._selection = new Selection({
			onSelectionChanged: () => this.setState({ selection: this._selection }),
		});
		this.state = {
			items: [exampleEmailSignature],
			columns: [{ key: 'column1', name: '', minWidth: 16, }],
			selection: this._selection,
			// selectedItem: exampleEmailSignature,
			// newSignatureItem: exampleEmailSignature,
			// hideNoSelectDialog: true,
			// hideEditDialog: true,
			// hideNewDialog: true,
			// hideDeleteDialog: true,
			// newConfirmed: false,
			// editConfirmed: false,
			// deleteConfirmed: false,
		};
		this.boundSetState = this.setState.bind(this);
	}

	deleting: Boolean = false

	boundSetState = (x: any) => { this.setState(x) }

	columns = (): IColumn[] => {
		return [
			{ key: 'column1', name: 'Signature name', fieldName: 'SignatureName', minWidth: 150, maxWidth: 300, isResizable: true, onColumnClick: this.onColumnClick, },
			{
				key: 'column2',
				name: 'Default signature',
				fieldName: 'DefaultSignature',
				minWidth: 120,
				maxWidth: 120,
				isResizable: true,
				onColumnClick: this.onColumnClick,
				onRender: (item: EmailSignature) => { return item.DefaultSignature ? <FontIcon iconName="CheckMark" /> : <></> },
			},
			{
				key: 'column3',
				name: 'Reply signature',
				fieldName: 'ReplySignature',
				minWidth: 120,
				maxWidth: 120,
				isResizable: true,
				onColumnClick: this.onColumnClick,
				onRender: (item: EmailSignature) => { return item.ReplySignature ? <FontIcon iconName="CheckMark" /> : <></> },
			},
			{
				key: 'column4',
				name: '# Embedded Pictures',
				fieldName: 'PicturesCount',
				minWidth: 150,
				maxWidth: 150,
				isResizable: true,
				onColumnClick: this.onColumnClick,
				onRender: (item: EmailSignature) => { return item.EmbeddedPictures?.length > 0 ? item.EmbeddedPictures?.length : '' },
			},
		]
	}

	componentDidUpdate(_prevProps: EmailSignaturesListProps, _prevState: EmailSignaturesListState) {
		if (_prevProps.signatureItems !== this.props.signatureItems) {
			this.setState({
				columns: this.columns(),
				items: this.props.signatureItems
			});
		}
		if (this._selection.getSelectedCount() === 0) {
			this._selection.setIndexSelected(0, true, true)
		}
	}

	componentDidMount() {
		this.setState({
			columns: this.columns(),
			items: this.props.signatureItems
		})
	}

	render(): JSX.Element {

		return (
			<Stack className='Width100'>
				<span className='Indent20'><h3>Signatures</h3></span>
				<CommandBar
					items={this.commandItems}
					ariaLabel="Use left and right arrow keys to navigate between commands"
				/>
				{this.props.signatureItems[0]?.SignatureName &&
					<ShimmeredDetailsList
						items={this.props.signatureItems}
						columns={this.state.columns}
						enableShimmer={!this.props.signatureItems}
						setKey="set"
						layoutMode={DetailsListLayoutMode.justified}
						selectionPreservedOnEmptyClick={true}
						selectionMode={SelectionMode.single}
						selection={(this._selection)}
						ariaLabelForSelectionColumn="Toggle selection"
						ariaLabelForSelectAllCheckbox="Toggle selection for all items"
						checkButtonAriaLabel="Row checkbox"
						checkboxVisibility={CheckboxVisibility.always}
						onActiveItemChanged={this.onActiveItemChanged}
					/>
				}
				{/* <DialogOkOnly
					setParentState={this.boundSetState}
					hidden={this.state.hideNoSelectDialog}
					hideState='hideNoSelectDialog'
					title='Nothing selected'
					text='You have to select a picture size to use this option.'
				/>
				<DialogDelete
					setParentState={this.boundSetState}
					hidden={this.state.hideDeleteDialog}
					hideState='hideDeleteDialog'
					confirmState='deleteConfirmed'
					filename={` Signature ${this.state.selectedItem.SignatureName}`}
				/>
				<DialogEditSignature
					setParentState={this.boundSetState}
					hidden={this.state.hideNewDialog}
					hideState='hideNewDialog'
					confirmState='newConfirmed'
					newSignatureItem='newSignatureItem'
					selectedItem={exampleEmailSignature}
					settings={this.props.settings}
					isNew={true}
				/>
				<DialogEditSignature
					setParentState={this.boundSetState}
					hidden={this.state.hideEditDialog}
					hideState='hideEditDialog'
					confirmState='editConfirmed'
					newSignatureItem='newSignatureItem'
					selectedItem={this.state.selectedItem}
					settings={this.props.settings}
					isNew={false}
				/> */}
			</Stack>
		);
	}

	//libraries={this.state.items[this.props.selectedIndex]?.Libraries}

	private commandItems: ICommandBarItemProps[] = [
		{
			key: 'newSize',
			text: 'New',
			iconProps: { iconName: 'Add' },
			onClick: () => this.props.onCommandItemClick('new', this.props.selectedItem),
		},
		{
			key: 'edit',
			text: 'Edit',
			iconProps: { iconName: 'Edit' },
			onClick: () => this.props.onCommandItemClick('edit', this.props.selectedItem),
		},
		{
			key: 'delete',
			text: 'Delete',
			iconProps: { iconName: 'Delete' },
			onClick: () => this.props.onCommandItemClick('delete', this.props.selectedItem),
		},
		// {
		// 	key: 'save',
		// 	text: 'Save',
		// 	disabled: true,
		// 	iconProps: { iconName: 'Save' },
		// 	onClick: () => console.log('Save'),
		// },
		// {
		// 	key: 'publish',
		// 	text: 'Publish',
		// 	disabled: true,
		// 	iconProps: { iconName: 'PublishContent' },
		// 	onClick: () => console.log('Publish'),
		// },
	];

	// private _addSignature() {
	// 	this.setState({ hideNewDialog: false })
	// }

	// private _editPrompt() {
	// 	if (this._selection.count > 0) {
	// 		this.setState({ hideEditDialog: false })
	// 	}
	// 	else {
	// 		this.setState({ hideNoSelectDialog: false })
	// 	}
	// }

	// private _deletePrompt() {
	// 	if (this._selection.count > 0) {
	// 		this.setState({ hideDeleteDialog: false })
	// 	}
	// 	else {
	// 		this.setState({ hideNoSelectDialog: false })
	// 	}
	// }

	// private _deleteSelectedSignature() {
	// 	this.props.deleteSignatures(this.state.selectedItem.SignatureName)
	// 	this.setState({
	// 		deleteConfirmed: false,
	// 	})
	// }

	// private _updateSelectedSignature() {
	// 	this.props.saveSignatures(this.state.newSignatureItem)
	// 	this.setState({
	// 		editConfirmed: false,
	// 		newConfirmed: false,
	// 	})
	// }

	private onActiveItemChanged = (item?: EmailSignature): void => {
		// this.setState({ selectedItem: item! })
		this.props.setSelectedItem(item!)
	}

	private onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
		const { columns, items } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		const newItems = this._copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
		this.setState({
			columns: newColumns,
			items: newItems,
		});
	};

	_copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
		const key = columnKey as keyof T;
		return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
	}
}
