import * as React from "react"
import { DefaultButton, Dialog, DialogFooter, DialogType, Image, PrimaryButton, TextField } from "@fluentui/react"
import { IconButton, Label, Stack } from "office-ui-fabric-react";
import { CustomDataItem } from "../../interfaces/CustomDataItem";
import { CustomDataListItem } from "../../interfaces/CustomDataListItem";
import { toBase64 } from "../../helpers/Base64";

export const DialogEditCustomDetail: React.FunctionComponent<{
	onDismiss: (hidden: boolean) => void
	onChangeData: (dataItem: CustomDataItem[], parentId: string) => void
	parentId: string,
	customDataItems: CustomDataItem[]
	customDataDefs: CustomDataListItem[]
	hidden: boolean,
	isNew: boolean
}> = (props) => {

	const [dataItems, setDataItems] = React.useState<CustomDataListItem[]>(props.customDataDefs);

	React.useEffect(() => {
		setDataItems(props.customDataDefs.map((val) => {
			return {
				...val,
				DataValue: props.customDataItems[0] && props.customDataItems[0].DataItems.find(v => v.FieldName.toLowerCase() === val.FieldName.toLowerCase())?.DataValue
			}
		}))
		console.log('Rerender')
	}, [props.parentId, props.customDataDefs, props.customDataItems])

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: props.isNew ? 'New custom data item' : 'Edit custom data item',
		// subText: props.isNew ? 'Add a new custom data field' : `Edit the current custom data field`,
	};

	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 450 } },
	};

	const onChangeHandler = async (callback: (data: string) => void, event: React.ChangeEvent<HTMLInputElement>) => {
		await Promise.all(Array.from(event.target.files!).map(async (item) => {
			try {
				let res = (await toBase64(item))
				let data = ''
				if (typeof res === 'string') { data = (res.split(',')[1]) }
				callback(data)
			} catch (error) {
				callback('')
			}
		}))
	}


	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.onDismiss(true)}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<Stack tokens={{ childrenGap: 5 }}>
				{dataItems.map((item: CustomDataListItem) => {
					switch (item.DataType) {
						case 'png':
						case 'jpeg':
							const acceptExtensions: string = `image/${item.DataType}`
							return (
								<Stack key={item.FieldName} tokens={{ childrenGap: '5px' }}								>
									<Label>{item.FieldLabels.find((val) => val.langCode.toLowerCase() === 'default')?.dataLabel}</Label>
									<Stack horizontal verticalAlign="center">
										<input title="Browse..." type="file" name="file" accept={acceptExtensions} onChange={(event) => {
											onChangeHandler((data) => {
												let newItems: CustomDataListItem[] = [...dataItems]
												newItems[newItems.findIndex(val => val.FieldName === item.FieldName)].DataValue = data
												setDataItems(newItems)
											}, event)
										}} />
										{item.DataValue && <IconButton
											iconProps={{ iconName: 'delete' }}
											onClick={() => {
												let newItems: CustomDataListItem[] = [...dataItems]
												newItems[newItems.findIndex(val => val.FieldName === item.FieldName)].DataValue = ''
												setDataItems(newItems)
											}}
										/>}
									</Stack>
									<Image
										styles={{ image: { maxWidth: 300, maxHeight: 70, objectFit: 'contain' } }}
										src={`data:image/${item.DataType};base64,${item.DataValue}`}
									/>
								</Stack>
							)
						case 'multiline':
							return (
								<TextField
									key={item.FieldName}
									label={item.FieldLabels.find((val) => val.langCode.toLowerCase() === 'default')?.dataLabel}
									value={item.DataValue!?.replace(/\v/g, '\n')}
									multiline
									rows={item.DataValue!?.split('\v').length}
									autoAdjustHeight
									onChange={(_ev, newValue) => {
										let newItems: CustomDataListItem[] = [...dataItems]
										newItems[newItems.findIndex(val => val.FieldName === item.FieldName)].DataValue = newValue!?.replace(/\n/g, '\v')
										setDataItems(newItems)
									}}
								/>
							)
						default:
							return (
								<TextField
									key={item.FieldName}
									label={item.FieldLabels.find((val) => val.langCode.toLowerCase() === 'default')?.dataLabel}
									value={item.DataValue!}
									onChange={(_ev, newValue) => {
										let newItems: CustomDataListItem[] = [...dataItems]
										newItems[newItems.findIndex(val => val.FieldName === item.FieldName)].DataValue = newValue
										setDataItems(newItems)
									}}
								/>
							)
					}

				})}
			</Stack>
			<DialogFooter>
				<PrimaryButton onClick={() => {
					props.onChangeData([Object.assign({ dataID: '', DataItems: [], SubLevels: [], groupIDs: [], LevelLabels: [], } as CustomDataItem, props.customDataItems[0], { DataItems: dataItems })], props.parentId)
					props.onDismiss(true)
				}}
					text="Update"
				/>
				<DefaultButton
					onClick={() => {
						props.onDismiss(true)
						// clearUseStates(); props.setParentState({ [props.hideState]: true }) 
					}}
					text="Cancel"
				/>
			</DialogFooter>
		</Dialog>
	);
};