import * as React from 'react';
import {
	CheckboxVisibility,
	CommandBar,
	Facepile,
	IButtonProps,
	IColumn,
	ICommandBarItemProps,
	Icon,
	IFacepilePersona,
	OverflowButtonType,
	Persona,
	PersonaSize,
	ProgressIndicator,
	SelectionMode,
	ShimmeredDetailsList,
	Stack,
	TooltipHost,
	mergeStyleSets,
	MessageBarType,
} from '@fluentui/react';
import { DetailsListLayoutMode, Selection, } from 'office-ui-fabric-react/lib/DetailsList';
import { sortArray } from '../../helpers/MiscFunctions';
import { GetSlidesPresentation } from '../../helpers/GetFromRestApi';
import Axios, { AxiosError, CancelTokenSource } from 'axios';
import { deletePresentationFile, publishPresentationFiles, updatePresentationFile, uploadPresentationFile } from '../../helpers/PostToRestApi';
import { DialogUploadSlides } from '../dialogs/DialogUploadSlides';
import SettingsCompany, { GetUseGroups } from '../../helpers/SettingsCompany';
import ReactDOMServer from 'react-dom/server';
import { GroupDetails } from '../../interfaces/GroupDetails';
import { examplePresentationList, PresentationListItem } from '../../interfaces/PresentationListItem';
import { UploadFile } from '../../interfaces/UploadFile';
import { SlideListItem } from '../../interfaces/SlideListItem';
import { DialogDelete } from '../dialogs/OldDialogDelete';
import { DialogOkAbort } from '../dialogs/OldDialogOkAbort';
import { DialogOkOnly } from '../dialogs/OldDialogOkOnly';
import { GroupInfo } from '../../interfaces/GroupInfo';
import { DialogEditSlides } from '../dialogs/DialogEditSlides';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { MessageContextType } from '../../interfaces/ContextType';

export interface SlidesFilesProps {
	setMessage: (x: MessageContextType) => void;
	getPresentations(version: string): void,
	getSlides(presentation: string, version: string): void,
	presentationItems: PresentationListItem[],
	accessToken: string,
	// apiUri: string,
	domain: string
	api: string
	templateVersion: string,
	groupIds: GroupInfo[];
	companyGroups: GroupDetails[];
	settings: SettingsCompany;
}

export interface SlidesFilesState {
	presentationItems: PresentationListItem[],
	// items: FileInfo[];
	columns: IColumn[];
	accessToken?: string,
	// apiUri?: string,
	selectedItem: PresentationListItem;
	selection: Selection;
	hideDeleteDialog: boolean;
	hideUploadDialog: boolean;
	hideEditDialog: boolean;
	hideNoSelectDialog: boolean;
	hideFileExist: boolean;
	hidePublishDialog: boolean;
	deleteConfirmed: boolean;
	uploadConfirmed: boolean;
	editConfirmed: boolean;
	publishConfirmed: boolean;
	uploading: boolean;
	editing: boolean;
	deleting: boolean;
	publishing: boolean;
	uploadItems: UploadFile[];
	publishFilename: string,
}

const classNames = mergeStyleSets({
	fileIconHeaderIcon: {
		padding: 0,
		fontSize: '16px',
	},
	fileIconCell: {
		textAlign: 'center',
		selectors: {
			'&:before': {
				content: '.',
				display: 'inline-block',
				verticalAlign: 'middle',
				height: '100%',
				width: '0px',
				visibility: 'hidden',
			},
		},
	},
	fileIconImg: {
		verticalAlign: 'middle',
		maxHeight: '16px',
		maxWidth: '16px',
	},
	controlWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	exampleToggle: {
		display: 'inline-block',
		marginBottom: '10px',
		marginRight: '30px',
	},
	selectionDetails: {
		marginBottom: '20px',
	},
});

let infoMessage: HTMLDivElement | null = null;

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class SlidesFiles extends React.Component<SlidesFilesProps, SlidesFilesState> {
	private _selection: Selection;

	constructor(props: SlidesFilesProps) {
		super(props);
		this._selection = new Selection({
			onSelectionChanged: () => this.setState({ selection: this._selection }),
		});
		this.state = {
			presentationItems: [],
			columns: [{ key: 'column1', name: '', minWidth: 16, }],
			selectedItem: {} as PresentationListItem,
			selection: this._selection,
			hideDeleteDialog: true,
			hideUploadDialog: true,
			hideNoSelectDialog: true,
			hideFileExist: true,
			hidePublishDialog: true,
			hideEditDialog: true,
			deleteConfirmed: false,
			uploadConfirmed: false,
			editConfirmed: false,
			publishConfirmed: false,
			uploading: false,
			editing: false,
			deleting: false,
			publishing: false,
			uploadItems: [],
			publishFilename: '',
		};
		this.boundSetState = this.setState.bind(this)
	}

	boundSetState = (x: any) => { this.setState(x) }

	getColums(): IColumn[] {
		return [
			{
				key: 'column0',
				name: 'Icon',
				fieldName: 'icon',
				className: classNames.fileIconCell,
				iconClassName: classNames.fileIconHeaderIcon,
				ariaLabel: 'Column operations for File type, Press to sort on File type',
				// iconName: 'Page',
				isIconOnly: true,
				minWidth: 16,
				maxWidth: 16,
				isResizable: false,
				onColumnClick: this._onColumnClick,
				onRender: () => { return <Icon {...getFileTypeIconProps({ extension: 'pptx', size: 16 })} />; },
			},
			{ key: 'column1', name: 'Filename', fieldName: 'presentationName', minWidth: 150, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick, },
			{ key: 'column2', name: 'File size', fieldName: 'fileSizeNum', minWidth: 80, maxWidth: 100, isResizable: true, onColumnClick: this._onColumnClick, onRender: (item: PresentationListItem) => { return <span>{item.fileSize}</span>; }, },
			{ key: 'column3', name: 'Upload date', fieldName: 'modifiedDate', minWidth: 150, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick, },
			{ key: 'column4', name: 'Publish date', fieldName: 'publishDate', minWidth: 150, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick, },
			...(GetUseGroups(this.props.settings) || (this.props.presentationItems.some((pres) => pres.slideGroups!?.length > 0))) ? [{
				key: 'column5',
				name: 'Groups',
				fieldName: 'slideGroups',
				minWidth: 150,
				maxWidth: 250,
				isResizable: true,
				// onColumnClick: this._onColumnClick,
				onRender: (item: PresentationListItem) => {
					if (item.slideGroups && !!this.props.groupIds) {
						const myGroups: GroupInfo[] = this.props.groupIds.filter((group: GroupInfo) => {
							return item.slideGroups?.filter((foldergroup) => {
								return group?.groupID === foldergroup.groupID
							}).length! > 0
						})
						// const groups: IFacepilePersona[] = item.templateGroups?.map((groupItem: GroupID) => {return {personaName: groupItem.groupID, label: '',  }});
						const groups: IFacepilePersona[] = myGroups.map((groupItem: GroupInfo) => { return { personaName: groupItem.groupName, label: '', } });
						if (GetUseGroups(this.props.settings)) {
							return (
								<Facepile
									personas={groups}
									personaSize={PersonaSize.size24}
									maxDisplayablePersonas={3}
									overflowButtonType={OverflowButtonType.descriptive}
									overflowButtonProps={{ ariaLabel: 'More' } as IButtonProps}
									ariaDescription={'To move through the items use left and right arrow keys.'}
									ariaLabel={'List of folder´s groups'}
									onRenderPersona={(item: IFacepilePersona | undefined) => {
										return (
											<Persona key={item?.personaName} text={item?.personaName} size={PersonaSize.size24} hidePersonaDetails={true} />
										)
									}}
								/>
							)
						}
						else if (groups.length > 0) {
							return (
								<TooltipHost
									content={<>This presentation is connected to a group. <b>Microsoft 365 Groups</b> needs to be enabled by an <b>Administrator</b> in <b>Settings</b>. The slides in this presentation is not accessible for any user.</>}
								>
									<Icon styles={{ root: { color: '#a80000' } }} iconName='BlockedSolid' />
								</TooltipHost>

							)
						}
						else { return null }
					}
					else { return null }
				},
			}] : [],
		]
	}

	commandItems = (): ICommandBarItemProps[] => {
		return [
			...(this.props.templateVersion === 'TEST' ? [{
				key: 'upload',
				text: 'Upload',
				// disabled: (this.props.templateVersion === 'PUB'),
				iconProps: { iconName: 'Upload' },
				onClick: () => this._uploadPrompt(),
			}] : []),
			{
				key: 'download',
				text: 'Download',
				iconProps: { iconName: 'Download' },
				onClick: () => this._downloadFile(),
			},
			...(this.props.templateVersion === 'TEST' ? [{
				key: 'edit',
				text: 'Edit',
				disabled: (!GetUseGroups(this.props.settings) && !(this.state.selectedItem.slideGroups!?.length! > 0)),
				iconProps: { iconName: 'Edit' },
				onClick: () => this._editPrompt(),
			}] : []),
			{
				key: 'delete',
				text: 'Delete',
				iconProps: { iconName: 'Delete' },
				onClick: () => this._deletePrompt(),
			},
			...(this.props.templateVersion === 'TEST' ? [{
				key: 'publish',
				text: 'Publish Presentation',
				// disabled: (this.props.templateVersion === 'PUB'),
				iconProps: { iconName: 'PublishContent' },
				onClick: () => this._publishPrompt(this.state.selectedItem.presentationName! || ''),
			}] : []),
			...(this.props.templateVersion === 'TEST' ? [{
				key: 'publishAll',
				text: 'Publish All',
				// disabled: (this.props.templateVersion === 'PUB'),
				iconProps: { iconName: 'PublishContent' },
				onClick: () => this._publishPrompt('ALL'),
			}] : []),
		]
	};

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	componentDidUpdate(prevProps: Readonly<SlidesFilesProps>, _prevState: Readonly<SlidesFilesState>, _snapshot?: any): void {
		if (prevProps.presentationItems !== this.props.presentationItems) {
			this.setState({
				columns: this.getColums(),
				presentationItems: this.props.presentationItems
			})
		}
		if (this.state.publishConfirmed && !this.state.publishing && this.state.publishFilename) {
			this._publishPresentation(this.state.publishFilename)
			if (infoMessage) { infoMessage.innerHTML = ReactDOMServer.renderToString(<ProgressIndicator label="Publishing presentations" description={`Updating slide gallery`} />) }
		}
		else if (this.state.deleteConfirmed && !this.state.deleting) {
			this._deleteSelectedFiles()
			if (infoMessage) { infoMessage.innerHTML = ReactDOMServer.renderToString(<ProgressIndicator label="Deleting presentation" description={`Updating slide gallery`} />) }
		}
		else if (this.state.uploadConfirmed && !this.state.uploading) {
			console.log(this.state.uploadItems)
			this._uploadFile()
			if (infoMessage) { infoMessage.innerHTML = ReactDOMServer.renderToString(<ProgressIndicator label="Uploading presentations" description={`Updating slide gallery`} />) }
		}
		else if (this.state.editConfirmed && !this.state.editing) {
			console.log(this.state.uploadItems)
			this._editFile()
			if (infoMessage) { infoMessage.innerHTML = ReactDOMServer.renderToString(<ProgressIndicator label="Editing presentation" description={`Updating slide gallery`} />) }
		}
		else {
			if (infoMessage) { infoMessage.innerHTML = '' }
		}
	}

	componentDidMount(): void {
		this.setState({
			columns: this.getColums(),
			presentationItems: this.props.presentationItems
		})
	}

	render(): JSX.Element {

		// if (!hasValues(this.state.slideItems)) {
		// 	this.getSlidesPresenations(this.props.templateVersion)
		// 	if (infoMessage) { infoMessage.innerHTML = ReactDOMServer.renderToString(<ProgressIndicator label="Fetching presentations" description={`Updating slide gallery`} />) }
		// }
		console.log(this.state)

		return (
			<Stack className='Width100'>
				<span className='Indent20'>
					<h3>{this.props.templateVersion === 'TEST' ? 'Presentations in Test' : 'Published Presentations'}</h3>
				</span>
				<Stack style={{ paddingLeft: '20px', paddingRight: '20px' }}>
					<div ref={element => { infoMessage = element }}></div>
				</Stack>
				<CommandBar
					items={this.commandItems()}
					ariaLabel="Use left and right arrow keys to navigate between commands"
				/>
				{this.state.uploading && <ProgressIndicator
						styles={{ root: { padding: '20px' } }}
						label="Uploading presentation..."
						description=''
					/>}
				{this.state.presentationItems !== examplePresentationList &&
					<ShimmeredDetailsList
						items={this.state.presentationItems}
						columns={this.state.columns}
						enableShimmer={this.state.presentationItems?.length === 0}
						setKey="set"
						layoutMode={DetailsListLayoutMode.justified}
						selectionPreservedOnEmptyClick={true}
						selectionMode={SelectionMode.single}
						selection={(this._selection)}
						ariaLabelForSelectionColumn="Toggle selection"
						ariaLabelForSelectAllCheckbox="Toggle selection for all items"
						checkButtonAriaLabel="Row checkbox"
						checkboxVisibility={CheckboxVisibility.always}
						onActiveItemChanged={this._onActiveItemChanged}
					/>
				}
				<DialogDelete setParentState={this.boundSetState} hidden={this.state.hideDeleteDialog} hideState='hideDeleteDialog' confirmState='deleteConfirmed' filename={this._selection.count === 1 ? ` file ${this.state.selectedItem.presentationName}` : ` ${this._selection.count} files`} />
				<DialogUploadSlides
					setParentState={this.boundSetState}
					hidden={this.state.hideUploadDialog}
					hideState='hideUploadDialog'
					confirmState='uploadConfirmed'
					uploadItemState='uploadItems'
					companyGroups={this.props.companyGroups}
					showGroups={GetUseGroups(this.props.settings)}
				/>
				<DialogEditSlides
					setParentState={this.boundSetState}
					hidden={this.state.hideEditDialog}
					hideState='hideEditDialog'
					confirmState='editConfirmed'
					uploadItemState='uploadItems'
					selectedPresentation={this.state.selectedItem}
					companyGroups={this.props.companyGroups}
					showGroups={GetUseGroups(this.props.settings) || this.state.selectedItem.slideGroups?.length! > 0}
				/>
				<DialogOkAbort setParentState={this.boundSetState} hidden={this.state.hidePublishDialog} hideState='hidePublishDialog' confirmState='publishConfirmed' title='Publish slides' text={this.state.publishFilename === 'ALL' ? 'Are you sure you want to replace all presentations in Published with the presentations from Test?' : `Are you sure you want to publish presentation file ${this.state.publishFilename}?`} />
				<DialogOkOnly setParentState={this.boundSetState} hidden={this.state.hideNoSelectDialog} hideState='hideNoSelectDialog' title='Nothing selected' text='You have to select a file to use this option.' />
				<DialogOkOnly setParentState={this.boundSetState} hidden={this.state.hideFileExist} hideState='hideFileExist' title='File exist' text='This filename already exist.' />
			</Stack>
		);
	}



	private _onActiveItemChanged = (item?: SlideListItem): void => {
		console.log(this._selection)
		console.log(item)
		this.setState({ selectedItem: item! })
		this.props.getSlides(item?.presentationName!, this.props.templateVersion)
	}

	private _uploadPrompt() {
		this.setState({ hideUploadDialog: false })
	}

	private _uploadFile(): void {
		this.setState({ uploading: true, uploadConfirmed: false })
		//this.setState({uploadItem: {filename: '123', base64: '123'}})
		try {
			this.state.uploadItems.forEach((value, _index, _array) => {
				uploadPresentationFile(
					this.props.accessToken,
					this.props.domain,
					this.props.api,
					value.filename,
					value.data
				)
					.then(async (response) => {
						if (response.status === 200) {
							console.log('GROUPS')
							console.log(value.groups!.length)
							if (value.groups!.length > 0) {
								setTimeout(() => {
									updatePresentationFile(
										this.props.accessToken,
										this.props.domain,
										this.props.api,
										value.filename,
										'TEST',
										JSON.stringify(value.groups)
									)
								}, 200)
							}
							if (_index === _array.length - 1) {
								setTimeout(() => {
									this.setState({
										presentationItems: [],
										uploadConfirmed: false,
										uploading: false,
										hideUploadDialog: true,
										uploadItems: [],
									})
									this.props.getPresentations(this.props.templateVersion)
									this.props.setMessage({ message: response.data, messageType: MessageBarType.success, visible: true })									
								}, 500)
							}
						}
						else if (response.status === 405) {
							this.setState({
								hideFileExist: false,
								uploadConfirmed: false,
								hideUploadDialog: true,
								uploading: false,
								uploadItems: [],
							})
							this.props.setMessage({ message: typeof response.data ==='string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
						}
						else {
							this.setState({
								uploadConfirmed: false,
								hideUploadDialog: true,
								uploading: false,
								uploadItems: [],
							})
							this.props.setMessage({ message: typeof response.data ==='string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
						}
					})
					.finally(() => {
						this.setState({
							uploadConfirmed: false,
							hideUploadDialog: true,
							uploading: false,
							uploadItems: [],
						})
					})
			})
		} catch (error) {
			this.setState({
				uploadConfirmed: false,
				hideUploadDialog: true,
				uploading: false,
				uploadItems: [],
			})
		}
	}

	private _editPrompt() {
		if (this._selection.count > 0 && !this._selection.isIndexSelected(0)) {
			this.setState({ hideEditDialog: false })
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}
		// this.setState({hideEditFolderDialog: false})
	}

	private _editFile(): void {
		this.setState({ editing: true, editConfirmed: false })
		//this.setState({uploadItem: {filename: '123', base64: '123'}})
		try {
			updatePresentationFile(
				this.props.accessToken,
				this.props.domain,
				this.props.api,
				this.state.uploadItems[0].filename,
				'TEST',
				JSON.stringify(this.state.uploadItems[0].groups)
			)
				.then(async (response) => {
					if (response.status === 200) {
						setTimeout(() => {
							this.setState({
								presentationItems: [],
								editConfirmed: false,
								editing: false,
								hideEditDialog: true,
								uploadItems: [],
							})
							this.props.getPresentations(this.props.templateVersion)
							this.props.setMessage({ message: response.data, messageType: MessageBarType.success, visible: true })
						}, 500)
					}
					else {
						this.setState({
							editConfirmed: false,
							hideEditDialog: true,
							editing: false,
							uploadItems: [],
						})
						this.props.setMessage({ message: typeof response.data ==='string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
					}
				})
		} catch (error) {
			this.setState({
				editConfirmed: false,
				hideEditDialog: true,
				editing: false,
				uploadItems: [],
			})
			this.props.setMessage({ message: (error as AxiosError).message, messageType: MessageBarType.error, visible: true })
		}
		this.setState({
			editing: false,
		})
	}

	private _deletePrompt() {
		if (this._selection.count > 0) {
			this.setState({ hideDeleteDialog: false })
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}
	}

	private _deleteSelectedFiles() {
		this.setState({ deleting: true, deleteConfirmed: false })
		if (true) {
			let i: number;
			const selectionLen: number = this._selection.getSelection().length
			for (i = 0; i < selectionLen; i++) {
				let selectedFile: PresentationListItem = this._selection.getSelection()[i] as PresentationListItem
				//TODO templates-version
				deletePresentationFile(this.props.accessToken, this.props.domain, this.props.api, selectedFile.presentationName, this.props.templateVersion)
			}
			setTimeout(() => {
				this.setState({
					presentationItems: [],
					selectedItem: {} as PresentationListItem,
					hideDeleteDialog: true,
					deleting: false,
				})
				this.props.getPresentations(this.props.templateVersion)
				this.props.getSlides('', this.props.templateVersion)
				this.props.setMessage({ message: selectionLen === 1 ? `Removed 1 file.` : `Removed ${selectionLen} files.`, messageType: MessageBarType.success, visible: true })
			}, 500)
		}

	}

	private _publishPrompt(fileName: string) {
		if (this._selection.count === 0 && fileName !== 'ALL') {
			this.setState({ hideNoSelectDialog: false })
		}
		else {
			this.setState({ hidePublishDialog: false, publishFilename: fileName })
		}
	}

	private _publishPresentation(fileName: string): void {
		this.setState({ publishing: true, publishConfirmed: false })
		publishPresentationFiles(
			this.props.accessToken,
			this.props.domain,
			this.props.api,
			fileName
		)
			.then((response) => {
				if (response.status === 200) {
					this.props.setMessage({ message: response.data, messageType: MessageBarType.success, visible: true })
				}
				else {
					this.props.setMessage({ message: typeof response.data ==='string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
				}
				this.props.getPresentations(this.props.templateVersion)				
			})
			.catch((error) => {
				this.props.setMessage({ message: (error as AxiosError).message, messageType: MessageBarType.error, visible: true })
			})
			.finally(() => {
				this.setState({ publishing: false, publishFilename: '' })
			})
	}

	private async _downloadFile() {
		if (typeof this._selection.getSelection()[0] !== 'undefined') {
			ourRequest = Axios.CancelToken.source()
			let selectedFile: PresentationListItem = this._selection.getSelection()[0] as PresentationListItem
			let fileBase64 = await GetSlidesPresentation(this.props.accessToken, this.props.domain, this.props.api, selectedFile.presentationName, this.props.templateVersion, ourRequest.token)
			let anchor: HTMLAnchorElement = document.createElement("a");
			anchor.href = 'data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,' + JSON.parse(fileBase64);
			anchor.download = selectedFile.presentationName;
			anchor.click();
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}

	}

	private _onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
		const { columns, presentationItems: slideItems } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
				// this.setState({
				//   announcedMessage: `${currColumn.name} is sorted ${
				// 	currColumn.isSortedDescending ? 'descending' : 'ascending'
				//   }`,
				// });
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		const newItems = sortArray(slideItems, currColumn.fieldName!, currColumn.isSortedDescending);
		this.setState({
			columns: newColumns,
			presentationItems: newItems,
		});
	};
}
