import { CheckboxVisibility, CommandBar, DetailsListLayoutMode, IColumn, ICommandBarItemProps, Icon, Image, Selection, SelectionMode, ShimmeredDetailsList, Stack, TooltipHost } from '@fluentui/react';
import * as React from 'react';
import { NewsItem } from '../../interfaces/NewsItem';
import { GetDate, GetTime } from '../../helpers/DateTime';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';

interface NewsListProps {
    templateVersion: 'PROD' | 'TEST'
    onCommandItemClick(key: string, selectedItem: NewsItem): void
    newsItems: NewsItem[]
    selectedItem: NewsItem | null
    setSelectedItem: (selectedItem: NewsItem) => void
}

const NewsList: React.FC<NewsListProps> = (props) => {

    let selection: Selection = new Selection({
        onSelectionChanged: () => props.setSelectedItem(selection.getSelection()[0] as NewsItem)
    });

    React.useEffect(() => {
        selection.selectToKey(props.selectedItem?.ID!)
    },[props.selectedItem])

    // React.useEffect(() => {
    //     selection = new Selection({
    //         onSelectionChanged: () => props.setSelectedItem(selection.getSelection()[0] as NewsItem)
    //     });
    // }, [])

    const columns: IColumn[] = React.useMemo(() =>
        [
            {
                key: 'column1',
                name: 'Title',
                fieldName: 'Title',
                minWidth: 150,
                maxWidth: 300,
                isResizable: true,
            },
            {
                key: 'column2',
                name: props.templateVersion === 'TEST' ? 'Post date' : 'Published date',
                fieldName: props.templateVersion === 'TEST' ? 'PostDate' : 'PublishDate',
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                onRender: (item: NewsItem) => {
                    if (!item.PostDate && !item.PublishDate) { return null }
                    return props.templateVersion === 'TEST' ? `${GetDate(new Date(item.PostDate!))} ${GetTime(new Date(item.PostDate!))}` : `${GetDate(new Date(item.PublishDate!))} ${GetTime(new Date(item.PublishDate!))}`
                }
            },
            {
                key: 'column3',
                name: 'Image',
                fieldName: 'Image',
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                onRender: (item: NewsItem) => {
                    return item.Image ? (
                        <TooltipHost
                            content={(
                                <Image
                                    styles={{ image: { maxWidth: 300, maxHeight: 300, objectFit: 'contain' } }}
                                    src={item.Image}
                                />
                            )}
                        >
                            {/* <Icon  styles={{root: {fontSize: '16px'}}} iconName='PictureCenter' />	 */}
                            <Icon {...getFileTypeIconProps({ extension: 'jpg', size: 20 })} />
                        </TooltipHost>
                    ) : null
                }
            },
            {
                key: 'column4',
                name: 'Link',
                fieldName: 'Link',
                minWidth: 150,
                maxWidth: 150,
                isResizable: true,
            },
        ], [props.templateVersion])

    const commandItems: ICommandBarItemProps[] = React.useMemo(() =>
        props.templateVersion === 'TEST' ? [
            {
                key: 'newSize',
                text: 'New',
                iconProps: { iconName: 'Add' },
                onClick: () => props.onCommandItemClick('new', props.selectedItem!),
            },
            {
                key: 'publish',
                text: 'Publish',
                iconProps: { iconName: 'PublishContent' },
                disabled: !props.selectedItem,
                onClick: () => props.onCommandItemClick('publish', props.selectedItem!),
            },
            {
                key: 'delete',
                text: 'Delete',
                iconProps: { iconName: 'Delete' },
                disabled: !props.selectedItem,
                onClick: () => props.onCommandItemClick('delete', props.selectedItem!),
            },
        ] : [
            {
                key: 'delete',
                text: 'Delete',
                iconProps: { iconName: 'Delete' },
                disabled: !props.selectedItem,
                onClick: () => props.onCommandItemClick('delete', props.selectedItem!),
            },
        ], [props.templateVersion, props.selectedItem])

    return (
        <Stack className='Width100'>
            <span className='Indent20'><h3>News list</h3></span>
            <CommandBar
                items={commandItems}
                ariaLabel="Use left and right arrow keys to navigate between commands"
            />
            {props.newsItems.length &&
                <ShimmeredDetailsList
                    items={props.newsItems.map((item) => {return {...item, key: item.ID}})}
                    columns={columns}
                    enableShimmer={!props.newsItems}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={SelectionMode.single}
                    selection={selection}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                    checkboxVisibility={CheckboxVisibility.always}
                    onActiveItemChanged={(item) => props.setSelectedItem(item as NewsItem)}
                />
            }
        </Stack>
    )
}

export default NewsList;