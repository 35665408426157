import * as React from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import { GraphContextType, MessageContextType, TenantContextType, UserContextType } from '../interfaces/ContextType';
import { MessageBarType } from '@fluentui/react';
import SettingsCompany from '../helpers/SettingsCompany';
import { UserAdminItem } from '../interfaces/UserAdminItem';
import { GraphUser } from '../interfaces/GraphUser';
import { GraphOrganizationData } from '../interfaces/GraphOrganizationData';

export const MessageContext = createContext({
  messageContext: {} as MessageContextType,
  setMessageContext: {} as React.Dispatch<React.SetStateAction<MessageContextType>>
});
export const GraphContext = createContext({
  graphContext: {} as GraphContextType,
  setGraphContext: {} as React.Dispatch<React.SetStateAction<GraphContextType>>
});
export const TenantContext = createContext({
  tenantContext: {} as TenantContextType,
  setTenantContext: {} as React.Dispatch<React.SetStateAction<TenantContextType>>
});
export const UserContext = createContext({
  userContext: {} as UserContextType,
  setUserContext: {} as React.Dispatch<React.SetStateAction<UserContextType>>
});

const ContextWrapper: React.FC = ({ children }) => {
  const [messageContext, setMessageContext] = useState<MessageContextType>({
    messageType: MessageBarType.info,
    message: '',
    visible: false
  });

  const [graphContext, setGraphContext] = useState<GraphContextType>({
    userPhoto: '',
    userDetails: {} as GraphUser,
    compDetails: {} as GraphOrganizationData,
    userDefaultDomain: '',
    verifiedDomain: [],
    adminMode: false
  });

  const [tenantContext, setTenantContext] = useState<TenantContextType>({
    tenantName: '',
    api: '',
    apiUri: '',
    groupIds: [],
    logo: '',
    settings: {} as SettingsCompany,
    templateVersion: '',
    MyTenant: false,
    companyGroups: [],
    allGroups: []
  });

  const [userContext, setUserContext] = useState<UserContextType>({
    userData: {} as UserAdminItem
  });

  useEffect(() => {
    const storedGraphContext = localStorage.getItem('graphContext');
    const storedTenantContext = localStorage.getItem('tenantContext');
    const storedUserContext = localStorage.getItem('userContext');

    if (storedGraphContext) setGraphContext(JSON.parse(storedGraphContext));
    if (storedTenantContext) setTenantContext(JSON.parse(storedTenantContext));
    if (storedUserContext) setUserContext(JSON.parse(storedUserContext));
  }, []);

  useEffect(() => {
    localStorage.setItem('graphContext', JSON.stringify(graphContext));
    localStorage.setItem('tenantContext', JSON.stringify(tenantContext));
    localStorage.setItem('userContext', JSON.stringify(userContext));
  }, [graphContext, tenantContext, userContext]);

  const memoizedMessageContext = useMemo(() => ({ messageContext, setMessageContext }), [messageContext]);
  const memoizedGraphContext = useMemo(() => ({ graphContext, setGraphContext }), [graphContext]);
  const memoizedTenantContext = useMemo(() => ({ tenantContext, setTenantContext }), [tenantContext]);
  const memoizedUserContext = useMemo(() => ({ userContext, setUserContext }), [userContext]);

  return (
    <MessageContext.Provider value={memoizedMessageContext}>
      <GraphContext.Provider value={memoizedGraphContext}>
        <TenantContext.Provider value={memoizedTenantContext}>
          <UserContext.Provider value={memoizedUserContext}>
            {children}
          </UserContext.Provider>
        </TenantContext.Provider>
      </GraphContext.Provider>
    </MessageContext.Provider>
  );
};

export default ContextWrapper;
