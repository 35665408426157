import React, { useContext, useEffect, } from "react";
import { CenterComponent } from "./components/common/CenterComponent";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { TenantContext, UserContext } from "./contexts/ContextWrapper";
import { getNavigationAddress } from "./helpers/NavigationHelper";

const Home: React.FC = () => {
    const { tenantContext } = useContext(TenantContext);
    const { userContext } = useContext(UserContext);
    // const {setMessageContext}=useContext(MessageContext);
    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;  // Added flag to check if component is still mounted
        console.log(!tenantContext.api, !tenantContext.tenantName, isMounted)
        if (isMounted) {
            if (!tenantContext.api || !tenantContext.tenantName) {
                setTimeout(() => {
                    if (!tenantContext.api || !tenantContext.tenantName) {
                        console.log('Home.tsx: useEffect: navigate')
                        navigate('/login');
                        // setMessageContext({ message: 'Session expired', messageType: MessageBarType.warning, visible: true });
                    }
                }, 2000);
            } else {
                const page: string = localStorage.getItem('selectedPage') || 'home';
                    navigate(getNavigationAddress(tenantContext,userContext,page));                
            }
        }
        return () => {
            isMounted = false;  // Update the flag when the component is unmounted
        };
    }, []);

    return (
        <CenterComponent>
            <Spinner className='spinner' size={SpinnerSize.large} label='Redirecting...' />
        </CenterComponent>
    );
}

export default Home;
