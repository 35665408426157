import { GroupID } from "./GroupID";

export interface TemplateListItem {
	id: string;
	icon: string;
	primaryText: string;
	fileSize: string;
	fileSizeNum: number;
	modifiedDate: Date;
	publishDate: Date;
	Base64?: string;
	Path?: string;
	Root?: string;
	Library?: string;
	hideOnline?: boolean;
	hideDesktop?: boolean;
	templateGroups?: GroupID[] | undefined;
}

export const exampleTemplateList: TemplateListItem[] = JSON.parse('[{"id":"-1","icon":"No items","primaryText":"No items","fileSize":null,"fileSizeNum":0,"modifiedDate":null,"Base64":"Not found"}]')