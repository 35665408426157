import * as React from 'react';
//import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { Checkbox, CheckboxVisibility, Dropdown, IColumn, Icon, Image, ImageFit, Label, Panel, PanelType, PrimaryButton, SearchBox, SelectionMode, ShimmeredDetailsList, Stack, Text, TooltipHost } from '@fluentui/react';
import { DetailsListLayoutMode, Selection, } from 'office-ui-fabric-react/lib/DetailsList';
import { uploadGroup } from '../../helpers/PostToRestApi';
import { sortArray } from '../../helpers/MiscFunctions';
import { GroupDetails } from '../../interfaces/GroupDetails';
import { GroupInfo } from '../../interfaces/GroupInfo';

export interface GroupsAddPanelProps {
	onDismiss: () => void;
	updateList(): void,
	//changeTextBox: (myTag: string, myValue: string) => void;
	groups: GroupDetails[],
	accessToken: string,
	// apiUri: string,
	domain: string,
	api: string,
}

export interface GroupsAddPanelState {
	filteredItems: GroupDetails[],
	selectedItem: GroupDetails,
	selection: Selection,
	columns: IColumn[],
	searchTerm: string,
	filtered: boolean,
	onlyPublic: boolean,
	onlyTeams: boolean,
	onlySecurity: boolean,
	selectedDomain: string,
}

const stackTokens = { childrenGap: 8 };
const buttonStyles = { root: { marginRight: 8 } };

export default class GroupsAddPanel extends React.Component<GroupsAddPanelProps, GroupsAddPanelState> {

	private _selection: Selection;

	constructor(props: GroupsAddPanelProps) {

		super(props);
		this._selection = new Selection({
			onSelectionChanged: () => this.setState({ selection: this._selection }),
		});
		this.state = {
			filteredItems: [],
			searchTerm: '',
			selectedItem: {} as GroupDetails,
			selection: this._selection,
			filtered: true,
			onlyPublic: false,
			onlyTeams: false,
			onlySecurity: false,
			columns: [{ key: 'column1', name: '', minWidth: 16, }],
			selectedDomain: [...new Set(this.props.groups.map(grp => grp.mail!?.split('@')[1] || ''))].filter(d => d)[0],
		}
	}

	getColums(): IColumn[] {
		return [
			{
				key: 'column1',
				name: 'displayName',
				fieldName: 'displayName',
				minWidth: 150,
				maxWidth: 600,
				isResizable: true,
				onColumnClick: this._onColumnClick,
			},
			{
				key: 'column2',
				name: 'main',
				fieldName: 'mail',
				minWidth: 150,
				maxWidth: 600,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				onRender: (item: GroupDetails) => {
					if (item.mail) { return item.mail.split('@')[1] || '' }
					return null
				},
			},
			{
				key: 'column5',
				name: 'Teams',
				fieldName: 'resourceProvisioningOptions',
				minWidth: 50,
				maxWidth: 50,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				onRender: (item: GroupDetails) => {
					return item.resourceProvisioningOptions![0] === "Team" ?
						<TooltipHost content={<>Teams</>}>
							<Image imageFit={ImageFit.contain} src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/svg/teams_16x1.svg" alt="Teams" style={{ width: 16, height: 16 }} />
						</TooltipHost>
						:
						item.securityEnabled ?
							<TooltipHost content={<>Security Group</>}>
								<Icon styles={{ root: { fontSize: '16px' } }} iconName='SecurityGroup' />
							</TooltipHost>
							:
							null
				},
			},
		]
	}

	onRenderFooterContent = () => {
		return (
			<Stack grow tokens={stackTokens}>
				<Stack.Item>
					<Label>{this.state.selectedItem.displayName}</Label>
					<Text>{this.state.selectedItem.description}<br/></Text>
					<Text>{this.state.selectedItem.mail}<br/></Text>
					<Text>{this.state.selectedItem.createdDateTime ? new Date(this.state.selectedItem.createdDateTime!).toLocaleDateString() : ''}<br/></Text>
					<Text>{this.state.selectedItem.visibility} {this.state.selectedItem.securityEnabled && 'Security Group'} {this.state.selectedItem.resourceProvisioningOptions!?.map(v => v)}</Text>
				</Stack.Item>
				<Stack.Item>
					<PrimaryButton styles={buttonStyles} onClick={() => this.addGroup()}>Add</PrimaryButton>
				</Stack.Item>
			</Stack>
		)
	}

	addGroup = async () => {
		let grpInfo: GroupInfo = {} as GroupInfo
		grpInfo.groupID = this.state.selectedItem.id
		grpInfo.groupName = this.state.selectedItem.displayName
		await uploadGroup(this.props.accessToken, this.props.domain, this.props.api, JSON.stringify(grpInfo))
			.then(() => { this.props.updateList() })
	}

	filterSearch = () => {
		this.setState({
			filteredItems: this.props.groups
				.filter(item => (item.displayName.toLowerCase()
					.includes(this.state.searchTerm) &&
					(!this.state.onlyPublic || item.visibility === 'Public') &&
					(!this.state.onlyTeams || item.resourceProvisioningOptions![0] === 'Team') &&
					(!this.state.onlySecurity || item.securityEnabled) &&
					(!item.mail || item.mail!?.split('@')[1] === this.state.selectedDomain)
				)),
			filtered: true,
		})
	}

	componentDidMount(): void {
		this.setState({ columns: this.getColums() })
	}

	domains: string[] = [...new Set(this.props.groups.map(grp => grp.mail!?.split('@')[1] || ''))].filter(d => d)

	render() {

		if (!this.state.filtered) {
			if (this.state.searchTerm.length > 1) {
				this.filterSearch();
			}
			else {
				this.setState({
					filteredItems: [],
					filtered: true,
				})
			}
		}

		return (
			<>
				<Panel
					headerText="Add Groups"
					isBlocking={false}
					isLightDismiss={true}
					allowTouchBodyScroll={true}
					isOpen={true}
					onDismiss={this.props.onDismiss}
					type={PanelType.medium}
					// You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
					closeButtonAriaLabel='Close'
					onRenderFooterContent={this.onRenderFooterContent}
					isFooterAtBottom={true}

				>
					{/* <Stack tokens={stackTokens} styles={{ root: { height: '80vh', overflow: 'auto' } }} style={{ paddingLeft: 15, paddingRight: 15 }}> */}
					<Stack tokens={stackTokens}>
						<Stack.Item>
							<SearchBox
								placeholder={'Search'}
								onChange={(_, newValue) => this.setState({
									searchTerm: newValue!.toString().toLowerCase(),
									filtered: false,
								})}
								styles={{ root: { width: '100%' } }}
								autoFocus
							/>
						</Stack.Item>
						{this.domains.length > 1 &&
							<Stack.Item>
								<Dropdown
									options={this.domains.map((d => { return { key: d, text: d } }))}
									selectedKey={this.state.selectedDomain}
									onChange={(_ev, option) => { this.setState({ selectedDomain: option?.key as string, filtered: false }) }}
								/>
							</Stack.Item>
						}
						<Stack.Item>
							<Stack horizontal tokens={stackTokens}>
								<Checkbox
									label="Only Public"
									disabled={this.state.onlySecurity}
									onChange={(_ev, checked) => { this.setState({ onlyPublic: checked!, onlySecurity: false, filtered: false }) }}
								/>
								<Checkbox
									label="Only Teams"
									disabled={this.state.onlySecurity}
									onChange={(_ev, checked) => { this.setState({ onlyTeams: checked!, onlySecurity: false, filtered: false }) }}
								/>
								<Checkbox
									label="Only Security Groups"
									disabled={this.state.onlyPublic || this.state.onlyTeams}
									onChange={(_ev, checked) => { this.setState({ onlySecurity: checked!, onlyPublic: false, onlyTeams: false, filtered: false }) }}
								/>
							</Stack>
						</Stack.Item>
						<Stack.Item grow styles={{ root: { height: '70vh', overflow: 'auto' } }}>
							<ShimmeredDetailsList
								items={this.state.filteredItems}
								columns={this.state.columns}
								enableShimmer={!this.state.filteredItems}
								setKey="set"
								layoutMode={DetailsListLayoutMode.justified}
								selectionPreservedOnEmptyClick={true}
								selectionMode={SelectionMode.single}
								ariaLabelForSelectionColumn="Toggle selection"
								ariaLabelForSelectAllCheckbox="Toggle selection for all items"
								ariaLabelForShimmer="Content is being fetched"
								ariaLabelForGrid="Item details"
								checkButtonAriaLabel="Row checkbox"
								selection={(this._selection)}
								checkboxVisibility={CheckboxVisibility.always}
								onActiveItemChanged={this._onActiveItemChanged}
								isHeaderVisible={false}
							/>
						</Stack.Item>
					</Stack>
				</Panel>
			</>

		);
	}

	private _onActiveItemChanged = (item?: GroupDetails): void => {
		// console.log(item?.User)
		this.setState({ selectedItem: item! })
	}

	private _onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
		const { columns, filteredItems: users } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
				// this.setState({
				//   announcedMessage: `${currColumn.name} is sorted ${
				// 	currColumn.isSortedDescending ? 'descending' : 'ascending'
				//   }`,
				// });
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		const newItems = sortArray(users, currColumn.fieldName!, currColumn.isSortedDescending);
		this.setState({
			columns: newColumns,
			filteredItems: newItems,
		});
	};
}