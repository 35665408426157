import * as React from 'react';
import {
	CheckboxVisibility,
	CommandBar,
	IColumn,
	ICommandBarItemProps,
	Icon,
	IProgressIndicatorStyles,
	ProgressIndicator,
	SelectionMode,
	ShimmeredDetailsList,
	Stack,
} from '@fluentui/react';
import { DetailsListLayoutMode, Selection, } from 'office-ui-fabric-react/lib/DetailsList';
import { PicturesState } from './Pictures';
import { deletePictureFolder } from '../../helpers/PostToRestApi';
import SettingsCompany, { GetMaxPictureCount } from '../../helpers/SettingsCompany';
import { DialogEditPictureSizes } from '../dialogs/DialogEditPictureSizes';
import { ExampleLayoutList, PictureSrcLib } from '../../interfaces/PictureSrcLib';
import { DialogOkOnly } from '../dialogs/OldDialogOkOnly';
import { DialogDelete } from '../dialogs/OldDialogDelete';

export interface PictureSrcLibExt extends PictureSrcLib {
	key: string,
	index: number,
	libraryCount: number,
}

export interface PicturesCategoriesProps {
	setParentState: (x: PicturesState) => void;
	getLayoutItems(reset: boolean): void;
	pictureLayoutItems: PictureSrcLib[];
	selectedIndex: number;
	accessToken: string,
	// apiUri: string,
	domain:string
	api:string
	settings: SettingsCompany;
	totalPicCount: number;
}

export interface PicturesCategoriesState {
	items: PictureSrcLibExt[];
	// extendedItems: PictureSrcLibExt[];
	columns: IColumn[];
	selection: Selection;
	selectedItem: PictureSrcLib;
	hideNoSelectDialog: boolean;

	hideSelectedHasPics: boolean;
	hideEditDialog: boolean;
	hideNewDialog: boolean;
	hideDeleteDialog: boolean;
	deleteConfirmed: boolean;
}



export default class PicturesLayouts extends React.Component<PicturesCategoriesProps, PicturesCategoriesState> {
	private _selection: Selection;

	constructor(props: PicturesCategoriesProps) {
		super(props);
		this._selection = new Selection({
			onSelectionChanged: () => this.setState({ selection: this._selection }),
		});
		this.state = {
			items: [],
			columns: [{ key: 'column1', name: '', minWidth: 16, }],
			selection: this._selection,
			selectedItem: {} as PictureSrcLib,
			hideNoSelectDialog: true,
			hideSelectedHasPics: true,
			hideEditDialog: true,
			hideNewDialog: true,
			hideDeleteDialog: true,
			deleteConfirmed: false,
		};
		this.boundSetState = this.setState.bind(this);
	}

	deleting: Boolean = false

	boundSetState = (x: any) => { this.setState(x) }

	getColums(): IColumn[] {
		return [
			{
				key: 'column0',
				name: 'Icon',
				fieldName: 'icon',
				// iconName: 'FabricFolder',				
				isIconOnly: true,
				minWidth: 16,
				maxWidth: 16,
				isResizable: false,
				onRender: (item: PictureSrcLibExt) => { return <Icon styles={{ root: { fontSize: '16px' } }} iconName={item.imgSrc === this.state.selectedItem!?.imgSrc ? 'SizeLegacy' : 'SizeLegacy'} />; },
			},
			{ key: 'column1', name: 'Size name', fieldName: 'imgSrc', minWidth: 150, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick, },
			{
				key: 'column2',
				name: 'Preview Size',
				ariaLabel: 'Preview Size is the size of the picture in the panel',
				fieldName: 'previewHeight',
				minWidth: 120,
				maxWidth: 120,
				isResizable: true,
				onRender: (item: PictureSrcLibExt) => {
					return "H: " + item.previewHeight + " px, W: " + item.previewWidth + " px"
				},
			},
			{
				key: 'column3',
				name: 'Tooltip Size',
				ariaLabel: 'Tooltip Size is the size of the picture when you hoover over a picture in the panel',
				fieldName: 'previewHeight',
				minWidth: 120,
				maxWidth: 120,
				isResizable: true,
				onRender: (item: PictureSrcLibExt) => {
					return "H: " + item.previewBigHeight + " px, W: " + item.previewBigWidth + " px"
				},
			},
			{ key: 'column4', name: '# Categories', fieldName: 'libraryCount', minWidth: 100, maxWidth: 200, isResizable: true, onColumnClick: this._onColumnClick, },
			{ key: 'column5', name: '# Pictures', fieldName: 'PicturesCount', minWidth: 100, maxWidth: 200, isResizable: true, onColumnClick: this._onColumnClick, },
		]
	}

	picCounter(): ICommandBarItemProps[] {
		const maxCount: number = GetMaxPictureCount(this.props.settings)
		const percentFull: number = (this.props.totalPicCount || 0) / (maxCount)
		const styleFull: Partial<IProgressIndicatorStyles> = {
			itemName: { color: '#a4262c' },
			progressBar: { backgroundColor: '#a4262c' }
		}
		return [
			{
				key: 'picCounter',
				onRender: () => {
					return <ProgressIndicator
						label={`${this.props.totalPicCount || 0} / ${maxCount} Pictures`}
						percentComplete={percentFull}
						barHeight={4}
						styles={{ root: { width: '200px' }, ...(percentFull >= 1 ? styleFull : null) }}
					/>
				}
			},
		]
	}

	getExtendedItems(items: PictureSrcLib[]): PictureSrcLibExt[] {
		console.log(items)
		if (!items) { return [] }
		else {
			return items.map((item: PictureSrcLib, index) => {
				return {
					...item,
					index: index++,
					key: 'item' + index,
					libraryCount: item.Libraries.length
				}
			})
		}
	}

	componentDidUpdate(prevProps: Readonly<PicturesCategoriesProps>, _prevState: Readonly<PicturesCategoriesState>, _snapshot?: any): void {
		if (prevProps.pictureLayoutItems !== this.props.pictureLayoutItems) {
			this.setState({
				columns: this.getColums(),
				items: this.getExtendedItems(this.props.pictureLayoutItems) || [],
				selectedItem: this.props.pictureLayoutItems![this.props.selectedIndex!  || 0] 
			})
		}
		else if (_prevState.selectedItem !== this.state.selectedItem) {
			this.setState({
				columns: this.getColums(),
			})
		}
		// else if (!_prevState.hideEditDialog && this.state.hideEditDialog) {
		// 	console.log(this.props)
		// 	this.props.getLayoutItems()
		// }
		if (this.state.deleteConfirmed && !this.deleting) {
			this._deleteSelectedLayout()
		}
		if (this._selection.getSelectedCount() === 0) {
			this._selection.setIndexSelected(0, true, true)
		}
	}

	componentDidMount(): void {
		this.setState({
			columns: this.getColums(),
			items: this.getExtendedItems(this.props.pictureLayoutItems) || [],
			selectedItem: this.props.pictureLayoutItems![this.props.selectedIndex! || 0] 
		})
	}

	render(): JSX.Element {
		return (
			<Stack className='Width100'>
				<span className='Indent20'><h3>Picture Size</h3></span>
				<CommandBar
					items={this.commandItems}
					ariaLabel="Use left and right arrow keys to navigate between commands"
					farItems={this.picCounter()}
				/>
				<ShimmeredDetailsList
					items={this.state.items}
					columns={this.state.columns}
					enableShimmer={!this.state.items}
					setKey="set"
					layoutMode={DetailsListLayoutMode.justified}
					selectionPreservedOnEmptyClick={true}
					selectionMode={this.props.pictureLayoutItems === ExampleLayoutList ? SelectionMode.none : SelectionMode.single}
					selection={(this._selection)}
					ariaLabelForSelectionColumn="Toggle selection"
					ariaLabelForSelectAllCheckbox="Toggle selection for all items"
					checkButtonAriaLabel="Row checkbox"
					checkboxVisibility={CheckboxVisibility.always}
					onActiveItemChanged={this._onActiveItemChanged}
				/>
				<DialogEditPictureSizes
					setParentState={this.boundSetState}
					getLayoutItems={this.props.getLayoutItems}
					hidden={this.state.hideEditDialog}
					hideState='hideEditDialog'
					items={this.state.items}
					selectedIndex={this.props.selectedIndex}
					accessToken={this.props.accessToken}
					domain={this.props.domain}
					api={this.props.api}
					isNew={false}
				/>
				<DialogEditPictureSizes
					setParentState={this.boundSetState}
					getLayoutItems={this.props.getLayoutItems}
					hidden={this.state.hideNewDialog}
					hideState='hideNewDialog'
					items={this.state.items}
					selectedIndex={this.state.items === ExampleLayoutList ? 0 : this.state.items.length}
					accessToken={this.props.accessToken}
					domain={this.props.domain}
					api={this.props.api}
					isNew={true}
				/>
				<DialogOkOnly
					setParentState={this.boundSetState}
					hidden={this.state.hideNoSelectDialog}
					hideState='hideNoSelectDialog'
					title='Nothing selected'
					text='You have to select a picture size to use this option.'
				/>
				<DialogOkOnly
					setParentState={this.boundSetState}
					hidden={this.state.hideSelectedHasPics}
					hideState='hideSelectedHasPics'
					title='Selected gallery is not empty'
					text='You have to delete all pictures in the gallery first.'
				/>
				<DialogDelete
					setParentState={this.boundSetState}
					hidden={this.state.hideDeleteDialog}
					hideState='hideDeleteDialog'
					confirmState='deleteConfirmed'
					filename={` picture size ${this.state.selectedItem!?.imgSrc}`}
				/>
			</Stack>
		);
	}

	private commandItems: ICommandBarItemProps[] = [
		{
			key: 'newSize',
			text: 'New Size',
			iconProps: { iconName: 'Add' },
			onClick: () => this._addSize(),
		},
		{
			key: 'edit',
			text: 'Edit',
			iconProps: { iconName: 'Edit' },
			onClick: () => this._editPrompt(),
		},
		{
			key: 'delete',
			text: 'Delete',
			iconProps: { iconName: 'Delete' },
			onClick: () => this._deletePrompt(),
		},
		// {
		// 	key: 'save',
		// 	text: 'Save',
		// 	disabled: true,
		// 	iconProps: { iconName: 'Save' },
		// 	onClick: () => console.log('Save'),
		// },
		// {
		// 	key: 'publish',
		// 	text: 'Publish',
		// 	disabled: true,
		// 	iconProps: { iconName: 'PublishContent' },
		// 	onClick: () => console.log('Publish'),
		// },
	];

	private _addSize() {
		this.setState({ hideNewDialog: false })
	}

	private _editPrompt() {
		if (this._selection.count > 0) {
			this.setState({ hideEditDialog: false })
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}
	}

	private _deletePrompt() {
		if (this._selection.count > 0) {
			console.log(this.state.selectedItem.PicturesCount!)
			if (this.state.selectedItem.PicturesCount! === 0) {
				this.setState({ hideDeleteDialog: false })
			}
			else {
				this.setState({ hideSelectedHasPics: false })
			}
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}
	}

	private _deleteSelectedLayout() {
		this.deleting = true
		deletePictureFolder(this.props.accessToken, this.props.domain, this.props.api, this.state.selectedItem.Path!)
			.then(() => {
				setTimeout(() => {
					this.setState({
						deleteConfirmed: false,
					})
					this.deleting = false
					this.props.getLayoutItems(true)
					this._selection.setAllSelected(false)
				}, 500)
			})
	}

	private _onActiveItemChanged = (item?: PictureSrcLibExt): void => {
		console.log(item)
		if (item) {
			this.props.setParentState({
				selectedPath: item.Path!,
				selectedIndex: item.index,
				pictureItems: [],
			})
			this.setState({ selectedItem: item })
		}
	}

	private _onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
		const { columns, items } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
				// this.setState({
				//   announcedMessage: `${currColumn.name} is sorted ${
				// 	currColumn.isSortedDescending ? 'descending' : 'ascending'
				//   }`,
				// });
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		const newItems = this._copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
		this.setState({
			columns: newColumns,
			items: newItems,
		});
	};

	_copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
		const key = columnKey as keyof T;
		return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
	}
}
