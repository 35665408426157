
import * as React from 'react';
import { ScrollablePane, Stack, Text } from '@fluentui/react';
import { ContentFrame } from '../common/ContentFrame';
import Axios, { CancelTokenSource } from 'axios';
import { GetAcademyLearningLevels, GetAcademyLevels, GetAcademyList, GetAcademyPlatforms } from '../../helpers/GetFromRestApi';
import { AuthContext } from '../../contexts/AuthContext';
import { TenantContext } from '../../contexts/ContextWrapper';
import AcademyList from './AcademyList';
import AcademyLesson from './AcademyLesson';
import { AcademyItem, AcademyLearningLevel, AcademyLevel, AcademyPlatform } from '../../interfaces/AcademyItem';
import { deleteAcademyItem, uploadAcademyItem } from '../../helpers/PostToRestApi';
import { DialogDelete } from '../dialogs/DialogDelete';
import { AcademyPlatformItems, AcademySimpleItem } from '../../interfaces/AcademyPlatform';

export interface AcademyProps {

}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

const Academy: React.FC<AcademyProps> = () => {

    const { accessToken } = React.useContext(AuthContext);
    const { tenantContext } = React.useContext(TenantContext);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    // const [selectedSimpleItem, setSelectedSimpleItem] = React.useState<AcademySimpleItem | null>(null);
    // const [selectedItem, setSelectedItem] = React.useState<AcademyItem | null>(null);
    const [selectedId, setSelectedId] = React.useState<string>('');
    const [academyItems, setAcademyItems] = React.useState<AcademySimpleItem[]>([]);
    const [academyLearningLevels, setAcademyLearningLevels] = React.useState<AcademyLearningLevel[]>([]);
    const [academyPlatforms, setAcademyPlatforms] = React.useState<AcademyPlatform[]>([]);
    const [academyLevels, setAcademyLevels] = React.useState<AcademyLevel[]>([]);
    const [deleteDialog, setDeleteDialog] = React.useState<boolean>(false)
    const [isEdited, setIsEdited] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (accessToken) {
            getAllItems()
            // setSelectedItem(null)
        }
        return () => {
            try {
                ourRequest.cancel();
            } catch (error) {
                // Handle cancellation error if necessary
            }
        };
    }, [accessToken]);

    const simpleItemsFromPlatforms = (platforms: AcademyPlatformItems[]): AcademySimpleItem[] => {
        return platforms.map((platform) => {
            return platform.Items.map((item) => {
                return {
                    key: item.academyID,
                    academyID: item.academyID,
                    Environment: item.Environment,
                    Name: item.Name,
                    Description: item.Description,
                    Slug: item.Slug,
                    LevelID: item.LevelID,
                    LevelName: item.LevelName,
                    LearningLevelID: item.LearningLevelID,
                    LearningLevelName: item.LearningLevelName,
                    PlatformID: platform.PlatformID
                }
            })
        }).flat();
    }

    const getAllItems = async () => {
        ourRequest = Axios.CancelToken.source();
        setIsLoading(true);

        try {
            const [
                academyItemsResponse,
                academyLearningLevelsResponse,
                academyPlatformsResponse,
                academyLevelsResponse
            ] = await Promise.all([
                // GetAllAcademyItems(accessToken!, tenantContext.api, ourRequest.token),
                GetAcademyList(accessToken!, tenantContext.api, 'sv',),
                GetAcademyLearningLevels(accessToken!, tenantContext.api,),
                GetAcademyPlatforms(accessToken!, tenantContext.api),
                GetAcademyLevels(accessToken!, tenantContext.api,)
            ]);

            const academySimpleList: AcademySimpleItem[] = simpleItemsFromPlatforms(academyItemsResponse);
            setAcademyItems(academySimpleList || []);
            setAcademyLearningLevels(academyLearningLevelsResponse || []);
            setAcademyPlatforms(academyPlatformsResponse || []);
            setAcademyLevels(academyLevelsResponse || []);
        } catch (error) {
            // Handle errors if necessary
            console.error("Failed to fetch academy items", error);
        } finally {
            setIsLoading(false);
        }
    };

    const createNewItem = () => {
        const newSimpleItem: AcademySimpleItem = { academyID: 'new', PlatformID: '', Name: 'New...', Slug: '', } as AcademySimpleItem;
        setAcademyItems([newSimpleItem, ...academyItems])
        setSelectedId('new')
    }

    const uploadItem = async (item: AcademyItem, onDuplicateSlug: (message: string) => void) => {
        if (item.academyID === 'new') { item.academyID = '' };
        uploadAcademyItem(accessToken!, '', tenantContext.api, item, ourRequest.token)
            .then((response) => {
                if (response.status === 200) {
                    setAcademyItems(simpleItemsFromPlatforms(response.data))
                    setSelectedId(item.academyID)
                    setIsEdited(false)
                }
                else if (response.status === 409) {
                    onDuplicateSlug(response.data)
                }
            })
            .catch((error) => {
                console.error("Failed to upload academy item", error);
                getAllItems();
                setSelectedId('')
            })
            
    }

    const deleteItem = async (academyID: string) => {
        if (academyID === 'new') {
            setAcademyItems(academyItems.filter(x => x.academyID !== academyID));
            setSelectedId('')
        }
        else {
            await deleteAcademyItem(accessToken!, '', tenantContext.api, academyID, ourRequest.token);
            setAcademyItems(academyItems.filter(x => x.academyID !== academyID));
            setSelectedId('')
        }
    }

    const onCommandItemClick = (key: string) => {
        switch (key) {
            case 'new':
                createNewItem();
                break;
            // case 'publish':
            //     setShowConfirmPublish(true)
            //     break;
            case 'delete':
                setDeleteDialog(true)
                break;
            default:
                break;
        }
    }

    return (
        <ScrollablePane>
            <Stack>
                <Stack.Item className="" styles={{ root: { margin: '30px' } }}>
                    <Text variant="xxLarge">RehnAcademy</Text>
                    {/* {isEdited ? <Text variant="medium" style={{ color: 'red' }}>Unsaved changes</Text> : null} */}
                </Stack.Item>
                <Stack horizontal horizontalAlign="space-between"  >
                    <Stack.Item >
                        <ContentFrame hidden={isLoading}>
                            <AcademyList
                                templateVersion='TEST'
                                onCommandItemClick={onCommandItemClick}
                                academyItems={academyItems}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                                levels={academyLevels}
                                platforms={academyPlatforms}
                                isEdited={isEdited}
                            />
                        </ContentFrame>
                    </Stack.Item>
                    <Stack.Item grow>
                        <ContentFrame hidden={!selectedId}>
                            <AcademyLesson
                                selectedId={selectedId}
                                learningLevels={academyLearningLevels}
                                platforms={academyPlatforms}
                                levels={academyLevels}
                                updateItem={uploadItem}
                                isEdited={isEdited}
                                setIsEdited={setIsEdited}
                            />
                        </ContentFrame>
                    </Stack.Item>
                </Stack>
                <DialogDelete
                    callbackDelete={() => {
                        deleteItem(selectedId)
                        setDeleteDialog(false)
                    }}
                    callbackCancel={() => {
                        setDeleteDialog(false)
                    }}
                    hidden={!deleteDialog}
                    title='Delete lesson'
                    description={`Are you sure you want to delete lesson "${academyItems.find((item) => item.academyID === selectedId)?.Name || 'New lesson'}"?`}
                />
            </Stack>
        </ScrollablePane>
    );
}

export default Academy;
