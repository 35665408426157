import React, { useCallback, useEffect, useState } from 'react';
import {
	CheckboxVisibility,
	DetailsListLayoutMode,
	Facepile,
	IButtonProps,
	IColumn,
	Icon,
	IFacepilePersona,
	OverflowButtonType,
	Persona,
	PersonaSize,
	SelectionMode,
	ShimmeredDetailsList,
	Stack,
	TooltipHost,
} from '@fluentui/react';
import SettingsCompany, { GetUseGroups } from '../../helpers/SettingsCompany';
import { IconListItem } from '../../interfaces/IconListItem';
import { GroupInfo } from '../../interfaces/GroupInfo';
import { GroupID } from '../../interfaces/GroupID';

export interface IconsCategoriesProps {
	changeCategory: (category: string, height: number, width: number) => void
	getIcons(version: string): void
	iconItems: IconListItem[]
	settings: SettingsCompany
	groupIds: GroupInfo[]
	selectedCategory: string
	templateVersion: string
}

const IconsCategories: React.FC<IconsCategoriesProps> = (props) => {
	const [iconItems, setIconItems] = useState<IconListItem[]>([]);
	const [columns, setColumns] = useState<IColumn[]>([{ key: 'column1', name: '', minWidth: 16 }]);

	useEffect(() => {
		setColumns(getColums());
		setIconItems(getCategoryItems(props.iconItems));
	}, [props.iconItems, props.selectedCategory]);


	const getColums = useCallback(() : IColumn[] => {
		return [
			{
				key: 'column0',
				name: 'Icon',
				fieldName: 'icon',
				// iconName: 'FabricFolder',				
				isIconOnly: true,
				minWidth: 16,
				maxWidth: 16,
				isResizable: false,
				onRender: (item: IconListItem) => { return <Icon styles={{ root: { fontSize: '16px' } }} iconName={item.libraryName === props.selectedCategory ? 'FabricOpenFolderHorizontal' : 'FabricPictureLibrary'} />; },
			},
			{ key: 'column1', name: 'Library', fieldName: 'libraryName', minWidth: 150, maxWidth: 300, isResizable: true, onColumnClick: onColumnClick, },
			{
				key: 'column2', name: 'Preview Size', fieldName: 'previewHeight', minWidth: 120, maxWidth: 120, isResizable: true, onRender: (item: IconListItem) => {
					return "H: " + item.previewHeight + " px, W: " + item.previewWidth + " px"
				}
			},
			{ key: 'column3', name: 'Number of icons', fieldName: 'iconsCount', minWidth: 120, maxWidth: 120, isResizable: true },
			...(GetUseGroups(props.settings) || (props.iconItems.some((icon) => icon.iconGroups!?.length > 0))) ? [{
				key: 'column4',
				name: 'Groups',
				fieldName: 'iconGroups',
				minWidth: 150,
				maxWidth: 300,
				isResizable: true,
				onColumnClick: onColumnClick,
				onRender: (item: IconListItem) => {
					if (item.iconGroups && !!props.groupIds) {
						const myGroups: GroupInfo[] = props.groupIds.filter((group: GroupInfo) => {
							return item.iconGroups?.filter((icongroup: GroupID) => {
								return group?.groupID === icongroup.groupID
							}).length! > 0
						})
						// const groups: IFacepilePersona[] = item.templateGroups?.map((groupItem: GroupID) => {return {personaName: groupItem.groupID, label: '',  }});
						const groups: IFacepilePersona[] = myGroups.map((groupItem: GroupInfo) => { return { personaName: groupItem.groupName, label: '', } });
						if (GetUseGroups(props.settings)) {
							return (
								<Facepile
									personas={groups}
									personaSize={PersonaSize.size24}
									maxDisplayablePersonas={3}
									overflowButtonType={OverflowButtonType.descriptive}
									overflowButtonProps={{ ariaLabel: 'More' } as IButtonProps}

									ariaDescription={'To move through the items use left and right arrow keys.'}
									ariaLabel={'List of folder´s groups'}
									onRenderPersona={(item: IFacepilePersona | undefined) => {
										return (
											<Persona key={item?.personaName} text={item?.personaName} size={PersonaSize.size24} hidePersonaDetails={true} />
										)
									}}
								/>
							)
						}
						else if (groups.length > 0) {
							return (
								<TooltipHost
									content={<>This library is connected to a group. <b>Microsoft 365 Groups</b> needs to be enabled by an <b>Administrator</b> in <b>Settings</b>. The icons in this category is not accessible for any user.</>}
								>
									<Icon styles={{ root: { color: '#a80000' } }} iconName='BlockedSolid' />
								</TooltipHost>

							)
						}
						else { return null }
					}
					else { return null }
				},
			}] : [],
		]
	}, [props.iconItems, props.selectedCategory, props.settings, props.groupIds]);

	const getCategoryItems = useCallback((iconItems: IconListItem[]): IconListItem[] => {
		return iconItems.filter((val, idx, arr) => arr.findIndex(item => item.libraryName === val.libraryName) === idx).map((item: IconListItem) => {
			return ({
				...item,
				iconsCount: iconItems.filter((obj) => obj.libraryName === item.libraryName).length
			})
		})
	}, [iconItems]);

	const onActiveItemChanged = (item?: IconListItem): void => {
		if (item) {
			props.changeCategory(item.libraryName, item.previewHeight, item.previewWidth);
		}
	};

	const onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		const newItems = copyAndSort(iconItems, currColumn.fieldName!, currColumn.isSortedDescending);
		setColumns(newColumns);
		setIconItems(newItems);
	};

	const copyAndSort = <T,>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] => {
		const key = columnKey as keyof T;
		return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
	};

	if (!!props.iconItems && props.iconItems[0] === null) {
		return (<></>);
	}

	return (
		<Stack className='Width100'>
			<span className='Indent20'>
				<h3>{props.templateVersion === 'TEST' ? 'Icon Libraries in Test' : 'Published Icon Libraries'}</h3>
			</span>
			{props.iconItems &&
				<ShimmeredDetailsList
					items={iconItems}
					columns={columns}
					enableShimmer={props.iconItems?.length === 0}
					setKey="set"
					layoutMode={DetailsListLayoutMode.justified}
					selectionPreservedOnEmptyClick={true}
					selectionMode={SelectionMode.single}
					ariaLabelForSelectionColumn="Toggle selection"
					ariaLabelForSelectAllCheckbox="Toggle selection for all items"
					checkButtonAriaLabel="Row checkbox"
					checkboxVisibility={CheckboxVisibility.always}
					onActiveItemChanged={onActiveItemChanged}
				/>
			}
		</Stack>
	);
}

export default IconsCategories;
