/* eslint-disable react/prop-types */
import * as React from "react"
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";

export interface DialogDeleteProps {
	callbackDelete: () => void,
	callbackCancel: () => void,
	hidden: boolean, 
	title?: string,
	description?: string
}


export const DialogDelete: React.FunctionComponent<DialogDeleteProps> = (props): JSX.Element => {
	
	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: props.title,
		subText: props.description,
	};
	
	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 450 } },
	};

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.callbackCancel()}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<DialogFooter>
				<PrimaryButton onClick={() => props.callbackDelete()} text="Delete" />
				<DefaultButton onClick={() => props.callbackCancel()} text="Cancel" />
			</DialogFooter>
		</Dialog>
	);
};