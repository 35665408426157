import * as React from "react"
import { DefaultButton, Dialog, DialogFooter, DialogType, IPersonaProps, Label, PrimaryButton } from "@fluentui/react"
import { PeoplePickerGroups } from "../../helpers/PeoplePicker";
import { UploadFile } from "../../interfaces/UploadFile";
import { GroupDetails } from "../../interfaces/GroupDetails";
import { PresentationListItem } from "../../interfaces/PresentationListItem";
import { GroupID } from "../../interfaces/GroupID";

export const DialogEditSlides: React.FunctionComponent<{
	setParentState: (x: any) => void,
	companyGroups: GroupDetails[]; 
	selectedPresentation: PresentationListItem;
	hideState: string, 
	confirmState: string, 
	uploadItemState: string, 
	showGroups: boolean,
	hidden: boolean,

}> = (props) => {

// const [uploadFiles, setUploadFiles] = React.useState<UploadFile[]>([]);
const [allGroups, setAllGroups] = React.useState<IPersonaProps[]>();
const [selectedGroups, setSelectedGroups] = React.useState<IPersonaProps[]>();


if (props.hidden) {
	// if (uploadFiles.length > 0) {setUploadFiles([])}
	if (!!allGroups) {setAllGroups(undefined)}
	if (!!selectedGroups) {setSelectedGroups(undefined)}
	return (<></>)
}

const dialogContentProps = {
	type: DialogType.largeHeader,
	title: `Edit presentation`,
	subText: `Edit which groups can access the presentation`,
};

const modalProps = {
	isBlocking: false,
	styles: { main: { maxWidth: 450 } },
};

if (!allGroups) {
	const myGroups: IPersonaProps[] = props.companyGroups?.map((item: GroupDetails) => {
		return ({
			id: item.id,
			text: item.displayName,
			secondaryText: item.description
		})
	})
	if (myGroups !== undefined) {			
		setAllGroups(myGroups)
		// setSelectedGroups([])
		setSelectedGroups(myGroups!.filter(
			(companyGroup: IPersonaProps) => {
				return props.selectedPresentation.slideGroups?.filter(
					(slideGroup: GroupID) => {
						return companyGroup.id === slideGroup.groupID
					}).length! > 0
			})
		)
	}		
}

return (
	<Dialog
		hidden={props.hidden}
		onDismiss={() => props.setParentState({[props.hideState]: true})}
		dialogContentProps={dialogContentProps}
		modalProps={modalProps}
	>
		{props.showGroups ?
				!!allGroups ? 
					<>
						<Label>Groups</Label>
						<PeoplePickerGroups
							setSelectedGroups={setSelectedGroups}
							companyGroups={allGroups!}
							selectedGroups={selectedGroups!}
						/>
					</>				
				:<>Groups not loaded!</>
			: null}			
		<DialogFooter>
			<PrimaryButton onClick={() => {
				if (props.selectedPresentation) {
					props.setParentState({
						[props.confirmState]: true, 
						[props.hideState]: true, 
						[props.uploadItemState]: [{
							filename: props.selectedPresentation.presentationName,
							data: '',
							groups:selectedGroups?.map((item) => {
								return {
									groupID: item.id
								}}),
						} as UploadFile]
					})
				}
			}} 
			text="Update" />
			<DefaultButton onClick={() => props.setParentState({[props.hideState]: true})} text="Cancel" />
		</DialogFooter>
	</Dialog>
);
};
