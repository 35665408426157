
import * as React from 'react';
import { DefaultButton, SpinnerSize, Stack, Text } from '@fluentui/react';
import { UserAdminItem } from '../../interfaces/UserAdminItem';
import { GetAllUsers } from '../../helpers/GetFromRestApi';
import Axios, { CancelTokenSource } from 'axios';
import { ContentFrame } from '../common/ContentFrame';
import { AuthContext } from '../../contexts/AuthContext';
import { Spinner } from 'office-ui-fabric-react';

export interface CompaniesProps {

}

const Companies: React.FC<CompaniesProps> = () => {
    const [users, setUsers] = React.useState<UserAdminItem[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const ourRequest = React.useRef<CancelTokenSource>(Axios.CancelToken.source());

    const { accessToken } = React.useContext(AuthContext)

    const updateUsers = async () => {
        ourRequest.current = Axios.CancelToken.source();
        setIsLoading(true);
        const allUsers = await GetAllUsers(accessToken!, true, ourRequest.current.token);
        setUsers(allUsers)
        setIsLoading(false);
    };

    React.useEffect(() => {
        if (accessToken) {
            updateUsers();
        }
        return () => {
            try {
                ourRequest.current.cancel();
            } catch (error) {
                // Handle cancellation error if necessary
            }
        };
    }, [accessToken]);

    const downloadEmailsAsCsv = () => {
        const headers = "Email;Domain;Admin";
        const csv: string = [headers, ...users.map((user) => { return `${user.User};${user.User.split('@')[1]};${user.isSettingsAdmin}` })].join('\n')
        downloadCSV(csv, '365TemplateExtender - Accounts.csv')
    }

    const downloadCompaniesAsCsv = () => {
        const domains: string[] = users.map((user) => {
            return user.Domains.filter(d => d.APIName.toLowerCase() === 'prod').map((domain) => {
                return `${domain.CompanyName};${domain.hasTemplates};${domain.hasIcons};${domain.hasPictures};${domain.hasSlides};${domain.hasWordContents};${domain.hasSections};${domain.DomainName}`
            })
        }).flat()
        const headers = "Customer;Has Templates;Has Icons;Has Pictures;Has Slides;Has WordContents;Has Sections;Domain";
        const csv: string = [headers, ...domains.filter((item, index, arr) => arr.indexOf(item) === index).sort()].join('\n');
        downloadCSV(csv, '365TemplateExtender - Customers.csv')
    }

    const downloadCSV = (csv: string, filename: string) => {
        const bom = '\uFEFF'; // UTF-8 Byte Order Mark (BOM)
        const csvWithBom = bom + csv; // Prepend BOM to the CSV string
        const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' }); // Specify charset=utf-8
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // Append the element to the body to ensure compatibility
        a.click();
        document.body.removeChild(a); // Clean up by removing the element
        window.URL.revokeObjectURL(url);
    };


    return (
        <Stack>
            <Stack.Item className="" styles={{ root: { margin: '30px' } }}>
                <Text variant="xxLarge">Export customer data</Text>
            </Stack.Item>
            <ContentFrame>
                <Stack >
                    {isLoading && <Spinner size={SpinnerSize.large} label="Loading..." />}
                    {!isLoading && users.length > 0 &&
                        <>
                            <Stack.Item className="" styles={{ root: { margin: '30px' } }}>
                                <DefaultButton
                                    iconProps={{ iconName: 'ExcelLogo16' }}
                                    onClick={downloadEmailsAsCsv}
                                >
                                    Export Accounts
                                </DefaultButton>
                            </Stack.Item>
                            <Stack.Item className="" styles={{ root: { margin: '30px' } }}>
                                <DefaultButton
                                    iconProps={{ iconName: 'ExcelLogo16' }}
                                    onClick={downloadCompaniesAsCsv}
                                >
                                    Export Customers
                                </DefaultButton>
                            </Stack.Item>
                        </>
                    }
                </Stack>
            </ContentFrame>
        </Stack>
    );
};

export default Companies;
