import * as React from 'react';
import {
	CheckboxVisibility,
	CommandBar,
	DetailsListLayoutMode,
	FontIcon,
	IColumn,
	ICommandBarItemProps,
	MarqueeSelection,
	SelectionMode,
	ShimmeredDetailsList,
	Stack,
} from '@fluentui/react';
import { Selection, } from '@fluentui/react/lib/DetailsList';
import { CustomDataLevelItem } from '../../interfaces/CustomDataLevelItem';
import { CustomDataListItem } from '../../interfaces/CustomDataListItem';
import { DataLabelItem } from '../../interfaces/DataLabelItem';
import { EditFieldCheckbox, EditFieldDropdown } from '../../helpers/EditFields';
import { TextField } from 'office-ui-fabric-react';
// import { DataLabelItem } from '../../interfaces/DataLabelItem';

export interface CustomDataLevelDefProps {
	dataLevel: number
	defDataLevelEdited: CustomDataLevelItem;
	onChangeDef: (defItem: CustomDataLevelItem) => void
	editMode: boolean
}

export interface CustomDataLevelDefState {
	selectedItems: CustomDataListItem[]
	columns: IColumn[]
}
export default class CustomDataLevelDef extends React.Component<CustomDataLevelDefProps, CustomDataLevelDefState> {
	private _selection: Selection;

	constructor(props: CustomDataLevelDefProps) {
		super(props);
		this.state = {
			selectedItems: [],
			columns: [{ key: 'column1', name: '', minWidth: 16, }],
		};
		this._selection = new Selection({
			onSelectionChanged: () => this.setState({
				selectedItems: this._selection.getSelectedCount() ? [this._selection.getSelection()[0] as CustomDataListItem] : []
			}),
		});
	}

	getColums(): IColumn[] | undefined {
		return [
			{
				key: 'column1',
				name: 'Name',
				fieldName: 'FieldName',
				minWidth: 100,
				maxWidth: 300,
				isResizable: true,
				onRender: (item: CustomDataListItem, itemIdx) => {
					return this.props.editMode ?
						<TextField
							value={item.FieldName}
							placeholder='Value'
							prefix={this.props.dataLevel === 0 ? 'cst' : `cs${this.props.dataLevel + 1}`}
							errorMessage={this.props.defDataLevelEdited.CustomDataDefs.filter(val => val.FieldName.toLowerCase() === item.FieldName.toLowerCase()).length > 1 ? 'Unique name required' : ''}
							onChange={(_ev, newValue) => {
								const newDefItems: CustomDataListItem[] = this.props.defDataLevelEdited.CustomDataDefs.map((val, valIdx) => {
									if (itemIdx === valIdx) { return { ...val, FieldName: `${newValue}`! } }
									else { return { ...val } }
								});
								this.props.onChangeDef(Object.assign(this.props.defDataLevelEdited, { CustomDataDefs: newDefItems }))
							}}
						/> : `${this.props.dataLevel === 0 ? 'cst' : `cs${this.props.dataLevel + 1}`}${item.FieldName}`
				},
			},
			...[...this.props.defDataLevelEdited.LevelLabels!].map((label: DataLabelItem) => {
				return {
					key: 'column_lang_' + label.langCode,
					name: `Caption (${label.langCode})`,
					fieldName: label.langCode,
					minWidth: 100,
					maxWidth: 300,
					isResizable: true,
					onRender: (item: CustomDataListItem, itemIdx) => {
						return this.props.editMode ?
							<TextField
								value={item.FieldLabels.find((field) => field.langCode.toLowerCase() === label.langCode.toLowerCase())?.dataLabel!}
								placeholder='Value'
								onChange={(_ev, newValue) => {
									const newDefItems: CustomDataListItem[] = this.props.defDataLevelEdited.CustomDataDefs.map((val, valIdx) => {
										if (itemIdx === valIdx && val.FieldLabels.length > 0) {
											let fieldLbls: DataLabelItem[] = JSON.parse(JSON.stringify(val.FieldLabels))
											try { fieldLbls.find(n => n.langCode.toLowerCase() === label.langCode.toLowerCase())!.dataLabel = newValue! } 
											catch (error) { }
											return { ...val, FieldLabels: fieldLbls }
										}
										else { return { ...val } }
									});
									this.props.onChangeDef(Object.assign(this.props.defDataLevelEdited, { CustomDataDefs: newDefItems }))
								}}
							/> : item.FieldLabels.find((field) => field.langCode.toLowerCase() === label.langCode.toLowerCase())?.dataLabel!
					},
				} as IColumn
			}),
			{
				key: 'column2',
				name: 'Data type',
				fieldName: 'DataType',
				minWidth: 100,
				maxWidth: 300,
				isResizable: true,
				onRender: (item: CustomDataListItem, itemIdx) => {
					return this.props.editMode ?
						<EditFieldDropdown
							// options={['text', 'picture']}
							options={['text', 'multiline', 'png', 'jpeg']}
							value={item.DataType}
							placeholder='Select'
							onChange={(option) => {
								const newDefItems: CustomDataListItem[] = this.props.defDataLevelEdited.CustomDataDefs.map((val, valIdx) => {
									if (itemIdx === valIdx) { return { ...val, DataType: option?.text } }
									else { return { ...val } }
								});
								this.props.onChangeDef(Object.assign(this.props.defDataLevelEdited, { CustomDataDefs: newDefItems }))
							}}
						/> : item.DataType
				},
			},
			{
				key: 'column3',
				name: 'Label item',
				fieldName: 'LabelItem',
				minWidth: 100,
				maxWidth: 300,
				isResizable: true,
				onRender: (item: CustomDataListItem, itemIdx) => {
					return this.props.editMode ?
						<EditFieldCheckbox
							value={item.LabelItem}
							placeholder=''
							onChange={(checked) => {
								const newDefItems: CustomDataListItem[] = this.props.defDataLevelEdited.CustomDataDefs.map((val, valIdx) => {
									if (checked) { return { ...val, LabelItem: itemIdx === valIdx } }
									else { return { ...val } }
								});
								this.props.onChangeDef(Object.assign(this.props.defDataLevelEdited, { CustomDataDefs: newDefItems }))
							}}
						/>
						:
						item.LabelItem ? <FontIcon iconName="CheckMark" /> : <></>
				},
			},
		]
	}

	getCommandItems(): ICommandBarItemProps[] {
		return [
			{
				key: 'newField',
				text: 'New Field',
				disabled: !this.props.editMode || (this.props.defDataLevelEdited.CustomDataDefs && this.props.defDataLevelEdited.CustomDataDefs.filter((v) => v.FieldName === '').length > 0),
				iconProps: { iconName: 'Add' },
				onClick: () => this.addNewField(),
			},
			{
				key: 'deleteField',
				text: 'Delete Field',
				disabled: !this.props.editMode || this.state.selectedItems.length === 0,
				iconProps: { iconName: 'Delete' },
				onClick: () => this.deleteSelectedField(),
			},
		];
	}

	addNewField() {
		this.props.onChangeDef(Object.assign(this.props.defDataLevelEdited, {
			CustomDataDefs: this.props.defDataLevelEdited.CustomDataDefs.concat({
				FieldLabels: [{ langCode: 'default', dataLabel: '' }, { langCode: 'en', dataLabel: '' }],
				FieldName: '',
				DataType: 'text',
				LabelItem: this.props.defDataLevelEdited.CustomDataDefs.length === 0,
			} as CustomDataListItem)
		}))
	}

	deleteSelectedField() {
		console.log(this._selection.getSelectedIndices())
		this.props.onChangeDef(Object.assign(this.props.defDataLevelEdited, {
			CustomDataDefs:
				this.props.defDataLevelEdited.CustomDataDefs.filter((_val, idx) => { return idx !== this._selection.getSelectedIndices()[0] })
		}))
	}

	handleEmptyDefs() {
		if (!this.props.defDataLevelEdited.CustomDataDefs) {
			this.props.onChangeDef(Object.assign(this.props.defDataLevelEdited, {
				CustomDataDefs: []
			}))
		}
	}

	componentDidUpdate(prevProps: CustomDataLevelDefProps, _prevState: CustomDataLevelDefState) {
		if (prevProps.defDataLevelEdited !== this.props.defDataLevelEdited ||
			prevProps.editMode !== this.props.editMode) {
			this.setState({ columns: this.getColums()! })
			this.handleEmptyDefs()
		}
	}

	componentDidMount() {
		this.setState({ columns: this.getColums()! })
		this.handleEmptyDefs()
	}

	render(): JSX.Element {


		return (
			<Stack className='Width100'>
				<span className='Indent20'>
					<h3>Level {this.props.dataLevel + 1} definitions</h3>
				</span>
				{this.props.defDataLevelEdited.LevelLabels.map((label) => {
					return <TextField
						styles={{ root: { maxWidth: '300px', marginLeft: '20px' } }}
						label={`Caption (${label.langCode})`}
						value={label.dataLabel}
						disabled={!this.props.editMode}
						onChange={(_ev, newValue) => {
							const newDefLabels: DataLabelItem[] = this.props.defDataLevelEdited.LevelLabels.map((val) => {
								if (val.langCode === label.langCode) { return { ...val, dataLabel: newValue! } }
								else { return { ...val } }
							});
							this.props.onChangeDef(Object.assign(this.props.defDataLevelEdited, { LevelLabels: newDefLabels }))
						}}
					/>
				})}
				<CommandBar
					items={this.getCommandItems()}
					ariaLabel="Use left and right arrow keys to navigate between commands"
				/>
				{this.props.defDataLevelEdited && this.props.defDataLevelEdited.CustomDataDefs &&
					<MarqueeSelection selection={this._selection}>
						<ShimmeredDetailsList
							items={this.props.defDataLevelEdited.CustomDataDefs}
							columns={this.state.columns}
							enableShimmer={this.props.defDataLevelEdited.CustomDataDefs.length === 0}
							setKey="set"
							layoutMode={DetailsListLayoutMode.justified}
							selectionPreservedOnEmptyClick={true}
							selectionMode={SelectionMode.single}
							selection={this._selection}
							ariaLabelForSelectionColumn="Toggle selection"
							ariaLabelForSelectAllCheckbox="Toggle selection for all items"
							checkButtonAriaLabel="Row checkbox"
							checkboxVisibility={CheckboxVisibility.always}
						// onActiveItemChanged={this._onActiveItemChanged}
						/>
					</MarqueeSelection>
				}
			</Stack>
		);
	}


	// private _onActiveItemChanged = (item?: CustomDataListItem): void => {
	// 	console.log(item?.FieldName)
	// }

	// private _onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
	// 	const { columns, items } = this.state;
	// 	const newColumns: IColumn[] = columns.slice();
	// 	const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
	// 	newColumns.forEach((newCol: IColumn) => {
	// 		if (newCol === currColumn) {
	// 			currColumn.isSortedDescending = !currColumn.isSortedDescending;
	// 			currColumn.isSorted = true;
	// 			// this.setState({
	// 			//   announcedMessage: `${currColumn.name} is sorted ${
	// 			// 	currColumn.isSortedDescending ? 'descending' : 'ascending'
	// 			//   }`,
	// 			// });
	// 		} else {
	// 			newCol.isSorted = false;
	// 			newCol.isSortedDescending = true;
	// 		}
	// 	});
	// 	const newItems = this._copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
	// 	this.setState({
	// 		columns: newColumns,
	// 		items: newItems,
	// 	});
	// };

	// _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
	// 	const key = columnKey as keyof T;
	// 	return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
	// }
}
