import React, { useContext } from 'react';
import { NewsItem } from '../../interfaces/NewsItem';
import NewsEditor from './NewsEditor';
import DOMPurify from 'dompurify';
import { Image, Link, Stack } from '@fluentui/react';
import { postNews } from '../../helpers/PostToAdminApi';
import { AuthContext } from '../../contexts/AuthContext';

interface NewsPreviewProps {
    refreshNews: () => void
    templateVersion: 'PROD' | 'TEST'
    newsItem: NewsItem | null
}

const NewsPreview: React.FC<NewsPreviewProps> = (props) => {
    // const [html, setHtml] = React.useState<string>(props.newsItem?.Content!)
    const [editedItem, setEditedItem] = React.useState<NewsItem>({ ...props.newsItem, Image: '' } as NewsItem)
    const [image, setImage] = React.useState<string>(props.newsItem?.Image!)

    const { accessToken } = useContext(AuthContext)

    React.useEffect(() => {
        setEditedItem({ ...props.newsItem, Image: '' } as NewsItem)
        setImage(props.newsItem?.Image!)
    }, [props.newsItem])

    const Reset = () => {
        setEditedItem({ ...props.newsItem, Image: '' } as NewsItem)
        setImage(props.newsItem?.Image!)
    }

    const Save = () => {
        console.log({ ...editedItem, Image: image } as NewsItem)
        postNews(accessToken!, { ...editedItem, Image: image } as NewsItem)
            .then(() => { props.refreshNews() })
    }

    if (!props.newsItem) { return null }

    return (
        <>
            {props.templateVersion === 'TEST' &&
                <NewsEditor
                    editedItem={editedItem}
                    setEditedItem={setEditedItem}
                    image={image}
                    setImage={setImage}
                    reset={Reset}
                    save={Save}
                />
            }
            <Stack className='Width100' styles={{ root: { paddingLeft: '20px', paddingRight: '20px' } }} tokens={{ childrenGap: '5px' }}>
                <h1>{editedItem.Title}</h1>
                <div dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(editedItem.Content)
                }} />
                <Link href={editedItem.Link}>{editedItem.Link}</Link>
                {image && <Image src={image} />}
            </Stack>
        </>
    );
}

export default NewsPreview;