import * as React from "react"
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Text } from "@fluentui/react"
import { UploadFile } from "../../interfaces/UploadFile";
import { toBase64 } from "../../helpers/Base64";

export const DialogUploadLogo: React.FunctionComponent<{setParentState: (x: any) => void, hideState: string, confirmState: string, uploadItemState: string, hidden: boolean}> = (props) => {
  
	const [noInput, setNoInput] = React.useState<boolean>(false);
	const [uploadFiles, setUploadFiles] = React.useState<UploadFile[]>([]);

	if (props.hidden) {
		if (noInput) {setNoInput(false)}
		if (uploadFiles.length > 0) {setUploadFiles([])}
		return (<></>)
	}

	const acceptExtensions: string = ".png"

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: `Upload logo`,
		subText: `Browse the logo you want to upload. Height must be exactly 160px and width between 160 - 500px. The logo will render in half the size.`,
	};
	
	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 450 } },
	};

	// let uploadFiles: UploadFile[];
	
	const onChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {

		await Promise.all(Array.from(event.target.files!).map(async (item) => {
			try {
				let res = (await toBase64(item))
				let data = ''
				if (typeof res === 'string') {data = (res.split(',')[1])}
				return({filename: item.name, data: data})
			} catch (error) {
				return({filename: '', data: ''})
			}
		}))
		.then((res: UploadFile[]) => {
			setUploadFiles(res)
			setNoInput(false)
		})
		.catch(() => 
			setUploadFiles([])
		)	
		//console.log(uploadfiles)
	}
	
	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.setParentState({[props.hideState]: true})}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<input title="Browse..." type="file" name="file" accept={acceptExtensions} onChange={(event) => {onChangeHandler(event)}} />
			{noInput ? <p><Text variant='small' style={{color: '#a4262c'}}>Nothing selected!</Text></p> : null}
			<DialogFooter>
				<PrimaryButton onClick={() => {
					if (uploadFiles.length > 0) {
						props.setParentState({
							[props.confirmState]: true, 
							[props.hideState]: true, 
							[props.uploadItemState]: uploadFiles 
						})
					}
					else {setNoInput(true)}
				}} 
				text="Upload" />
				<DefaultButton onClick={() => props.setParentState({[props.hideState]: true})} text="Cancel" />
			</DialogFooter>
		</Dialog>
	);
};