import * as React from 'react';
import { Image, Stack, Text } from '@fluentui/react';
import { CancelTokenSource } from 'axios';
import GroupsList from './GroupsList';
import GroupsContent from './GroupContent';
import GroupTemplates from './GroupTemplates';
import GroupIcons from './GroupIcons';
import { GroupDetails } from '../../interfaces/GroupDetails';
import { ContentFrame } from '../common/ContentFrame';
import SigninButton from '../../assets/signin.svg';

export interface GroupsProps {
	getCompanyGroups: () => void,
	// authCallback:  (err: any, res: any, instance: any) => void,
	scopes: string[],
	setNewScope: (scope: string[]) => void,
	// groupData: UserAdminItem,
	accessToken: string,
	// apiUri: string,
	domain: string,
	api: string,
	allGroups: GroupDetails[];
	companyGroups: GroupDetails[],
	isUsersTenant: boolean,
}

export interface GroupsState {
	// fetching: boolean,
	// allGroups:  GroupDetails[],
	selectedGroup: GroupDetails,
	templateVersion?: string,
}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class Groups extends React.Component<GroupsProps, GroupsState> {
	constructor(props: GroupsProps) {
		super(props);
		this.state = {
			templateVersion: 'TEST',
			selectedGroup: {} as GroupDetails,
		};
		this.setState = this.setState.bind(this)
		this.changeVersion = this.changeVersion.bind(this)
		// this.getGroups = this.getGroups.bind(this);
	}

	changeVersion = (version: string): void => {
		this.setState({ templateVersion: version })
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	render() {

		console.log('this.props.allGroups')
		console.log(this.props.allGroups)


		return (
			<Stack >
				<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
					<Text variant="xxLarge">
						Groups
					</Text>
				</Stack.Item>

				{/* {this.props.isUsersTenant && this.props.allGroups === connectionFailedGroupDetails ? */}
				{!this.props.scopes.includes('Group.Read.All') ?
					<ContentFrame>
						<Stack style={{ marginLeft: '20px', marginBottom: '50px' }}>
							<h3>Could not reach list of groups from Microsoft 365.<br />Please sign in to confirm permission.</h3>
							{/* <Image alt='Signin' src={SigninButton} onClick={()=> this.props.setNewScope(['Group.Read.All'])} /> */}
							<Image alt='Signin' src={SigninButton} onClick={()=> this.props.setNewScope([...this.props.scopes, 'Group.Read.All'])} />
						</Stack>
					</ContentFrame>
					:
					<>
						<ContentFrame>
							<GroupsList
								setGroupState={this.setState}
								getCompanyGroups={this.props.getCompanyGroups}
								setNewScope={this.props.setNewScope}
								scopes={this.props.scopes}
								// updateGroups={this.getGroups} 
								allGroups={this.props.allGroups}
								companyGroups={this.props.companyGroups}
								selectedGroup={this.state.selectedGroup}
								accessToken={this.props.accessToken!}
								domain={this.props.domain}
								api={this.props.api}
								isUsersTenant={this.props.isUsersTenant}
							/>
						</ContentFrame>
						<ContentFrame>
							<>
								<GroupsContent
									changeVersion={this.changeVersion}
									accessToken={this.props.accessToken}
									allGroups={this.props.allGroups}
									companyGroups={this.props.companyGroups}
									selectedGroup={this.state.selectedGroup}
									isUsersTenant={this.props.isUsersTenant}
									templateVersion={this.state.templateVersion!}
								/>
								<GroupTemplates
									accessToken={this.props.accessToken!}
									domain={this.props.domain}
									api={this.props.api}
									templateVersion={this.state.templateVersion!}
									selectedGroup={this.state.selectedGroup}
								/>
								<GroupIcons
									accessToken={this.props.accessToken!}
									domain={this.props.domain}
									api={this.props.api}
									templateVersion={this.state.templateVersion!}
									selectedGroup={this.state.selectedGroup}
								/>
							</>
						</ContentFrame>
					</>}
			</Stack>
		)
	}
}