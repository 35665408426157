import * as React from "react";
import {
	CommandBar,
	ICommandBarItemProps,
	SelectionMode,
	ShimmeredDetailsList,
	IColumn,
	FontIcon,
	CheckboxVisibility,
	OverflowButtonType,
	PersonaSize,
	Persona,
	Stack,
	Icon,
	TooltipHost,
	MessageBarType,
} from '@fluentui/react';
import { Facepile, IFacepilePersona } from 'office-ui-fabric-react/lib/Facepile';
import { DetailsListLayoutMode, Selection, } from 'office-ui-fabric-react/lib/DetailsList';
import { createTemplateFolder, deleteTemplateFolder, publishTemplates, updateTemplates } from '../../helpers/PostToRestApi';
import { hasValues } from '../../helpers/MiscFunctions';
import { DialogCreateEditFolder } from '../dialogs/OldDialogCreateEditFolder';
import SettingsCompany, { GetUseGroups } from '../../helpers/SettingsCompany';
import { exampleFolderItem, TemplateFolderItem } from '../../interfaces/TemplateFolderItem';
import { GroupInfo } from '../../interfaces/GroupInfo';
import { GroupDetails } from '../../interfaces/GroupDetails';
import { NewFolderItem } from '../../interfaces/NewFolderItem';
import { DialogDelete } from '../dialogs/OldDialogDelete';
import { DialogOkOnly } from '../dialogs/OldDialogOkOnly';
import { DialogOkAbort } from '../dialogs/OldDialogOkAbort';
import { MessageContextType } from '../../interfaces/ContextType';

export interface TemplatesFoldersProps {
	setMessage: (x: MessageContextType) => void;
	getFolders(version: string): void;
	getTemplates(path: string, library: string, version: string): void;
	folderItems: TemplateFolderItem[];
	accessToken: string,
	// apiUri: string,
	domain: string
	api: string
	groupIds: GroupInfo[];
	companyGroups: GroupDetails[];
	settings: SettingsCompany;
	templateVersion: string;
}

export interface TemplatesFoldersState {
	items: TemplateFolderItem[];
	columns: IColumn[];
	selectedItem: TemplateFolderItem;
	selection: Selection;
	newFolderItem: NewFolderItem;
	hideDeleteDialog: boolean;
	hideNewFolderDialog: boolean;
	hideEditFolderDialog: boolean;
	hideNoSelectDialog: boolean;
	hidePublishDialog: boolean;
	hideFileExist: boolean;
	deleteConfirmed: boolean;
	newFolderConfirmed: boolean;
	publishConfirmed: boolean;
	deleting: boolean;
	creating: boolean;
	publishing: boolean;
}



export default class TemplatesFolders extends React.Component<TemplatesFoldersProps, TemplatesFoldersState> {
	private _selection: Selection;


	constructor(props: TemplatesFoldersProps) {
		super(props);
		this._selection = new Selection({
			onSelectionChanged: () => this.setState({ selection: this._selection }),
		});
		this.state = {
			items: [],
			columns: [{ key: 'column1', name: '', minWidth: 16, }],
			selectedItem: {} as TemplateFolderItem,
			selection: this._selection,
			newFolderItem: {} as NewFolderItem,
			hideDeleteDialog: true,
			hideNewFolderDialog: true,
			hideEditFolderDialog: true,
			hideNoSelectDialog: true,
			hidePublishDialog: true,
			hideFileExist: true,
			deleteConfirmed: false,
			newFolderConfirmed: false,
			publishConfirmed: false,
			deleting: false,
			creating: false,
			publishing: false,
		};
		this.boundSetState = this.setState.bind(this)
	}

	// deleting: Boolean = false
	// creating: Boolean = false

	boundSetState = (x: any) => { this.setState(x) }

	// componentWillUnmount() {
	// 	try {
	// 		ourRequest.cancel()
	// 	} catch (error) {

	// 	}
	// }

	commandItems = (): ICommandBarItemProps[] => [
		...(this.props.templateVersion === 'TEST' ? [{
			key: 'newFolder',
			text: 'New Folder',
			// disabled: (this.props.templateVersion === 'PUB'),
			iconProps: { iconName: 'FabricNewFolder' },
			onClick: () => this._newFolderPrompt(),
		},
		{
			key: 'edit',
			text: 'Edit',
			disabled: this._selection.count === 0 || this._selection.isIndexSelected(0),
			iconProps: { iconName: 'Edit' },
			onClick: () => this._editFolderPrompt(),
		},
		{
			key: 'delete',
			text: 'Delete',
			disabled: this._selection.count === 0 || this._selection.isIndexSelected(0),
			iconProps: { iconName: 'Delete' },
			onClick: () => this._deletePrompt(),
		},
		{
			key: 'publish',
			text: 'Publish All',
			// disabled: (this.props.templateVersion === 'PUB'),
			iconProps: { iconName: 'PublishContent' },
			onClick: () => this._publishPrompt(),
		}] : []),
	];

	getColums(): IColumn[] {
		return [
			{
				key: 'column0',
				name: 'Icon',
				fieldName: 'icon',
				// iconName: 'FabricFolder',				
				isIconOnly: true,
				minWidth: 16,
				maxWidth: 16,
				isResizable: false,
				onRender: (item: TemplateFolderItem) => { return <Icon styles={{ root: { fontSize: '16px' } }} iconName={item.Path === this.state.selectedItem.Path ? 'FabricOpenFolderHorizontal' : 'FabricFolder'} />; },
			},
			{
				key: 'column1',
				name: 'Library',
				fieldName: 'Library',
				minWidth: 250,
				maxWidth: 400,
				isResizable: true,
				// onColumnClick: this._onColumnClick,
				onRender: (item: TemplateFolderItem) => { return !item.Library ? '\\' : item.Library },
			},
			{
				key: 'column2',
				name: 'Date modified',
				fieldName: 'modifiedDate',
				minWidth: 150,
				maxWidth: 150,
				isResizable: true,
				// onColumnClick: this._onColumnClick,
			},
			{
				key: 'column3',
				name: 'Hide Online',
				fieldName: 'hideOnline',
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				// onColumnClick: this._onColumnClick,
				onRender: (item: TemplateFolderItem) => { return item.hideOnline ? <FontIcon iconName="CheckMark" /> : <></> },
			},
			{
				key: 'column4',
				name: 'Hide Desktop',
				fieldName: 'hideDesktop',
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
				// onColumnClick: this._onColumnClick,
				onRender: (item: TemplateFolderItem) => { return item.hideDesktop ? <FontIcon iconName="CheckMark" /> : <></> },
			},
			...(GetUseGroups(this.props.settings) || (this.props.folderItems.some((folder) => folder.templateGroups!?.length > 0))) ? [{
				key: 'column5',
				name: 'Groups',
				fieldName: 'templateGroups',
				minWidth: 150,
				maxWidth: 250,
				isResizable: true,
				// onColumnClick: this._onColumnClick,
				onRender: (item: TemplateFolderItem) => {
					if (item.templateGroups && !!this.props.groupIds) {
						const myGroups: GroupInfo[] = this.props.groupIds.filter((group: GroupInfo) => {
							return item.templateGroups?.filter((foldergroup) => {
								return group?.groupID === foldergroup.groupID
							}).length! > 0
						})
						// const groups: IFacepilePersona[] = item.templateGroups?.map((groupItem: GroupID) => {return {personaName: groupItem.groupID, label: '',  }});
						const groups: IFacepilePersona[] = myGroups.map((groupItem: GroupInfo) => { return { personaName: groupItem.groupName, label: '', } });
						if (GetUseGroups(this.props.settings)) {
							return (
								<Facepile
									personas={groups}
									personaSize={PersonaSize.size24}
									maxDisplayablePersonas={3}
									overflowButtonType={OverflowButtonType.descriptive}
									overflowButtonProps={{ ariaLabel: 'More' }}
									ariaDescription={'To move through the items use left and right arrow keys.'}
									ariaLabel={'List of folder´s groups'}
									onRenderPersona={(item: IFacepilePersona | undefined) => {
										return (
											<Persona key={item?.personaName} text={item?.personaName} size={PersonaSize.size24} hidePersonaDetails={true} />
										)
									}}
								/>
							)
						}
						else if (groups.length > 0) {
							return (
								<TooltipHost
									content={<>This folder is connected to a group. <b>Microsoft 365 Groups</b> needs to be enabled by an <b>Administrator</b> in <b>Settings</b>. The templates in this folder is not accessible for any user.</>}
								>
									<Icon styles={{ root: { color: '#a80000' } }} iconName='BlockedSolid' />
								</TooltipHost>

							)
						}
						else { return null }
					}
					else { return null }
				},
			}] : [],
		]
	}

	componentDidUpdate(prevProps: Readonly<TemplatesFoldersProps>, _prevState: Readonly<TemplatesFoldersState>, _snapshot?: any): void {
		if (prevProps.folderItems !== this.props.folderItems || prevProps.groupIds !== this.props.groupIds) {
			const selected: TemplateFolderItem = this.props.folderItems.find((item) => { return item.Path === this.state.selectedItem?.Path })!
			this.setState({
				columns: this.getColums(),
				items: this.props.folderItems,
				selectedItem: !!selected ? selected : this.props.folderItems[0]
			});
		}
		else if (_prevState.selectedItem !== this.state.selectedItem) {
			this.setState({
				columns: this.getColums(),
			});
		}
		if (this.state.publishConfirmed && !this.state.publishing) {
			this._publishTemplates()
		}
		if (this.state.deleteConfirmed && !this.state.deleting) {
			this._deleteSelectedFolder()
		}
		if (this.state.newFolderConfirmed && !this.state.creating) {
			console.log(this.state.newFolderItem)
			this._createFolder()
		}
		if (this._selection.getSelectedCount() === 0) {
			this._selection.setIndexSelected(0, true, true)
		}
	}

	componentDidMount(): void {
		const selected: TemplateFolderItem = this.props.folderItems.find((item) => { return item.Path === this.state.selectedItem?.Path })!
		this.setState({
			columns: this.getColums(),
			items: this.props.folderItems,
			selectedItem: !!selected ? selected : this.props.folderItems[0]
		});
	}


	render(): JSX.Element {

		if (!this.state.items) {
			return (<></>)
		}

		return (
			<Stack className='Width100'>
				<span className='Indent20'>
					<h3>{this.props.templateVersion === 'TEST' ? 'Folders in Test' : 'Published Folders'}</h3>
				</span>

				{this.props.templateVersion === 'TEST' && (
					<CommandBar
						items={this.commandItems()}
						ariaLabel="Use left and right arrow keys to navigate between commands"
					/>
				)}
				<ShimmeredDetailsList
					items={this.state.items}
					columns={this.state.columns}
					enableShimmer={!hasValues(this.state.items)}
					setKey="set"
					layoutMode={DetailsListLayoutMode.justified}
					selectionMode={this.props.folderItems === exampleFolderItem ? SelectionMode.none : SelectionMode.single}
					selection={(this._selection)}
					selectionPreservedOnEmptyClick={true}
					ariaLabelForSelectionColumn="Toggle selection"
					ariaLabelForSelectAllCheckbox="Toggle selection for all items"
					checkButtonAriaLabel="Row checkbox"
					checkboxVisibility={CheckboxVisibility.always}
					onActiveItemChanged={this._onActiveItemChanged}
				/>
				<DialogDelete
					setParentState={this.boundSetState}
					hidden={this.state.hideDeleteDialog}
					hideState='hideDeleteDialog'
					confirmState='deleteConfirmed'
					filename={` folder ${this.state.selectedItem?.primaryText}`}
				/>
				<DialogCreateEditFolder
					setParentState={this.boundSetState}
					hidden={this.state.hideNewFolderDialog && this.state.hideEditFolderDialog}
					hideState={['hideNewFolderDialog', 'hideEditFolderDialog']}
					confirmState='newFolderConfirmed'
					newFolderItem='newFolderItem'
					folders={this.props.folderItems}
					folderItem={this.state.selectedItem}
					defaultPath={typeof this.state.selectedItem?.Root === 'undefined' ? '\\' : this.state.selectedItem?.Library!}
					// folderName={this.state.selectedItem.primaryText}
					isNew={!this.state.hideNewFolderDialog}
					companyGroups={this.props.companyGroups}
					showGroups={GetUseGroups(this.props.settings) || (this.state.hideNewFolderDialog && this.state.selectedItem.templateGroups?.length! > 0)}
				/>
				<DialogOkAbort setParentState={this.boundSetState} hidden={this.state.hidePublishDialog} hideState='hidePublishDialog' confirmState='publishConfirmed' title='Publish templates' text='Are you sure you want to replace all templates in Published with the templates from Test?' />
				<DialogOkOnly setParentState={this.boundSetState} hidden={this.state.hideNoSelectDialog} hideState='hideNoSelectDialog' title='Not a subfolder' text='You have to select a subfolder to use this option.' />
				<DialogOkOnly setParentState={this.boundSetState} hidden={this.state.hideFileExist} hideState='hideFileExist' title='Folder exist' text='This folder already exist.' />
			</Stack>
		);
	}



	private _onActiveItemChanged = (item?: TemplateFolderItem): void => {
		// console.log(this._selection.count)
		this.setState({
			selectedItem: item!,
		})
		this.props.getTemplates(item?.Path!, typeof item?.Root !== 'undefined' ? item?.Library! : '\\', this.props.templateVersion)
	}

	private _newFolderPrompt() {
		if (this._selection.count > 0) {
			this.setState({ hideNewFolderDialog: false })
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}
		// this.setState({hideNewFolderDialog: false})
	}

	private _editFolderPrompt() {
		if (this._selection.count > 0 && !this._selection.isIndexSelected(0)) {
			this.setState({ hideEditFolderDialog: false })
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}
		// this.setState({hideEditFolderDialog: false})
	}

	private _createFolder(): void {
		this.setState({ creating: true, newFolderConfirmed: false })
		createTemplateFolder(
			this.props.accessToken,
			this.props.domain,
			this.props.api,
			JSON.stringify(this.state.newFolderItem).replace(/\\\\/g, '\\'),
		)
			.then((response) => {
				this.props.setMessage({ message: response.data, messageType: response.status === 200 ? MessageBarType.success : MessageBarType.error, visible: true })
				setTimeout(() => {
					updateTemplates(
						this.props.accessToken,
						this.props.domain,
						this.props.api,
					)
						.then(() => {
							setTimeout(() => {
								this.props.getFolders(this.props.templateVersion)
							}, 500)
						})
				}, 500)
			})
			.catch((error) => {
				this.props.setMessage({ message: error.response.data, messageType: MessageBarType.error, visible: true })
				if (error.response.data === 'Folder already exist') {
					this.setState({ hideFileExist: false })
				}
			})
			.finally(() => {
				// const item: TemplateFolderItem = this.state.items.find((item) => {return item.Path === this.state.selectedItem.Path})!
				this.setState({
					creating: false,
				})
			})
	}

	private _publishTemplates(): void {
		this.setState({ publishing: true, publishConfirmed: false })
		publishTemplates(
			this.props.accessToken,
			this.props.domain,
			this.props.api,
		)
			.then((response) => {
				this.props.setMessage({ message: response.data, messageType: response.status === 200 ? MessageBarType.success : MessageBarType.error, visible: true })
				setTimeout(() => {
					updateTemplates(
						this.props.accessToken,
						this.props.domain,
						this.props.api,
					)
						.then(() => {
							setTimeout(() => {
								this.props.getFolders(this.props.templateVersion)
							}, 500)
						})
				}, 500)
			})
			.catch((error) => {
				this.props.setMessage({ message: error.response.data, messageType: MessageBarType.error, visible: true })
			})
			.finally(() => {
				this.setState({ publishing: false })
			})
	}

	private _deletePrompt() {
		if (this._selection.count > 0) {
			this.setState({ hideDeleteDialog: false })
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}
	}

	private _publishPrompt() {
		this.setState({ hidePublishDialog: false })
	}

	private _deleteSelectedFolder() {
		this.setState({ deleting: true, deleteConfirmed: false })

		deleteTemplateFolder(
			this.props.accessToken,
			this.props.domain,
			this.props.api,
			this.state.selectedItem.Path
		)
			.then((response) => {
				if (response.status === 200) {
					this.props.setMessage({ message: response.data, messageType: MessageBarType.success, visible: true })
					this._selection.setAllSelected(false)
					// this._selection.setIndexSelected(this._selection.getSelectedIndices()[0]-1, true, true)
					updateTemplates(
						this.props.accessToken,
						this.props.domain,
						this.props.api,
					)
						.then(() => {
							this.props.getFolders(this.props.templateVersion)
							// this.props.getTemplates(this.state.selectedItem?.Path!, typeof this.state.selectedItem?.Root !== 'undefined' ? this.state.selectedItem?.Library! : '\\', this.state.selectedItem?.Root?.split('\\')[2].toUpperCase() || this.state.selectedItem?.Path.split('\\')[2].toUpperCase() || '')
						})
				}
				else {
					this.props.setMessage({ message: response.data, messageType: MessageBarType.error, visible: true })
				}
			})
			.catch((error) => {
				this.props.setMessage({ message: error.response.data, messageType: MessageBarType.error, visible: true })
			})
			.finally(() => {
				this.setState({
					deleting: false,
				})
			})
	}




	// private _onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
	// 	const { columns, items } = this.state;
	// 	const newColumns: IColumn[] = columns.slice();
	// 	const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
	// 	newColumns.forEach((newCol: IColumn) => {
	// 	  if (newCol === currColumn) {
	// 		currColumn.isSortedDescending = !currColumn.isSortedDescending;
	// 		currColumn.isSorted = true;
	// 		// this.setState({
	// 		//   announcedMessage: `${currColumn.name} is sorted ${
	// 		// 	currColumn.isSortedDescending ? 'descending' : 'ascending'
	// 		//   }`,
	// 		// });
	// 	  } else {
	// 		newCol.isSorted = false;
	// 		newCol.isSortedDescending = true;
	// 	  }
	// 	});
	// 	const newItems = sortArray(items, currColumn.fieldName!, currColumn.isSortedDescending);
	// 	this.setState({
	// 	  columns: newColumns,
	// 	  items: newItems,
	// 	});
	// };
}
