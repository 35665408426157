import * as React from "react"
import { DefaultButton, Dialog, DialogFooter, DialogType, IPersonaProps, Label, PrimaryButton, Text } from "@fluentui/react"
import { PeoplePickerGroups } from "../../helpers/PeoplePicker";
import { UploadFile } from "../../interfaces/UploadFile";
import { GroupDetails } from "../../interfaces/GroupDetails";
import { toBase64 } from "../../helpers/Base64";

export const DialogUploadSlides: React.FunctionComponent<{
	setParentState: (x: any) => void,
	companyGroups: GroupDetails[]; 
	hideState: string, 
	confirmState: string, 
	uploadItemState: string, 
	showGroups: boolean,
	hidden: boolean,

}> = (props) => {

const [noInput, setNoInput] = React.useState<boolean>(false);
const [uploadFiles, setUploadFiles] = React.useState<UploadFile[]>([]);
const [allGroups, setAllGroups] = React.useState<IPersonaProps[]>();
const [selectedGroups, setSelectedGroups] = React.useState<IPersonaProps[]>();


if (props.hidden) {
	if (noInput) {setNoInput(false)}
	if (uploadFiles.length > 0) {setUploadFiles([])}
	if (selectedGroups && selectedGroups.length > 0){ setSelectedGroups([]) }
	return (<></>)
}

const acceptExtensions: string = ".pptx"

const dialogContentProps = {
	type: DialogType.largeHeader,
	title: `Upload presentations`,
	subText: `Browse the presentations you want to upload`,
};

const modalProps = {
	isBlocking: false,
	styles: { main: { maxWidth: 450 } },
};

// let uploadfiles: UploadFile[];

const onChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {

	await Promise.all(Array.from(event.target.files!).map(async (item) => {
		try {
			let res = (await toBase64(item))
			let data = ''
			if (typeof res === 'string') {data = (res.split(',')[1])}
			console.log(data)
			return({filename: item.name, data: data})
		} catch (error) {
			console.log(error)
			return({filename: '', data: ''})
		}
	})).then((res: UploadFile[]) => {
		setUploadFiles(res)
		setNoInput(false)
	})		
	.catch(() => 
		setUploadFiles([])
	)
}

if (!allGroups) {
	const myGroups: IPersonaProps[] = props.companyGroups?.map((item: GroupDetails) => {
		return ({
			id: item.id,
			text: item.displayName,
			secondaryText: item.description
		})
	})
	if (myGroups !== undefined) {			
		setAllGroups(myGroups)
		setSelectedGroups([])
	}		
}

return (
	<Dialog
		hidden={props.hidden}
		onDismiss={() => props.setParentState({[props.hideState]: true})}
		dialogContentProps={dialogContentProps}
		modalProps={modalProps}
	>
		<input title="Browse..." type="file" multiple name="file" accept={acceptExtensions} onChange={(event) => {onChangeHandler(event)}} />
		{noInput ? <p><Text variant='small' style={{color: '#a4262c'}}>Nothing selected!</Text></p> : null}
		{props.showGroups ?
				!!allGroups ? 
					<>
						<Label>Groups</Label>
						<PeoplePickerGroups
							setSelectedGroups={setSelectedGroups}
							companyGroups={allGroups!}
							selectedGroups={selectedGroups!}
						/>
					</>				
				:<>Groups not loaded!</>
			: null}			
		<DialogFooter>
			<PrimaryButton onClick={() => {
				if (uploadFiles.length > 0) {
					props.setParentState({
						[props.confirmState]: true, 
						[props.hideState]: true, 
						[props.uploadItemState]: uploadFiles.map((item) => {
							return {
								...item, 
								groups:selectedGroups?.map((item) => {
									return {
										groupID: item.id
									}})
							}})
					})
				}
				else {setNoInput(true)}
			}} 
			text="Upload" />
			<DefaultButton onClick={() => props.setParentState({[props.hideState]: true})} text="Cancel" />
		</DialogFooter>
	</Dialog>
);
};
