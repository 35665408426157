import * as React from 'react';
import {
	CommandBar,
	DetailsListLayoutMode,
	ICommandBarItemProps,
	mergeStyleSets,
	MessageBarType,
	SelectionMode,
	ShimmeredDetailsList,
	IColumn,
	CheckboxVisibility,
	Facepile,
	IFacepilePersona,
	PersonaSize,
	OverflowButtonType,
	IButtonProps,
	Label,
	Stack,
	Persona,
	TooltipHost,
	Icon,
	FontIcon,
	Selection,
} from '@fluentui/react';
// import { DetailsListLayoutMode, Selection, } from 'office-ui-fabric-react/lib/DetailsList';
import { deleteTemplate, publishTemplate, updateTemplate, updateTemplates, uploadTemplate } from '../../helpers/PostToRestApi';
import { GetTemplateId } from '../../helpers/GetFromRestApi';
import { hasValues, sortArray } from '../../helpers/MiscFunctions';
import Axios, { CancelTokenSource } from 'axios';
import { DialogUploadTemplates } from '../dialogs/DialogUploadTemplates';
import SettingsCompany, { GetUseGroups } from '../../helpers/SettingsCompany';
import { exampleTemplateList, TemplateListItem } from '../../interfaces/TemplateListItem';
import { TemplateFolderItem } from '../../interfaces/TemplateFolderItem';
import { GroupInfo } from '../../interfaces/GroupInfo';
import { GroupID } from '../../interfaces/GroupID';
import { ExtendedTemplateListItem } from '../../interfaces/ExtendedTemplateListItem';
import { UploadFile } from '../../interfaces/UploadFile';
import { TemplateApiHeaders } from '../../interfaces/TemplateApiHeaders';
import { DialogDelete } from '../dialogs/OldDialogDelete';
import { DialogOkOnly } from '../dialogs/OldDialogOkOnly';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { MessageContextType } from '../../interfaces/ContextType';
import { DialogOkAbort } from '../dialogs/DialogOkAbort';
import { TemplateSettings } from '../../interfaces/TemplateSettings';
import { DialogEditTemplateSettings } from '../dialogs/DialogEditTemplateSettings';
import { GroupDetails } from '../../interfaces/GroupDetails';

export interface TemplatesDetailsProps {
	setMessage: (x: MessageContextType) => void;
	getTemplates(path: string, library?: string, version?: string): void;
	path: string;
	library: string;
	templateVersion: string;
	templateItems: TemplateListItem[];
	folderItems: TemplateFolderItem[];
	groupIds: GroupInfo[];
	folderGroupIds: GroupID[] | undefined;
	companyGroups: GroupDetails[];
	settings: SettingsCompany;
	accessToken: string,
	// apiUri: string,
	domain: string
	api: string
}

export interface TemplatesDetailsState {
	items: ExtendedTemplateListItem[];
	// extendedItems: ExtendedTemplateListItem[];
	columns: IColumn[];
	selectedItem: ExtendedTemplateListItem;
	selection: Selection;
	hideDeleteDialog: boolean;
	hideUploadDialog: boolean;
	hidePublishDialog: boolean;
	hideEditDialog: boolean;
	hideNoSelectDialog: boolean;
	hideFileExist: boolean;
	deleteConfirmed: boolean;
	// uploadConfirmed: boolean;
	publishConfirmed: boolean;
	editConfirmed: boolean;
	// uploadItems: UploadFile[];
	editedSettings: TemplateSettings
}

const classNames = mergeStyleSets({
	fileIconHeaderIcon: {
		padding: 0,
		fontSize: '16px',
	},
	fileIconCell: {
		textAlign: 'center',
		selectors: {
			'&:before': {
				content: '.',
				display: 'inline-block',
				verticalAlign: 'middle',
				height: '100%',
				width: '0px',
				visibility: 'hidden',
			},
		},
	},
	fileIconImg: {
		verticalAlign: 'middle',
		maxHeight: '16px',
		maxWidth: '16px',
	},
	controlWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	exampleToggle: {
		display: 'inline-block',
		marginBottom: '10px',
		marginRight: '30px',
	},
	selectionDetails: {
		marginBottom: '20px',
	},
});

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class TemplatesDetails extends React.Component<TemplatesDetailsProps, TemplatesDetailsState> {
	private _selection: Selection;

	constructor(props: TemplatesDetailsProps) {
		super(props);
		this._selection = new Selection({
			onSelectionChanged: () => this.setState({ selection: this._selection }),
		});
		this.state = {
			items: [],
			columns: [{ key: 'column1', name: '', minWidth: 16, }],
			selectedItem: {} as ExtendedTemplateListItem,
			selection: this._selection,
			hideDeleteDialog: true,
			hideUploadDialog: true,
			hidePublishDialog: true,
			hideEditDialog: true,
			hideNoSelectDialog: true,
			hideFileExist: true,
			deleteConfirmed: false,
			publishConfirmed: false,
			editConfirmed: false,
			editedSettings: { hideDesktop: false, hideOnline: false, templateGroups: [], updatePub: false } as TemplateSettings,
		};
		this.boundSetState = this.setState.bind(this)
	}

	boundSetState = (x: any) => { this.setState(x) }

	commandItems = (): ICommandBarItemProps[] => {
		return [
			...(this.props.templateVersion === 'TEST' ? [{
				key: 'upload',
				text: 'Upload',
				iconProps: { iconName: 'Upload' },
				onClick: () => this._uploadPrompt(),
			}] : []),
			{
				key: 'download',
				text: 'Download',
				disabled: this._selection.count === 0,
				iconProps: { iconName: 'Download' },
				onClick: () => this._downloadFile(),
			},
			...(this.props.templateVersion === 'TEST' ? [{
				key: 'edit',
				text: 'Edit',
				disabled: this._selection.count === 0,
				iconProps: { iconName: 'Edit' },
				onClick: () => this._editPrompt(),
			}] : []),
			{
				key: 'delete',
				text: 'Delete',
				disabled: this._selection.count === 0,
				iconProps: { iconName: 'Delete' },
				onClick: () => this._deletePrompt(),
			},
			...(this.props.templateVersion === 'TEST' ? [{
				key: 'publish',
				text: 'Publish',
				disabled: this._selection.count === 0,
				iconProps: { iconName: 'PublishContent' },
				onClick: () => this._publishPrompt(),
			}] : []),

		]
	}

	getColums(): IColumn[] {
		return [
			{
				key: 'column1',
				name: 'Icon',
				fieldName: 'icon',
				className: classNames.fileIconCell,
				iconClassName: classNames.fileIconHeaderIcon,
				ariaLabel: 'Column operations for File type, Press to sort on File type',
				// iconName: 'Page',
				isIconOnly: true,
				minWidth: 16,
				maxWidth: 16,
				isResizable: false,
				onColumnClick: this._onColumnClick,
				onRender: (item: ExtendedTemplateListItem) => { return <Icon {...getFileTypeIconProps({ extension: item.fileExt, size: 20 })} /> },
			},
			{ key: 'column2', name: 'Name', fieldName: 'primaryText', minWidth: 150, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick, },
			{ key: 'column3', name: 'Type', fieldName: 'icon', minWidth: 100, maxWidth: 300, isResizable: true, isSorted: true, isSortedDescending: false, onColumnClick: this._onColumnClick, },
			{ key: 'column4', name: 'File size', fieldName: 'fileSizeNum', minWidth: 60, maxWidth: 100, isResizable: true, onColumnClick: this._onColumnClick, onRender: (item: ExtendedTemplateListItem) => { return <span>{item.fileSize}</span>; }, },
			{ key: 'column5', name: 'Upload date', fieldName: 'modifiedDate', minWidth: 100, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick, },
			{ key: 'column6', name: 'Publish date', fieldName: 'publishDate', minWidth: 100, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick, },
			{
				key: 'column7',
				name: 'Hide Online',
				fieldName: 'hideOnline',
				minWidth: 80,
				maxWidth: 150,
				isResizable: true,
				// onColumnClick: this._onColumnClick,
				onRender: (item: TemplateFolderItem) => { return item.hideOnline ? <FontIcon iconName="CheckMark" /> : <></> },
			},
			{
				key: 'column8',
				name: 'Hide Desktop',
				fieldName: 'hideDesktop',
				minWidth: 90,
				maxWidth: 150,
				isResizable: true,
				// onColumnClick: this._onColumnClick,
				onRender: (item: TemplateFolderItem) => { return item.hideDesktop ? <FontIcon iconName="CheckMark" /> : <></> },
			},
			...(GetUseGroups(this.props.settings) || (this.props.folderGroupIds && this.props.folderGroupIds.length > 0)) ? [{
				key: 'column9',
				name: 'Groups',
				fieldName: 'templateGroups',
				minWidth: 150,
				maxWidth: 300,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				onRender: (item: ExtendedTemplateListItem) => {
					if (item.templateGroups && !!this.props.groupIds) {
						const myGroups: GroupInfo[] = this.props.groupIds.filter((group: GroupInfo) => {
							return item.templateGroups?.filter((foldergroup) => {
								return group?.groupID === foldergroup.groupID
							}).length! > 0
						})
						// const groups: IFacepilePersona[] = item.templateGroups?.map((groupItem: GroupID) => {return {personaName: groupItem.groupID, label: '',  }});
						const groups: IFacepilePersona[] = myGroups.map((groupItem: GroupInfo) => { return { personaName: groupItem.groupName, label: '', } });
						if (GetUseGroups(this.props.settings)) {
							return (
								<Facepile
									personas={groups}
									personaSize={PersonaSize.size24}
									maxDisplayablePersonas={3}
									overflowButtonType={OverflowButtonType.descriptive}
									overflowButtonProps={{ ariaLabel: 'More' } as IButtonProps}
									ariaDescription={'To move through the items use left and right arrow keys.'}
									ariaLabel={'List of folder´s groups'}
									onRenderPersona={(item: IFacepilePersona | undefined) => {
										return (
											<Persona key={item?.personaName} text={item?.personaName} size={PersonaSize.size24} hidePersonaDetails={true} />
										)
									}}
								/>
							)
						}
						else if (groups.length > 0) {
							return (
								<TooltipHost
									content={<>This folder is connected to a group. <b>Microsoft 365 Groups</b> needs to be enabled by an <b>Administrator</b> in <b>Settings</b>. The templates in this folder is not accessible for any user.</>}
								>
									<Icon styles={{ root: { color: '#a80000' } }} iconName='BlockedSolid' />
								</TooltipHost>

							)
						}
						else { return null }
					}
					else { return null }
				},
			}] : [],
		]
	}

	getExtendedItems(items: TemplateListItem[]): ExtendedTemplateListItem[] {
		return sortArray(
			items.map((item: TemplateListItem): ExtendedTemplateListItem => {
				let fileExt: string
				let mimetype: string
				switch (item.icon) {
					case 'ExcelWorkbook':
						fileExt = 'xlsx'
						mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						break;
					case 'PowerPointPresentation':
						fileExt = 'pptx'
						mimetype = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
						break;
					case 'WordDocument':
						fileExt = 'docx'
						mimetype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
						break;
					default:
						fileExt = 'txt'
						mimetype = ''
						break;
				}
				return (
					{
						...item,
						fileExt: fileExt,
						mimetype: mimetype
					}
				)
			}),
			'type',
			false)
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	componentDidUpdate(prevProps: Readonly<TemplatesDetailsProps>, _prevState: Readonly<TemplatesDetailsState>, _snapshot?: any): void {
		if (prevProps.templateItems !== this.props.templateItems ||
			prevProps.groupIds !== this.props.groupIds ||
			(_prevState.items && !this.state.items)) {
			this.setState({
				columns: this.getColums(),
				items: this.getExtendedItems(this.props.templateItems)
			});
		}
		if (this.state.deleteConfirmed) {
			this._deleteSelectedFiles()
		}
		// if (this.state.uploadConfirmed) {
		// 	console.log(this.state.uploadItems)
		// 	this._uploadFile()
		// }
	}

	componentDidMount() {
		this.setState({
			columns: this.getColums(),
			items: this.getExtendedItems(this.props.templateItems)
		});
	}

	uploadFiles = (uploadItems: UploadFile[]) => {
		console.log(this.props)
		console.log(uploadItems)
		uploadItems.forEach((value, idx, arr) => {
			uploadTemplate(
				this.props.accessToken,
				this.props.domain,
				this.props.api,
				value.filename,
				value.path || this.props.path,
				value.data,
			)
				.then(async (response) => {
					if (idx === arr.length - 1) {
						// console.log(response)
						if (response.status === 200) {
							setTimeout(() => {
								updateTemplates(
									this.props.accessToken,
									this.props.domain,
									this.props.api,
								)
									.then(() => {
										setTimeout(() => {
											this.setState({
												items: [],
												hideUploadDialog: true,
											})
											this.props.getTemplates(value.path || this.props.path)
											this.props.setMessage({ message: response.data, messageType: MessageBarType.success, visible: true })
										}, 500)
									})
							}, 500)
						}
						else if (response.status === 405) {
							this.setState({
								hideFileExist: false,
								hideUploadDialog: true,
							})
							this.props.setMessage({ message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
						}
						else {
							this.setState({
								hideUploadDialog: true,
							})
							this.props.setMessage({ message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
						}
					}
				});
		})
	}

	render(): JSX.Element {
		if (this.props.templateItems && !this.state.items) {
			return (<></>)
		}

		const groupList = (): JSX.Element => {
			if (!!this.props.groupIds && GetUseGroups(this.props.settings)) {
				const myGroups: GroupInfo[] = this.props.groupIds.filter((group: GroupInfo) => {
					return this.props.folderGroupIds?.filter((foldergroup) => {
						return group?.groupID === foldergroup.groupID
					}).length! > 0
				})
				const groups: IFacepilePersona[] = myGroups.map((groupItem: GroupInfo) => { return { personaName: groupItem.groupName, label: '', } });
				return (
					<Stack horizontal style={{ textIndent: '0px', marginLeft: '20px', }} tokens={{ childrenGap: '10px' }}>
						<Label>Groups:</Label>
						<Facepile
							personas={groups}
							personaSize={PersonaSize.size24}
							maxDisplayablePersonas={10}
							overflowButtonType={OverflowButtonType.descriptive}
							overflowButtonProps={{ ariaLabel: 'More' } as IButtonProps}

							ariaDescription={'To move through the items use left and right arrow keys.'}
							ariaLabel={'List of folder´s groups'}
							onRenderPersona={(item: IFacepilePersona | undefined) => {
								return (
									<Persona key={item?.personaName} text={item?.personaName} size={PersonaSize.size24} hidePersonaDetails={true} />
								)
							}}
						/>
					</Stack>
				)
			}
			else { return <></> }
		}

		return (
			<Stack className='Width100'>
				<span className='Indent20'>
					<h3>{`${this.props.templateVersion === 'TEST' ? 'Test' : 'Published'} Templates ${!!this.props.library ? ` in ${this.props.library}` : ''}`}</h3>
				</span>
				{groupList()}
				<CommandBar
					items={this.commandItems()}
					ariaLabel="Use left and right arrow keys to navigate between commands"
				/>
				<ShimmeredDetailsList
					items={this.state.items}
					columns={this.state.columns}
					enableShimmer={!hasValues(this.state.items)}
					setKey="set"
					layoutMode={DetailsListLayoutMode.justified}
					selectionMode={this.props.templateItems === exampleTemplateList ? SelectionMode.none : SelectionMode.multiple}
					selection={(this._selection)}
					selectionPreservedOnEmptyClick={true}
					ariaLabelForSelectionColumn="Toggle selection"
					ariaLabelForSelectAllCheckbox="Toggle selection for all items"
					checkButtonAriaLabel="Row checkbox"
					checkboxVisibility={CheckboxVisibility.always}
					onActiveItemChanged={this._onActiveItemChanged}
				/>
				<DialogDelete
					setParentState={this.boundSetState}
					hidden={this.state.hideDeleteDialog}
					hideState='hideDeleteDialog'
					confirmState='deleteConfirmed'
					filename={this._selection.count === 1 ? ` file ${this.state.selectedItem.primaryText}` : ` ${this._selection.count} files`}
					isPublished={this.props.templateVersion === 'PUB'}
				/>
				{!this.state.hideUploadDialog && <DialogUploadTemplates
					// setParentState={this.boundSetState}
					// hidden={this.state.hideUploadDialog}
					// hideState='hideUploadDialog'
					// confirmState='uploadConfirmed'
					// uploadItemState='uploadItems'
					callbackUpload={this.uploadFiles}
					callbackAbort={() => this.setState({ hideUploadDialog: true })}
					folders={this.props.folderItems}
					defaultPath={this.props.library}
				/>}
				<DialogOkOnly setParentState={this.boundSetState} hidden={this.state.hideNoSelectDialog} hideState='hideNoSelectDialog' title='Nothing selected' text='You have to select a file to use this option.' />
				<DialogOkOnly setParentState={this.boundSetState} hidden={this.state.hideFileExist} hideState='hideFileExist' title='File exist' text='This filename already exist.' />
				<DialogOkAbort
					callbackOk={() => this._publishTemplates()}
					callbackAbort={() => this.setState({ hidePublishDialog: true })}
					hidden={this.state.hidePublishDialog}
					title='Publish templates'
					description='Are you sure you want to publish the selected templates?'
				/>
				<DialogEditTemplateSettings
					callbackOk={(settings) => this._editTemplates(settings)}
					callbackAbort={() => this.setState({ hideEditDialog: true })}
					hidden={this.state.hideEditDialog}
					files={this._selection.getSelection().map((item) => item as TemplateListItem)}
					// defaultSettings={this._selection.getItems().length > 0 ? this._selection.getItems()[0] as TemplateListItem : { hideDesktop: false, hideOnline: false, templateGroups: [], updatePub: false } as TemplateSettings}
					defaultSettings={this.state.selectedItem}
					showGroups={GetUseGroups(this.props.settings) || (this.state.selectedItem.templateGroups?.length! > 0)}
					companyGroups={this.props.companyGroups}
					folders={this.props.folderItems}
				/>
			</Stack>
		);
	}

	private _onActiveItemChanged = (item?: ExtendedTemplateListItem): void => {
		// console.log(this._selection.count)
		this.setState({ selectedItem: item! })
	}

	private _uploadPrompt() {
		this.setState({ hideUploadDialog: false })
	}

	
	private _publishTemplates(): void {
		this.setState({ hidePublishDialog: true, publishConfirmed: false })
		for (let i: number = 0; i < this._selection.getSelection().length; i++) {
			try {
				publishTemplate(
					this.props.accessToken,
					this.props.domain,
					this.props.api,
					this._selection.getSelection()[i] as ExtendedTemplateListItem
				)
					.then(async (response) => {
						if (i === this._selection.getSelection().length - 1) {
							console.log(response)
							if (response.status === 200) {
								setTimeout(() => {
									updateTemplates(
										this.props.accessToken,
										this.props.domain,
										this.props.api,
									)
										.then(() => {
											setTimeout(() => {
												this.setState({
													items: [],
													hidePublishDialog: true,
													publishConfirmed: false,
												})
												this.props.getTemplates(this.props.path, this.props.library, this.props.templateVersion)
												this.props.setMessage({ message: response.data, messageType: MessageBarType.success, visible: true })
											}, 500)
										})
								}, 500)
							}
							else if (response.status === 405) {
								this.setState({
									hideFileExist: false,
									hidePublishDialog: true,
									publishConfirmed: false,
								})
								this.props.setMessage({ message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
							}
							else {
								this.setState({
									hidePublishDialog: true,
									publishConfirmed: false,
								})
								this.props.setMessage({ message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
							}
						}
					})
					.catch((error) => {
						console.log(error)
						this.setState({
							items: [],
							selectedItem: {} as ExtendedTemplateListItem,
							hidePublishDialog: true,
							publishConfirmed: false,
						})
						this.props.getTemplates(this.props.path, this.props.library, this.props.templateVersion)
						this.props.setMessage({ message: typeof error.data === 'string' ? error.data : error.data.Message, messageType: MessageBarType.error, visible: true })
					})
			} catch (error) {
				this.setState({
					items: [],
					selectedItem: {} as ExtendedTemplateListItem,
					hidePublishDialog: true,
					publishConfirmed: false,
				})
				this.props.getTemplates(this.props.path, this.props.library, this.props.templateVersion)
				this.props.setMessage({ message: error!.toString(), messageType: MessageBarType.error, visible: true })
			}
		}
	}

	private _editTemplates(settings: TemplateSettings): void {
		this.setState({ hideEditDialog: true, editConfirmed: false })
		for (let i: number = 0; i < this._selection.getSelection().length; i++) {
			try {
				updateTemplate(
					this.props.accessToken,
					this.props.domain,
					this.props.api,
					{ ...(this._selection.getSelection()[i] as TemplateListItem), hideDesktop: settings.hideDesktop, hideOnline: settings.hideOnline, templateGroups: settings.templateGroups } as TemplateListItem,
					settings.Root! || '',
					settings.updatePub! || false
				)
					.then(async (response) => {
						if (i === this._selection.getSelection().length - 1) {
							console.log(response)
							if (response.status === 200) {
								setTimeout(() => {
									updateTemplates(
										this.props.accessToken,
										this.props.domain,
										this.props.api,
									)
										.then(() => {
											setTimeout(() => {
												this.setState({
													items: [],
													hideEditDialog: true,
													editConfirmed: false,
												})
												this.props.getTemplates(this.props.path, this.props.library, this.props.templateVersion)
												this.props.setMessage({ message: response.data, messageType: MessageBarType.success, visible: true })
											}, 500)
										})
								}, 500)
							}
							else if (response.status === 405) {
								this.setState({
									hideFileExist: false,
									hideEditDialog: true,
									editConfirmed: false,
								})
								this.props.setMessage({ message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
							}
							else {
								this.setState({
									hideEditDialog: true,
									editConfirmed: false,
								})
								this.props.setMessage({ message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
							}
						}
					})
					.catch((error) => {
						console.log(error)
						this.setState({
							items: [],
							selectedItem: {} as ExtendedTemplateListItem,
							hideEditDialog: true,
							editConfirmed: false,
						})
						this.props.getTemplates(this.props.path, this.props.library, this.props.templateVersion)
						this.props.setMessage({ message: typeof error.data === 'string' ? error.data : error.data.Message, messageType: MessageBarType.error, visible: true })
					})
			} catch (error) {
				this.setState({
					items: [],
					selectedItem: {} as ExtendedTemplateListItem,
					hideEditDialog: true,
					editConfirmed: false,
				})
				this.props.getTemplates(this.props.path, this.props.library, this.props.templateVersion)
				this.props.setMessage({ message: error!.toString(), messageType: MessageBarType.error, visible: true })
			}
		}
	}

	private _editPrompt() {
		if (this._selection.count > 0) {
			this.setState({ hideEditDialog: false })
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}
	}

	private _deletePrompt() {
		if (this._selection.count > 0) {
			this.setState({ hideDeleteDialog: false })
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}
	}

	private _publishPrompt() {
		if (this._selection.count > 0) {
			this.setState({ hidePublishDialog: false })
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}
	}

	private _deleteSelectedFiles() {
		if (true) {
			this.setState({ hideDeleteDialog: true, deleteConfirmed: false })
			for (let i: number = 0; i < this._selection.getSelection().length; i++) {
				let file: ExtendedTemplateListItem = this._selection.getSelection()[i] as ExtendedTemplateListItem
				const templateHeaders: TemplateApiHeaders = {
					templateVersion: this.props.templateVersion,
					templateEnviron: '',
					templateType: '',
					myGroups: '',
					groupSelection: 'ALL-TEMPLATES',
				}
				try {
					deleteTemplate(
						this.props.accessToken,
						this.props.domain,
						this.props.api,
						file.id,
						templateHeaders,
					)
						.then(async (response) => {
							if (i === this._selection.getSelection().length - 1) {
								console.log(response)
								if (response.status === 200) {
									setTimeout(() => {
										updateTemplates(
											this.props.accessToken,
											this.props.domain,
											this.props.api,
										)
											.then(() => {
												setTimeout(() => {
													this.setState({
														items: [],
														hideUploadDialog: true,
													})
													this.props.getTemplates(this.props.path, this.props.library, this.props.templateVersion)
													this.props.setMessage({ message: response.data, messageType: MessageBarType.success, visible: true })
												}, 500)
											})
									}, 500)
								}
								else if (response.status === 405) {
									this.setState({
										hideFileExist: false,
										hideUploadDialog: true,
									})
									this.props.setMessage({ message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
								}
								else {
									this.setState({
										hideUploadDialog: true,
									})
									this.props.setMessage({ message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
								}
							}
						})
						.catch((error) => {
							console.log(error)
							this.setState({
								items: [],
								selectedItem: {} as ExtendedTemplateListItem,
								hideDeleteDialog: true,
								deleteConfirmed: false,
							})
							this.props.getTemplates(this.props.path, this.props.library, this.props.templateVersion)
							this.props.setMessage({ message: typeof error.data === 'string' ? error.data : error.data.Message, messageType: MessageBarType.error, visible: true })
						})
				} catch (error) {
					this.setState({
						items: [],
						selectedItem: {} as ExtendedTemplateListItem,
						hideDeleteDialog: true,
						deleteConfirmed: false,
					})
					this.props.getTemplates(this.props.path, this.props.library, this.props.templateVersion)
					this.props.setMessage({ message: error!.toString(), messageType: MessageBarType.error, visible: true })
				}
			}
		}

	}

	private async _downloadFile() {
		if (typeof this._selection.getSelection()[0] !== 'undefined') {
			for (let i: number = 0; i < this._selection.getSelection().length; i++) {
				ourRequest = Axios.CancelToken.source()
				let selectedFile: ExtendedTemplateListItem = this._selection.getSelection()[i] as ExtendedTemplateListItem
				const templateHeaders: TemplateApiHeaders = {
					templateVersion: this.props.templateVersion,
					templateEnviron: '',
					templateType: '',
					myGroups: '',
					groupSelection: 'ALL-TEMPLATES',
				}
				let fileBase64 = await GetTemplateId(this.props.accessToken, this.props.domain, this.props.api, selectedFile.id, templateHeaders, ourRequest.token)
				let anchor: HTMLAnchorElement = document.createElement("a");
				anchor.href = 'data:' + selectedFile.mimetype + ';base64,' + JSON.parse(fileBase64);
				console.log(selectedFile)
				anchor.download = selectedFile.Path!.substring(selectedFile.Path!.lastIndexOf('\\') + 1);
				anchor.click(); //Downloaded file
			}
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}

	}

	private _onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
		const { columns, items } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
				// this.setState({
				//   announcedMessage: `${currColumn.name} is sorted ${
				// 	currColumn.isSortedDescending ? 'descending' : 'ascending'
				//   }`,
				// });
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		const newItems = sortArray(items, currColumn.fieldName!, currColumn.isSortedDescending);
		this.setState({
			columns: newColumns,
			items: newItems,
		});
	};
}
