import * as React from 'react';
import {
	Image,
	DefaultButton,
	Spinner,
	SpinnerSize,
	Stack,
} from '@fluentui/react';
import SettingsCompany from '../../helpers/SettingsCompany';
import { Label } from 'office-ui-fabric-react';
import { UploadFile } from '../../interfaces/UploadFile';
import { uploadLogo } from '../../helpers/PostToRestApi';
import { GetLogo } from '../../helpers/GetFromRestApi';
import Axios, { CancelTokenSource } from 'axios';
import { DialogUploadLogo } from '../dialogs/DialogUploadLogo';

export interface SettingsLogoProps {
	setNewLogo: (x: string) => void;
	updateSettings(): Promise<void>;
	editedSettings: SettingsCompany;
	companyLogo: string,
	domain: string,
	accessToken: string,
	// apiUri: string,
	api:string
}

export interface SettingsLogoState {
	// companyLogo: string
	hideUploadDialog: boolean
	uploadConfirmed: boolean
	uploadItems: UploadFile[]
	updatingLogo: boolean
}

//let mySettings: SettingsCompany | any = {} as SettingsCompany;

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class SettingsLogo extends React.Component<SettingsLogoProps, SettingsLogoState> {
	// onChangeSettings: ((event: MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) => void) | undefined;

	constructor(props: SettingsLogoProps) {
		super(props);
		this.state = {
			// companyLogo: this.props.companyLogo,
			hideUploadDialog: true,
			uploadConfirmed: false,
			uploadItems: [],
			updatingLogo: false
		};
		this.boundSetState = this.setState.bind(this)
	}

	boundSetState = (x: any) => { this.setState(x) }

	updateLogo = () => {
		this.setState({ updatingLogo: true })
		ourRequest = Axios.CancelToken.source()

		GetLogo(this.props.accessToken, this.props.domain,this.props.api, ourRequest.token).then((promise: string) => {
			this.props.setNewLogo(typeof promise.length !== 'undefined' ? promise : this.props.companyLogo)
			this.setState({ updatingLogo: false })
		})
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	componentDidUpdate(prevProps: SettingsLogoProps, prevState: SettingsLogoState) {
		if (prevProps.companyLogo !== this.props.companyLogo) {
			this.updateLogo()
		}
		if (!prevState.uploadConfirmed && this.state.uploadConfirmed) {
			this._uploadFile()
		}
	}

	componentDidMount() {

	}

	render(): JSX.Element {

		// if (this.state.companyLogo !== this.props.companyLogo && !this.updatingLogo) {
		// 	return(<Spinner className='spinner' size={SpinnerSize.large} label='Updating logo...' styles={{root: { margin: '20px' }}}/>)
		// }

		if (this.state.uploadConfirmed || this.state.updatingLogo) {
			return (<Spinner className='spinner' size={SpinnerSize.large} label='Updating logo...' styles={{ root: { margin: '20px' } }} />)
		}

		return (
			<Stack tokens={{ childrenGap: '10px' }} styles={{ root: { width: '100%' } }}>
				<Stack.Item align='start'>
					<DefaultButton
						key='upload'
						text='Upload Logo'
						primary
						iconProps={{ iconName: 'Upload' }}
						onClick={() => { this._uploadPrompt() }}
					/>
				</Stack.Item>
				<Stack.Item align='start'>
					<Stack horizontal tokens={{ childrenGap: 50 }}>
						<Stack tokens={{ childrenGap: 5 }}>
							<Label>Normal<br />resolution</Label>
							{this.props.companyLogo !== '' ? <Image src={"data:image/png;base64," + this.props.companyLogo} style={{ height: 80 }} alt="" /> : null}
						</Stack>
						<Stack tokens={{ childrenGap: 5 }}>
							<Label>High<br />resolution</Label>
							{this.props.companyLogo !== '' ? <Image src={"data:image/png;base64," + this.props.companyLogo} style={{ height: 160 }} alt="" /> : null}
						</Stack>
					</Stack>
				</Stack.Item>
				<DialogUploadLogo setParentState={this.boundSetState} hidden={this.state.hideUploadDialog} hideState='hideUploadDialog' confirmState='uploadConfirmed' uploadItemState='uploadItems' />
			</Stack>
		);
	}

	// private commandItems: ICommandBarItemProps[] = [
	// 	{
	// 		key: 'upload',
	// 		text: 'Upload Logo',
	// 		iconProps: { iconName: 'Upload' },
	// 		onClick: () => { this._uploadPrompt() },
	// 	},
	// ];

	private _uploadPrompt() {
		this.setState({ hideUploadDialog: false })
	}

	private _uploadFile(): void {

		//this.setState({uploadItem: {filename: '123', base64: '123'}})

		this.state.uploadItems.forEach((value, _index, _array) => {
			uploadLogo(
				this.props.accessToken,
				this.props.domain,
				this.props.api,
				value.data
			)
				.then(async (response) => {
					setTimeout(() => {
						// this.props.updateSettings()
						this.setState({ uploadConfirmed: false })
						this.updateLogo()
					}, 500)

					if (response.status === 200) {

					}
					else if (response.status === 405) {

					}
					else {

					}
				});
		})
	}
}
