import * as React from 'react';
import { exampleGroupDetails, GroupDetails } from '../../interfaces/GroupDetails';
import {
	CommandBar,
	ICommandBarItemProps,
	Image,
	SelectionMode,
	ShimmeredDetailsList,
	IColumn,
	CheckboxVisibility,
	MessageBar,
	MessageBarType,
	TooltipHost,
	Icon,
	Stack,
	// MessageBarButton,
} from '@fluentui/react';
import { DetailsListLayoutMode, Selection, } from 'office-ui-fabric-react/lib/DetailsList';
import { hasValues, sortArray } from '../../helpers/MiscFunctions';
// import { DialogDelete, DialogEditUser, DialogOkOnly } from '../../helpers/Dialogs';
// import { createNewUser, deleteUser, updateUser } from '../../helpers/PostToAdminApi';
import GroupsAddPanel from './GroupsAddPanel';
import { deleteGroups } from '../../helpers/PostToRestApi';
import { GroupsState } from './Groups';
import { DialogDelete } from '../dialogs/OldDialogDelete';
import { DialogOkOnly } from '../dialogs/OldDialogOkOnly';
import SigninButton from '../../assets/signin.svg';


export interface GroupsListProps {
	// setAppState: (x: AppState) => void,
	setGroupState: (x: GroupsState) => void;
	getCompanyGroups: () => void,
	// authCallback:  (err: any, res: any, instance: any) => void,
	scopes: string[],
	setNewScope: (scope: string[]) => void,
	// updateGroups(): void,
	accessToken: string,
	// apiUri: string,
	domain: string,
	api: string,
	allGroups: GroupDetails[],
	companyGroups: GroupDetails[],
	selectedGroup: GroupDetails,
	isUsersTenant: boolean,
}

export interface GroupsListState {
	// allGroups: GroupDetails[],
	// listGroups: GroupDetails[];
	sortedListGroups: GroupDetails[];
	// groupIds: GroupInfo[];
	// selectedItem: GroupDetails,
	selection: Selection,
	columns: IColumn[],
	showGroupsPanel: boolean,
	hideNoSelectDialog: boolean,
	hideDeleteDialog: boolean,
	deleteConfirmed: boolean,
}

export default class GroupsList extends React.Component<GroupsListProps, GroupsListState> {

	private _selection: Selection;

	constructor(props: GroupsListProps) {
		super(props);
		this._selection = new Selection({
			onSelectionChanged: () => this.setState({ selection: this._selection }),
		});
		this.state = {
			sortedListGroups: this.props.companyGroups,
			selection: this._selection,
			showGroupsPanel: false,
			hideDeleteDialog: true,
			hideNoSelectDialog: true,
			deleteConfirmed: false,
			columns: [{ key: 'column1', name: '', minWidth: 16, }],
		};
		this.boundSetState = this.setState.bind(this)
	}

	getColums(): IColumn[] {
		return [
			{
				key: 'column1',
				fieldName: 'displayName',
				name: 'Name',
				minWidth: 150,
				maxWidth: 500,
				isResizable: true,
				isSorted: true,
				isSortedDescending: false,
				onColumnClick: this._onColumnClick,
				onRender: (item: GroupDetails) => {
					if (item.visibility === 'deleted') {
						return (
							<TooltipHost content={<>Group is missing or deleted. Content tied to this group will not be available.</>}>
								<span style={{ textDecoration: 'line-through', color: '#999' }}>{item.displayName}</span>{' '}
								<Icon iconName="Info" title="Info" ariaLabel="Info" />
							</TooltipHost>
						)
					}
					else {
						return item.displayName
					}
				},
			},
			{
				key: 'column2',
				fieldName: 'description',
				name: 'Description',
				minWidth: 150,
				maxWidth: 500,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				// onRender: (item: UserAdminItem) => { return item.isSettingsAdmin ? <FontIcon iconName="CheckMark"/> : <></> },
			},
			{
				key: 'column3',
				fieldName: 'createdDateTime',
				name: 'Date created',
				minWidth: 150,
				maxWidth: 150,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				onRender: (item: GroupDetails) => {
					if (item.createdDateTime === null) {
						return ''
					}
					else {
						const date = new Date(item.createdDateTime!)
						return date.toLocaleDateString('sv-SE', { timeZone: 'Europe/Stockholm' }) + ' ' + date.toLocaleTimeString('sv-SE', { timeZone: 'Europe/Stockholm' })
					}
				},
			},
			{
				key: 'column4',
				fieldName: 'visibility',
				name: 'Visibility',
				minWidth: 60,
				maxWidth: 60,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				onRender: (item: GroupDetails) => {
					if (item.visibility === 'deleted') {
						return 'Deleted'
					}
					else {
						return item.visibility
					}
				},
			},
			{
				key: 'column5',
				fieldName: 'resourceProvisioningOptions',
				name: '',
				minWidth: 32,
				maxWidth: 32,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				onRender: (item: GroupDetails) => {
					return item.resourceProvisioningOptions![0] === "Team" ?
						<TooltipHost content={<>Teams</>}>
							<Image src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/svg/teams_16x1.svg" alt="Teams" style={{ width: 16, height: 16 }} />
						</TooltipHost>
						:
						item.securityEnabled ?
							<TooltipHost content={<>Security Group</>}>
								<Icon styles={{ root: { fontSize: '16px' } }} iconName='SecurityGroup' />
							</TooltipHost>
							: null
				},
			},
		]
	}

	commandItems = (): ICommandBarItemProps[] => {
		return [
			{
				key: 'newGroup',
				text: 'Add Group',
				disabled: !this.props.isUsersTenant || (this.props.allGroups.length === 0 || this.props.allGroups[0]?.displayName === 'No groups'),
				iconProps: { iconName: 'Add' },
				onClick: () => { this.setState({ showGroupsPanel: true }) },
			},
			{
				key: 'edit',
				text: 'Edit',
				iconProps: { iconName: 'Edit' },
				disabled: true,
				onClick: () => { this.setState({ showGroupsPanel: true }) },
			},
			{
				key: 'delete',
				text: 'Delete',
				disabled: !this.props.isUsersTenant,
				iconProps: { iconName: 'Delete' },
				onClick: () => this._deletePrompt(),
			},
		]
	};

	boundSetState = (x: any) => { this.setState(x) }

	componentDidUpdate(prevProps: GroupsListProps, prevState: GroupsListState) {
		if (prevProps.companyGroups !== this.props.companyGroups) {
			this.setState({
				sortedListGroups: sortArray(this.props.companyGroups, 'displayName', false)
			})
		}
		if (prevProps.scopes !== this.props.scopes) {
			this.setState({
				columns: this.getColums()
			})
		}
		if (this.state.deleteConfirmed && !prevState.deleteConfirmed) {
			this._deleteSelectedGroup()
		}
	}

	componentDidMount() {
		this.setState({
			sortedListGroups: sortArray(this.props.companyGroups.length > 0 ? this.props.companyGroups : exampleGroupDetails, 'displayName', false),
			columns: this.getColums()
		})
	}

	render(): JSX.Element {

		// if (this.state.listGroups !== this.props.companyGroups) {
		// 	this.setState({
		// 		listGroups: this.props.companyGroups,
		// 		sortedListGroups: sortArray(this.props.companyGroups, 'displayName', false)
		// 	})
		// }

		// if (this.state.deleteConfirmed) {
		// 	this._deleteSelectedGroup()
		// }

		console.log('LIST GROUPS')
		console.log(this.props.isUsersTenant)
		console.log(this.props.allGroups.length)
		console.log(this.props.allGroups[0]?.displayName)

		// if (true) {return <></>}
		return (
			<>
				<Stack className='Width100'>
					<span className='Indent20'><h3>Groups</h3></span>
					<CommandBar
						items={this.commandItems()}
						ariaLabel="Use left and right arrow keys to navigate between commands"
					/>
					{this.props.isUsersTenant && (this.props.allGroups.length === 0 || this.props.allGroups === exampleGroupDetails) ?
						<MessageBar
							messageBarType={MessageBarType.warning}
							isMultiline={false}
							actions={
								<div>
									<Image alt='Signin' src={SigninButton} onClick={() => this.props.setNewScope(['Group.Read.All'])} />
									{/* <MicrosoftLogin
										buttonTheme='light_short'
										clientId={process.env.REACT_APP_CLIENTID!}
										authCallback={this.props.authCallback}
										prompt="select_account"
										children={undefined}
									/> */}
								</div>
							}
						>
							Could not reach list of groups from Microsoft 365.<br />Please sign in again.
						</MessageBar>
						: null}
					<ShimmeredDetailsList
						items={this.state.sortedListGroups}
						columns={this.state.columns}
						enableShimmer={!hasValues(this.state.sortedListGroups)}
						setKey="set"
						layoutMode={DetailsListLayoutMode.justified}
						selectionPreservedOnEmptyClick={true}
						selectionMode={SelectionMode.single}
						ariaLabelForSelectionColumn="Toggle selection"
						ariaLabelForSelectAllCheckbox="Toggle selection for all items"
						ariaLabelForShimmer="Content is being fetched"
						ariaLabelForGrid="Item details"
						checkButtonAriaLabel="Row checkbox"
						selection={(this._selection)}
						checkboxVisibility={CheckboxVisibility.always}
						onActiveItemChanged={this._onActiveItemChanged}
					/>
					<DialogDelete
						setParentState={this.boundSetState}
						hidden={this.state.hideDeleteDialog}
						hideState='hideDeleteDialog'
						confirmState='deleteConfirmed'
						filename={` group ${this.props.selectedGroup.displayName}`}
					/>
					<DialogOkOnly
						setParentState={this.boundSetState}
						hidden={this.state.hideNoSelectDialog}
						hideState='hideNoSelectDialog'
						title='Nothing selected'
						text='You have to select a group to use this option.'
					/>
				</Stack>
				{this.state.showGroupsPanel ?
					<GroupsAddPanel
						onDismiss={() => this.setState({ showGroupsPanel: false })}
						updateList={this.props.getCompanyGroups}
						groups={this.props.allGroups}
						accessToken={this.props.accessToken}
						domain={this.props.domain}
						api={this.props.api}
					/>
					: null}
			</>
		);
	}



	private _deletePrompt() {
		if (this._selection.count === 0 || typeof this.props.selectedGroup.id === 'undefined') {
			this.setState({ hideNoSelectDialog: false })
		}
		else {
			this.setState({ hideDeleteDialog: false })
		}
	}

	private _deleteSelectedGroup() {
		if (true) {
			console.log(this.props.selectedGroup)
			console.log(this.props.selectedGroup.id)
			deleteGroups(this.props.accessToken, this.props.domain, this.props.api, this.props.selectedGroup.id)
				.then(async (response) => {
					console.log(response)
					this.props.getCompanyGroups()
					setTimeout(() => {
						this.setState({
							deleteConfirmed: false,
						})
					}, 500)
				})
		}
	}

	private _onActiveItemChanged = (item?: GroupDetails): void => {
		// console.log(item?.User)
		// this.setState({selectedItem: item!})
		this.props.setGroupState({ selectedGroup: item! })

	}

	private _onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
		const { columns, sortedListGroups } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		const newItems = sortArray(sortedListGroups, currColumn.fieldName!, currColumn.isSortedDescending);
		this.setState({
			columns: newColumns,
			sortedListGroups: newItems,
		});
	};
}
