import axios, { AxiosRequestConfig } from 'axios';


export interface AIPrompt {
    model: string;
    messages: Message[];
    temperature?: number;
}

interface Message {
    role: string;
    content: string;
}

export interface AIResponse {
    id: string;
    object: string;
    created: number;
    model: string;
    choices: Choice[];
    usage: Usage;
    system_fingerprint: null;
}

interface Usage {
    prompt_tokens: number;
    completion_tokens: number;
    total_tokens: number;
}

interface Choice {
    index: number;
    message: Message;
    logprobs: null;
    finish_reason: string;
}

interface Message {
    role: string;
    content: string;
}

export const getGPTResponse = async (gptToken: string, systemMessage: string, userMessage: string): Promise<string> => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${gptToken}`
        }
    }
    const prompt: AIPrompt = {
        "model": "gpt-3.5-turbo",
        "messages": [
            { "role": "system", "content": systemMessage },
            { "role": "user", "content": userMessage }
        ]
    }
    return await axios.post('https://api.openai.com/v1/chat/completions', prompt, config)
        .then(response => {
            const aiRes: AIResponse = response.data
            return aiRes.choices[0].message.content
        })
        .catch(error => {
            console.error('Failed to get GPT response', error)
            return error
        })
}