import { GroupDetails } from "../interfaces/GroupDetails"
import { GroupInfo } from "../interfaces/GroupInfo"
import { getDomainGroups } from "./GetFromGraph"
import { GetGroups } from "./GetFromRestApi"

export const FetchGroups = async (accessToken: string, domain: string, api: string): Promise<[GroupInfo[], GroupDetails[]]> => {
    const companyGroups: GroupInfo[] =  await GetGroups(accessToken, domain, api) || []
    const allGroups: GroupDetails[] = await getDomainGroups(accessToken) ||[]

    return [companyGroups, allGroups] as [GroupInfo[], GroupDetails[]]
    // return await GetGroups(accessToken, domain, api)
    //     .then(async (tenantGroupIds) => {
    //         if (tenantGroupIds.length > 0) {
    //             const allGroups = await getDomainGroups(accessToken)
    //             return [tenantGroupIds, allGroups] as [GroupInfo[], GroupDetails[]]
    //         }
    //         else {
    //             return [[], []] as [GroupInfo[], GroupDetails[]]
    //         }
    //     })
    //     .catch(() => {
    //         return [[], []] as [GroupInfo[], GroupDetails[]]
    //     })
}