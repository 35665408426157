import axios, { CancelToken } from 'axios';
import { Buffer } from 'buffer';
import { GraphUser } from '../interfaces/GraphUser';
import { connectionFailedGroupDetails, GroupDetails } from '../interfaces/GroupDetails';
import { hasValues } from './MiscFunctions';
import { GraphOrganizationData } from '../interfaces/GraphOrganizationData';
import { VerifiedDomain } from '../interfaces/VerifiedDomain';

// export const GetFromGraph = async (accesstoken: string) => {

// 	localStorage.setItem('accesstoken', accesstoken);
// 	Promise.all([
// 		getProfileData(accesstoken),
// 		getCompanyData(accesstoken),
// 		getTenant(accesstoken),
// 		getProfilePhoto(accesstoken),
// 		getDomainGroups(accesstoken),
// 	])
// };

export const getProfileData = async (accesstoken: string): Promise<GraphUser> => {

	return getGraphData("https://graph.microsoft.com/v1.0/me/", accesstoken)
		.then(async (response) => {
			localStorage.setItem('userDetails', JSON.stringify(response.data));
			return response.data
		})
		.catch((requestError) => {
			console.log(requestError)
			console.log("error getProfileData");
		});
};

export const getProfileDataExtended = async (accesstoken: string, fields: string[]): Promise<any> => {

return getGraphData(`https://graph.microsoft.com/v1.0/me?$select=${fields.join(',')}`, accesstoken)
		.then(async (response) => {
			localStorage.setItem('userDetails', JSON.stringify(response.data));
			return response.data
		})
		.catch((requestError) => {
			console.log(requestError)
			console.log("error getProfileData");
		});
};

export const getCompanyData = async (accesstoken: string): Promise<GraphOrganizationData> => {

	return getGraphData("https://graph.microsoft.com/v1.0/organization/?$select=displayName,street,postalCode,city,state,country,businessPhones", accesstoken)
		.then(async (response) => {
			localStorage.setItem('compDetails', JSON.stringify(response.data.value[0]));
			return response.data.value[0]
		})
		.catch((requestError) => {
			console.log(requestError)
			console.log("error getCompanyData");
		});
};

export const getTenant = async (accesstoken: string): Promise<VerifiedDomain[]> => {

	return getGraphData("https://graph.microsoft.com/v1.0/organization/?$select=verifiedDomains", accesstoken)
		.then(async (response) => {
			// const domaintoken: string = JSON.parse(JSON.stringify(response.data.value[0].verifiedDomains.filter((item: { isDefault: boolean; }) => item.isDefault === true)[0].name))
			// localStorage.setItem('userDomain', domaintoken);
			// localStorage.setItem('verifiedDomains', JSON.stringify(response.data.value[0].verifiedDomains));
			return response.data.value[0].verifiedDomains
		})
		.catch((requestError) => {
			console.log(requestError);
			console.log("error getDomainData");
			return []
		});
};

export const getProfilePhoto = async (accesstoken: string): Promise<string> => {

	return getGraphPicData("https://graph.microsoft.com/v1.0/me/photos/120x120/$value", accesstoken)
		.then(async (response) => {
			// localStorage.setItem('userPhoto', Buffer.from(response.data).toString('base64'));
			return Buffer.from(response.data).toString('base64')
		})
		.catch((requestError) => {
			console.log(requestError);
			console.log("NoProfilePhoto");
			return ''
		});
};

export const getUserPhoto = async (accesstoken: string, userId: string): Promise<any> => {

	return getGraphPicData(`https://graph.microsoft.com/v1.0/users/${userId}/photos/96x96/$value`, accesstoken)
		.then(async (response) => {
			return Buffer.from(response.data).toString('base64');
		})
		.catch((requestError) => {
			console.log(requestError);
			console.log("NoProfilePhoto");
			return null
		});
};

// export const getDomainGroups = async (accesstoken: string): GroupDetails[] => {

// 	return getGraphData( "https://graph.microsoft.com/v1.0/groups?$filter=groupTypes/any(c:c+eq+'Unified')&$select=id,displayName,description,resourceProvisioningOptions,createdDateTime,visibility", accesstoken )
// 	.then( async (response) => {
// 		localStorage.setItem('domainGroups', JSON.stringify(response.data.value));
// 	})
// 	.catch ( (requestError) => {
// 		console.log(requestError);
// 		console.log("error getDomainGrops");
// 	});
// };

export async function getDomainGroups(accesstoken: string): Promise<GroupDetails[]> {
	let nextlink = null;
	let groups: GroupDetails[] = await getGraphData("https://graph.microsoft.com/v1.0/groups?$select=id,displayName,description,resourceProvisioningOptions,createdDateTime,visibility,securityEnabled,mail", accesstoken)
		.then(async (response) => {
			nextlink = response.data['@odata.nextLink']
			let tempGroups: GroupDetails[] = response.data.value
			// console.log(nextlink)
			while (hasValues(nextlink)) {
				let moregroups: GroupDetails[] = await getGraphData(nextlink, accesstoken)
					// eslint-disable-next-line no-loop-func
					.then(async (subresponse) => {
						nextlink = subresponse.data['@odata.nextLink'];
						return subresponse.data.value as GroupDetails[]
					})
					// eslint-disable-next-line no-loop-func
					.catch((requestError) => {
						console.log(requestError);
						nextlink = null;
						return []
					});
				// console.log(nextlink)
				tempGroups = tempGroups.concat(moregroups)
			}

			// console.log(tempGroups)
			return tempGroups
		})
		.catch((requestError) => {
			console.log(requestError);
			console.log("error getDomainGrops");
			return connectionFailedGroupDetails
		});
	return groups
}

export const getGroupMembers = async (accesstoken: string, groupId: string): Promise<GraphUser[]> => {
	return getGraphData(`https://graph.microsoft.com/v1.0/groups/${groupId}/members`, accesstoken)
		.then(async (response) => {
			return response.data.value
		})
		.catch((requestError) => {
			console.log(requestError);
			console.log("error getGroupMembers");
			return null
		});
};

export const getGraphData = async (url: string, accesstoken: string, cancelToken?: CancelToken) => {
	const response = await axios({
		url: url,
		method: 'get',
		headers: { 'Authorization': `Bearer ${accesstoken}` },
		cancelToken: cancelToken!,
	});
	return response;
};

export const getGraphPicData = async (url: string, accesstoken: string, cancelToken?: CancelToken) => {
	const response = await axios({
		url: url,
		method: 'get',
		responseType: 'arraybuffer',
		headers: { 'Authorization': `Bearer ${accesstoken}`, 'content-type': 'image/jpeg' },
		cancelToken: cancelToken!,
	});
	return response;
};