import React, { useState, useEffect } from 'react';
import { Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import Axios, { CancelTokenSource } from 'axios';
import { GetAllNews, } from '../../helpers/GetFromRestApi';
import { ContentFrame } from '../common/ContentFrame';
import { VersionSelector } from '../common/VersionSelector';
import { checkValidToken } from '../../helpers/LogginHelper';
import { NewsItem } from '../../interfaces/NewsItem';
import NewsList from './NewsList';
import NewsPreview from './NewsPreview';
import { DialogOkAbort } from '../dialogs/DialogOkAbort';
import { deleteNews, publishNews } from '../../helpers/PostToAdminApi';
import { AuthContext } from '../../contexts/AuthContext';

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

const News: React.FC = () => {

    const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
    const [selectedItem, setSelectedItem] = useState<NewsItem | null>(null);
    const [templateVersion, setTemplateVersion] = useState<'PROD' | 'TEST'>('TEST');
    const [isLoadingNews, setIsLoadingNews] = useState<boolean>(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
    const [showConfirmPublish, setShowConfirmPublish] = useState<boolean>(false);

    // const { tenantContext } = React.useContext(TenantContext);
    // const { messageContext, setMessageContext } = React.useContext(MessageContext);
    const { accessToken, refreshToken } = React.useContext(AuthContext);

    const getNews = async (version: 'PROD' | 'TEST') => {
        ourRequest = Axios.CancelToken.source();
        setIsLoadingNews(true);
        setTemplateVersion(version);

        GetAllNews(accessToken!, version, ourRequest.token)
            .then((response) => {
                setNewsItems(response);
            })
            .finally(() => {
                setIsLoadingNews(false);
            });
    }

    useEffect(() => {
        getNews(templateVersion);
        setSelectedItem(null);
    }, [accessToken!, templateVersion]);

    useEffect(() => {
        getNews(templateVersion);
        return () => { try { ourRequest.cancel(); } catch (error) { } }
    }, []);

    const onCommandItemClick = (key: string, selectedItem: NewsItem) => {
        console.log(key, selectedItem);
        switch (key) {
            case 'new':
                if (newsItems.find(x => x.ID === '')) {
                    setSelectedItem(newsItems.find(x => x.ID === '')!)
                    return
                }
                const newNewsItem: NewsItem = { ID: '', Title: '', PostDate: '' } as NewsItem;
                setNewsItems([...newsItems, newNewsItem]);
                setSelectedItem(newNewsItem)
                break;
            case 'publish':
                setShowConfirmPublish(true)
                break;
            case 'delete':
                setShowConfirmDelete(true)
                break;
            default:
                break;
        }
    }

    const deleteSelectedNewsItem = () => {
        deleteNews(accessToken!, selectedItem!?.ID!, templateVersion)
            .then(() => {
                getNews(templateVersion)
                if (newsItems.length > 0) { setSelectedItem(newsItems[0]) }
                else { setSelectedItem(null) }
            });
        setShowConfirmDelete(false)
    }

    const publishSelectedNewsItem = () => {
        publishNews(accessToken!, selectedItem!?.ID!, templateVersion)
            .then(() => {
                setTemplateVersion('PROD')
                getNews('PROD')
            })
        setShowConfirmPublish(false)
    }

    if (!checkValidToken(accessToken!)) { refreshToken() }

    return (
        <Stack>
            <Stack.Item className="" styles={{ root: { margin: '30px' } }}>
                <Text variant="xxLarge">News</Text>
            </Stack.Item>
            <VersionSelector
                onVersionChange={(version) => getNews(version === 'TEST' ? 'TEST' : 'PROD')}
                version={templateVersion === 'TEST' ? 'TEST' : 'PUB'}
                testHeader='Draft'
                pubHeader='Published'
                testDescription={
                    <>
                        You're now in draft mode.
                    </>
                }
                pubDescription={
                    <>
                        You're now in published mode.
                    </>
                }
            />
            {isLoadingNews && <Spinner className='spinner' size={SpinnerSize.large} label='Loading news...' />}
            <ContentFrame hidden={isLoadingNews}>
                <NewsList
                    templateVersion={templateVersion}
                    onCommandItemClick={onCommandItemClick}
                    newsItems={newsItems}
                    selectedItem={selectedItem}
                    setSelectedItem={(item) => setSelectedItem(item)}
                />
            </ContentFrame>
            <ContentFrame hidden={isLoadingNews || newsItems!?.length === 0}>
                <NewsPreview
                    refreshNews={() => getNews(templateVersion)}
                    newsItem={selectedItem}
                    templateVersion={templateVersion}
                />
            </ContentFrame>
            <DialogOkAbort
                title='Confirm delete'
                description={`Are you sure you want to delete the news item "${selectedItem!?.Title}"?`}
                callbackOk={deleteSelectedNewsItem}
                callbackAbort={() => { setShowConfirmDelete(false) }}
                hidden={!showConfirmDelete}
            />
            <DialogOkAbort
                title='Confirm publish'
                description={`Are you sure you want to publish the news item "${selectedItem!?.Title}"?`}
                callbackOk={() => { publishSelectedNewsItem() }}
                callbackAbort={() => { setShowConfirmPublish(false) }}
                hidden={!showConfirmPublish}
            />
        </Stack>
    );
};

export default News;
