import axios, { AxiosRequestConfig } from 'axios';
import { adminApiUris } from './SettingsMisc';
import { NewsItem } from '../interfaces/NewsItem';

const adminOperation = async (url: string, method: AxiosRequestConfig["method"], accessToken: string, api: string, domain: string, data: Object, header?: any) => {
	const response = await axios({
		url: url,
		method: method,
		headers: {
			'Authorization': 'Bearer ' + accessToken,
			'API': api,
			'domain': domain,
			'Content-Type': 'application/json',
			...header,
		},
		data: data ? data : null,
	});
	return response;
};

export const createNewDomain = async (accessToken: string, api: string, data: Object) => {

	return adminOperation(`${adminApiUris()}admin_domains`, 'POST', accessToken, api, '', data)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteDomain = async (accessToken: string, api: string, domain: string) => {
	return adminOperation(`${adminApiUris()}admin_domains`, 'DELETE', accessToken, api, domain, '')
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const createNewUser = async (accessToken: string, data: Object) => {

	return adminOperation(`${adminApiUris()}admin_users`, 'POST', accessToken, '', '', data)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const updateUser = async (accessToken: string, data: Object) => {

	return adminOperation(`${adminApiUris()}admin_users`, 'PUT', accessToken, '', '', data)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};


export const deleteUser = async (accessToken: string, data: string) => {

	return adminOperation(`${adminApiUris()}admin_users`, 'DELETE', accessToken, '', '', { "User": data })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const postNews = async (accessToken: string, data: NewsItem) => {

	return adminOperation(`${adminApiUris()}admin_news`, 'POST', accessToken, '', '', data)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const publishNews = async (accessToken: string, newsID: string, newsVersion: 'TEST' | 'PROD') => {

	return adminOperation(`${adminApiUris()}admin_news?newsid=${newsID}`, 'PUT', accessToken, '', '', '', { 'NEWS-VERSION': newsVersion })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteNews = async (accessToken: string, newsID: string, newsVersion: 'TEST' | 'PROD') => {

	return adminOperation(`${adminApiUris()}admin_news?newsid=${newsID}`, 'DELETE', accessToken, '', '', '', { 'NEWS-VERSION': newsVersion })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

//Redirect
export const postRedirect = async (accessToken: string, api: string, domain: string, targetDomain: string) => {

	return adminOperation(`${adminApiUris()}admin_domains?redirect=true&domain=${domain}&targetdomain=${targetDomain}`, 'POST', accessToken, api, '', '', {'API': api})
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteRedirect = async (accessToken: string, api: string, domain: string) => {

	return adminOperation(`${adminApiUris()}admin_domains?redirect=true&domain=${domain}`, 'DELETE', accessToken, api, '', '', {'API': api})
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};