import * as React from 'react';
import { Dropdown, IDropdownOption, Stack, Toggle } from '@fluentui/react';
import { CustomDataDefItem } from '../../interfaces/CustomDataDefItem';
import SettingsCompany, { GetUseGroups } from '../../helpers/SettingsCompany';
import { CustomDataLevelItem } from '../../interfaces/CustomDataLevelItem';
import { DataLabelItem } from '../../interfaces/DataLabelItem';
import { CustomDataListItem } from '../../interfaces/CustomDataListItem';
import { getTooltipFromString } from '../../helpers/Descriptions';

export interface CustomDataDefSettingsProps {
	settings: SettingsCompany
	editMode: boolean
	CustomDataDefEdited: CustomDataDefItem
	onChangeDef: (newDef: CustomDataDefItem) => void
}

export interface CustomDataDefSettingsState {
}

export default class CustomDataDefSettings extends React.Component<CustomDataDefSettingsProps, CustomDataDefSettingsState> {
	constructor(props: CustomDataDefSettingsProps) {
		super(props);
		this.state = {
		};
	}

	componentDidUpdate(_prevProps: CustomDataDefSettingsProps, _prevState: CustomDataDefSettingsState) {
	}

	componentDidMount() {
	}

	onChangeLevelCounts = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
		if (option) {
			const emptyLabels: DataLabelItem[] = [{ dataLabel: '', langCode: 'default' }, { dataLabel: '', langCode: 'en' }]
			const emptyField: CustomDataListItem[] = [{ FieldLabels: emptyLabels, DataType: 'text', FieldName: '', LabelItem: true }]
			let level2: CustomDataLevelItem | undefined = this.props.CustomDataDefEdited.Level2
			let level3: CustomDataLevelItem | undefined = this.props.CustomDataDefEdited.Level3
			switch (option?.data) {
				case 1:
					level2 = undefined
					level3 = undefined
					break
				case 2:
					if (!level2 || level2 === undefined || level2!?.LevelLabels.length === 0) {
						level2 = { LevelLabels: emptyLabels, CustomDataDefs: emptyField }
					}
					level3 = undefined
					break
				case 3:
					if (!level2 || level2 === undefined || level2!?.LevelLabels.length === 0) {
						level2 = { LevelLabels: emptyLabels, CustomDataDefs: emptyField }
					}
					if (!level3 || level3 === undefined || level3!?.LevelLabels.length === 0) {
						level3 = { LevelLabels: emptyLabels, CustomDataDefs: emptyField }
					}
					break
				default:
					break;
			}
			console.log(option.data, level2, level3)
			this.props.onChangeDef(Object.assign(this.props.CustomDataDefEdited, { LevelCounts: option.data, Level2: level2, Level3: level3 } as CustomDataDefItem))
		}

		// if (option) {
		// 	this.props.onChangeDef(Object.assign(this.props.CustomDataDefEdited, { LevelCounts: option.data } as CustomDataDefItem))
		// }
	}

	render() {

		console.log(this.props.CustomDataDefEdited)
		return (
			<Stack className='Width100'>
				<span className='Indent20'>
					<h3>Definitions options</h3>
				</span>

				<Stack styles={{ root: { margin: '20px' } }} tokens={{ childrenGap: '10px' }} horizontalAlign='start'>
					<Stack.Item >
						<Dropdown
							// inlineLabel
							label={`Number of Levels`}
							styles={{ root: { justifyContent: 'center', marginBottom: '0px' } }}
							selectedKey={this.props.CustomDataDefEdited.LevelCounts.toString()}
							options={[{ key: '1', text: '1', data: 1 }, { key: '2', text: '2', data: 2 }, { key: '3', text: '3', data: 3 }]}
							disabled={!this.props.editMode}
							onChange={this.onChangeLevelCounts}
						// onChange={(_ev, option) =>{ this.props.onChangeDef(Object.assign(this.props.CustomDataDefEdited, { LevelCounts: option?.data } as CustomDataDefItem))}}
						/>
					</Stack.Item>
					<Stack.Item>
						<Stack horizontal verticalAlign='center' tokens={{ childrenGap: '3px' }}>
							<Toggle
								inlineLabel
								label={`Show list as treeview`}
								styles={{ root: { justifyContent: 'center', marginBottom: '0px' } }}
								checked={this.props.CustomDataDefEdited.TreeViewSelector}
								disabled={!this.props.editMode || this.props.CustomDataDefEdited.LevelCounts === 1}
								onChange={(_ev, checked) => this.props.onChangeDef(Object.assign(this.props.CustomDataDefEdited, { TreeViewSelector: checked } as CustomDataDefItem))}
							/>
							{getTooltipFromString('Only applicable when more than one level is defined. If enabled the selection list is shown as a treeview. If disabled the selection list is shown as a dropdown.')}
						</Stack>
					</Stack.Item>
					<Stack.Item >
						<Stack horizontal verticalAlign='center' tokens={{ childrenGap: '3px' }}>
							<Toggle
								inlineLabel
								label={`Enable Multiselect`}
								styles={{ root: { justifyContent: 'center', marginBottom: '0px' } }}
								checked={this.props.CustomDataDefEdited.MultiSelect}
								disabled={!this.props.editMode}
								onChange={(_ev, checked) => this.props.onChangeDef(Object.assign(this.props.CustomDataDefEdited, { MultiSelect: checked } as CustomDataDefItem))}
							/>
							{getTooltipFromString('The selection list, in 365|TemplateExtender addin is always visible for templates with custom data. If disabled the selection list is shown only when no selection is made.')}
						</Stack>
					</Stack.Item>
					{GetUseGroups(this.props.settings) && <Stack.Item>
						<Toggle
							inlineLabel
							label={`Enable Groups`}
							styles={{ root: { justifyContent: 'center', marginBottom: '0px' } }}
							checked={this.props.CustomDataDefEdited.UseGroups}
							// disabled={!GetUseGroups(this.props.settings)}
							disabled={!this.props.editMode}
							onChange={(_ev, checked) => this.props.onChangeDef(Object.assign(this.props.CustomDataDefEdited, { UseGroups: checked, UniqueGroups: false } as CustomDataDefItem))}
						/>
					</Stack.Item>}
					{GetUseGroups(this.props.settings) && <Stack.Item >
						<Toggle
							inlineLabel
							label={`Enable Unique Groups`}
							styles={{ root: { justifyContent: 'center', marginBottom: '0px' } }}
							checked={this.props.CustomDataDefEdited.UniqueGroups}
							disabled={!this.props.editMode || !this.props.CustomDataDefEdited.UseGroups}
							onChange={(_ev, checked) => this.props.onChangeDef(Object.assign(this.props.CustomDataDefEdited, { UniqueGroups: checked } as CustomDataDefItem))}
						/>
					</Stack.Item>}
				</Stack>
			</Stack>
		)
	}
}