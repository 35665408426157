import * as React from 'react';
import { ActionButton, IconButton, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
// import { exampleFolderItem, exampleTemplateList, GroupDetails, GroupInfo, TemplateApiHeaders, TemplateFolderItem, TemplateListItem } from '../../helpers/MiscItems';
import { GetWordContent, GetWordContentFolders } from '../../helpers/GetFromRestApi';
import Axios, { CancelTokenSource } from 'axios';
import WordContentFolders from './WordContentFolders';
import SettingsCompany from '../../helpers/SettingsCompany';
import { GroupDetails } from '../../interfaces/GroupDetails';
import { GroupInfo } from '../../interfaces/GroupInfo';
import { VersionSelector } from '../common/VersionSelector';
import { ContentFrame } from '../common/ContentFrame';
import WordContentDetails from './WordContentDetails';
import { ContentFolder } from '../../interfaces/ContentFolder';
import { ContentItem } from '../../interfaces/ContentItem';
import { deleteWordContent, deleteWordContentFolder, publishAllWordContent, publishWordContent, uploadWordContent, uploadWordContentFolder } from '../../helpers/PostToRestApi';
import { UploadItem } from '../../interfaces/UploadItem';
import { DialogOkAbort } from '../dialogs/DialogOkAbort';
import { DialogEditFolder } from '../dialogs/DialogEditFolder';

export interface WordContentProps {
	accessToken: string
	// apiUri: string,
	domain:string
	api:string
	groupIds: GroupInfo[]
	companyGroups: GroupDetails[]
	settings: SettingsCompany;
}

export interface WordContentState {
	folderItems: ContentFolder[]
	contentItems: ContentItem[]
	selectedPathId: string
	templateVersion: string
	newEditFolderItem: UploadItem
	deleteFolderItem: UploadItem
	publishAllItem: UploadItem
	editContentItem: UploadItem
	deleteContentItem: UploadItem
	publishContentItem: UploadItem
}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

//TODO remove hashedUSER
const hashedUser: string = ''

export default class WordContent extends React.Component<WordContentProps, WordContentState> {

	constructor(props: WordContentProps) {
		super(props);
		this.state = {
			folderItems: [],
			contentItems: [],
			selectedPathId: '',
			templateVersion: 'TEST',
			newEditFolderItem: { inProgress: false, id: '', parentId: '', name: '' } as UploadItem,
			deleteFolderItem: { inProgress: false, id: '', name: '', } as UploadItem,
			publishAllItem: { inProgress: false, id: '', name: '', } as UploadItem,
			editContentItem: { inProgress: false, id: '', name: '', data: '', parentId: '' } as UploadItem,
			deleteContentItem: { inProgress: false, id: '', name: '', } as UploadItem,
			publishContentItem: { inProgress: false, id: '', name: '', } as UploadItem,
		};
		this.setPathId = this.setPathId.bind(this)
	}




	getIsAdmin = async () => {

	}

	getFolders = async (version: string) => {
		ourRequest = Axios.CancelToken.source()
		this.setState({
			folderItems: [],
			contentItems: [],
			selectedPathId: '',
			templateVersion: version,
		})
		let allFolders: ContentFolder[]
		let folderContent: ContentItem[]
		[allFolders, folderContent] = await Promise.all([
			GetWordContentFolders(this.props.accessToken!, this.props.domain,this.props.api, version, hashedUser, ourRequest.token),
			GetWordContent(this.props.accessToken!, this.props.domain,this.props.api, version, '', ourRequest.token)
		])
		if (!allFolders) { allFolders = [] }
		console.log(folderContent)
		this.setState({
			folderItems: [{ FullPath: '\\', PathID: '' } as ContentFolder].concat(allFolders),
			contentItems: folderContent,
			selectedPathId: '',
		})
	}

	getContent = async (pathId: string) => {
		ourRequest = Axios.CancelToken.source()
		this.setState({
			contentItems: [],
			selectedPathId: '',
		})
		let folderContent: ContentItem[] = await GetWordContent(this.props.accessToken!, this.props.domain,this.props.api, this.state.templateVersion, '', ourRequest.token)
		this.setState({
			contentItems: folderContent,
			selectedPathId: pathId,
		})
	}

	setPathId = async (pathId: string) => {
		this.setState({
			selectedPathId: pathId,
		})
	}

	uploadFolder = async (item: UploadItem) => {
		ourRequest = Axios.CancelToken.source()
		await uploadWordContentFolder(this.props.accessToken, this.props.domain,this.props.api, { Path: item.name, PathID: item.id, RootPathID: item.parentId!, FullPath: '' }, this.state.templateVersion, hashedUser, ourRequest.token)
		this.setState({ newEditFolderItem: { inProgress: false, id: '', name: '', parentId: '' } })
		setTimeout(() => {
			this.getFolders(this.state.templateVersion)
		}, 500)
	}

	deleteFolder = async (item: UploadItem) => {
		ourRequest = Axios.CancelToken.source()
		await deleteWordContentFolder(this.props.accessToken, this.props.domain,this.props.api, item.id, this.state.templateVersion, hashedUser)
		this.setState({ deleteFolderItem: { inProgress: false, id: '', name: '' } })
		setTimeout(() => {
			this.getFolders(this.state.templateVersion)
		}, 500)
	}

	publishAll = async () => {
		ourRequest = Axios.CancelToken.source()
		await publishAllWordContent(this.props.accessToken, this.props.domain,this.props.api, this.state.templateVersion, hashedUser)
		this.setState({ publishAllItem: { inProgress: false, id: '', name: '' } })
		setTimeout(() => {
			this.getFolders(this.state.templateVersion)
		}, 500)
	}

	updateContent = async (item: UploadItem) => {
		ourRequest = Axios.CancelToken.source()
		await uploadWordContent(this.props.accessToken!, this.props.domain,this.props.api, item.id, item.name, item.data!, item.parentId!, this.state.templateVersion, hashedUser, ourRequest.token)
		this.setState({ editContentItem: { inProgress: false, id: '', name: '', parentId: '', data: '' } })
		setTimeout(() => {
			this.getContent(this.state.selectedPathId)
		}, 500)
	}

	deleteContent = async (item: UploadItem) => {
		ourRequest = Axios.CancelToken.source()
		await deleteWordContent(this.props.accessToken!, this.props.domain,this.props.api, item.id, this.state.templateVersion, hashedUser, ourRequest.token)
		this.setState({ deleteContentItem: { inProgress: false, id: '', name: '' } })
		setTimeout(() => {
			this.getContent(this.state.selectedPathId)
		}, 500)
	}

	publishContent = async (item: UploadItem) => {
		ourRequest = Axios.CancelToken.source()
		await publishWordContent(this.props.accessToken!, this.props.domain,this.props.api, item.id, this.state.templateVersion, hashedUser, ourRequest.token)
		this.setState({ publishContentItem: { inProgress: false, id: '', name: '' } })
		setTimeout(() => {
			this.getContent(this.state.selectedPathId)
		}, 500)
	}

	updateFolderItem = (key: string, selectedItem: ContentFolder) => {
		switch (key) {
			case 'newFolder':
				this.setState({ newEditFolderItem: { ...this.state.newEditFolderItem, id: '', parentId: selectedItem.PathID, name: '', inProgress: true } })
				break;
			case 'edit':
				this.setState({ newEditFolderItem: { ...this.state.newEditFolderItem, id: selectedItem.PathID, parentId: selectedItem.RootPathID, name: selectedItem.Path, inProgress: true } })
				break;
			case 'delete':
				this.setState({ deleteFolderItem: { ...this.state.deleteFolderItem, id: selectedItem.PathID, name: selectedItem.Path, inProgress: true } })
				break;
			case 'publishAll':
				this.setState({ publishAllItem: { ...this.state.publishAllItem, inProgress: true } })
				break;
			default:
				break;
		}
	}

	updateContentItem = (key: string, selectedItem: ContentItem) => {
		switch (key) {
			case 'edit':
				this.setState({ editContentItem: { ...this.state.newEditFolderItem, id: selectedItem.ContentID, parentId: selectedItem.PathID, name: selectedItem.ContentName, data: selectedItem.OXML, inProgress: true } })
				break;
			case 'delete':
				this.setState({ deleteContentItem: { ...this.state.deleteFolderItem, id: selectedItem.ContentID, name: selectedItem.ContentName, inProgress: true } })
				break;
			case 'publish':
				this.setState({ publishContentItem: { ...this.state.publishAllItem, id: selectedItem.ContentID, name: selectedItem.ContentName, inProgress: true } })
				break;
			default:
				break;
		}
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	componentDidUpdate(prevProps: Readonly<WordContentProps>, _prevState: Readonly<WordContentState>, _snapshot?: any): void {
		if (prevProps.domain !== this.props.domain ||
			prevProps.api !== this.props.api || 
			prevProps.accessToken !== this.props.accessToken) {
			this.getFolders(this.state.templateVersion)
		}
	}

	componentDidMount(): void {
		this.getFolders(this.state.templateVersion)
	}

	render() {
		return (
			<Stack >
				<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
					<Text variant="xxLarge">
						Contents
					</Text>
				</Stack.Item>
				<VersionSelector
					onVersionChange={this.getFolders}
					version={this.state.templateVersion}
					testDescription={<>
						You're now in test mode. Contents that you upload in Word will appear here. You can delete, rename and move contents and folders, but they will not be available to all users before you publish them.<br />
						To publish all items use the<ActionButton iconProps={{ iconName: 'PublishContent' }} >Publish All</ActionButton>button in the <b>Folders in Test</b> section. To publish a single content use the<IconButton iconProps={{ iconName: 'PublishContent' }} />button in the content card.<br />
						Users that have Content, Contributer or Admin privileges can upload content from the Word addin when they switch from <b>Published</b> to <b>Test</b> in the <b>Contents</b> tab. Administrators can manage user accounts in this portal.
					</>}
					pubDescription={<>
						You're now in published mode. All contents are available for all users.
					</>}
				/>
				{!this.state.folderItems.length && <Spinner className='spinner' size={SpinnerSize.large} label='Loading contents...' />}
				{['TEST', 'PUB'].map((version) => {
					if (this.state.folderItems.length) {
						return (
							<ContentFrame key={version} hidden={version !== this.state.templateVersion}>
								<WordContentFolders
									getFolders={this.getFolders}
									setPathId={this.setPathId}
									onCommandItemClick={this.updateFolderItem}
									folderItems={this.state.folderItems.map((folder) => { return { ...folder, ContentCount: this.state.contentItems ? this.state.contentItems.filter((cnt) => cnt.PathID === folder.PathID).length : 0 } })}
									selectedPathId={this.state.selectedPathId}
									groupIds={this.props.groupIds}
									companyGroups={this.props.companyGroups}
									settings={this.props.settings}
									templateVersion={version}
								/>
							</ContentFrame>
						)
					}
					else { return null }
				})}
				{this.state.folderItems && this.state.contentItems && this.state.folderItems!.map((folder: ContentFolder) => {
					if (this.state.folderItems.length) {
						return (
							<ContentFrame key={folder.PathID} hidden={folder.PathID !== this.state.selectedPathId}>
								<WordContentDetails
									onButtonClick={this.updateContentItem}
									accessToken={this.props.accessToken}
									domain={this.props.domain}
									api={this.props.api}
									contentItems={this.state.contentItems.filter((val) => val.PathID === folder.PathID)}
									path={folder.FullPath}
									templateVersion={this.state.templateVersion}
								/>
						</ContentFrame>
						)
					}
					else { return null }
				})}
				<DialogEditFolder
					callbackOk={(name, pathId, rootPathId) => this.uploadFolder({ ...this.state.newEditFolderItem, name: name, id: pathId, parentId: rootPathId })}
					callbackAbort={() => this.setState({ newEditFolderItem: { inProgress: false, id: '', name: '', parentId: '' } })}
					title={this.state.newEditFolderItem.id === '' ? 'New Folder' : 'Edit Folder'}
					hidden={!this.state.newEditFolderItem.inProgress}
					pathOptions={this.state.folderItems.map((item) => { if (item) { return { key: item.PathID, text: item.FullPath } } else { return { key: '', text: '' } } })}
					defaultName={this.state.newEditFolderItem.name}
					defaultId={this.state.newEditFolderItem.id}
					defaultParentId={this.state.newEditFolderItem.parentId!}
					isFolder={true}
				/>
				<DialogOkAbort
					callbackOk={() => { this.deleteFolder(this.state.deleteFolderItem) }}
					callbackAbort={() => { this.setState({ deleteFolderItem: { inProgress: false, name: '', id: '' } }) }}
					title='Delete Content Folder?'
					description={`Do you want to delete the folder ${this.state.deleteFolderItem.name}?`}
					hidden={!this.state.deleteFolderItem.inProgress}
				/>
				<DialogOkAbort
					callbackOk={() => { this.publishAll() }}
					callbackAbort={() => { this.setState({ publishAllItem: { inProgress: false, name: '', id: '' } }) }}
					title='Publish All Contents?'
					description={`Do you want to publish all the contents?`}
					hidden={!this.state.publishAllItem.inProgress}
				/>
				<DialogEditFolder
					callbackOk={(name, contentId, pathId) => this.updateContent({ ...this.state.editContentItem, name: name, id: contentId, parentId: pathId })}
					callbackAbort={() => this.setState({ editContentItem: { inProgress: false, id: '', name: '', parentId: '' } })}
					title={'Edit Content'}
					hidden={!this.state.editContentItem.inProgress}
					pathOptions={this.state.folderItems.map((item) => { if (item) { return { key: item.PathID, text: item.FullPath } } else { return { key: '', text: '' } } })}
					defaultName={this.state.editContentItem.name}
					defaultId={this.state.editContentItem.id}
					defaultParentId={this.state.editContentItem.parentId!}
					isFolder={false}
				/>
				<DialogOkAbort
					callbackOk={() => { this.deleteContent(this.state.deleteContentItem) }}
					callbackAbort={() => { this.setState({ deleteContentItem: { inProgress: false, name: '', id: '' } }) }}
					title='Delete Content?'
					description={`Do you want to delete ${this.state.deleteContentItem.name} from ${this.state.templateVersion === 'TEST' ? 'Test' : 'Published'}?`}
					hidden={!this.state.deleteContentItem.inProgress}
				/>
				<DialogOkAbort
					callbackOk={() => { this.publishContent(this.state.publishContentItem) }}
					callbackAbort={() => { this.setState({ publishContentItem: { inProgress: false, name: '', id: '' } }) }}
					title='Publish Content?'
					description={`Do you want to publish ${this.state.publishContentItem.name}?`}
					hidden={!this.state.publishContentItem.inProgress}
				/>
			</Stack>
		)
	}
}
