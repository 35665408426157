import * as React from "react"
import { Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react"

export const DialogOkOnly: React.FunctionComponent<{setParentState: (x: any) => void, hideState: string, hidden: boolean, title: string, text: string}> = (props) => {
  
	if (props.hidden) {
		return (<></>)
	}

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: props.title,
		subText: props.text,
	};
	
	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 450 } },
	};

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.setParentState({[props.hideState]: true})}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<DialogFooter>
				<PrimaryButton onClick={() => props.setParentState({[props.hideState]: true})} text="Ok" />
			</DialogFooter>
		</Dialog>
	);
};