
export const hasValues = (obj: any): boolean => {
	try {
		return Object.values(obj).some(v => v !== null && typeof v !== "undefined");
	} catch (error) {
		return false
	}
}

export function validateEmail(email: string): boolean {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function sortArray<T>(items: T[], sortKey: string, isSortedDescending?: boolean): T[] {
	const key = sortKey as keyof T;
	return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

export const getTenantId = (token: string): string => {
	try {
		const claims = JSON.parse(atob(token.split('.')[1]));
		console.log(claims.tid)
		return claims.tid
	} catch (error) {
		return ''
	}
}

export const cloneObject = <T,>(obj: T): T => {
	return JSON.parse(JSON.stringify(obj));
}
