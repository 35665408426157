import { useContext, useState } from "react";
import { UserContext } from "../contexts/ContextWrapper";
import { UserAdminItem } from "../interfaces/UserAdminItem";
import { GetUser } from "../helpers/GetFromRestApi";
import { VerifiedDomain } from "../interfaces/VerifiedDomain";
import { upnFromToken } from "../helpers/LogginHelper";

export const useUserFetch = () => {
    const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false)

    const { userContext, setUserContext } = useContext(UserContext);

    const FetchUser = async (accessToken: string, verifiedDomains?: VerifiedDomain[]): Promise<UserAdminItem> => {
        setIsLoadingUser(true)
        console.log('Fetching user')
        console.log(JSON.stringify(verifiedDomains)!?.length)
        console.log(verifiedDomains!?.length)
        const myDomains = JSON.stringify(verifiedDomains)!?.length < 12000 ?
            JSON.stringify(verifiedDomains) :
            JSON.stringify(verifiedDomains!?.filter((item) => { return item.isDefault === true || item.name === upnFromToken(accessToken).split('@')[1] }))
        const user: UserAdminItem = await GetUser(accessToken, myDomains)
        console.log(user)
        setUserContext({ userData: user })
        console.log(user)
        setIsLoadingUser(false)
        return user
    }

    const HasUser = (): boolean => {
        return userContext.userData !== undefined
    }

    return { FetchUser, HasUser, isLoadingUser }
}