import * as React from 'react';
import { GroupedData } from '../../interfaces/GroupedData';
import { Checkbox, CommandBar, DefaultButton, ICommandBarItemProps, IconButton, Separator, Spinner, SpinnerSize, Stack, TextField, Toggle } from '@fluentui/react';
import { GetUseCustomData, GetUseUserInformation } from '../../helpers/SettingsCompany';
import { aadFieldsMenuProps, customFieldsMenuProps } from '../../helpers/HtmlStyling';
import { TenantContext } from '../../contexts/ContextWrapper';
import { useUserData } from '../../hooks/useUserData';
import * as DOMPurify from 'dompurify';
import { useReplace } from '../../hooks/useReplace';
import { useCustomData } from '../../hooks/useCustomData';
import HtmlButtons from '../common/HtmlButtons';

export interface CombinedDataEditProps {
    updateItem(item: GroupedData): void
    getItems(): void
    selectedItem: GroupedData
    isLoading: boolean
}

export const CombinedDataEdit: React.FunctionComponent<CombinedDataEditProps> = (props) => {

    const [editedItem, setEditedItem] = React.useState<GroupedData>(props.selectedItem)
    const [targetItemIndex, setTargetItemIndex] = React.useState<number>(0)
    const [cursorPositionStart, setCursorPositionStart] = React.useState<number>(0)
    const [cursorPositionEnd, setCursorPositionEnd] = React.useState<number>(0)
    // const [customDataItem, setCustomDataItem] = React.useState<CustomDataItem[]>([])


    const { tenantContext } = React.useContext(TenantContext)

    const { userDataItems } = useUserData()
    const { customDataItem } = useCustomData()
    const { ReplaceInText, isLoadingUserData } = useReplace()

    React.useEffect(() => {
        setEditedItem(JSON.parse(JSON.stringify(props.selectedItem)))
    }, [props.selectedItem])

    const commandItems = (): ICommandBarItemProps[] => [
        {
            key: 'save',
            text: 'Save',
            iconProps: { iconName: 'Save' },
            onClick: () => { props.updateItem(editedItem) },
        },
        {
            key: 'abort',
            text: 'Abort',
            iconProps: { iconName: 'Cancel' },
            disabled: editedItem.ID !== '' && JSON.stringify(editedItem) === JSON.stringify(props.selectedItem),
            onClick: () => {
                props.getItems()
            },
        }
    ]

    const InsertTextAtSelection = (startText: string, endText?: string) => {
        let newItems = [...editedItem.DataItems]
        newItems[targetItemIndex].DataItem = `${newItems[targetItemIndex].DataItem.substring(0, cursorPositionStart)}${startText}${newItems[targetItemIndex].DataItem.substring(cursorPositionStart, cursorPositionEnd)}${endText ? endText : ''}${newItems[targetItemIndex].DataItem.substring(cursorPositionEnd, newItems[targetItemIndex].DataItem.length)}`
        setEditedItem({ ...editedItem, DataItems: newItems })
    }

    const setCursor = (event: any, index: number) => {
        setTargetItemIndex(index)
        setCursorPositionStart(event.currentTarget.selectionStart!)
        setCursorPositionEnd(event.currentTarget.selectionEnd!)
    }

    const GetDataItems = (withValues: boolean): string[] => {
        return editedItem.DataItems && editedItem.DataItems!?.map((item) => {
            if (withValues) { return ReplaceInText(item.DataItem, true) }
            return item.DataItem
        }).filter((v) => v.length > 0)
    }

    const GetPreview = (withValues: boolean): JSX.Element => {
        return <Stack.Item styles={{ root: { paddingRight: '50px' } }}>
            {editedItem.HTML ?
                <div dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        GetDataItems(withValues).join(editedItem.Delimiter),
                        { ADD_ATTR: ['target'] }
                    )
                }} />
                :
                editedItem.Delimiter === '\v' ?
                    GetDataItems(withValues).map((item) => {
                        return <div>{item}<br /></div>
                    })
                    :
                    GetDataItems(withValues).join(editedItem.Delimiter)
            }
        </Stack.Item>
    }

    if (props.isLoading) { <Spinner size={SpinnerSize.large} /> }

    return (
        <Stack className='Width100'>
            <span className='Indent20'><h3>{props.selectedItem.GroupedDataName}</h3></span>
            <CommandBar
                items={commandItems()}
                ariaLabel="Use left and right arrow keys to navigate between commands"
            />
            <Stack horizontal wrap styles={{ root: { padding: '20px' } }}>
                {GetPreview(false)}
                {GetPreview(true)}
            </Stack>
            <Separator key='sepPreview' />
            <Stack styles={{ root: { padding: '20px' } }}>
                <Stack horizontal className="SettingsStack">
                    <Stack.Item align="center" className="SettingsStackCaption">Name</Stack.Item>
                    <Stack.Item align="center" styles={{ root: { width: '400px' } }}>
                        <TextField
                            aria-label="Name"
                            value={editedItem.GroupedDataName}
                            onChange={(_ev, newValue) => {
                                setEditedItem({ ...editedItem, GroupedDataName: newValue! })
                                setTargetItemIndex(-1)
                            }}
                        />
                    </Stack.Item>
                </Stack>
                <Stack horizontal className="SettingsStack">
                    <Stack.Item align="center" className="SettingsStackCaption">Delimiter</Stack.Item>
                    <Stack horizontal verticalAlign="center" styles={{ root: { width: '400px' } }}>
                        <Stack.Item align="center" grow>
                            <TextField
                                aria-label="Delimiter"
                                value={editedItem.Delimiter === '\v' ? '\u21b5' : editedItem.Delimiter.replace('\v', '\u21b5')}
                                onChange={(_ev, newValue) => {
                                    setEditedItem({ ...editedItem, Delimiter: newValue!.replace('\u21b5', '\v') })
                                    setTargetItemIndex(-1)
                                }}
                                disabled={editedItem.Delimiter === '\v'}
                            />
                        </Stack.Item>
                        {!editedItem.HTML && <Stack.Item align='center' styles={{ root: { paddingLeft: '10px' } }}>
                            <Checkbox
                                label='Line feed'
                                checked={editedItem.Delimiter === '\v'}
                                onChange={(_ev, checked) => {
                                    setEditedItem({ ...editedItem, Delimiter: checked! ? '\v' : '' })
                                    setTargetItemIndex(-1)
                                }}
                            />
                        </Stack.Item>}
                    </Stack>
                </Stack>
                <Stack horizontal className="SettingsStack">
                    <Stack.Item align="center" className="SettingsStackCaption">HTML</Stack.Item>
                    <Stack.Item align="center" styles={{ root: { width: '400px' } }}>
                        <Toggle
                            aria-label="HTML"
                            inlineLabel={true}
                            checked={editedItem.HTML}
                            onChange={(_ev, checked) => {
                                setEditedItem({ ...editedItem, HTML: checked! })
                                setTargetItemIndex(-1)
                            }}
                        />
                    </Stack.Item>
                </Stack>
                <Stack horizontal className="SettingsStack">
                    <h3>Data items</h3>
                </Stack>
                {editedItem.DataItems.map((item, index) => {
                    return (

                        <Stack horizontal className="SettingsStack">
                            <Stack.Item align="center" className="SettingsStackCaption">
                                <IconButton
                                    iconProps={{ iconName: 'Add' }}
                                    ariaLabel="Add"
                                    onClick={() => {
                                        let newItems = [...editedItem.DataItems]
                                        newItems.splice(index, 0, { DataItem: '' })
                                        setTargetItemIndex(index)
                                        setEditedItem({ ...editedItem, DataItems: newItems })
                                    }}
                                />
                                <IconButton
                                    iconProps={{ iconName: 'Delete' }}
                                    ariaLabel="Add"
                                    // onClick={() => { setEditedItem({ ...editedItem, DataItems: [...editedItem.DataItems.splice(index, 1)] }) }}
                                    onClick={() => { setEditedItem({ ...editedItem, DataItems: editedItem.DataItems.filter((_v, i) => i !== index) }) }}
                                />
                            </Stack.Item>
                            <Stack.Item align="center" styles={{ root: { width: '400px' } }}>
                                <TextField
                                    aria-label="1"
                                    value={item.DataItem}
                                    componentRef={(input) => { if (input && index === targetItemIndex) { input.focus() } }}
                                    // onChange={(_ev, newValue) => { setEditedItem({ ...editedItem, Delimiter: newValue! }) }}
                                    onChange={(_event, value) => {
                                        let newItems = [...editedItem.DataItems]
                                        newItems[index].DataItem = value!
                                        setEditedItem({ ...editedItem, DataItems: newItems })
                                    }}
                                    onClick={(event) => {
                                        setCursor(event, index)
                                    }}
                                    onKeyUp={(event) => {
                                        setCursor(event, index)
                                    }}
                                    onSelectCapture={(event) => {
                                        setCursor(event, index)
                                    }}
                                />
                            </Stack.Item>
                        </Stack>
                    )
                })}
                {(editedItem.DataItems.length === 0 || editedItem.DataItems[editedItem.DataItems.length - 1].DataItem !== '') &&
                    <Stack horizontal className="SettingsStack">
                        <Stack.Item align="center" className="SettingsStackCaption">
                            <IconButton
                                iconProps={{ iconName: 'Add' }}
                                ariaLabel="Add"
                                onClick={() => {
                                    setEditedItem({ ...editedItem, DataItems: [...editedItem.DataItems, { DataItem: '' }] })
                                    setTargetItemIndex(editedItem.DataItems.length)
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item align="center">

                        </Stack.Item>
                    </Stack>
                }
                <Stack horizontal tokens={{ childrenGap: '5px' }}>
                    {editedItem.HTML &&
                        <HtmlButtons
                            InsertTextAtSelection={InsertTextAtSelection}
                            disabled={editedItem.DataItems.length === 0}
                        />
                    }
                    {!isLoadingUserData && GetUseUserInformation(tenantContext.settings) &&
                        <DefaultButton
                            text='User Data'
                            disabled={editedItem.DataItems.length === 0}
                            menuProps={aadFieldsMenuProps(userDataItems, InsertTextAtSelection)}
                        />
                    }
                    {GetUseCustomData(tenantContext.settings) && customDataItem &&
                        <DefaultButton
                            text='Custom Data'
                            disabled={editedItem.DataItems.length === 0}
                            menuProps={customFieldsMenuProps(customDataItem, InsertTextAtSelection)}
                        />
                    }

                </Stack>
            </Stack>
        </Stack>
    );
}

export default CombinedDataEdit;