import * as React from "react"
import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IDropdownOption, PrimaryButton, TextField } from "@fluentui/react"
import { validateEmail } from "../../helpers/MiscFunctions";
import { Label, Toggle } from "office-ui-fabric-react";
import { APIItem } from "../../interfaces/APIItem";
import { UserAdminItem } from "../../interfaces/UserAdminItem";
import { DomainItem } from "../../interfaces/DomainItem";
import SettingsCompany from "../../helpers/SettingsCompany";

export const DialogEditUser: React.FunctionComponent<{
	setParentState: (x: any) => void,
	settings: SettingsCompany,
	hideState: string,
	confirmState: string,
	newUserItem: string,
	hidden: boolean,
	domains: DomainItem[],
	apis: APIItem[],
	defaultApi: string,
	defaultDomain: string,
	selectedItem: UserAdminItem,
	canChangeTenant: boolean,
	isNew: boolean
}> = (props) => {

	const [hasItems, setHasItems] = React.useState(false);

	const [username, setUsername] = React.useState<string>('');
	const [invalidEmail, setInvalidEmail] = React.useState<boolean>(false);
	const [emptyApis, setEmptyApis] = React.useState<boolean>(false);
	const [emptyDomains, setEmptyDomains] = React.useState<boolean>(false);
	const [selectedDomains, setSelectedDomains] = React.useState<string[]>([props.defaultDomain]);
	const [selectedApis, setSelectedApis] = React.useState<string[]>([props.defaultApi]);

	const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
	const [isSettingsAdmin, setIsSettingsAdmin] = React.useState<boolean>(false);
	const [isContributorAdmin, setIsContributorAdmin] = React.useState<boolean>(false);
	const [isTemplateAdmin, setIsTemplateAdmin] = React.useState<boolean>(false);
	const [isPictureAdmin, setIsPictureAdmin] = React.useState<boolean>(false);
	const [isIconAdmin, setIsIconAdmin] = React.useState<boolean>(false);
	const [isSlideAdmin, setIsSlideAdmin] = React.useState<boolean>(false);
	const [isContentAdmin, setIsContentAdmin] = React.useState<boolean>(false);
	const [isSectionsAdmin, setIsSectionsAdmin] = React.useState<boolean>(false);

	// const [isTemplateDefAdmin, setIsTemplateDefAdmin] = React.useState<boolean>(true);
	// const [isPictureDefAdmin, setIsPictureDefAdmin] = React.useState<boolean>(true);

	const [changeTenantEnabled, setChangeTenantEnabled] = React.useState<boolean>(false);
	const [changeTenantDomain, setChangeTenantDomain] = React.useState<string>('');


	const clearUseStates = () => {
		setHasItems(false)
		setUsername('');
		setInvalidEmail(false)
		setEmptyApis(false)
		setEmptyDomains(false)
		setIsAdmin(false)
		setIsSettingsAdmin(false)
		setIsContributorAdmin(false)
		setIsSectionsAdmin(false)
		setIsTemplateAdmin(false)
		setIsPictureAdmin(false)
		setIsIconAdmin(false)
		setIsSlideAdmin(false)
		setIsContentAdmin(false)
		setChangeTenantEnabled(false)
		setChangeTenantDomain('')
		setSelectedApis([props.defaultApi]);
		setSelectedDomains([props.defaultDomain]);
	}

	if (props.hidden) {
		if (hasItems) { clearUseStates() }
		return (<></>)
	}

	if (!hasItems && typeof props.selectedItem.User !== 'undefined') {
		setUsername(props.selectedItem.User);
		setIsAdmin(props.selectedItem.isAdmin)
		setIsSettingsAdmin(props.selectedItem.isSettingsAdmin)
		setIsContributorAdmin(props.selectedItem.isContributorAdmin && !props.selectedItem.isSettingsAdmin)
		setIsTemplateAdmin(props.selectedItem.isTemplateAdmin && !props.selectedItem.isContributorAdmin && !props.selectedItem.isSettingsAdmin)
		setIsPictureAdmin(props.selectedItem.isPictureAdmin && !props.selectedItem.isContributorAdmin && !props.selectedItem.isSettingsAdmin)
		setIsIconAdmin(props.selectedItem.isIconAdmin && !props.selectedItem.isContributorAdmin && !props.selectedItem.isSettingsAdmin)
		setIsSlideAdmin(props.selectedItem.isSlideAdmin && !props.selectedItem.isContributorAdmin && !props.selectedItem.isSettingsAdmin)
		setIsContentAdmin(props.selectedItem.isContentAdmin && !props.selectedItem.isContributorAdmin && !props.selectedItem.isSettingsAdmin)
		setIsSectionsAdmin(props.selectedItem.isSectionsAdmin && !props.selectedItem.isContributorAdmin && !props.selectedItem.isSettingsAdmin)
		setSelectedApis(props.selectedItem.API.map((item) => { return item.APIName }));
		setSelectedDomains(props.selectedItem.Domains.map((item) => { return item.DomainName }));
		if (props.canChangeTenant) {
			setChangeTenantEnabled(props.selectedItem.changeTenantEnabled || false)
			setChangeTenantDomain(props.selectedItem.changeTenantDomain || '')
		}

		setHasItems(true)
	}

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: props.isNew ? 'Create new account' : 'Edit selected account',
		subText: props.isNew ? 'Username has to be a valid Microsoft account' : '',
	};

	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 600 } },
	};

	const uniqueDomains = [...new Set(props.domains.map(data => data.DomainName))]

	const tenantOptions: IDropdownOption[] = uniqueDomains.map((item) => {
		return ({
			key: item, text: item
		})
	})

	const apiOptions: IDropdownOption[] = props.apis.map((item) => {
		return ({
			key: item.APIName, text: item.APIName
		})
	})

	const changeTenantOptions: IDropdownOption[] =
		selectedDomains.filter((value) => value !== props.defaultDomain).map((item) => {
			return ({
				key: item, text: item
			})
		})



	const onChangeDomains = (item: IDropdownOption): void => {
		if (item) {
			setSelectedDomains(
				item.selected ?
					[...selectedDomains, item.key as string] :
					selectedDomains.filter(key => (key !== item.key))
			)
		}
	};

	const onChangeApi = (item: IDropdownOption): void => {
		if (item) {
			setSelectedApis(
				item.selected ?
					[...selectedApis, item.key as string] :
					selectedApis!.filter(key => (key !== item.key))
			)
		}
	};

	const onChangePrivilege = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined): void => {
		let el: Partial<HTMLElement> = event.target
		let id: string = el.getAttribute!('id') || ''

		console.log(id)
		console.log(checked)
		switch (id) {
			case 'admin':
				setIsSettingsAdmin(checked!)
				if (checked) {
					setIsContributorAdmin(false)
					setIsTemplateAdmin(false)
					setIsPictureAdmin(false)
					setIsIconAdmin(false)
					setIsSlideAdmin(false)
					setIsContentAdmin(false)
					setIsSectionsAdmin(false)
				}
				break;
			case 'contributor':
				setIsContributorAdmin(checked!)
				if (checked) {
					setIsTemplateAdmin(false)
					setIsPictureAdmin(false)
					setIsIconAdmin(false)
					setIsSlideAdmin(false)
					setIsContentAdmin(false)
					setIsSectionsAdmin(false)
				}
				break;
			case 'template':
				setIsTemplateAdmin(checked!)
				break;
			case 'picture':
				setIsPictureAdmin(checked!)
				break;
			case 'icon':
				setIsIconAdmin(checked!)
				break;
			case 'slide':
				setIsSlideAdmin(checked!)
				break;
			case 'content': 
				setIsContentAdmin(checked!)
				break;
			case 'sections':
				setIsSectionsAdmin(checked!)
				break;
			default:
				break;
		}
	}

	let ApiPart: JSX.Element = (<></>)
	let TenantsPart: JSX.Element = (<></>)

	if (apiOptions.length > 1) {
		ApiPart = (
			<Dropdown
				placeholder="Select api"
				label="Select api"
				multiSelect
				options={apiOptions}
				defaultSelectedKeys={selectedApis}
				disabled={isAdmin}
				onChange={(_event, option) => onChangeApi(option!)}
				errorMessage={emptyApis ? 'Must select at least one API' : ''}
			/>
		)
	}

	if (tenantOptions.length > 1) {
		TenantsPart = (
			<Dropdown
				placeholder="Select domains"
				label="Select domains"
				multiSelect
				options={tenantOptions}
				defaultSelectedKeys={selectedDomains}
				disabled={isAdmin}
				onChange={(_event, option) => onChangeDomains(option!)}
				errorMessage={emptyDomains ? 'Must select at least one domain' : ''}
			/>
		)
	}

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.setParentState({ [props.hideState]: true })}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}

		>
			<TextField
				label='Username'
				value={username}
				disabled={!props.isNew || isAdmin}
				onChange={(_event, value) => setUsername(value!)}
				errorMessage={invalidEmail ? 'Not a valid username' : ''}
			/>
			{ApiPart}
			{TenantsPart}
			<Label>Privilege</Label>
			<Toggle
				label="Admin"
				id="admin"
				onText="On"
				offText="Off"
				inlineLabel
				disabled={isAdmin}
				checked={isSettingsAdmin}
				onChange={onChangePrivilege}
			/>
			<Toggle
				label="Contributor"
				id="contributor"
				onText="On"
				offText="Off"
				inlineLabel
				disabled={isSettingsAdmin || isAdmin}
				checked={isContributorAdmin}
				onChange={onChangePrivilege}
			/>
			{props.settings.Extensions?.find((val) => val.ExtensionName === 'Templates') && <Toggle
				label="Manage templates"
				id="template"
				onText="On"
				offText="Off"
				inlineLabel
				disabled={isContributorAdmin || isSettingsAdmin || isAdmin}
				checked={isTemplateAdmin}
				onChange={onChangePrivilege}
			/>}
			{props.settings.Extensions?.find((val) => val.ExtensionName === 'Pictures') && <Toggle
				label="Manage pictures"
				id="picture"
				onText="On"
				offText="Off"
				inlineLabel
				disabled={isContributorAdmin || isSettingsAdmin || isAdmin}
				checked={isPictureAdmin}
				onChange={onChangePrivilege}
			/>}
			{props.settings.Extensions?.find((val) => val.ExtensionName === 'Icons') && <Toggle
				label="Manage icons"
				id="icon"
				onText="On"
				offText="Off"
				inlineLabel
				disabled={isContributorAdmin || isSettingsAdmin || isAdmin}
				checked={isIconAdmin}
				onChange={onChangePrivilege}
			/>}
			{props.settings.Extensions?.find((val) => val.ExtensionName === 'Slides') && <Toggle
				label="Manage slides"
				id="slide"
				onText="On"
				offText="Off"
				inlineLabel
				disabled={isContributorAdmin || isSettingsAdmin || isAdmin}
				checked={isSlideAdmin}
				onChange={onChangePrivilege}
			/>}
			{props.settings.Extensions?.find((val) => val.ExtensionName === 'Contents') && <Toggle
				label="Manage contents"
				id="content"
				onText="On"
				offText="Off"
				inlineLabel
				disabled={isContributorAdmin || isSettingsAdmin || isAdmin}
				checked={isContentAdmin}
				onChange={onChangePrivilege}
			/>}
			{props.settings.Extensions?.find((val) => val.ExtensionName === 'Sections') && <Toggle
				label="Manage sections documents"
				id="sections"
				onText="On"
				offText="Off"
				inlineLabel
				disabled={isContributorAdmin || isSettingsAdmin || isAdmin}
				checked={isSectionsAdmin}
				onChange={onChangePrivilege}
			/>}
			{props.canChangeTenant && <Toggle
				label="Change Tenant"
				id="changeTenant"
				onText="On"
				offText="Off"
				inlineLabel
				checked={changeTenantEnabled}
				onChange={(_ev, checked) => { setChangeTenantEnabled(checked!); setChangeTenantDomain('') }}
			/>}
			{/* {props.isTestDomain && changeTenantEnabled && 
			<TextField
				value={changeTenantDomain}
				onChange={(_event, value) => setChangeTenantDomain(value!)}
				errorMessage={invalidEmail ? 'Not a valid username' : ''}
			/>} */}
			{props.canChangeTenant && changeTenantEnabled &&
				<Dropdown
					placeholder="Select domains"
					label="Select domains"
					options={changeTenantOptions}
					defaultSelectedKey={changeTenantDomain}
					onChange={(_event, option) => setChangeTenantDomain(option?.text!)}
					errorMessage={emptyDomains ? 'Must select at least one domain' : ''}
				/>}
			<DialogFooter>
				<PrimaryButton
					onClick={() => {

						setInvalidEmail(!validateEmail(username))
						setEmptyApis(selectedApis.length === 0)
						setEmptyDomains(selectedDomains.length === 0)

						if (validateEmail(username) && !(selectedApis.length === 0) && !(selectedDomains.length === 0)) {
							clearUseStates()
							const changeTenantSettings = props.canChangeTenant ? {
								changeTenantEnabled: changeTenantEnabled,
								changeTenantDomain: changeTenantDomain,
							} : null
							props.setParentState({
								[props.hideState]: true,
								[props.confirmState]: true,
								[props.newUserItem]: {
									User: username.toLowerCase(),
									Domains: selectedDomains.map(item => { return { DomainName: item } }),
									API: selectedApis.map(item => { return { APIName: item } }),
									isAdmin: false,
									isSettingsAdmin: isSettingsAdmin,
									isContributorAdmin: isContributorAdmin,
									isTemplateAdmin: isTemplateAdmin,
									isPictureAdmin: isPictureAdmin,
									isIconAdmin: isIconAdmin,
									isSlideAdmin: isSlideAdmin,
									isContentAdmin: isContentAdmin,
									isSectionsAdmin: isSectionsAdmin,
									...changeTenantSettings
								} as UserAdminItem
							})
						}
					}}
					// disabled={isAdmin}
					text={props.isNew ? "Create" : "Update"}
				/>
				<DefaultButton
					onClick={() => {
						clearUseStates()
						props.setParentState({
							[props.hideState]: true
						})
					}}
					text="Cancel"
				/>
			</DialogFooter>
		</Dialog>
	);
};
