import * as React from "react"
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Text } from "@fluentui/react"
import { UploadFile } from "../../interfaces/UploadFile";

export const DialogUploadJson: React.FunctionComponent<{
    setParentState: (x: any) => void, 
    hideState: string, 
    confirmState: string, 
    uploadItemState: string, 
    hidden: boolean,
}> = (props) => {

const [noInput, setNoInput] = React.useState<boolean>(false);
const [uploadFiles, setUploadFiles] = React.useState<UploadFile[]>([]);



if (props.hidden) {
    if (noInput) {setNoInput(false)}
    if (uploadFiles.length > 0) {setUploadFiles([])}
    return (<></>)
}

const acceptExtensions: string = ".json"

const dialogContentProps = {
    type: DialogType.largeHeader,
    title: `Upload icon library`,
    subText: `Browse the icon library you want to upload`,
};

const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 450 } },
};

// let uploadfiles: UploadFile[];

const onChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {

    await Promise.all(Array.from(event.target.files!).map(async (item) => {
        try {
            let res = (await blobToText(item))
            let data = ''
            if (typeof res === 'string') {data = JSON.stringify(JSON.parse(res))}
            console.log(data)
            return({filename: item.name, data: data})
        } catch (error) {
            console.log(error)
            return({filename: '', data: ''})
        }
    })).then((res: UploadFile[]) => {
        setUploadFiles(res)
        setNoInput(false)
    })		
    .catch(() => 
        setUploadFiles([])
    )
    //console.log(uploadfiles)
}

const blobToText = (file: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {	
        resolve(reader.result)
    }
    reader.onerror = error => reject(error);
});

return (
    <Dialog
        hidden={props.hidden}
        onDismiss={() => props.setParentState({[props.hideState]: true})}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
    >
        <input title="Browse..." type="file" multiple name="file" accept={acceptExtensions} onChange={(event) => {onChangeHandler(event)}} />
        {noInput ? <p><Text variant='small' style={{color: '#a4262c'}}>Nothing selected!</Text></p> : null}
        <DialogFooter>
            <PrimaryButton onClick={() => {
                if (uploadFiles.length > 0) {
                    props.setParentState({
                        [props.confirmState]: true, 
                        [props.hideState]: true, 
                        [props.uploadItemState]: uploadFiles 
                    })
                }
                else {setNoInput(true)}
            }} 
            text="Upload" />
            <DefaultButton onClick={() => props.setParentState({[props.hideState]: true})} text="Cancel" />
        </DialogFooter>
    </Dialog>
);
};
