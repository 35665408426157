import { Stack, Text } from '@fluentui/react';
import { ExtensionItem } from '../../interfaces/ExtensionItem';
import Parser from 'html-react-parser';
import React from 'react';

interface ExtensionItemProps {
    item: ExtensionItem;
    status: string;
    added: boolean;
    allExtensions: ExtensionItem[]; // Assuming you have this prop
}


const ExtensionComponent: React.FC<ExtensionItemProps> = (props) => {

    const svgBackground: string = `url("data:image/svg+xml,${encodeURIComponent(props.allExtensions?.find(v => v.ExtensionName === props.item.ExtensionName)?.ImageString ? props.allExtensions?.find(v => v.ExtensionName === props.item.ExtensionName)?.ImageString! : '')}`

    return (
        <div key={props.item.ExtensionName} style={{ position: 'relative' }}>
            {/* {props.status === 'new' && <div style={{ position: 'absolute', top: '0px', right: '55px', borderRadius: '10px', backgroundColor: '#eec73a', padding: '3px 15px 3px 15px', fontSize: '10px', fontWeight: 'bold' }}>NEW!</div>}
            {props.status === 'comingsoon' && <div style={{ position: 'absolute', top: '0px', right: '55px', borderRadius: '10px', backgroundColor: '#a8d4ba', padding: '3px 10px 3px 10px', fontSize: '10px', fontWeight: 'bold' }}>COMING SOON!</div>} */}
            <Stack key={props.item.ExtensionName} verticalAlign="start" tokens={{ childrenGap: '5px' }} styles={{ root: { width: '80%', minWidth: '200px', height: '100%', padding: '10px', border: '1px solid #e1dfdd', backgroundColor: '#faf9f8' } }}>
                <Stack.Item align="center" styles={{ root: { width: '48px', height: '48px', maxWidthidth: '48px', maxHeight: '48px' } }}>
                    <div style={{ width: '48px', height: '48px', background: svgBackground, backgroundSize: 'cover' }}></div>
                </Stack.Item>
                <Stack.Item align="center" >
                    <Stack horizontal tokens={{ childrenGap: '5px' }}>
                        <Text className="ms-fontSize-18 ms-fontWeight-semibold">{props.item.ExtensionName}</Text>
                    </Stack>
                </Stack.Item>
                <Stack.Item align="center">
                    <Stack horizontal tokens={{ childrenGap: '5px' }}>
                        {props.item.ExistInWord && <div className="ms-BrandIcon--icon16 ms-BrandIcon--word" />}
                        {props.item.ExistInPowerPoint && <div className="ms-BrandIcon--icon16 ms-BrandIcon--powerpoint" />}
                        {props.item.ExistInExcel && <div className="ms-BrandIcon--icon16 ms-BrandIcon--excel" />}
                        {props.item.ExistInOutlook && <div className="ms-BrandIcon--icon16 ms-BrandIcon--outlook" />}
                    </Stack>
                </Stack.Item>
                <Stack.Item align="center" styles={{ root: { height: '90px' } }}>
                    <Text className="ms-fontSize-10">
                        {/* {props.item.Description} */}
                        {Parser(`${props.allExtensions?.find(v => v.ExtensionName === props.item.ExtensionName)?.Description}`)}
                    </Text>
                </Stack.Item>
                <Stack.Item align="center" >
                    {props.status === 'new' && <div style={{ position: 'absolute', top: '10px', left: '10px', borderRadius: '10px', backgroundColor: '#eec73a', padding: '3px 15px 3px 15px', fontSize: '10px', fontWeight: 'bold' }}>NEW!</div>}
                    {props.status === 'comingsoon' && <div style={{ position: 'absolute', top: '10px', left: '10px', borderRadius: '10px', backgroundColor: '#a8d4ba', padding: '3px 10px 3px 10px', fontSize: '10px', fontWeight: 'bold' }}>COMING SOON!</div>}
                </Stack.Item>
                {/* <Stack.Item align="center" >
                    <Stack horizontal verticalAlign='center' tokens={{ childrenGap: '5px' }}>
                        <DefaultButton
                            text={props.added ? "Active" : "Order"}
                            iconProps={{ iconName: props.added ? "SkypeCircleCheck" : "Add" }}
                            primary={!props.added}
                            disabled={props.status === 'comingsoon'}
                        />
                    </Stack>
                </Stack.Item> */}
            </Stack>
        </div>
    );
};

export default ExtensionComponent;