import * as React from 'react';
import { Image, Stack } from '@fluentui/react';
import SigninButton from './assets/signin.svg';
import TemplateExtenderLogo from './assets/templateextender.svg';
import { TenantContext, UserContext } from './contexts/ContextWrapper';
import { useNavigate } from 'react-router-dom';
import LoadingSession from './components/common/LoadingSession';
import { AuthContext } from './contexts/AuthContext';
import { useSession } from './hooks/useSession';
import { getNavigationAddress } from './helpers/NavigationHelper';
import SettingsCompany from './helpers/SettingsCompany';

const Login: React.FC = () => {

	const { tenantContext, setTenantContext } = React.useContext(TenantContext);
	const { userContext } = React.useContext(UserContext);
	const { handleLogin, handleLogout, isSigningIn, isAuthenticated } = React.useContext(AuthContext);
	const { LoginSession } = useSession()

	const navigate = useNavigate();

	React.useEffect(() => {
		if (isAuthenticated && tenantContext.tenantName !== '' && tenantContext.api !== '') {
			const page: string = localStorage.getItem('selectedPage') || 'home';
			navigate(getNavigationAddress(tenantContext, userContext, page));
			// setMessageContext({ message: '', messageType: MessageBarType.info, visible: false });
		}
	}, [isAuthenticated, navigate, tenantContext.api, tenantContext.tenantName]);

	if (isAuthenticated || isSigningIn) {
		return (
			<LoadingSession logout={handleLogout} />
		)
	}

	// console.log(isAuthenticated, isSigningIn, tenantContext.tenantName, tenantContext.api)

	return (
		<div className="login-container" style={{ width: '100%', height: '100%' }}>
			<Stack styles={{ root: { height: '100%' } }}>
				<Stack grow horizontalAlign='center' verticalAlign='center' tokens={{ childrenGap: 20 }} styles={{ root: { height: '100%', width: '100%' } }}>
					<div style={{ height: '40vh', maxHeight: '80vw', maxWidth: '80vw' }}>
						<Image alt='TemplateExtender' src={TemplateExtenderLogo} width={'100%'} height={'100%'} />
					</div>
					<Image alt='Signin' src={SigninButton} onClick={() => {
						handleLogin(["User.Read", "Organization.Read.All"])
							.then(async (accessToken) => {
								console.log(accessToken)
								await LoginSession(
									(api: string, tenantName: string) => {
										setTenantContext({ ...tenantContext, api: api, tenantName: tenantName, settings: {} as SettingsCompany })
										console.log(`Login.tsx: useEffect: navigate: /${api.toLowerCase()}/${tenantName.toLowerCase()}/${localStorage.getItem('selectedPage') || 'home'}`)
										navigate(getNavigationAddress(tenantContext, userContext, localStorage.getItem('selectedPage') || 'home'))
									},
									(error: string) => { console.error(error) },
									accessToken!)
							})
							.catch(() => { console.error('Login Failed') })
					}} />
				</Stack>
				<Stack.Item align='center'>
					<div style={{ paddingBottom: '20px' }} ><a href='https://www.rehngruppen.se' target='_blank' rel="noopener noreferrer" style={{ fontSize: '0.8em', color: 'gray', textDecoration: 'none' }}>© Rehngruppen AB {new Date().getFullYear()}</a></div>
				</Stack.Item>
			</Stack>
		</div>
	);
}

export default Login;