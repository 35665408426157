/* tslint:disable:jsx-wrap-multiline */

import * as React from 'react';
import {
	TooltipHost,
	ITooltipHostStyles,
	Image,
	IconButton,
	Stack,
	Spinner,
	SpinnerSize,
} from '@fluentui/react';

import { GetPicture } from '../../helpers/GetFromRestApi';
import Axios, { CancelTokenSource } from 'axios';
import { PictureListItem } from '../../interfaces/PictureListItem';

export interface PictureButtonProps {
	setParentState: (x: any) => void
	item: PictureListItem;
	accessToken: string,
	// apiUri: string,
	domain: string
	api: string
	// showPanel: boolean,
}

export interface PictureButtonState {
	previewBig: string;
}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class PictureItem extends React.Component<PictureButtonProps, PictureButtonState> {

	constructor(props: PictureButtonProps) {
		super(props);
		this.state = {
			previewBig: '',
		}
	}

	calloutProps = { gapSpace: 0 };
	hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

	timeoutOnMouseOver: any;

	handleMouseOver = async () => {
		if (this.state.previewBig === '') {
			if (this.timeoutOnMouseOver) {
				clearTimeout(this.timeoutOnMouseOver);
				ourRequest.cancel();
			}
			this.timeoutOnMouseOver = setTimeout(async () => {
				ourRequest = Axios.CancelToken.source()
				this.setState({
					previewBig: 'data:image/' + (this.props.item.imgType === '.png' ? 'png' : 'jpeg') + ';base64,' + await GetPicture(this.props.accessToken, this.props.domain, this.props.api, this.props.item.id, ourRequest.token, 'big')
				})
				this.timeoutOnMouseOver = false
			});
		}
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	async onClick() {
		this.props.setParentState({
			selectedItem: this.props.item,
			showPreviewModal: true,
			selectedItemBase64: await GetPicture(this.props.accessToken, this.props.domain, this.props.api, this.props.item.id, ourRequest.token),
		})
	}

	render() {

		const tooltip: JSX.Element = (
			<Stack tokens={{ childrenGap: 5 }}>
				<span style={{ fontWeight: 'bold' }}>{this.props.item.imgName.substr(0, 35)}</span>
				{this.props.item.imgDescription ? <span>{this.props.item.imgDescription}</span> : null}
				{(this.state.previewBig !== '') ?
					<Image src={this.state.previewBig} alt={this.props.item.imgDescription} /> :
					<Spinner className='spinner' size={SpinnerSize.medium} label='Loading preview...' />
				}
			</Stack>
		)

		return (
			<TooltipHost
				content={tooltip}
				id={this.props.item.id}
				calloutProps={this.calloutProps}
				styles={this.hostStyles}
			>
				<div onMouseOver={this.handleMouseOver}>
					<IconButton
						ariaLabel={this.props.item.imgName}
						style={{ width: this.props.item.previewWidth + 6, height: this.props.item.previewHeight + 6, padding: 2, }}
						onClick={() => { this.onClick() }}
					>
						<Image
							//src={'data:image/jpeg;base64,' + GetPictureFromRestObjectById(this.props.item.domainToken, this.props.item.imgId, 'list')}
							src={'data:image/' + (this.props.item.imgType === '.png' ? 'png' : 'jpeg') + ';base64,' + this.props.item.previewBase64}
							alt={this.props.item.imgDescription}
							width={this.props.item.previewWidth}
							height={this.props.item.previewHeight}
						/>
					</IconButton>
				</div>
			</TooltipHost>
		);
	}
}