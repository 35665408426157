import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IDropdownOption, Label, PrimaryButton, TextField, Toggle } from "@fluentui/react";
import * as React from "react"

import { IPersonaProps } from 'office-ui-fabric-react/lib/Persona';
// import { IBasePickerSuggestionsProps, ValidationState } from 'office-ui-fabric-react/lib/Pickers';
import { PeoplePickerGroups } from "../../helpers/PeoplePicker";
import { GroupDetails } from "../../interfaces/GroupDetails";
import { TemplateFolderItem } from "../../interfaces/TemplateFolderItem";
import { GroupID } from "../../interfaces/GroupID";
// import { people, mru } from '@uifabric/example-data';

export const DialogCreateEditFolder: React.FunctionComponent<{
		setParentState: (x: any) => void, 
		companyGroups: GroupDetails[];
		hideState: string[], 
		confirmState: string, 
		newFolderItem: string, 
		hidden: boolean, 
		folders: TemplateFolderItem[], 
		folderItem: TemplateFolderItem, 
		defaultPath: string, 
		showGroups: boolean,
		isNew: boolean
	}> = (props) => {
  
	const [folderName, setFolderName] = React.useState<string>('');
	const [invalidFolderName, setInvalidFolderName] = React.useState<boolean>(false);
	const [emptyRoot, setEmptyRoot] = React.useState<boolean>(false);
	const [selectedRoot, setSelectedRoot] = React.useState<IDropdownOption>();
	const [hideOnline, setHideOnline] = React.useState<boolean>(false);
	const [hideDesktop, setHideDesktop] = React.useState<boolean>(false);
	const [allGroups, setAllGroups] = React.useState<IPersonaProps[]>();
	const [selectedGroups, setSelectedGroups] = React.useState<IPersonaProps[]>();
	
	const clearUseStates = () => {
		setFolderName('');
		setEmptyRoot(false)
		setInvalidFolderName(false)
		setHideOnline(false)
		setHideDesktop(false)		
		setAllGroups(undefined)
		setSelectedGroups(undefined)
		setSelectedRoot(undefined)
	}

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: props.isNew ? 'Create new folder' : 'Edit folder',
		subText: props.isNew ? '' : `Edit folder ${props.folderItem?.primaryText}`,
	};
	
	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 600 } },
	};

	if (props.hidden) {
		if (folderName !== '') {clearUseStates()}
		return (<></>)
	}

	const rootFolderOptions: IDropdownOption[] = props.folders.map((item) => {
		console.log(typeof item.Root === 'undefined' ? item.Path : item.Root + '\\' + item.Path)
		return ({
			key: typeof item.Root === 'undefined' ? '\\' : item.Library!, 
			text: typeof item.Root === 'undefined' ? '\\' : item.Library!,
			data: item
		})
	})
	
	// const setGroups = (groups: IPersonaProps[]) => {
	// 	console.log(groups)
	// }
	
	if (!selectedRoot){
		setSelectedRoot(rootFolderOptions.find((item) => {return item.key === props.defaultPath}))
	}	

	if (!folderName && !props.isNew){	
		setFolderName(props.folderItem.primaryText)
	}

	if (!allGroups) {
		const myGroups: IPersonaProps[] = props.companyGroups?.map((item: GroupDetails) => {
			return ({
				id: item.id,
				text: item.displayName,
				secondaryText: item.description
			})
		})
		if (myGroups !== undefined) {			
			setAllGroups(myGroups)
			setHideOnline(props.folderItem.hideOnline!)
			setHideDesktop(props.folderItem.hideDesktop!)
			setSelectedGroups(myGroups!.filter(
				(companyGroup: IPersonaProps) => {
					return props.folderItem.templateGroups?.filter(
						(templateGroup: GroupID) => {
							return companyGroup.id === templateGroup.groupID
						}).length! > 0
				})
			)
		}		
	}

	const onChangeRoot = (item: IDropdownOption): void => {
		setSelectedRoot(item)
		setHideOnline((item.data as TemplateFolderItem).hideOnline!)
		setHideDesktop((item.data as TemplateFolderItem).hideDesktop!)
		setSelectedGroups(allGroups!.filter(
			(companyGroup: IPersonaProps) => {
				return (item.data as TemplateFolderItem).templateGroups?.filter(
					(templateGroup: GroupID) => {
						return companyGroup.id === templateGroup.groupID
					}).length! > 0
			})
		)
	};

	let RootPart: JSX.Element= (<></>)


	if (props.isNew) {		
		RootPart = (
			<Dropdown
				placeholder="Select parent folder"
				label="Select parent folder"
				options={rootFolderOptions}
				selectedKey={selectedRoot ? selectedRoot.key : props.defaultPath}
				onChange={(_event, option) => onChangeRoot(option!)}				
				errorMessage={emptyRoot ? 'Must select a root folder' : ''}
			/>
		)
	}

	console.log('allGroups')
	console.log(allGroups)
	console.log('selectedGroups')
	console.log(selectedGroups)

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.setParentState({[props.hideState[0]]: true, [props.hideState[1]]: true})}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<TextField
				label='Folder name'
				value={folderName}
				onChange={(_event, value) => setFolderName(value!)}
				errorMessage={invalidFolderName ? 'Not a valid folder name' : ''}
			/>
			{RootPart}
			<Toggle 
				label="Hide online" 
				onText="On"
				offText="Off" 
				inlineLabel
				checked={hideOnline}
				onChange={(_event, checked) => {setHideOnline(checked!)}} 
			/>
			<Toggle 
				label="Hide desktop" 
				onText="On"
				offText="Off" 
				inlineLabel
				checked={hideDesktop}
				onChange={(_event, checked) => {setHideDesktop(checked!)}} 
			/>		
			{props.showGroups ?
				!!allGroups ? 
					<>
						<Label>Groups</Label>
						<PeoplePickerGroups
							setSelectedGroups={setSelectedGroups}
							companyGroups={allGroups!}
							selectedGroups={selectedGroups!}
						/>
					</>				
				:<>Groups not loaded!</>
			: null}					
			<DialogFooter>
				<PrimaryButton 
					onClick={() => {
						
						setEmptyRoot(!selectedRoot)
						setInvalidFolderName(folderName === '')

						if (!!selectedRoot && folderName !== '') {
							const selectedPath = typeof selectedRoot.data.Root === 'undefined' ? selectedRoot.data.Path : selectedRoot.data.Root + '\\' + selectedRoot.data.Path
							props.setParentState({
								[props.hideState[0]]: true,
								[props.hideState[1]]: true,
								[props.confirmState]: true,
								[props.newFolderItem]: {
									Root: props.isNew ? selectedPath : selectedPath.toString().substring(0, selectedPath.toString().lastIndexOf('\\')),
									Path: props.isNew ? '' : selectedPath.toString().split('\\').pop(), //.substring(selectedRoot.key.toString().lastIndexOf('\\')+1),
									primaryText: folderName,
									hideOnline: hideOnline,
									hideDesktop: hideDesktop,
									templateGroups: selectedGroups?.map((item) => {return {groupID: item.id}})
								} as TemplateFolderItem
							})					
							clearUseStates()	
						}					
					}} 
					text= {props.isNew ? "Create" : "Update" }
				/>
				<DefaultButton 
					onClick={() => {						
						props.setParentState({
							[props.hideState[0]]: true,
							[props.hideState[1]]: true,
						})
						clearUseStates()
					}} 
					text="Cancel" 
				/>
			</DialogFooter>
		</Dialog>
	);
};
  