import * as React from 'react';
import {
	CommandBar,
	IButtonStyles,
	ICommandBarItemProps,
	IconButton,
	Image,
	ShimmerElementsGroup,
	Stack,
	Text,
	TooltipHost,
} from '@fluentui/react';
import Axios, { CancelTokenSource } from 'axios';
import { Shimmer, ShimmerElementType } from 'office-ui-fabric-react';
import { UploadFile } from '../../interfaces/UploadFile';
import { EmbeddedPictureItem } from '../../interfaces/EmbeddedPictureItem';
import { DialogUploadPictures } from '../dialogs/DialogUploadPictures';
import { uploadEmailsignaturePicture } from '../../helpers/PostToRestApi';

export interface EmailSignaturesPreviewProps {
	// setParentState: (x: EmailSignaturesState) => void
	getEmbeddedPictures: () => void
	embeddedPictureItems: EmbeddedPictureItem[]
	// setAppState: (x: AppState) => void;
	// getLayoutItems(): void;
	// pictureItems: PictureListItem[];
	accessToken: string
	// apiUri: string
	domain:string,
	api:string,
	// selectedSize: PictureSrcLib;
	// settings: SettingsCompany;
	// totalPicCount: number;
}

export interface EmailSignaturesPreviewState {
	// hideDeleteDialog: boolean;
	// hideEditDialog: boolean;
	hideUploadDialog: boolean
	// hideNoSelectDialog: boolean;
	// hideFileExist: boolean;
	// hideUploadFull: boolean;
	// deleteConfirmed: boolean;
	uploading: boolean
	uploadConfirmed: boolean
	uploadItems: UploadFile[]

}

// let infoMessage: HTMLDivElement | null = null;

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class EmailSignaturesPreview extends React.Component<EmailSignaturesPreviewProps, EmailSignaturesPreviewState> {

	constructor(props: EmailSignaturesPreviewProps) {
		super(props);
		this.state = {
			hideUploadDialog: true,
			uploading: false,
			uploadConfirmed: false,
			uploadItems:[],
		};
		this.boundSetState = this.setState.bind(this)
	}

	deleting: Boolean = false
	uploading: Boolean = false

	boundSetState = (x: any) => { this.setState(x) }

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	componentDidUpdate(_prevProps: EmailSignaturesPreviewProps, prevState: EmailSignaturesPreviewState) {
		if (!prevState.uploadConfirmed && this.state.uploadConfirmed) {
			this._uploadPictures()
		}
	}

	componentDidMount() {

	}


	render(): JSX.Element {

		//TODO
		const commandItems: ICommandBarItemProps[] = [
			{
				key: 'upload',
				text: 'Upload',
				// disabled: true,
				iconProps: { iconName: 'Upload' },
				onClick: () => this._uploadPrompt(),
			},
			{
				key: 'download',
				text: 'Download',
				disabled: true,
				iconProps: { iconName: 'Download' },
				// onClick: () => this._downloadFile(),
			},
			{
				key: 'edit',
				text: 'Edit',
				disabled: true,
				iconProps: { iconName: 'Edit' },
				// onClick: () => this._editPrompt(),
			},
			{
				key: 'delete',
				text: 'Delete',
				disabled: true,
				iconProps: { iconName: 'Delete' },
				// onClick: () => this._deletePrompt(),
			},
		];

		const shimmerGroup = (<ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, width: 100, height: 100 }]} />)

		function shimmerItems(): JSX.Element[] {
			let items = []
			for (let i = 0; i < 20; i++) {
				items.push(<Stack.Item styles={{ root: { padding: '5px' } }}><Shimmer customElementsGroup={shimmerGroup} /></Stack.Item>);
			}
			return items
		}

		// buttonStyles: IButtonStyles | undefined;
		const buttonStyles: IButtonStyles = {
			root: {
				border: '1px',
				borderStyle: 'solid',
				borderColor: '#fff'
			},
			rootHovered: {
				border: '1px',
				borderStyle: 'solid',
				borderColor: '#e1dfdd'
			}
		}

		return (
			<Stack className='Width100'>
				<span className='Indent20'><h3>Uploaded Pictures</h3></span>
				<CommandBar
					items={commandItems}
					ariaLabel="Use left and right arrow keys to navigate between commands"
				/>
				<Stack horizontal wrap tokens={{ childrenGap: 15 }} styles={{ root: { margin: '20px' } }}>
					{/* {LoadingProgress} */}
					{(this.props.embeddedPictureItems!?.length > 0) ?
					(!this.state.uploading) ?
						this.props.embeddedPictureItems.map((item: EmbeddedPictureItem, idx: number) => {

							const tooltip: JSX.Element = (
								<Stack tokens={{ childrenGap: 5 }}>
									<span style={{ fontWeight: 'bold' }}>{item.PictureName}</span>
									<Image src={`data:image/${item.PictureName.split('.').pop()?.toLowerCase() === 'jpg' ? 'jpeg' : item.PictureName.split('.').pop()?.toLowerCase()};base64,${item.Base64}`} alt={item.PictureName} />
								</Stack>
							)

							return (
								<>
									<Stack.Item styles={{ root: { padding: '5px' } }}>
										<TooltipHost
											content={tooltip}
											id={idx.toString()}
											calloutProps={{ gapSpace: 0 }}
											styles={{ root: { display: 'inline-block' } }}
										>
											<IconButton
												ariaLabel={item.PictureName}
												styles={buttonStyles}
												style={{ width: 104, height: 104, padding: 2, border: '' }}
											// onClick={() => { this.onClick() }}
											>
												<Stack horizontalAlign='center' verticalAlign='center'>
													<Image
														src={`data:image/${item.PictureName.split('.').pop()?.toLowerCase() === 'jpg' ? 'jpeg' : item.PictureName.split('.').pop()?.toLowerCase()};base64,${item.Base64}`}
														alt={item.PictureName}
														styles={{ image: { objectFit: 'contain', height: 'auto', width: 'auto', maxHeight: 86, maxWidth: 100 } }}
														onClick={() => { navigator.clipboard.writeText(`<img src="${item.PictureName}">`) }}
													/>
													<Text variant='xSmall'>{item.PictureName}</Text>
												</Stack>
											</IconButton>
										</TooltipHost>
										{/* <TextField
											value={`<img src="${item.PictureName}">`}
											onClick={() => {navigator.clipboard.writeText(`<img src="${item.PictureName}">`)}}
										/> */}
									</Stack.Item>
								</>
							)
						})
						:
						shimmerItems()
					 : null}
				</Stack>
				<DialogUploadPictures
					setParentState={this.boundSetState}
					hidden={this.state.hideUploadDialog}
					hideState='hideUploadDialog'
					confirmState='uploadConfirmed'
					uploadItemState='uploadItems'
				/>				
			</Stack>
		);
	}

	private _uploadPrompt() {
		this.setState({ hideUploadDialog: false })
	}

	private async _uploadPictures() {
		this.setState({ uploading: true })

		await Promise.all(this.state.uploadItems.map(async (file: UploadFile) => {
			ourRequest = Axios.CancelToken.source()
			const pic: EmbeddedPictureItem = { PictureName: file.filename, Base64: file.data }
			await uploadEmailsignaturePicture(
				this.props.accessToken,
				this.props.domain,
				this.props.api,
				JSON.stringify(pic),
				ourRequest.token,
			)
		}))
			.then(async () => {
				setTimeout(() => {
					this.props.getEmbeddedPictures()
				}, 500)
			})
	}

	// private _deletePrompt() {
	// 	if (this._selection.count > 0) {
	// 		this.setState({ hideDeleteDialog: false })
	// 	}
	// 	else {
	// 		this.setState({ hideNoSelectDialog: false })
	// 	}
	// }

	// private async _deleteSelectedPicture() {
	// 	this.setState({ uploading: true })

	// 	await Promise.all(this.state.uploadItems.map(async (file: UploadFile) => {
	// 		ourRequest = Axios.CancelToken.source()
	// 		const pic: EmbeddedPictureItem = { PictureName: file.filename, Base64: file.data }
	// 		await uploadEmailsignaturePicture(
	// 			this.props.accessToken,
	// 			this.props.apiUri,
	// 			JSON.stringify(pic),
	// 			ourRequest.token,
	// 		)
	// 	}))
	// 		.then(async () => {
	// 			setTimeout(() => {
	// 				this.props.getEmbeddedPictures()
	// 			}, 500)
	// 		})
	// }

	// private async _downloadFile() {
	// 	if (typeof this._selection.getSelection()[0] !== 'undefined') {
	// 		let selectedFile: PictureListItem = this._selection.getSelection()[0] as PictureListItem
	// 		let fileBase64 = await GetPicture(this.props.accessToken, selectedFile.id, this.props.apiUri)
	// 		let anchor: HTMLAnchorElement = document.createElement("a");
	// 		anchor.href = 'data:image/' + (selectedFile.imgType === '.png' ? 'png' : 'jpeg') + ';base64,' + JSON.parse(fileBase64);
	// 		anchor.download = selectedFile.imgName + selectedFile.imgType
	// 		anchor.click(); //Downloaded file
	// 	}
	// 	else {
	// 		this.setState({ hideNoSelectDialog: false })
	// 	}

	// }
}
