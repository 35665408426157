import * as React from 'react';
import { Stack, Text } from '@fluentui/react';
import PicturesDetails from './PicturesDetails';
import PicturesLayouts from './PicturesLayouts';
import { GetPictureSizes, GetPicturesByPath } from '../../helpers/GetFromRestApi';
import { hasValues } from '../../helpers/MiscFunctions';
import { updatePictureLibraries } from '../../helpers/PostToRestApi';
import Axios, { CancelTokenSource } from 'axios';
import SettingsCompany from '../../helpers/SettingsCompany';
import { ExampleLayoutList, PictureSrcLib } from '../../interfaces/PictureSrcLib';
import { examplePictureList, PictureListItem } from '../../interfaces/PictureListItem';
import { ContentFrame } from '../common/ContentFrame';
import { MessageContextType } from '../../interfaces/ContextType';

export interface PicturesProps {
	setMessage: (x: MessageContextType) => void;
	accessToken: string,
	// apiUri: string,
	domain: string
	api: string
	settings: SettingsCompany;
}

export interface PicturesState {
	pictureLayoutItems?: PictureSrcLib[];
	selectedIndex: number;
	// selectedLayout: string;
	selectedPath: string;
	pictureItems: PictureListItem[];
	// accessToken?: string,
	// apiUri?: string,
	fetching?: boolean,
}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class Pictures extends React.Component<PicturesProps, PicturesState> {
	constructor(props: PicturesProps) {
		super(props);
		this.state = {
			pictureLayoutItems: [],
			selectedIndex: 0,
			selectedPath: '',
			pictureItems: [],
			fetching: false,
		};
		this.boundSetState = this.setState.bind(this);
		this.getPictures = this.getPictures.bind(this);
		this.getLayoutItems = this.getLayoutItems.bind(this);
	}

	boundSetState = (x: PicturesState) => { this.setState(x) }

	getLayoutItems = async (reset: boolean = false) => {
		this.setState({ fetching: true })
		ourRequest = Axios.CancelToken.source()
		let myLayouts: PictureSrcLib[] = await GetPictureSizes(this.props.accessToken!, this.props.domain, this.props.api, ourRequest.token)
		// console.log(myLayouts)
		if (!hasValues(myLayouts)) { myLayouts = [] }
		this.setState({
			pictureLayoutItems: myLayouts,
			pictureItems: [],
			fetching: false,
			selectedPath: this.state.selectedPath && !reset ? this.state.selectedPath : myLayouts.length > 0 ? myLayouts[0].Path! : '',
		})
		if (this.state.selectedPath && !reset) {
			this.getPictures()
		}
	}

	setLayoutItems = async () => {
		ourRequest = Axios.CancelToken.source()
		await updatePictureLibraries(this.props.accessToken!, this.props.domain, this.props.api, JSON.stringify(this.state.pictureLayoutItems), ourRequest.token)
	}

	getPictures = async () => {
		this.setState({ fetching: true })
		ourRequest = Axios.CancelToken.source()
		let pictures: PictureListItem[] = await GetPicturesByPath(
			this.props.accessToken,
			this.props.domain,
			this.props.api,
			this.state.selectedPath,
			ourRequest.token,
		)
		if (!hasValues(pictures)) { pictures = examplePictureList }
		this.setState({
			pictureItems: pictures,
			fetching: false,
		})
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	componentDidUpdate(_prevProps: Readonly<PicturesProps>, _prevState: Readonly<PicturesState>, _snapshot?: any): void {
		if (_prevProps.domain !== this.props.domain ||
			_prevProps.api !== this.props.api ||
			_prevProps.accessToken !== this.props.accessToken) {
			this.getLayoutItems()
		}
		if (_prevState.selectedPath !== this.state.selectedPath && this.state.selectedPath) {
			this.getPictures()
		}
	}

	componentDidMount(): void {
		if (this.props.api && this.props.domain && this.props.accessToken) {
			this.getLayoutItems()
		}
	}

	totalPicCount: number = 0

	render() {

		// if ((this.state.apiUri !== this.props.apiUri || this.state.accessToken !== this.props.accessToken) && !this.state.fetching) {
		// 	this.getLayoutItems()
		// 	// return(<></>)
		// }

		// if (this.state.selectedPath !== '' && !hasValues(this.state.pictureItems) && !this.state.fetching) {
		// 	this.getPictures()
		// 	// return(<></>)
		// }

		// if (this.state.selectedPath === '' && hasValues(this.state.pictureItems)) {
		// 	this.setState({ pictureItems:[] })
		// }

		try {
			this.totalPicCount = 0
			this.totalPicCount += this.state.pictureLayoutItems!.map((item) => { return item.PicturesCount! }).reduce((acc, cur) => acc + cur)
		} catch (error) {
		}

		return (
			<Stack >
				<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
					<Text variant="xxLarge">
						Pictures
					</Text>
				</Stack.Item>
				<ContentFrame >
					<PicturesLayouts
						setParentState={this.boundSetState}
						getLayoutItems={this.getLayoutItems}
						selectedIndex={this.state.selectedIndex}
						pictureLayoutItems={this.state.pictureLayoutItems!}
						accessToken={this.props.accessToken}
						domain={this.props.domain}
						api={this.props.api}
						settings={this.props.settings}
						totalPicCount={this.totalPicCount}
					/>
				</ContentFrame>
				<ContentFrame>
					{this.state.selectedPath !== '' && this.state.pictureLayoutItems !== ExampleLayoutList ?
						<PicturesDetails
							setMessage={this.props.setMessage}
							getLayoutItems={this.getLayoutItems}
							getPictures={this.getPictures}
							pictureItems={this.state.pictureItems}
							accessToken={this.props.accessToken}
							domain={this.props.domain}
							api={this.props.api}
							selectedSize={typeof this.state.pictureLayoutItems === 'undefined' ? {} as PictureSrcLib : this.state.pictureLayoutItems![this.state.selectedIndex]! || {} as PictureSrcLib}
							settings={this.props.settings}
							totalPicCount={this.totalPicCount}
						/>
						: null}
				</ContentFrame>
			</Stack>
		)
	}
}
