import { GroupID } from "./GroupID";

export interface SlideListItem {
	id: string,
	index: number,
	title: string,
	searchText: string,
	base64: string,
	Width: number,
	Height: number,
	presentationName: string,
	pub: boolean,
	slideGroups: GroupID[] | undefined,
}

export const exampleSlidesList: SlideListItem[] = [{
	id: '',
	index: 0,
	title: '',
	searchText: '',
	base64: '',
	Width: 0,
	Height: 0,
	presentationName: '',
	pub: false,
	slideGroups: [],
}]