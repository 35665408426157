import React, { useEffect, useState } from 'react';
import { Image, Spinner, SpinnerSize, registerIcons } from '@fluentui/react';
import { CancelTokenSource } from 'axios';
import { ExtensionItem } from '../../interfaces/ExtensionItem';
import moment from 'moment';
import ExtensionComponent from './ExtensionComponent';
import { GetAllExtensions, GetAvailableExtensions } from '../../helpers/GetFromRestApi';
import { TenantContext } from '../../contexts/ContextWrapper';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CenterComponent } from '../common/CenterComponent';
import { AuthContext } from '../../contexts/AuthContext';

export interface ExtensionsHomeProps {
}

const ImageVaultLogo = require('../../assets/imagevault.svg');
const UnsplashLogo = require('../../assets/unsplash.svg');

const responsive = {
	xlarge: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5
	},
	large: {
		breakpoint: { max: 3000, min: 1250 },
		items: 4
	},
	medium: {
		breakpoint: { max: 1250, min: 750 },
		items: 3
	},
	small: {
		breakpoint: { max: 750, min: 550 },
		items: 2
	},
	xsmall: {
		breakpoint: { max: 550, min: 0 },
		items: 1
	}
};

registerIcons({
	icons: {
		'ImageVault': (
			<Image alt='ImageVault Logo' src={ImageVaultLogo} />
		),
		'Unsplash': (
			<Image alt='Unsplash Logo' src={UnsplashLogo} />
		),
	},
});

const ExtensionsHome: React.FC<ExtensionsHomeProps> = () => {
	const [ourRequest] = useState<CancelTokenSource>({} as CancelTokenSource);
	const [availableExtensions, setAvailableExtensions] = useState<ExtensionItem[]>([]);
	const [allExtensions, setAllExtensions] = useState<ExtensionItem[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { tenantContext } = React.useContext(TenantContext);
	const { accessToken } = React.useContext(AuthContext);

	useEffect(() => {
		return () => {
			try { ourRequest.cancel(); }
			catch (error) { }
		};
	}, []);

	useEffect(() => {
		let mounted = true;
		const fetchExtensions = async () => {
			setIsLoading(true);
			const [availableExtensions, allExtensions] = await Promise.all([
				GetAvailableExtensions(accessToken!!, tenantContext.tenantName, tenantContext.api!, ourRequest.token),
				GetAllExtensions(accessToken!!, tenantContext.tenantName, tenantContext.api!, ourRequest.token),
			])
			if (!mounted) return;
			setAvailableExtensions(availableExtensions);
			setAllExtensions(allExtensions);
			setIsLoading(false);
		}
		fetchExtensions();
		return () => { mounted = false; }
	}, [accessToken, tenantContext.tenantName, tenantContext.api]);

	if (isLoading) {
		return (
			<CenterComponent>
				<Spinner className='spinner' size={SpinnerSize.large} label='Loading extensions...' />
			</CenterComponent>
		)
	}

	return (
		<Carousel
			swipeable={false}
			draggable={false}
			showDots={true}
			responsive={responsive}
			// ssr={true} // means to render carousel on server-side.
			infinite={true}
			// autoPlay={false}
			// autoPlaySpeed={1000}
			keyBoardControl={true}
			// customTransition="all .5"
			transitionDuration={500}
			containerClass="carousel-container"
			removeArrowOnDeviceType={["xsmall"]}
		// deviceType={'desktop'}
		// dotListClass="custom-dot-list-style"
		// itemClass="carousel-item-padding-40-px"
		>
			{allExtensions?.map((settingsItem) => {
				const item: ExtensionItem = allExtensions?.find((val) => val.ExtensionName === settingsItem.ExtensionName)!;
				const dateDiff: number = item.ProductionDate ? (new Date(moment().format('YYYY-MM-DD')).getTime() - new Date(moment(item.ProductionDate).format('YYYY-MM')).getTime()) / 86400000 || -1000 : 1000;
				// console.log(dateDiff);
				if (item && item.Description && dateDiff > -100) {
					return (
						<ExtensionComponent
							key={item.ExtensionName}
							item={item}
							status={dateDiff >= 0 ? dateDiff > 60 ? '' : 'new' : 'comingsoon'}
							added={true}
							allExtensions={allExtensions!}

						/>
					);
				}
				return null;
			})}
			{availableExtensions?.map((item) => {
				const dateDiff: number = item.ProductionDate ? (new Date(moment().format('YYYY-MM-DD')).getTime() - new Date(moment(item.ProductionDate).format('YYYY-MM')).getTime()) / 86400000 || -1000 : 1000;
				// console.log(dateDiff);
				if (item.Description && dateDiff > -100) {
					return (
						<ExtensionComponent
							key={item.ExtensionName}
							item={item}
							status={dateDiff >= 0 ? dateDiff > 60 ? '' : 'new' : 'comingsoon'}
							added={false}
							allExtensions={allExtensions!}
						/>
					);
				}
				return null;
			})}
		</Carousel>
	);
};

export default ExtensionsHome;
