import * as React from "react"
import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IDropdownOption, PrimaryButton, Text } from "@fluentui/react"
import { TemplateFolderItem } from "../../interfaces/TemplateFolderItem";
import { UploadFile } from "../../interfaces/UploadFile";
import { toBase64 } from "../../helpers/Base64";

const getDropdownOption = (item: TemplateFolderItem): IDropdownOption => {
	return ({ key: typeof item.Root === 'undefined' ? '\\' : item.Library!, text: typeof item.Root === 'undefined' ? '\\' : item.Library!, data: item })
}

export const DialogUploadTemplates: React.FunctionComponent<{
	callbackUpload: (files: UploadFile[]) => void,
	callbackAbort: () => void,
	folders: TemplateFolderItem[],
	defaultPath: string,
}> = (props) => {

	const [emptyRoot, setEmptyRoot] = React.useState<boolean>(false);
	const [selectedRoot, setSelectedRoot] = React.useState<IDropdownOption>(props.folders.map((item) => { return getDropdownOption(item) }).find((item) => item.key === props.defaultPath)!);
	// const [allGroups, setAllGroups] = React.useState<IPersonaProps[]>();
	// const [selectedGroups, setSelectedGroups] = React.useState<IPersonaProps[]>();
	const [noInput, setNoInput] = React.useState<boolean>(false);
	const [uploadFiles, setUploadFiles] = React.useState<UploadFile[]>([]);



	// const clearUseStates = () => {
	// 	setNoInput(false)
	// 	setUploadFiles([])
	// 	setEmptyRoot(false)
	// 	// setAllGroups(undefined)
	// 	// setSelectedGroups(undefined)
	// 	setSelectedRoot(undefined)
	// }

	// if (props.hidden) {
	// 	// if (noInput) {setNoInput(false)}
	// 	// if (uploadFiles.length > 0) {setUploadFiles([])}
	// 	if (selectedRoot) {clearUseStates()}
	// 	return (<></>)		
	// }

	const acceptExtensions: string = ".docx, .pptx, .xlsx"

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: `Upload templates`,
		subText: `Browse the template(s) you want to upload`,
	};

	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 450 } },
	};

	// let uploadfiles: UploadFile[];

	const onChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {

		await Promise.all(Array.from(event.target.files!).map(async (item) => {
			try {
				let res = (await toBase64(item))
				let data = ''
				if (typeof res === 'string') { data = (res.split(',')[1]) }
				return ({ filename: item.name, data: data, path: selectedRoot.data.Path })
			} catch (error) {
				return ({ filename: '', data: '', path: '\\' })
			}
		}))
			.then((res: UploadFile[]) => {
				setUploadFiles(res)
				setNoInput(false)
			})
			.catch(() => {
				setUploadFiles([]);

			})
	}

	const onChangeRoot = (item: IDropdownOption): void => {
		setSelectedRoot(item)
		setUploadFiles(uploadFiles.map((file) => {
			return ({ filename: file.filename, data: file.data, path: item.data.Path })
		}))
	};

	// const rootFolderOptions: IDropdownOption[] = props.folders.map((item) => {
	// 	return ({
	// 		key: typeof item.Root === 'undefined' ? '\\' : item.Library!,
	// 		text: typeof item.Root === 'undefined' ? '\\' : item.Library!,
	// 		data: item
	// 	})
	// })



	// if (!allGroups) {
	// 	const myGroups: IPersonaProps[] = props.companyGroups?.map((item: GroupDetails) => {
	// 		return ({
	// 			id: item.id,
	// 			text: item.displayName,
	// 			secondaryText: item.description
	// 		})
	// 	})
	// 	if (myGroups !== undefined) {			
	// 		setAllGroups(myGroups)
	// 		setSelectedGroups(myGroups!.filter(
	// 			(companyGroup: IPersonaProps) => {
	// 				return props.folderItem.templateGroups?.filter(
	// 					(templateGroup: GroupID) => {
	// 						return companyGroup.id === templateGroup.groupID
	// 					}).length! > 0
	// 			})
	// 		)
	// 	}		
	// }

	console.log(uploadFiles)

	return (
		<Dialog
			hidden={false}
			// hidden={props.hidden}
			// onDismiss={() => props.setParentState({[props.hideState]: true})}
			onDismiss={() => props.callbackAbort()}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<input title="Browse..." type="file" multiple name="file" accept={acceptExtensions} onChange={(event) => { onChangeHandler(event) }} />
			{noInput ? <p><Text variant='small' style={{ color: '#a4262c' }}>Nothing selected!</Text></p> : null}
			<Dropdown
				placeholder="Select folder"
				label="Select folder"
				options={props.folders.map((item) => getDropdownOption(item))}
				selectedKey={selectedRoot ? selectedRoot.key : props.defaultPath}
				onChange={(_event, option) => onChangeRoot(option!)}
				errorMessage={emptyRoot ? 'Must select a root folder' : ''}
			/>
			<DialogFooter>
				<PrimaryButton onClick={() => {
					if (selectedRoot === undefined) { setEmptyRoot(true) }
					else if (uploadFiles.length < 1) { setNoInput(true) }
					else { props.callbackUpload(uploadFiles) }
				}}
					text="Upload" />
				{/* <DefaultButton onClick={() => props.setParentState({ [props.hideState]: true })} text="Cancel" /> */}
				<DefaultButton onClick={() => props.callbackAbort()} text="Cancel" />
			</DialogFooter>
		</Dialog>
	);
};
