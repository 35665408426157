import { CheckboxVisibility, CommandBar, DetailsListLayoutMode, IColumn, ICommandBarItemProps, Icon, IDetailsGroupDividerProps, IDetailsGroupRenderProps, IGroup, Selection, SelectionMode, ShimmeredDetailsList, Stack } from '@fluentui/react';
import * as React from 'react';
import { AcademyLevel, AcademyPlatform } from '../../interfaces/AcademyItem';
import { AcademySimpleItem } from '../../interfaces/AcademyPlatform';
import { DialogOkOnly } from '../dialogs/DialogOkOnly';

interface AcademyListProps {
    templateVersion: 'PROD' | 'TEST'
    onCommandItemClick(key: string): void
    academyItems: AcademySimpleItem[]
    selectedId: string
    setSelectedId: (selectedId: string) => void
    levels: AcademyLevel[]
    platforms: AcademyPlatform[]
    isEdited: boolean
}

const AcademyList: React.FC<AcademyListProps> = (props) => {

    const [showEditModal, setShowEditModal] = React.useState<boolean>(false)
    const [minimizedMode, setMinimizedMode] = React.useState<boolean>(true)
    const [updateUi, setUpdateUi] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (updateUi) { setUpdateUi(false) }
    }, [updateUi])

    let selection: Selection = new Selection({
        onSelectionChanged: () => {
            // console.log('onSelectionChanged')
            // console.log(props.selectedId)
            if (selection.getSelection().length === 0) return
            props.setSelectedId((selection.getSelection()[0] as AcademySimpleItem).academyID)
        },
        onItemsChanged: () => {
            // console.log('onItemsChanged')
            // console.log(props.selectedId)
            if (props.selectedId === '') return
            selection.selectToKey(props.selectedId)
        }
    });

    const getIcon = (platform: string) => {
        switch (platform) {
            case "Outlook":
                return <div style={{ marginRight: '5px' }}><div className="ms-BrandIcon--icon16 ms-BrandIcon--outlook ProgramIcon16"></div></div>
            case "PowerPoint":
                return <div style={{ marginRight: '5px' }}><div className="ms-BrandIcon--icon16 ms-BrandIcon--powerpoint ProgramIcon16"></div></div>
            case "Word":
                return <div style={{ marginRight: '5px' }}><div className="ms-BrandIcon--icon16 ms-BrandIcon--word ProgramIcon16"></div></div>
            case "Excel":
                return <div style={{ marginRight: '5px' }}><div className="ms-BrandIcon--icon16 ms-BrandIcon--excel ProgramIcon16"></div></div>
            default:
                return <></>
        }
    }

    const columns: IColumn[] = React.useMemo(() => {
        const nameColumn: IColumn = {
            key: 'column2',
            name: 'Name',
            fieldName: 'Name',
            minWidth: 300,
            maxWidth: 500,
            isResizable: true,
            onRender: (item: AcademySimpleItem) => {
                if (props.isEdited) {
                    if (item.academyID === props.selectedId && props.isEdited) { return <Stack horizontal><Icon iconName='Edit' /><b><i>&nbsp;{item.Name}</i></b></Stack> }
                    else { return <span style={{ color: '#ccc' }}>{item.Name}</span> }
                }
                else {
                    if (item.academyID === props.selectedId) { return <b>{item.Name}</b> }
                    else { return item.Name }
                }
            }
        }
        if (minimizedMode) return [
            nameColumn
        ]
        return [
            nameColumn,
            {
                key: 'column4',
                name: 'Learning level',
                fieldName: 'LearningLevelName',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
            },
            {
                key: 'column6',
                name: 'Level',
                fieldName: 'LevelName',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                // onRender: (item: AcademySimpleItem) => {
                //     return item.LevelName
                // }
            } as IColumn,

        ]
    }, [props.templateVersion, props.selectedId, props.isEdited, minimizedMode])


    const groups: IGroup[] = React.useMemo(() => {
        let grp: IGroup[] = props.platforms.sort((a, b) => a.Order - b.Order).map((platform) => {
            return {
                key: platform.PlatformID,
                name: platform.PlatformID || '',
                startIndex: props.academyItems.findIndex(item => item.PlatformID === platform.PlatformID),
                count: props.academyItems.filter(item => item.PlatformID === platform.PlatformID).length,

            } as IGroup
        })
        const newGrp: IGroup = {
            key: 'New',
            name: 'New',
            startIndex: props.academyItems.findIndex(item => item.PlatformID === ''),
            count: props.academyItems.filter(item => item.PlatformID === '').length,
        }
        if (newGrp.count === 0) return grp
        return [newGrp, ...grp,]
    }, [props.academyItems, props.platforms])

    const groupProps: IDetailsGroupRenderProps = React.useMemo(() => {
        return {
            showEmptyGroups: true,
            onRenderHeader: (props, defaultRender) => {
                const newProps: IDetailsGroupDividerProps = {
                    ...props, onRenderName: (props, _defaultRender) => {
                        return (
                            <Stack horizontal verticalAlign='center' >
                                {getIcon(props?.group?.key || '')}
                                <span style={{ fontSize: '14px' }}>{props?.group?.name} ({props?.group?.count})</span>
                            </Stack>)
                    }
                }
                return (defaultRender!(newProps))
            }
        }
    }, [props.academyItems, props.platforms])

    const commandItems: ICommandBarItemProps[] = React.useMemo(() =>
        props.templateVersion === 'TEST' ? [
            {
                key: 'newSize',
                text: 'New',
                iconProps: { iconName: 'Add' },
                disabled: props.academyItems.some(x => x.academyID === 'new'),
                onClick: () => props.onCommandItemClick('new'),
            },
            {
                key: 'publish',
                text: 'Publish',
                iconProps: { iconName: 'PublishContent' },
                // disabled: !props.selectedItem,
                disabled: true,
                onClick: () => props.onCommandItemClick('publish'),
            },
            {
                key: 'delete',
                text: 'Delete',
                iconProps: { iconName: 'Delete' },
                disabled: !props.selectedId,
                onClick: () => props.onCommandItemClick('delete'),
            },
            {
                key: 'minimize',
                text: minimizedMode ? 'Expand' : 'Minimize',
                iconProps: { iconName: minimizedMode ? 'MaximumValue' : 'MinimumValue' },
                onClick: () => {
                    setMinimizedMode(!minimizedMode)
                    setUpdateUi(true)
                }
            }
        ] : [
            {
                key: 'delete',
                text: 'Delete',
                iconProps: { iconName: 'Delete' },
                disabled: !props.selectedId,
                onClick: () => props.onCommandItemClick('delete'),
            },
        ], [props.templateVersion, props.academyItems, props.selectedId, minimizedMode])

    return (
        <Stack className='Width100'>
            <span className='Indent20'><h3>Lessons list</h3></span>
            <CommandBar
                items={commandItems}
                ariaLabel="Use left and right arrow keys to navigate between commands"
            />
            {props.academyItems.length && !updateUi &&
                <Stack.Item
                    onClick={(_event) => {
                        console.log('onClick')
                        console.log(showEditModal)
                        if (props.isEdited) setShowEditModal(true)
                    }}
                >
                    <ShimmeredDetailsList
                        items={props.academyItems.map((item) => { return { ...item, key: item.academyID } })}
                        compact={true}
                        groups={groups}
                        columns={columns}
                        enableShimmer={!props.academyItems}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionPreservedOnEmptyClick={true}
                        selectionMode={props.isEdited ? SelectionMode.none : SelectionMode.single}
                        groupProps={groupProps}
                        selection={selection}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkboxVisibility={CheckboxVisibility.hidden}
                        onRenderDetailsHeader={()=> <></>}
                    // onActiveItemChanged={(item: AcademySimpleItem) => {
                    //     props.setSelectedId(item.academyID)
                    // }}
                    />
                </Stack.Item>
            }
            <DialogOkOnly
                callbackDismiss={() => setShowEditModal(false)}
                title='Lesson is being edited'
                description='Please save or cancel your changes before continuing'
                hidden={!showEditModal}
            />
        </Stack>
    )
}

export default AcademyList;