import { IBasePickerSuggestionsProps, IPersonaProps, NormalPeoplePicker, ValidationState } from "@fluentui/react";
import React from "react";


export const PeoplePickerGroups: React.FunctionComponent<{setSelectedGroups: (groups: IPersonaProps[]) => void, companyGroups: IPersonaProps[], selectedGroups: IPersonaProps[]}> = (props) => {
	
	const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState<IPersonaProps[]>(props.companyGroups);
	const [groupList, setGroupList] = React.useState<IPersonaProps[]>(props.companyGroups);

	// const examplePersona: IPersonaSharedProps = {
	// 	imageInitials: '',
	// 	text: '',
	// 	secondaryText: '',
	// };
  
	const picker = React.useRef(null);
  
	const suggestionProps: IBasePickerSuggestionsProps = {
		// suggestionsHeaderText: 'Suggested People',
		// mostRecentlyUsedHeaderText: 'Suggested Contacts',
		noResultsFoundText: 'No groups found',
		loadingText: 'Loading',
		// showRemoveButtons: true,
		// suggestionsAvailableAlertText: 'People Picker Suggestions available',
		// suggestionsContainerAriaLabel: 'Suggested contacts',
	  };
	  
	const onFilterChanged = (
	  filterText: string,
	  currentPersonas: IPersonaProps[],
	  limitResults?: number,
	): IPersonaProps[] | Promise<IPersonaProps[]> => {
	  if (filterText) {
		let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);
  
		filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
		filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
		return filteredPersonas;
	  } else {
		return [];
	  }
	};
  
	const filterPersonasByText = (filterText: string): IPersonaProps[] => {
	  return groupList.filter(item => doesTextStartWith(item.text as string, filterText));
	};
  
	// const filterPromise = (personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
	//   if (delayResults) {
	// 	return convertResultsToPromise(personasToReturn);
	//   } else {
	// 	return personasToReturn;
	//   }
	// };
  
	const returnMostRecentlyUsed = (currentPersonas: IPersonaProps[]): IPersonaProps[] => {
	  return removeDuplicates(mostRecentlyUsed, currentPersonas);
	};
  
	const onRemoveSuggestion = (item: IPersonaProps): void => {
	  const indexGroupList: number = groupList.indexOf(item);
	  const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);
  
	  if (indexGroupList >= 0) {
		const newGroups: IPersonaProps[] = groupList
		  .slice(0, indexGroupList)
		  .concat(groupList.slice(indexGroupList + 1));
		console.log(newGroups)
		setGroupList(newGroups);
	  }
  
	  if (indexMostRecentlyUsed >= 0) {
		const newSuggestedGroups: IPersonaProps[] = mostRecentlyUsed
		  .slice(0, indexMostRecentlyUsed)
		  .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
		setMostRecentlyUsed(newSuggestedGroups);
	  }
	};
  
	return (
	  <div>
		<NormalPeoplePicker
		  // eslint-disable-next-line react/jsx-no-bind
		//   onResolveSuggestions={onFilterChanged}
		 onResolveSuggestions={(filter: string ,selected: IPersonaProps[] | undefined) => onFilterChanged(filter, selected!)}
		
		  // eslint-disable-next-line react/jsx-no-bind
		//   onEmptyInputFocus={returnMostRecentlyUsed}
		onEmptyResolveSuggestions={(selected) => returnMostRecentlyUsed(selected!)}
		  getTextFromItem={getTextFromItem}
		  className={'ms-PeoplePicker'}
		  pickerSuggestionsProps={suggestionProps}
		//   defaultSelectedItems={props.selectedGroups}
		  selectedItems={props.selectedGroups}
		  key={'list'}
		  // eslint-disable-next-line react/jsx-no-bind
		  onRemoveSuggestion={onRemoveSuggestion}
		  onValidateInput={validateInput}
		  onChange={(items) => {props.setSelectedGroups(items!)}}
		  inputProps={{
			onBlur: (_ev: React.FocusEvent<HTMLInputElement>) => console.log('onBlur called'),
			onFocus: (_ev: React.FocusEvent<HTMLInputElement>) => console.log('onFocus called'),
			'aria-label': 'People Picker',
		  }}
		  componentRef={picker}
		  resolveDelay={300}
		/>
	  </div>
	);
  };
  
  function doesTextStartWith(text: string, filterText: string): boolean {
	return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
  }
  
  function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
	return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
  }
  
  function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
	if (!personas || !personas.length || personas.length === 0) {
	  return false;
	}
	return personas.filter(item => item.text === persona.text).length > 0;
  }
  
  function getTextFromItem(persona: IPersonaProps): string {
	return persona.text as string;
  }
  
  function validateInput(input: string): ValidationState {
	if (input.indexOf('@') !== -1) {
	  return ValidationState.valid;
	} else if (input.length > 1) {
	  return ValidationState.warning;
	} else {
	  return ValidationState.invalid;
	}
  }