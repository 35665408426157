
import * as React from "react"
import { CommandBar, DefaultButton, Dialog, DialogFooter, DialogType, ICommandBarItemProps, Icon, PrimaryButton, SelectionMode, TextField, TooltipHost } from "@fluentui/react"
import { DetailsList, DetailsListLayoutMode, Selection } from 'office-ui-fabric-react/lib/DetailsList';
import { Label, Stack } from "office-ui-fabric-react";
import { updatePictureLibraries, updatePictures } from "../../helpers/PostToRestApi";
import { ExampleLayoutList, PictureSrcLib } from "../../interfaces/PictureSrcLib";
import { PictureLibrary } from "../../interfaces/PictureLibrary";

export const DialogEditPictureSizes: React.FunctionComponent<{ setParentState: (x: any) => void, getLayoutItems: (r: boolean) => void, hideState: string, hidden: boolean, items: PictureSrcLib[], selectedIndex: number, accessToken: string, domain: string, api: string, isNew: boolean }> = (props) => {

	// const items = useConst<ISelection<IObjectWithKey>>(() => );

	// let selection = new Selection({onSelectionChanged: () => setSelection(this._selection)});

	//const [cboOptions, setCboOptions] = React.useState<IComboBoxOption[]>([]);
	const [hasItems, setHasItems] = React.useState(false);
	const [previewWidth, setPreviewWidth] = React.useState<number>(0);
	const [previewHeight, setPreviewHeight] = React.useState<number>(0);
	const [previewBigWidth, setPreviewBigWidth] = React.useState<number>(0);
	const [previewBigHeight, setPreviewBigHeight] = React.useState<number>(0);
	const [newSizeName, setNewSizeName] = React.useState<string>('');
	const [newCategoryName, setNewCategoryName] = React.useState<string>('');
	//PictureLibrary
	const [selectedItem, setSelectedItem] = React.useState<PictureLibrary>({} as PictureLibrary);
	// const [selectionDetails, setSelectionDetails] = React.useState<string>('');
	// const [selectionPath, setSelectionPath] = React.useState<string>('');
	// const [selectionPicCount, setSelectionPicCount] = React.useState<number>(0);
	const [libItems, setLibItems] = React.useState<PictureLibrary[]>([]); //props.items[props.selectedIndex]?.Libraries
	const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
	// const selection = useConst<IObjectWithKey[]>({} as IObjectWithKey[]);

	const [emptyName, setEmptyName] = React.useState<boolean>(false);
	const [emptyLibraries, setEmptyLibraries] = React.useState<boolean>(false);
	const [nonEmptyLibrary, setNonEmptyLibrary] = React.useState<boolean>(false);

	// const forceUpdate = useForceUpdate();
	// const selectionItems = useConst<PictureLibrary[]>(() => items);
	// const _selection: ISelection<PictureLibrary[]> = React.useMemo(() =>
	// 	new Selection({
	// 		onSelectionChanged: () => forceUpdate,
	// 		selectionItems
	// 	}),
	// 	[forceUpdate, selectionItems]
	// );

	const _selection = new Selection({
		// setNonEmptyLibrary(false)
		// 	setSelectedItem(item)
		// 	setSelectedIndex(index!)
		// 	setNewCategoryName(item.LibraryName)
		onSelectionChanged: () => {
			setNonEmptyLibrary(false)
			if (_selection.count) {
				const item: PictureLibrary = _selection.getSelection()[0] as PictureLibrary
				setSelectedItem(item)
				setSelectedIndex(_selection.getSelectedIndices()[0]);
				setNewCategoryName(item.LibraryName)
			}
			else {
				setSelectedItem({} as PictureLibrary)
				setSelectedIndex(-1)
			}
			console.log(_selection.getSelection())
			console.log(_selection.getSelectedIndices())
		}
	});

	const clearUseStates = () => {
		setHasItems(false)
		setNewSizeName('');
		setEmptyName(false)
		setEmptyLibraries(false)
		setNonEmptyLibrary(false)
	}

	if (props.hidden) {
		if (hasItems) { clearUseStates() }
		return (<></>)
	}

	if (!hasItems && typeof props.items[props.selectedIndex] !== 'undefined') {
		console.log(props.items[props.selectedIndex])
		setNewSizeName(props.items[props.selectedIndex]?.imgSrc)
		setLibItems(props.items[props.selectedIndex]?.Libraries)
		setPreviewWidth(props.items[props.selectedIndex]?.previewWidth)
		setPreviewHeight(props.items[props.selectedIndex]?.previewHeight)
		setPreviewBigWidth(props.items[props.selectedIndex]?.previewBigWidth)
		setPreviewBigHeight(props.items[props.selectedIndex]?.previewBigHeight)
		setHasItems(true)
	}

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: props.isNew ? 'New picture size' : 'Edit picture size',
		subText: props.isNew ? 'Add a new size with libraries' : `Edit the current size's libraries and previews`,
	};

	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 450 } },
	};

	const commandItemsNew: ICommandBarItemProps[] = [
		{
			key: 'add',
			text: 'Add',
			ariaLabel: 'Add',
			iconOnly: true,
			iconProps: { iconName: 'Add' },
			onClick: () => addCategory(),
		},
		{
			key: 'edit',
			text: 'Update Name',
			ariaLabel: 'Update Name',
			iconOnly: true,
			iconProps: { iconName: 'Edit' },
			onClick: () => editCategory(),
		},
	]

	const commandItems: ICommandBarItemProps[] = [
		{
			key: 'moveUp',
			text: 'Move Up',
			ariaLabel: 'Move Up',
			iconOnly: true,
			iconProps: { iconName: 'ChevronUp' },
			onClick: () => moveUp(),
		},
		{
			key: 'moveDown',
			text: 'Move Down',
			ariaLabel: 'Move Down',
			iconOnly: true,
			iconProps: { iconName: 'ChevronDown' },
			onClick: () => moveDown(),
		},

		{
			key: 'delete',
			text: 'Delete',
			ariaLabel: 'Delete',
			iconOnly: true,
			iconProps: { iconName: 'Delete' },
			onClick: () => deleteSelected(),
		},
	];

	// const onActiveItemChanged = (item?: PictureLibrary, index?: number | undefined): void => {
	// 	// console.log(item)
	// 	if (item) {
	// 		setNonEmptyLibrary(false)
	// 		setSelectedItem(item)
	// 		setSelectedIndex(index!)
	// 		setNewCategoryName(item.LibraryName)
	// 	}
	// }

	const moveUp = () => {
		console.log(selectedIndex)
		console.log(selectedItem)
		if (selectedIndex > 0) {
			setLibItems(() => {
				libItems.splice(selectedIndex, 1,)
				libItems.splice(selectedIndex - 1, 0, selectedItem)
				console.log(libItems)
				return libItems
			})
			// _selection.setAllSelected(true)
			setSelectedIndex(selectedIndex - 1)
		}
	}

	const moveDown = () => {
		console.log(_selection)
		console.log(selectedIndex)
		if (selectedIndex >= 0 && selectedIndex < libItems.length - 1) {
			setLibItems(() => {
				libItems.splice(selectedIndex, 1,)
				libItems.splice(selectedIndex + 1, 0, selectedItem)
				return libItems
			})
			// setSelectedIndex(selectedIndex+1)
			_selection.selectToIndex(selectedIndex + 1)
		}
	}

	const addCategory = () => {
		if (newCategoryName !== '') {
			if (typeof libItems.length !== 'undefined') {
				setLibItems(libItems.concat({ LibraryName: newCategoryName }))
			}
			else {
				setLibItems([{ LibraryName: newCategoryName }])
			}
		}
	}

	const editCategory = () => {
		if (selectedIndex > 0) {
			if (selectedItem.Path!) {
				setLibItems(libItems.map((item: PictureLibrary) => {
					return {
						...item,
						LibraryName: item.Path === selectedItem.Path ? newCategoryName : item.LibraryName
					}
				}))
			}
			else {
				if (newCategoryName !== '') {
					setLibItems(libItems.map((item: PictureLibrary) => {
						return {
							...item,
							LibraryName: item.LibraryName === selectedItem.LibraryName ? newCategoryName : item.LibraryName
						}
					}))
				}
			}
		}
	}

	const deleteSelected = () => {
		if (selectedIndex > 0) {
			if (selectedItem.PicturesCount! > 0) {
				setNonEmptyLibrary(true)
			}
			else {

				setLibItems(libItems.filter(function (value, _index, _arr) { return value.LibraryName !== selectedItem.LibraryName; }))
			}
		}
	}

	const updatePictureSizes = async (): Promise<void> => {
		var allitems: PictureSrcLib[] = props.items

		console.log(allitems)
		console.log(libItems)

		if (props.isNew) {
			allitems[props.selectedIndex] = {} as PictureSrcLib
		}
		allitems[props.selectedIndex].imgSrc = newSizeName
		allitems[props.selectedIndex].Libraries = libItems
		allitems[props.selectedIndex].previewWidth = previewWidth
		allitems[props.selectedIndex].previewHeight = previewHeight
		allitems[props.selectedIndex].previewBigWidth = previewBigWidth
		allitems[props.selectedIndex].previewBigHeight = previewBigHeight
		// console.log(allitems)
		updatePictureLibraries(props.accessToken, props.domain, props.api, JSON.stringify(allitems))
			.then(() => {
				updatePictures(props.accessToken, props.domain, props.api)
					.then(() => {
						props.setParentState({ [props.hideState]: true })
						props.getLayoutItems(false)
					})
			})
	}

	const columns = [
		{ key: 'column1', name: 'Name', fieldName: 'LibraryName', minWidth: 100, maxWidth: 300, isResizable: true },
		{ key: 'column2', name: '# Pictures', fieldName: 'PicturesCount', minWidth: 70, maxWidth: 300, isResizable: true },
	];

	console.log((props.items === ExampleLayoutList))
	console.log((props.items))
	console.log((ExampleLayoutList))

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.setParentState({ [props.hideState]: true })}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<Stack tokens={{ childrenGap: 5 }}>

				<TextField
					label='Size name'
					required={props.isNew}
					defaultValue={newSizeName}
					onChange={(_event, value) => { setNewSizeName(value!) }}
					errorMessage={emptyName ? 'Size must have a name' : ''}
				/>
				<Stack horizontal tokens={{ childrenGap: 5 }}>
					<TextField
						onRenderLabel={() => (
							<div>
								Preview height (px){' '}
								<TooltipHost content={<>Preview height is the height of the picture in the panel in pixels. Max 400 px</>}>
									<Icon iconName="Info" aria-label="Info tooltip" />
								</TooltipHost>
							</div>
						)}
						ariaLabel='Preview height (px)'
						defaultValue={previewHeight.toString()}
						onChange={(_event, value) => { setPreviewHeight(+value!) }}
						errorMessage={previewHeight < 0 ? 'Height must be set to a value' : previewHeight > 400 ? 'Height can´t be set over 400' : ''}
					/>
					<TextField
						onRenderLabel={() => (
							<div>
								Preview width (px){' '}
								<TooltipHost content={<>Preview width is the width of the picture in the panel in pixels. Max 275 px</>}>
									<Icon iconName="Info" aria-label="Info tooltip" />
								</TooltipHost>
							</div>
						)}
						ariaLabel='Preview width (px)'
						defaultValue={previewWidth.toString()}
						onChange={(_event, value) => { setPreviewWidth(+value!) }}
						errorMessage={previewWidth < 0 ? 'Width must be set to a value' : previewWidth > 275 ? 'Width can´t be set over 275' : ''}
					/>
				</Stack>
				<Stack horizontal tokens={{ childrenGap: 5 }}>
					<TextField
						onRenderLabel={() => (
							<div>
								Tooltip height (px){' '}
								<TooltipHost content={<>Tooltip height is the height of the picture when you hoover over a picture in the panel in pixels. Max 400 px</>}>
									<Icon iconName="Info" aria-label="Info tooltip" />
								</TooltipHost>
							</div>
						)}
						ariaLabel='Tooltip height (px)'
						defaultValue={previewBigHeight.toString()}
						onChange={(_event, value) => { setPreviewBigHeight(+value!) }}
						errorMessage={previewBigHeight < 0 ? 'Height must be set to a value' : previewBigHeight > 400 ? 'Height can´t be set over 400' : ''}
					/>
					<TextField
						onRenderLabel={() => (
							<div>
								Tooltip width (px){' '}
								<TooltipHost content={<>Tooltip width is the width of the picture when you hoover over a picture in the panel in pixels. Max 300 px</>}>
									<Icon iconName="Info" aria-label="Info tooltip" />
								</TooltipHost>
							</div>
						)}
						ariaLabel='Tooltip width (px)'
						defaultValue={previewBigWidth.toString()}
						onChange={(_event, value) => { setPreviewBigWidth(+value!) }}
						errorMessage={previewBigWidth < 0 ? 'Width must be set to a value' : previewBigWidth > 300 ? 'Width can´t be set over 300' : ''}
					/>
				</Stack>
				<Label>Categories</Label>
				<Stack horizontal >
					<TextField
						label='New:'
						underlined
						placeholder='Category name'
						value={newCategoryName}
						onChange={(_event, value) => setNewCategoryName(value!)}
						width='100%'
						errorMessage={emptyLibraries ? 'Must have at least one category' : nonEmptyLibrary ? 'Can only delete empty category' : ''}
					/>
					<CommandBar
						items={commandItemsNew}
						ariaLabel="Use left and right arrow keys to navigate between commands"
						styles={{ root: { margin: 0, padding: 0 } }}
					/>
				</Stack>
				<CommandBar
					items={commandItems}
					ariaLabel="Use left and right arrow keys to navigate between commands"
					styles={{ root: { margin: 0, padding: 0 } }}
				/>
				<DetailsList
					compact={true}
					items={libItems}
					columns={columns}
					isHeaderVisible={true}
					setKey="set"
					layoutMode={DetailsListLayoutMode.justified}
					selection={_selection}
					selectionPreservedOnEmptyClick={true}
					selectionMode={SelectionMode.single}
					ariaLabelForSelectionColumn="Toggle selection"
					ariaLabelForSelectAllCheckbox="Toggle selection for all items"
					checkButtonAriaLabel="Row checkbox"
				// onActiveItemChanged={onActiveItemChanged}
				/>
			</Stack>
			<DialogFooter>
				<PrimaryButton onClick={() => {
					setEmptyName(newSizeName === '')
					setEmptyLibraries(libItems.length === 0)
					// setEmptyPreviewWidth(previewWidth <= 0)
					// setEmptyPreviewHeight(previewHeight <= 0)
					// setEmptyPreviewBigWidth(previewBigWidth <= 0)
					// setEmptyPreviewBigHeight(previewBigHeight <= 0)

					console.log(newSizeName)
					console.log((libItems.length === 0))
					console.log(previewWidth)
					console.log(previewHeight)
					console.log(previewBigWidth)
					console.log(previewBigHeight)

					if (!((newSizeName === '') || (libItems.length === 0) || (previewWidth <= 0) || (previewHeight <= 0) || (previewBigWidth <= 0) || (previewBigHeight <= 0))) {
						clearUseStates();
						updatePictureSizes();
					}
					else {
						// console.log('123')
					};
				}}
					text="OK"
				/>
				<DefaultButton onClick={() => { clearUseStates(); props.setParentState({ [props.hideState]: true }) }} text="Cancel" />
			</DialogFooter>
		</Dialog>
	);
};