
import React from 'react';
import { Stack, Text } from '@fluentui/react';
import { GetUser } from '../../helpers/GetFromRestApi';
import DomainsList from './DomainsList';
import Axios, { CancelTokenSource } from 'axios';
import { DomainItem } from '../../interfaces/DomainItem';
import { UserAdminItem } from '../../interfaces/UserAdminItem';
import { ContentFrame } from '../common/ContentFrame';
import { MessageContextType } from '../../interfaces/ContextType';

interface DomainsProps {
	setMessage: (x: MessageContextType) => void;
	updateUserData(): void;
	userData: UserAdminItem;
	accessToken: string;
	domain: string;
	api: string;
}

interface DomainsState {
	domainItems: DomainItem[];
	fetching: boolean;
}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class Domains extends React.Component<DomainsProps, DomainsState> {
	constructor(props: DomainsProps) {
		super(props);
		this.state = {
			domainItems: [],
			fetching: false,
		};
		this.getUsers = this.getUsers.bind(this);
	}


	getUsers = async () => {
		this.setState({ fetching: true })
		ourRequest = Axios.CancelToken.source()
		let myDomains: DomainItem[] = (await GetUser(this.props.accessToken, undefined, ourRequest.token))?.Domains
		this.props.updateUserData()
		setTimeout(() => {
			this.setState({ domainItems: myDomains, fetching: false })
		}, 500)
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	componentDidUpdate(_prevProps: Readonly<DomainsProps>, _prevState: Readonly<DomainsState>, _snapshot?: any): void {

	}

	componentDidMount(): void {
		this.getUsers()
	}

	render() {

		// if (!hasValues(this.state.domainItems) && !this.state.fetching) {
		// 	this.setState({ fetching: true })
		// 	this.getUsers()
		// }

		return (
			<Stack >
				<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
					<Text variant="xxLarge">
						Domains
					</Text>
				</Stack.Item>
				<ContentFrame >
					<DomainsList
						setMessage={this.props.setMessage}
						updateUsers={this.getUsers}
						userData={this.props.userData}
						domains={this.state.domainItems}
						accessToken={this.props.accessToken}
						domain={this.props.domain}
						api={this.props.api}
					/>
				</ContentFrame>
			</Stack>
		)
	}
}