import * as React from "react"
import { Pivot, PivotItem, Stack } from "@fluentui/react"
import { ContentFrame } from "../common/ContentFrame"
import { CustomDataDefItem } from "../../interfaces/CustomDataDefItem"

export interface DataDefSelectorProps {
	onModeChange: (definitionMode: boolean) => void
	definitionMode: boolean
	CustomDataDefItem: CustomDataDefItem;
}

export const DataDefSelector: React.FunctionComponent<DataDefSelectorProps> = (props) => {
	// const fontIconClass = mergeStyles({ FontSize: '32px', height: 32, width:32 })
	console.log(props.CustomDataDefItem.LevelCounts)

	return (
		<ContentFrame>
			<>
				<Pivot
					className='Indent20'
					aria-label="Version Selector"
					onLinkClick={(item) => { props.onModeChange(item!.props.itemKey! === 'DEF') }}
					selectedKey={props.definitionMode ? 'DEF' : 'DATA'}
				>
					<PivotItem key='DATA' itemKey='DATA' headerText='Data' />
					<PivotItem key='DEF' itemKey='DEF' headerText='Definitions' />
				</Pivot>
				<Stack className='Indent20' >
					{props.definitionMode ?
						<>
							<p style={{ fontSize: '14px' }}> To update the definition of Custom Data press <b>Edit mode</b></p>
							<p style={{ fontSize: '14px' }}> It's possible to have a list of up to 3 levels.<br/><i>e.g. Countries, Companies, Departments</i></p>
							<p style={{ fontSize: '14px' }}> Each level contains it's own data fields<br/><i>e.g. Country: Name, Flag | Company: CompanyName, Logo | Department: DepartmentName, Phone</i></p>
							<p style={{ fontSize: '14px' }}> There are four different data types, <b>text</b> (single line text), <b>multiline</b> (text divided by linefee, recommended for addresses), <b>png</b> and <b>jpeg</b> (inserts an image, e.g Logo or Flag)</p>
							<p style={{ fontSize: '14px' }}> <b>Label item</b> is the field that will be displayed in the selection list, in 365|TemplateExtender addin. There should be one label item for each level and it has to be Data type <b>Text</b>.</p>
						</>
						:
						<>
							<p style={{ fontSize: '14px' }}> You can add <b>new</b>, <b>edit</b> and <b>delete</b> data items in the list below.</p>
							{props.CustomDataDefItem.LevelCounts > 1 && <p style={{ fontSize: '14px' }}> By selecting an item you can change it's subitems. It's not mandatory to create subitems for each item.</p>}
							<p style={{ fontSize: '14px' }}> It's possible to change the structure and field names by clicking <b>Definitions</b>.</p>
						</>
					}
				</Stack>
			</>
		</ContentFrame>
	)
}