import * as React from "react"
import { Stack } from "@fluentui/react"

export const CenterComponent: React.FunctionComponent = (props) => {

    return (
        <Stack horizontalAlign='center' verticalAlign='center' styles={{ root: { height: '100%' } }}>
            {props.children}
        </Stack>
    )
}