import * as React from 'react';
import { Image, getTheme, ImageFit } from '@fluentui/react';
import SettingsCompany from '../../helpers/SettingsCompany';

const theme = getTheme();

export interface HeaderPreviewProps {
	settings: SettingsCompany;
	title: string;
	logo: string;
}

export interface HeaderPreviewState {

}

export default class HeaderPreview extends React.Component<HeaderPreviewProps, HeaderPreviewState> {

    render() {
        
        const {
			settings: companySettings,
			logo,
			title,
		} = this.props;

        return (
			<div style={{minWidth: 250}}>
				<section style={{backgroundColor: theme.palette.neutralLighter}} className='ms-welcome__header ms-u-fadeIn500'>
					{logo !== '' && !companySettings.hideLogo ? 
						<Image imageFit={ImageFit.contain} height='80px' src= {"data:image/png;base64," + logo} alt={title} title={title} />
					: null}                
					{!companySettings.hideCaption && title ? 
						<h1 className='ms-fontSize-xl ms-fontWeight-semilight ms-fontColor-neutralPrimary'>
							{title}
						</h1>
					: null}  						
				</section>		
			</div>
			
        );
    }
}
