export interface GroupDetails {
	id: string;
	displayName: string;
	description: string;
	resourceProvisioningOptions: string[];
	createdDateTime: Date | null;
	visibility: string;
	securityEnabled: boolean;
	mail: string | null;
}

export const exampleGroupDetails: GroupDetails[] = [{
	id: '',
	displayName: 'No groups',
	description: '',
	resourceProvisioningOptions: [''],
	createdDateTime: null,
	visibility: '',
	securityEnabled: false,
	mail: null,
}]

export const connectionFailedGroupDetails: GroupDetails[] = [{
	id: '',
	displayName: 'Connection to Graph failed',
	description: '',
	resourceProvisioningOptions: [''],
	createdDateTime: null,
	visibility: '',
	securityEnabled: false,
	mail: null,
}]