import * as React from 'react';
import { SectionsDocument, SectionsItem } from '../../interfaces/SectionsDocument';
import { Checkbox, CommandBar, ICommandBarItemProps, Separator, Stack, Toggle } from '@fluentui/react';

export interface SectionsDetailsProps {
    onSave: (item: SectionsDocument) => void;
    selectedItem: SectionsDocument;
}

const getStyle = (level: number) => {
    switch (level) {
        case 1:
            return { fontSize: '1.1em', fontWeight: 'bold' }
        case 2:
            return { fontSize: '1.1em', fontWeight: 'normal' }
        case 3:
            return { fontSize: '0.9em', fontWeight: 'bold' }
        case 4:
            return { fontSize: '0.9em', fontWeight: 'normal' }
        case 5:
            return { fontSize: '0.9em', fontWeight: 'italic' }
        case 6:
            return { fontSize: '0.8em', fontWeight: 'normal' }
        case 7:
            return { fontSize: '0.8em', fontWeight: 'italic' }
        case 8:
            return { fontSize: '0.7em', fontWeight: 'normal' }
        case 9:
            return { fontSize: '0.7em', fontWeight: 'italic' }
        default:
            return { fontSize: '0.7em', fontStyle: 'italic' }
    }
}

const HeadingItem: React.FC<{ item: SectionsItem, index: number, hideIndex: boolean, checkAll: boolean }> = ({ item, index, hideIndex, checkAll }) => {
    const style = getStyle(item.HeadingLevel);
    return (
        <p key={index} style={style}>
            <div style={{ display: 'inline-block', width: '32px' }}><Checkbox checked={checkAll} disabled /></div>
            {!hideIndex && <div style={{ display: 'inline-block', width: '70px' }}>{item.HeadingIndex}</div>}
            <div style={{ display: 'inline-block' }}>{item.HeadingTitle}</div>
        </p>
    );
};



const SectionsDetails: React.FC<SectionsDetailsProps> = ({ onSave, selectedItem }) => {

    const [checkAll, setCheckAll] = React.useState<boolean>(selectedItem.CheckAll);
    const [hideIndex, setHideIndex] = React.useState<boolean>(selectedItem.HideIndex);
    const [ignoreHierarchy, setIgnoreHierarchy] = React.useState<boolean>(selectedItem.IgnoreHierarchy);
    const [usedHeadingLevels, setUsedHeadingLevels] = React.useState<number[]>(selectedItem.UsedHeadingLevels);

    React.useEffect(() => {
        setCheckAll(selectedItem.CheckAll);
        setHideIndex(selectedItem.HideIndex);
        setIgnoreHierarchy(selectedItem.IgnoreHierarchy);
        setUsedHeadingLevels(selectedItem.UsedHeadingLevels);
    }, [selectedItem]);

    const isDirty: boolean = hideIndex !== selectedItem.HideIndex || ignoreHierarchy !== selectedItem.IgnoreHierarchy || checkAll !== selectedItem.CheckAll || usedHeadingLevels !== selectedItem.UsedHeadingLevels;

    const commandItems = (): ICommandBarItemProps[] => {
        return [
            {
                key: 'save',
                text: 'Save',
                disabled: !isDirty,
                iconProps: { iconName: 'Save' },
                onClick: () => { 
                    onSave({ ...selectedItem, CheckAll: checkAll, HideIndex: hideIndex, IgnoreHierarchy: ignoreHierarchy, UsedHeadingLevels: usedHeadingLevels })
                 }
            },
            {
                key: 'reset',
                text: 'Reset',
                disabled: !isDirty,
                iconProps: { iconName: 'Undo' },
                onClick: () => { 
                    setCheckAll(selectedItem.CheckAll);
                    setHideIndex(selectedItem.HideIndex);
                    setIgnoreHierarchy(selectedItem.IgnoreHierarchy);
                    setUsedHeadingLevels(selectedItem.UsedHeadingLevels);
                 },
            },
        ];
    };

    return (
        <Stack className="Width100">
            <span className="Indent20"><h3>Sections in {selectedItem.DocName}</h3></span>
            <Separator />
            <CommandBar
                items={commandItems()}
                ariaLabel="Use left and right arrow keys to navigate between commands"
            />
            <div className="Indent20">
                <Toggle
                    label="Check all"
                    inlineLabel
                    onText="On"
                    offText="Off"
                    checked={checkAll}
                    onClick={() => { setCheckAll(!checkAll) }}
                />
                <Toggle
                    label="Hide index"
                    inlineLabel
                    onText="On"
                    offText="Off"
                    checked={hideIndex}
                    onClick={() => { setHideIndex(!hideIndex) }}
                />
                <Toggle
                    label="Ignore hierarchy"
                    inlineLabel
                    onText="On"
                    offText="Off"
                    checked={ignoreHierarchy}
                    onClick={() => { setIgnoreHierarchy(!ignoreHierarchy) }}
                />
                <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
                    {usedHeadingLevels && Array.from({ length: selectedItem.MaxHeadingLevel || 9 }, (_, i) => i + 1).map((item) => (
                        <Checkbox
                            key={item}
                            label={`Level ${item}`}
                            checked={usedHeadingLevels.includes(item)}
                            onChange={(_ev, checked) => {
                                if (checked) { setUsedHeadingLevels([...usedHeadingLevels, item]); }
                                else { setUsedHeadingLevels(usedHeadingLevels.filter((i) => i !== item)); }
                            }} />
                    ))
                    }
                </Stack>
            </div>
            <Separator />
            <div className="Indent20">
                {selectedItem.HeadingItems?.map((item, index) => (
                    <HeadingItem
                        key={index}
                        item={item}
                        index={index}
                        hideIndex={hideIndex}
                        checkAll={checkAll}
                    />
                ))}
            </div>
        </Stack>
    );
};

export default SectionsDetails;
