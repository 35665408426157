import * as React from 'react';
import { useMsal } from '@azure/msal-react';
import { checkValidToken } from '../helpers/LogginHelper';

interface AuthContextType {
  handleLogin: (scopes?: string[]) => Promise<string>;
  handleLogout: () => Promise<void>;
  refreshToken: () => Promise<void>;
  scopes: string[];
  setScopes: React.Dispatch<React.SetStateAction<string[]>>;
  accessToken: string | null;
  isAuthenticated: boolean;
  isSigningIn: boolean;
}

const TOKEN_RENEWAL_INTERVAL = 60000;

export const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { instance } = useMsal();
  const [accessToken, setAccessToken] = React.useState<string | null>(localStorage.getItem('accessToken') || null);
  const [scopes, setScopes] = React.useState<string[]>(JSON.parse(localStorage.getItem('graphScope') || '["User.Read", "Organization.Read.All"]'));
  const [isSigningIn, setIsSigningIn] = React.useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(checkValidToken(accessToken!));

  const refreshToken = React.useCallback(async () => {
    if (instance.getAllAccounts().length > 0) {
      try {
        const response = await instance.acquireTokenSilent({
          account: instance.getAllAccounts()[0],
          scopes: scopes,
        });
        setAccessToken(response.accessToken);
      } catch (error) {
        clearOnError(error);
      } finally {
        setIsSigningIn(false);
      }
    } else {
      clearOnError('No accounts found');
    }
  }, [instance, scopes]);

  React.useEffect(() => {
    setIsAuthenticated(checkValidToken(accessToken!));
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    } else {
      localStorage.removeItem('accessToken');
    }
    localStorage.setItem('graphScope', JSON.stringify(scopes));
  }, [accessToken, scopes]);

  React.useEffect(() => {
    if (!isAuthenticated && !isSigningIn && instance.getAllAccounts().length > 0) {
      setIsSigningIn(true);
      refreshToken().finally(() => setIsSigningIn(false));
    }
  }, [isAuthenticated, isSigningIn, instance]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (checkValidToken(accessToken!)) {
        refreshToken();
      }
    }, TOKEN_RENEWAL_INTERVAL);

    return () => clearInterval(interval);
  }, [accessToken, refreshToken]);

  

  const clearOnError = (error: any) => {
    console.error(error);
    localStorage.clear();
    setAccessToken(null);
    setIsSigningIn(false);
    setIsAuthenticated(false);
  };

  const handleLogin = (newScopes: string[] = scopes): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      setIsSigningIn(true);
      try {
        const response = await instance.loginPopup({
          scopes: newScopes,
          prompt: "select_account",
        });
        setAccessToken(response.accessToken);
        setScopes(response.scopes);
        setIsAuthenticated(true);
        setIsSigningIn(false);
        resolve(response.accessToken);
      } catch (error) {
        setIsSigningIn(false);
        clearOnError(error);
        reject('');
      }
    });
  };

  const handleLogout = async () => {
    localStorage.clear();
    setAccessToken(null);
    setIsSigningIn(true);
    await instance.logoutPopup();
    setIsSigningIn(false);
  };

  return (
    <AuthContext.Provider value={{ accessToken, handleLogin, handleLogout, refreshToken, scopes, setScopes, isAuthenticated, isSigningIn }}>
      {children}
    </AuthContext.Provider>
  );
};
