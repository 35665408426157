import * as React from "react"
import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IDropdownOption, PrimaryButton, TextField } from "@fluentui/react"
import { GraphContext, TenantContext } from "../../contexts/ContextWrapper";

export interface DialogNewRedirectProps {
	callbackOk: (domainName: string) => void,
	callbackAbort: () => void,
	hidden: boolean,
}

export const DialogNewRedirect: React.FunctionComponent<DialogNewRedirectProps> = (props) => {

	// const [hasItems, setHasItems] = React.useState(false);
	const [doaminName, setDomainName] = React.useState<string>('');

	const { tenantContext } = React.useContext(TenantContext)
	const { graphContext } = React.useContext(GraphContext)

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: 'Add new redirect',
		// subText: '',
	};

	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 600 } },
	};

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.callbackAbort()}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			{(graphContext!?.verifiedDomain!?.length > 0 &&
				graphContext!?.verifiedDomain!?.length < 50 &&
				tenantContext.MyTenant) ?
				<Dropdown
					label='Domain name'
					options={graphContext!?.verifiedDomain.map((domain) => ({ key: domain.name, text: domain.name } as IDropdownOption))}
					onChange={(_event, option) => setDomainName(option!.key.toString())}
				/>
				:
				<TextField
					label='Domain name'
					value={doaminName}
					onChange={(_event, value) => setDomainName(value!)}
				/>
			}

			<DialogFooter>
				<PrimaryButton onClick={() => props.callbackOk(doaminName)} text="Create" />
				<DefaultButton onClick={() => props.callbackAbort()} text="Cancel" />
			</DialogFooter>
		</Dialog>
	);
};
