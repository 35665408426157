import * as React from "react"
import { Checkbox, DefaultButton, Dialog, DialogFooter, DialogType, Label, PrimaryButton, Stack, Text, Toggle } from "@fluentui/react"
import { UploadFile } from "../../interfaces/UploadFile";
import { toBase64 } from "../../helpers/Base64";
import { TenantContext } from "../../contexts/ContextWrapper";
import { AuthContext } from "../../contexts/AuthContext";
import { uploadSectionsDocument } from "../../helpers/PostToRestApi";
import { GetSectionsCheckAll, GetSectionsHeadingLevels, GetSectionsHideIndex, GetSectionsIgnoreHierarchy } from "../../helpers/SettingsCompany";

export interface DialogUploadSectionsProps {
	callbackOk: () => void,
	callbackAbort: () => void,
	hidden: boolean,
}

interface HeadingLevel {
	level: number,
	visible: boolean,
}

const acceptExtensions: string = ".docx"

export const DialogUploadSections: React.FunctionComponent<DialogUploadSectionsProps> = (props) => {

	// const [hasItems, setHasItems] = React.useState(false);
	// const [headingLevels, setHeadingLevels] = React.useState<HeadingLevel[]>([{ level: 1, visible: true }, { level: 2, visible: true }, { level: 3, visible: true }, { level: 4, visible: true }]);
	const { accessToken } = React.useContext(AuthContext)
	const { tenantContext } = React.useContext(TenantContext)

	const [uploadFiles, setUploadFiles] = React.useState<UploadFile[]>([]);

	const [headingLevels, setHeadingLevels] = React.useState<HeadingLevel[]>([1, 2, 3, 4, 5, 6, 7, 8, 9].map(item => { return { level: item, visible: GetSectionsHeadingLevels(tenantContext.settings).includes(item) } }));
	const [checkAll, setCheckAll] = React.useState<boolean>(GetSectionsCheckAll(tenantContext.settings));
	const [hideIndex, setHideIndex] = React.useState<boolean>(GetSectionsHideIndex(tenantContext.settings));
	const [ignoreHierarchy, setIgnoreHierarchy] = React.useState<boolean>(GetSectionsIgnoreHierarchy(tenantContext.settings));

	const [noInput, setNoInput] = React.useState<boolean>(false);



	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: 'Upload new sections document',
		// subText: '',
	};

	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 600 } },
	};

	const onChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {

		await Promise.all(Array.from(event.target.files!).map(async (item) => {
			try {
				let res = (await toBase64(item))
				let data = ''
				if (typeof res === 'string') { data = (res.split(',')[1]) }
				return ({ filename: item.name, data: data, path: '' })
			} catch (error) {
				return ({ filename: '', data: '', path: '\\' })
			}
		}))
			.then((res: UploadFile[]) => {
				setUploadFiles(res)
				setNoInput(false)
			})
			.catch(() => {
				setUploadFiles([]);
			})
	}

	const UploadDocument = async () => {
		uploadSectionsDocument(
			accessToken!,
			uploadFiles[0].filename,
			tenantContext.tenantName,
			tenantContext.api,
			uploadFiles[0].data,
			'test',
			headingLevels.filter(item => item.visible).map(item => item.level).join(','),
			checkAll,
			hideIndex,
			ignoreHierarchy,
		)
			.then((res) => {
				if (res) {
					props.callbackOk()
				}
				else {
					props.callbackAbort()
				}
			})
			.catch(() => {
				props.callbackAbort()
			})
	}

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.callbackAbort()}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<Stack tokens={{ childrenGap: '5px' }}>
				<Stack.Item>
					<input title="Browse..." type="file" name="file" accept={acceptExtensions} onChange={(event) => { onChangeHandler(event) }} />
					{noInput ? <p><Text variant='small' style={{ color: '#a4262c' }}>Nothing selected!</Text></p> : null}
				</Stack.Item>
				<Stack.Item>
					<Toggle
						label={`Check all`}
						inlineLabel
						onText="On"
						offText="Off"
						checked={checkAll}
						onClick={() => { setCheckAll(!checkAll) }}
					/>
					<Toggle
						label="Hide index"
						inlineLabel
						onText="On"
						offText="Off"
						checked={hideIndex}
						onClick={() => { setHideIndex(!hideIndex) }}
					/>
					<Toggle
						label="Ignore hierarchy"
						inlineLabel
						onText="On"
						offText="Off"
						checked={ignoreHierarchy}
						onClick={() => { setIgnoreHierarchy(!ignoreHierarchy) }}
					/>
				</Stack.Item>
				<Label>Paragraph outline levels</Label>
				{headingLevels.map((item, index) => {
					return (
						<Checkbox
							key={item.level}
							label={`Level ${item.level}`}
							checked={item.visible}
							onChange={(_ev, checked) => {
								let newLevels = [...headingLevels]
								newLevels[index].visible = checked!
								setHeadingLevels(newLevels)
							}}
						/>
					)
				})}
			</Stack>
			<DialogFooter>
				<PrimaryButton onClick={() => {
					if (uploadFiles.length > 0) {
						UploadDocument()
					}
					else { setNoInput(true) }
				}} text="Create" />
				<DefaultButton onClick={() => props.callbackAbort()} text="Cancel" />
			</DialogFooter>
		</Dialog>
	);
};
