import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MessageContext, TenantContext, UserContext } from './contexts/ContextWrapper';
import Header from './components/header/Header';
import Login from './Login';
import Body from './Body';
import { IStackStyles, MessageBar, MessageBarType, Stack } from '@fluentui/react';
import Selections from './components/header/Selections';
import Home from './Home';
import LoadingSession from './components/common/LoadingSession';
import './App.css';
import { AuthContext } from './contexts/AuthContext';
// import { useSession } from './hooks/useSession';

const App: React.FC = () => {
	const { setTenantContext } = React.useContext(TenantContext);
	const { messageContext, setMessageContext } = React.useContext(MessageContext);
	const { userContext } = React.useContext(UserContext);

	const { handleLogout, isAuthenticated, isSigningIn } = React.useContext(AuthContext);

	const logout = React.useCallback(() => {
		handleLogout().then(() => {
			setTenantContext((prev) => ({ ...prev, tenantName: '', api: '', apiUri: '' }));
			setMessageContext({ message: 'Logged out', messageType: MessageBarType.info, visible: true });
			localStorage.clear();
		});
	}, [handleLogout, setTenantContext, setMessageContext]);

	const dismissMessage = React.useCallback(() => {
		setMessageContext({ message: '', messageType: MessageBarType.blocked, visible: false });
	}, [setMessageContext]);

	const headerStyles: IStackStyles = {
		root: { height: '100vh', maxHeight: '100vh', overflow: 'hidden' }
	};

	if (isSigningIn) {
		return <LoadingSession logout={logout} />;
	}

	return (
		<Router>
			<Stack styles={headerStyles}>
				<Header logout={logout} loggedIn={isAuthenticated} />
				{userContext.userData && <Selections loggedIn={isAuthenticated} />}
				{messageContext.visible && messageContext.message !== '' && (
					<MessageBar
						messageBarType={messageContext.messageType}
						isMultiline
						onDismiss={dismissMessage}
						dismissButtonAriaLabel='Close'
					>
						{messageContext.message}
					</MessageBar>
				)}
				<Stack.Item grow>
					<Routes>
						<Route index element={<Home />} />
						<Route path="/login" element={<Login />} />
						<Route path="/:api/:domain/*" element={<Body />} />
						<Route path="/*" element={<Home />} />
					</Routes>
				</Stack.Item>
			</Stack>
		</Router>
	);
}

export default App;
