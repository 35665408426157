import React, { useEffect, useState } from 'react';
import { Stack, Text } from '@fluentui/react';
import { ContentFrame } from '../common/ContentFrame';
import Axios, { CancelTokenSource } from 'axios';
import { TenantContext } from '../../contexts/ContextWrapper';
import { GetLoginStatistics } from '../../helpers/GetFromRestApi';
import { StatisticMonthLogInItem } from '../../interfaces/Statisticd';
import StatisticsBarChart from '../statistics/StatisticsBarChart';
import ExtensionsHome from './ExtensionsHome';
import FunctionsHome from './FunctionsHome';
import NewsHome from './NewsHome';
import { AuthContext } from '../../contexts/AuthContext';

let ourRequest: CancelTokenSource | undefined;

const HomeTenant: React.FC = () => {

	const [loginItems, setLoginItems] = useState<StatisticMonthLogInItem[]>([]);

	const { tenantContext } = React.useContext(TenantContext);
	const { accessToken } = React.useContext(AuthContext);



	useEffect(() => {
		let mounted = true;
		const getLoginStatistics = async () => {
			if (ourRequest) {
				ourRequest.cancel();
			}
			ourRequest = Axios.CancelToken.source();

			try {
				if (accessToken && tenantContext.tenantName && tenantContext.api) {
					const loginItems = await GetLoginStatistics(
						accessToken!,
						tenantContext.tenantName,
						tenantContext.api,
						ourRequest.token
					);
					if (!mounted) return;
					setLoginItems(loginItems || []);
				}
			} catch (error) {

				// Handle the error appropriately
			}
		};
		getLoginStatistics();
		return () => {
			mounted = false;
		};
	}, [accessToken!, tenantContext.tenantName, tenantContext.api]);

	if (!accessToken || !tenantContext.tenantName || !tenantContext.api) {
		return null
	}

	return (
		<Stack >
			<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
				<Text variant="xxLarge">
					Home
				</Text>
			</Stack.Item>
			<ContentFrame>
				<Stack>
					<Stack horizontal wrap>
						<Stack.Item styles={{ root: { padding: 20 } }}>
							<Stack tokens={{ childrenGap: '5px' }}>
								<Text variant="xLarge">News</Text>
								<NewsHome />
							</Stack>
						</Stack.Item>
						{loginItems!?.length > 0 && <Stack.Item styles={{ root: { padding: 20, maxHeight: 350, maxWidth: 340 } }}>
							<Stack tokens={{ childrenGap: '5px' }}>
								<Text variant="xLarge">Monthly unique logins</Text>
								<StatisticsBarChart
									title=""
									plotItems={loginItems
										.slice(0, 5)
										.reverse()
										.map((item) => ({
											x: item.Month + ' ' + item.Year,
											y: item.UniqueLogIn,
										}))}
									horizontal={true}
								/>
							</Stack>
						</Stack.Item>}
						{/* <Stack.Item styles={{ root: { padding: 20, maxHeight: 350, maxWidth: 340 } }}>
							<Stack tokens={{ childrenGap: '5px' }}>
								<Text variant="xLarge">Documents</Text>
								<Documents />
							</Stack>
						</Stack.Item> */}
					</Stack>
					<Stack.Item styles={{ root: { padding: 20, maxHeight: 350 } }}>
						<Stack tokens={{ childrenGap: '5px' }}>
							<Text variant="xLarge">Available extensions</Text>
							<ExtensionsHome />
						</Stack>
					</Stack.Item>
					<Stack.Item styles={{ root: { padding: 20, maxHeight: 350 } }}>
						<Stack tokens={{ childrenGap: '5px' }}>
							<Text variant="xLarge">Available functions</Text>
							<FunctionsHome />
						</Stack>
					</Stack.Item>
				</Stack>
			</ContentFrame>
		</Stack>
	)
}

export default HomeTenant;