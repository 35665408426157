import * as React from "react"
import { DocumentCard, DocumentCardPreview, DocumentCardTitle, IconButton, Stack } from "@fluentui/react"
import Axios, { CancelTokenSource } from "axios";
import { GetWordContentById } from "../../helpers/GetFromRestApi";
import { ContentItem } from "../../interfaces/ContentItem";

export interface WordContentCardProps {
	onButtonClick(key: string, selectedItem: ContentItem): void
	accessToken: string
	// apiUri: string
	domain: string
	api: string
	id: string
	templateVersion: string
}

export interface WordContentCardState {
	content: ContentItem
}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class WordContentCard extends React.Component<WordContentCardProps, WordContentCardState> {

	constructor(props: WordContentCardProps) {
		super(props);
		this.state = {
			content: { ContentID: '', Base64: '', ContentName: '' } as ContentItem
		};
	}

	getContent = async () => {
		ourRequest = Axios.CancelToken.source()
		const myContent: ContentItem = await GetWordContentById(this.props.accessToken, this.props.domain, this.props.api, this.props.id, this.props.templateVersion, ourRequest.token) || null
		this.setState({ content: myContent })
	}

	componentDidUpdate(prevProps: Readonly<WordContentCardProps>, _prevState: Readonly<WordContentCardState>, _snapshot?: any): void {
		if (prevProps.id !== this.props.id ||
			prevProps.accessToken !== this.props.accessToken ||
			prevProps.domain !== this.props.domain ||
			prevProps.api !== this.props.api) {
			this.getContent()
		}
	}

	componentDidMount(): void {
		this.getContent()
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	render(): JSX.Element {
		if (this.state.content && this.state.content!.ContentID) {
			return (
				<DocumentCard>
					<DocumentCardPreview
						previewImages={[
							{
								name: this.state.content.ContentName,
								previewImageSrc: 'data:image/png;base64,' + this.state.content.Base64,
								// iconSrc: TestImages.iconPpt,
								// imageFit: ImageFit.cover,
								width: 270,
								height: 270,
							},
						]}
					/>
					<DocumentCardTitle
						title={this.state.content.ContentName}
					/>
					<Stack horizontal>
						{this.props.templateVersion === 'TEST' &&
							<IconButton
								aria-label='Edit content'
								iconProps={{ iconName: 'Edit' }}
								onClick={() => this.props.onButtonClick('edit', this.state.content)}
							/>}
						<IconButton
							aria-label='Remove content'
							iconProps={{ iconName: 'Delete' }}
							onClick={() => this.props.onButtonClick('delete', this.state.content)}
						/>
						{this.props.templateVersion === 'TEST' &&
							<IconButton
								aria-label='Publish content'
								iconProps={{ iconName: 'PublishContent' }}
								onClick={() => this.props.onButtonClick('publish', this.state.content)}
							/>}
					</Stack>
				</DocumentCard>
			)
		}
		else {
			return (
				<div style={{ width: 272, height: 359 }}>
					&nbsp;
				</div>
			)
		}
	}
}