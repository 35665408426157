import * as React from 'react';
import {
	mergeStyleSets,
	SelectionMode,
	ShimmeredDetailsList,
	IColumn,
	CheckboxVisibility,
	Stack,
	Icon,
} from '@fluentui/react';
import { DetailsListLayoutMode, } from 'office-ui-fabric-react/lib/DetailsList';
import { sortArray } from '../../helpers/MiscFunctions';
// import { AppState } from '../../App';
import Axios, { CancelTokenSource } from 'axios';
// import SettingsCompany from '../../helpers/SettingsCompany';
import { GetTemplatesList } from '../../helpers/GetFromRestApi';
import { GroupDetails } from '../../interfaces/GroupDetails';
import { exampleTemplateList, TemplateListItem } from '../../interfaces/TemplateListItem';
import { TemplateApiHeaders } from '../../interfaces/TemplateApiHeaders';
import { ExtendedTemplateListItem } from '../../interfaces/ExtendedTemplateListItem';
import { GroupID } from '../../interfaces/GroupID';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';

export interface GroupTemplatesProps {
	// setAppState: (x: AppState) => void;
	// getTemplates(path: string, library: string, version: string): void;
	// path: string;
	// library: string;
	templateVersion: string;
	// templateItems: TemplateListItem[];
	// folderItems: TemplateFolderItem[];
	// groupIds: GroupInfo[];
	// folderGroupIds: GroupID[] | undefined;
	// companySettings: SettingsCompany;
	accessToken: string,
	// apiUri: string,
	domain: string,
	api: string,
	selectedGroup: GroupDetails,
}

export interface GroupTemplatesState {
	items: TemplateListItem[];
	columns: IColumn[];
}

const classNames = mergeStyleSets({
	fileIconHeaderIcon: {
		padding: 0,
		fontSize: '16px',
	},
	fileIconCell: {
		textAlign: 'center',
		selectors: {
			'&:before': {
				content: '.',
				display: 'inline-block',
				verticalAlign: 'middle',
				height: '100%',
				width: '0px',
				visibility: 'hidden',
			},
		},
	},
	fileIconImg: {
		verticalAlign: 'middle',
		maxHeight: '16px',
		maxWidth: '16px',
	},
	controlWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	exampleToggle: {
		display: 'inline-block',
		marginBottom: '10px',
		marginRight: '30px',
	},
	selectionDetails: {
		marginBottom: '20px',
	},
});

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class GroupTemplates extends React.Component<GroupTemplatesProps, GroupTemplatesState> {
	// private _selection: Selection;

	constructor(props: GroupTemplatesProps) {
		super(props);
		// this._selection = new Selection({
		// 	onSelectionChanged: () => this.setState({ selection: this._selection }),
		// });
		this.state = {
			items: [],
			columns: [{ key: 'column1', name: '', minWidth: 16, }],
		};
	}

	getColums(): IColumn[] {
		return [
			{
				key: 'column1',
				name: 'Icon',
				fieldName: 'icon',
				className: classNames.fileIconCell,
				iconClassName: classNames.fileIconHeaderIcon,
				ariaLabel: 'Column operations for File type, Press to sort on File type',
				iconName: 'Page',
				isIconOnly: true,
				minWidth: 16,
				maxWidth: 16,
				isResizable: false,
				onColumnClick: this._onColumnClick,
				onRender: (item: ExtendedTemplateListItem) => { return <Icon {...getFileTypeIconProps({ extension: item.fileExt, size: 20 })} /> },
			},
			{ key: 'column2', name: 'Name', fieldName: 'primaryText', minWidth: 120, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick, },
			{ key: 'column3', name: 'Path', fieldName: 'Library', minWidth: 120, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick, },
			{ key: 'column4', name: 'Type', fieldName: 'icon', minWidth: 140, maxWidth: 300, isResizable: true, isSorted: true, isSortedDescending: false, onColumnClick: this._onColumnClick, },
			{ key: 'column5', name: 'File size', fieldName: 'fileSizeNum', minWidth: 80, maxWidth: 100, isResizable: true, onColumnClick: this._onColumnClick, onRender: (item: ExtendedTemplateListItem) => { return <span>{item.fileSize}</span>; }, },
			{ key: 'column6', name: 'Upload date', fieldName: 'modifiedDate', minWidth: 120, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick, },
			{ key: 'column7', name: 'Publish date', fieldName: 'publishDate', minWidth: 120, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick, },
		]
	}

	getGroupTemplates = async () => {
		ourRequest = Axios.CancelToken.source()
		const templateHeaders: TemplateApiHeaders = {
			templateVersion: this.props.templateVersion,
			templateEnviron: '',
			templateType: '',
			myGroups: JSON.stringify([{ groupID: this.props.selectedGroup.id } as GroupID]),
			groupSelection: 'ONLY-GROUP',
			showPath: 'SHOW-PATH',
		}
		let allTemplates: TemplateListItem[] = await GetTemplatesList(this.props.accessToken!, this.props.domain, this.props.api, templateHeaders, ourRequest.token)
		// console.log(allTemplates)
		if (allTemplates) { allTemplates = exampleTemplateList }
		// console.log(allTemplates)
		this.setState({
			columns: this.getColums(),
			items: allTemplates && sortArray(allTemplates?.map((item: TemplateListItem): ExtendedTemplateListItem => {
				let fileExt: string
				let mimetype: string
				switch (item.icon) {
					case 'ExcelWorkbook':
						fileExt = 'xlsx'
						mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						break;
					case 'PowerPointPresentation':
						fileExt = 'pptx'
						mimetype = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
						break;
					case 'WordDocument':
						fileExt = 'docx'
						mimetype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
						break;
					default:
						fileExt = 'txt'
						mimetype = ''
						break;
				}
				return (
					{
						...item,
						fileExt: fileExt,
						mimetype: mimetype
					}
				)
			}),
				'type',
				false),
		})
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	componentDidUpdate(prevProps: Readonly<GroupTemplatesProps>, _prevState: Readonly<GroupTemplatesState>, _snapshot?: any): void {
		if (prevProps.selectedGroup !== this.props.selectedGroup || prevProps.templateVersion !== this.props.templateVersion) {
			this.getGroupTemplates()
		}
	}

	componentDidMount(): void {
		this.getGroupTemplates()
	}

	render(): JSX.Element {
		if (!this.state.items) {
			return (<></>)
		}
		if (this.state.items && this.state.items[0]?.primaryText !== 'No items') {
			return (
				<Stack style={{ width: '100%', marginTop: 50 }}>
					<span className='Indent20'><h4>Group Templates</h4></span>
					<ShimmeredDetailsList
						items={this.state.items!}
						columns={this.state.columns}
						enableShimmer={!this.state.items}
						setKey="set"
						layoutMode={DetailsListLayoutMode.justified}
						selectionMode={SelectionMode.none}
						ariaLabelForSelectionColumn="Toggle selection"
						ariaLabelForSelectAllCheckbox="Toggle selection for all items"
						checkButtonAriaLabel="Row checkbox"
						checkboxVisibility={CheckboxVisibility.always}
					/>
				</Stack>
			);
		}
		else {
			return (<></>)
		}

	}

	private _onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
		const { columns, items } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
				// this.setState({
				//   announcedMessage: `${currColumn.name} is sorted ${
				// 	currColumn.isSortedDescending ? 'descending' : 'ascending'
				//   }`,
				// });
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		const newItems = sortArray(items!, currColumn.fieldName!, currColumn.isSortedDescending);
		this.setState({
			columns: newColumns,
			items: newItems,
		});
	};
}
