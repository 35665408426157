import * as React from "react"
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Text, TextField } from "@fluentui/react"
import { updatePictureMeta, updatePictures } from "../../helpers/PostToRestApi";
import { AxiosResponse } from "axios";
import { PictureListItem } from "../../interfaces/PictureListItem";

export const DialogUpdatePictureMeta: React.FunctionComponent<{setParentState: (x: any) => void, getPictures: () => void, hideState: string, hidden: boolean, selectedItem: PictureListItem, accessToken: string, domain: string, api: string}> = (props) => {

	const [emptyName, setEmptyName] = React.useState<boolean>(false);
	const [emptyDescription, setEmptyDescription] = React.useState<boolean>(false);
	const [initialized, setInitialized] = React.useState<boolean>(false);
	const [updateFailed, setUpdateFailed] = React.useState<boolean>(false);
	// const [emptyLibraries, setEmptyLibraries] = React.useState<boolean>(false);
	// const [selectedLibrary, setSelectedLibrary] = React.useState<IDropdownOption>();
	const [pictureName, setPictureName] = React.useState<string>('');
	const [pictureDescription, setPictureDescription] = React.useState<string>('');
	
	if (props.hidden) {
		if (pictureName !== '') {setPictureName('')}
		if (pictureDescription !== '') {setPictureDescription('')}
		if (initialized) {setInitialized(false)}
		if (updateFailed) {setUpdateFailed(false)}
		return (<></>)
	}

	if (!initialized) {
		setPictureName(props.selectedItem.imgName)
		setPictureDescription(props.selectedItem.imgDescription!)
		setInitialized(true)
	}

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: `Edit picture properties`,
		subText: `Edit name and description of the selected picture`,
	};
	
	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 450 } },
	};
	
	// const libraryOptions: IDropdownOption[] = props.libraries.map((item) => {
	// 	return ({
	// 		key: item.Path!, text: item.LibraryName
	// 	})
	// })
		
	// const onChangeLibrary = (item: IDropdownOption): void => {
	// 	setSelectedLibrary(item)	
	// };
	
	const updateMetaData = async () => {

		await updatePictureMeta(
			props.accessToken, 
			props.domain,
			props.api, 
			props.selectedItem.imgPath,
			JSON.stringify({imgName: pictureName, imgDescription: pictureDescription})
		)
		.then(async (response: AxiosResponse) => {
			console.log(response)
			switch (response.status) {
				case 200:
					await updatePictures(
						props.accessToken, 
						props.domain,
						props.api,
					)
					.then(() => {
						setTimeout(async () => {
							props.getPictures()
							props.setParentState({
								// [props.confirmState]: true, 
								[props.hideState]: true, 
							})
						}, 500)
					})					
					break;
				default:
					setUpdateFailed(true)
					break;
			}
		});	
	}

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.setParentState({[props.hideState]: true})}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<TextField
				label='Picture Name'
				defaultValue={pictureName}
				onChange={(_event, value) => {setPictureName(value!)}}
				errorMessage={emptyName ? 'Picture must have a name' : ''}
			/>
			<TextField
				label='Picture Description'
				defaultValue={pictureDescription}
				onChange={(_event, value) => {setPictureDescription(value!)}}
				errorMessage={emptyDescription ? '' : ''}
			/>
			{/* <Dropdown
				placeholder="Select library"
				label="Select library"
				options={libraryOptions}
				selectedKey={selectedLibrary ? selectedLibrary.key : props.libraries[0].Path}
				onChange={(_event, option) => onChangeLibrary(option!)}				
				errorMessage={emptyLibraries ? 'Must select a library' : ''}
			/> */}
			{updateFailed ? <p><Text variant='small' style={{color: '#a4262c'}}>Update failed!</Text></p> : null}
			<DialogFooter>
				<PrimaryButton onClick={() => {
					// setEmptyLibraries(selectedLibrary?.key === ''); 
					setEmptyName(pictureName === '')
					setEmptyDescription(pictureDescription === '') //ignored

					if (!emptyName){
						updateMetaData()						
					}				
				}} 
				text="Update" />
				<DefaultButton onClick={() => {props.setParentState({[props.hideState]: true})}} text="Cancel" />
			</DialogFooter>
		</Dialog>
	);
};