import * as React from 'react';
import { Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import IconsAdminFiles from './IconsAdminFiles';
import { VersionSelector } from '../common/VersionSelector';
import Axios, { CancelTokenSource } from 'axios';
import { GetIconsFileList } from '../../helpers/GetFromRestApi';
import { FileInfo } from '../../interfaces/FileInfo';
import { ContentFrame } from '../common/ContentFrame';
import { MessageContextType } from '../../interfaces/ContextType';

export interface IconsAdminProps {
	setMessage: (x: MessageContextType) => void;
	// iconItems: IconListItem[];
	accessToken: string,
	domain: string,
	api: string,
}

export interface IconsAdminState {
	fileItems: FileInfo[]
	templateVersion: string
}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class IconsAdmin extends React.Component<IconsAdminProps, IconsAdminState> {
	constructor(props: IconsAdminProps) {
		super(props);
		this.state = {
			fileItems: [],
			templateVersion: 'TEST',
		};
	}

	boundSetState = (x: IconsAdminState) => { this.setState(x) }

	getIconFiles = async (version: string) => {
		ourRequest = Axios.CancelToken.source()
		let iconFiles: FileInfo[] = await GetIconsFileList(this.props.accessToken!, this.props.domain, this.props.api, version, ourRequest.token)
		if (!iconFiles) { iconFiles = [{ fileName: 'No files' }] }
		this.setState({
			templateVersion: version,
			fileItems: iconFiles,
		})
	}

	componentDidUpdate(prevProps: Readonly<IconsAdminProps>, _prevState: Readonly<IconsAdminState>, _snapshot?: any): void {
		if (prevProps.domain !== this.props.domain ||
			prevProps.api !== this.props.api ||
			prevProps.accessToken !== this.props.accessToken) {
			this.getIconFiles(this.state.templateVersion)
		}
	}

	componentDidMount(): void {
		this.getIconFiles(this.state.templateVersion)
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	render() {
		return (
			<Stack >
				<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
					<Text variant="xxLarge">
						Icons Admin
					</Text>
				</Stack.Item>
				<VersionSelector
					onVersionChange={this.getIconFiles}
					version={this.state.templateVersion}
				/>
				{!this.state.fileItems.length && <Spinner className='spinner' size={SpinnerSize.large} label='Loading icons...' />}
				{['TEST', 'PUB'].map((version) => {
					if (this.state.fileItems.length) {
						return (
							<ContentFrame key={version} hidden={version !== this.state.templateVersion} >
								<IconsAdminFiles
									setMessage={this.props.setMessage}
									getIcons={() => this.getIconFiles(this.state.templateVersion)}
									items={this.state.fileItems}
									accessToken={this.props.accessToken}
									domain={this.props.domain}
									api={this.props.api}
									templateVersion={this.state.templateVersion}
								/>
							</ContentFrame>
						)
					}
					else { return null }
				})}
			</Stack>
		)
	}
}
