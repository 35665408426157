/* eslint-disable react/prop-types */
import * as React from "react"
import { Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";

export interface DialogOkOnlyProps {
	callbackDismiss: () => void,
	hidden: boolean, 
	title?: string,
	description?: string
}


export const DialogOkOnly: React.FunctionComponent<DialogOkOnlyProps> = (props): JSX.Element => {
	
	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: props.title,
		subText: props.description,
	};
	
	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 450 } },
	};

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.callbackDismiss()}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<DialogFooter>
				<PrimaryButton onClick={() => props.callbackDismiss()} text="Ok" />
			</DialogFooter>
		</Dialog>
	);
};