import { GroupID } from "./GroupID";

export interface IconListItem {
	id: string;
	iconName: string;
	iconDescription?: string;
	ImageString: string;
	libraryName: string;
	previewHeight: number;
	previewWidth: number;
	hideOnline?: boolean;
	hideDesktop?: boolean;
	objType?: string;
	iconGroups?: GroupID[] | undefined;  
	iconsCount?: number;
}

export const exampleIconList: IconListItem[] = [{
	ImageString: '',
	iconName: 'No icons',
	id: '',
	libraryName: '',
	previewHeight: 0,
	previewWidth: 0,
	iconDescription: '',
}]

// export const exampleCategoryItem: IconListItem[] = [{
// 	ImageString: '',
// 	iconName: 'No items',
// 	iconsCount: 0,
// 	id: '',
// 	libraryName: 'No items',
// 	previewHeight: 0,
// 	previewWidth: 0,
// 	iconDescription: '',
// }]