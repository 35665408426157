import * as React from 'react';
import { DefaultButton, IChoiceGroupOption, IChoiceGroupOptionProps, Stack, Text } from '@fluentui/react';
// import CustomDataDetails from './CustomDataDetails';
// import CustomDataCategories from './CustomDataCategories';
// import { GetCustomDataList, GetCustomDataUpdate } from '../../helpers/GetFromRestApi';
// import { hasValues } from '../../helpers/MiscFunctions';
import Axios, { CancelTokenSource } from 'axios';
import SettingsCompany from '../../helpers/SettingsCompany';
// import { exampleCustomDataList, CustomDataListItem } from '../../interfaces/CustomDataListItem';
import { GroupInfo } from '../../interfaces/GroupInfo';
// import { updateOldCustomDataFiles } from '../../helpers/PostToRestApi';
// import { UserAdminItem } from '../../interfaces/UserAdminItem';
import { CustomDataDefItem } from '../../interfaces/CustomDataDefItem';
import { CustomDataLevelItem } from '../../interfaces/CustomDataLevelItem';
import CustomDataLevel from './CustomDataLevel';
import CustomDataLevelDef from './CustomDataLevelDef';
import CustomDataOptions from './CustomDataOptions';
import { GetCustomData, GetCustomDataDef } from '../../helpers/GetFromRestApi';
import { deleteCustomDataField, uploadCustomData, uploadCustomDataDef } from '../../helpers/PostToRestApi';
import { CustomDataItem } from '../../interfaces/CustomDataItem';
import CustomDataDefSettings from './CustomDataDefSettings';
import { ContentFrame } from '../common/ContentFrame';
import { DataDefSelector } from './DataDefSelector';
// import { ThemeSettingName } from 'office-ui-fabric-react';

export interface CustomDataProps {
	// iconItems: CustomDataListItem[];
	accessToken: string,
	domain: string,
	api: string,
	groupIds: GroupInfo[];
	settings: SettingsCompany;
}

export interface CustomDataState {
	CustomDataDefItem: CustomDataDefItem
	CustomDataDefEdited: CustomDataDefItem
	CustomDataItem: CustomDataItem[]
	// CustomDataEdited: CustomDataItem[]
	SelectedItems: CustomDataItem[][]
	// selectedDefLevel: number
	// selectedCustomDataLevelItem: CustomDataLevelItem
	selectedLevel: string[]
	definitionMode: boolean
	editMode: boolean
}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

const exampelCustomDef: CustomDataDefItem = {
	LevelCounts: 2,
	TreeViewSelector: false,
	MultiSelect: false,
	UseGroups: false,
	UniqueGroups: false,
	Level1: {
		LevelLabels: [{ langCode: 'default', dataLabel: 'Företagsuppgifter' }, { langCode: 'en', dataLabel: 'Company information' }],
		CustomDataDefs: [
			{
				FieldLabels: [{ langCode: 'default', dataLabel: 'Företagasnamn' }, { langCode: 'en', dataLabel: 'Company name' }],
				FieldName: 'companyName',
				DataType: 'text',
				LabelItem: true,
			},
			{
				FieldLabels: [{ langCode: 'default', dataLabel: 'Organisationsnummer' }, { langCode: 'en', dataLabel: 'Organization number' }],
				FieldName: 'orgNr',
				DataType: 'text',
				LabelItem: false,
			},
			{
				FieldLabels: [{ langCode: 'default', dataLabel: 'Logotyp' }, { langCode: 'en', dataLabel: 'Logo' }],
				FieldName: 'logo',
				DataType: 'picture',
				LabelItem: false,
			}
		]
	},
	Level2: {
		LevelLabels: [],
		CustomDataDefs: []
	},
	Level3: {
		LevelLabels: [],
		CustomDataDefs: []
	},
}

const exampelCustomData: CustomDataItem[] = [{
	dataID: '',
	groupIDs: [],
	DataItems: [{
		FieldLabels: [{ langCode: 'default', dataLabel: 'Företagasnamn' }, { langCode: 'en', dataLabel: 'Company name' }],
		FieldName: 'companyName',
		DataType: 'text',
		LabelItem: true,
	},
	{
		FieldLabels: [{ langCode: 'default', dataLabel: 'Organisationsnummer' }, { langCode: 'en', dataLabel: 'Organization number' }],
		FieldName: 'orgNr',
		DataType: 'text',
		LabelItem: false,
	},
	{
		FieldLabels: [{ langCode: 'default', dataLabel: 'Logotyp' }, { langCode: 'en', dataLabel: 'Logo' }],
		FieldName: 'logo',
		DataType: 'picture',
		LabelItem: false,
	}],
	SubLevels: [],
	LevelLabels: [],
}]

export default class CustomData extends React.Component<CustomDataProps, CustomDataState> {
	constructor(props: CustomDataProps) {
		super(props);
		this.state = {
			CustomDataDefItem: { LevelCounts: 0, TreeViewSelector: false, MultiSelect: false, UseGroups: false, UniqueGroups: false, Level1: { CustomDataDefs: [], LevelLabels: [] }, Level2: { CustomDataDefs: [], LevelLabels: [] }, Level3: { CustomDataDefs: [], LevelLabels: [] } },
			CustomDataDefEdited: { LevelCounts: 0, TreeViewSelector: false, MultiSelect: false, UseGroups: false, UniqueGroups: false, Level1: { CustomDataDefs: [], LevelLabels: [] }, Level2: { CustomDataDefs: [], LevelLabels: [] }, Level3: { CustomDataDefs: [], LevelLabels: [] } },
			CustomDataItem: [],
			// CustomDataEdited: [],
			SelectedItems: [],
			// selectedDefLevel: 0,
			// selectedCustomDataLevelItem: { CustomDataDefs: [], LevelLabels: [] },
			selectedLevel: ['', '', ''],
			definitionMode: false,
			editMode: false,
		};
	}

	getData = async () => {
		ourRequest = Axios.CancelToken.source()
		// let CustomDataDef: CustomDataDefItem = await GetCustomDataDef(this.props.accessToken, this.props.apiUri!, JSON.stringify([{ groupID: '123' }]), 'ALL-ICONS', ourRequest.token)
		let CustomData: CustomDataItem[] = await GetCustomData(this.props.accessToken, this.props.domain, this.props.api, ourRequest.token)
		console.log(CustomData)
		if (!CustomData) { CustomData = exampelCustomData }
		console.log(CustomData)

		const selectedLevels: string[] = this.state.selectedLevel.map((val, idx, arr) => {
			if (val === '') {
				if (idx === 0) {
					return CustomData[0]!?.dataID || ''
				}
				if (idx === 1) {
					return CustomData.find(c => c.dataID === arr[idx - 1])!?.SubLevels[0]!?.dataID || ''
				}
				if (idx === 2) {
					return CustomData.find(c => c.dataID === arr[idx - 2])!?.SubLevels.find(s => s.dataID === arr[idx - 1])!?.SubLevels[0]!?.dataID || ''
				}
				else { return '' }
			}
			else {
				return val
			}
		})

		this.setState({
			CustomDataItem: JSON.parse(JSON.stringify(CustomData)),
			selectedLevel: selectedLevels,
			// CustomDataEdited: JSON.parse(JSON.stringify(CustomData)),			
		})
	}

	uploadData = async (data: CustomDataItem[], parentId: string) => {
		ourRequest = Axios.CancelToken.source()
		data.map(async (val) => {
			const res = await uploadCustomData(this.props.accessToken, this.props.domain, this.props.api, JSON.stringify(val), parentId, ourRequest.token);
			// this.setState({
			// 	CustomDataDefItem: JSON.parse(JSON.stringify(data)),
			// 	CustomDataDefEdited: JSON.parse(JSON.stringify(data)),
			// })
			if (res.status === 200) {
				console.log(res.data);
				this.getData()
			}
		})

	}

	deleteDataField = async (id: string, lvl: number) => {
		ourRequest = Axios.CancelToken.source()
		this.setState({ selectedLevel: this.state.selectedLevel.map((val, idx) => idx >= lvl ? '' : val) })
		const res = await deleteCustomDataField(this.props.accessToken, this.props.domain, this.props.api, id, ourRequest.token)
		if (res.status === 200) {
			console.log(res.data);
			this.getData()
		}
	}

	getDefData = async () => {
		ourRequest = Axios.CancelToken.source()
		// let CustomDataDef: CustomDataDefItem = await GetCustomDataDef(this.props.accessToken, this.props.apiUri!, JSON.stringify([{ groupID: '123' }]), 'ALL-ICONS', ourRequest.token)
		let CustomDataDef: CustomDataDefItem = await GetCustomDataDef(this.props.accessToken, this.props.domain, this.props.api, ourRequest.token)
		console.log(CustomDataDef)
		if (!CustomDataDef) { CustomDataDef = exampelCustomDef }

		this.setState({
			CustomDataDefItem: JSON.parse(JSON.stringify(CustomDataDef)),
			CustomDataDefEdited: JSON.parse(JSON.stringify(CustomDataDef)),
		})
	}

	uploadDefData = async (data: CustomDataDefItem) => {
		ourRequest = Axios.CancelToken.source()
		uploadCustomDataDef(this.props.accessToken, this.props.domain, this.props.api, JSON.stringify(data), ourRequest.token)
			.then((res) => {
				this.setState({
					CustomDataDefItem: JSON.parse(JSON.stringify(data)),
					CustomDataDefEdited: JSON.parse(JSON.stringify(data)),
				})
				setTimeout(() => {
					console.log(res)
				}, 500);
			})
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	componentDidUpdate(prevProps: CustomDataProps, _prevState: CustomDataState) {
		if (prevProps.accessToken !== this.props.accessToken ||
			prevProps.domain !== this.props.domain ||
			prevProps.api !== this.props.api ||
			this.state.CustomDataDefItem?.LevelCounts === 0) {
			this.getDefData()
			this.getData()
		}
		// if (prevState.selectedLevel !== this.state.selectedLevel) {
		// 	this.setState({
		// 		SelectedItems: [this.getDataLevel(0, this.state.selectedLevel), this.state.selectedLevel[0] ? this.getDataLevel(1, this.state.selectedLevel) : [], this.state.selectedLevel[1] ? this.getDataLevel(2, this.state.selectedLevel) : [],]
		// 	})
		// }
	}

	componentDidMount() {
		this.getDefData()
		this.getData()
	}

	onRenderField = (opt: IChoiceGroupOption | IChoiceGroupOptionProps | undefined): JSX.Element => {
		return (
			<DefaultButton
				primary={(opt!.key === 'DEF' && this.state.definitionMode) || (opt!.key !== 'DEF' && !this.state.definitionMode)}
				disabled={opt!.disabled}
				// styles={buttonStyles}
				// prevent focusing on the button
				tabIndex={-1}
				// hide the button from screen readers to avoid reading both a <button>
				// and a radio <input>
				aria-hidden
				onClick={() => this.onChangeVersion(opt!.key! === 'DEF')}
			>
				{opt!.text}
			</DefaultButton>
		);
	};

	versionOptions: IChoiceGroupOption[] = [
		{ key: 'DATA', id: 'DATA', text: 'Data', onRenderField: this.onRenderField },
		{ key: 'DEF', id: 'DEF', text: 'Definitions', onRenderField: this.onRenderField }
	];



	onChangeVersion = (def: boolean): void => {
		this.setState({ definitionMode: def })
	}

	getDataLevel(idx: number): CustomDataItem[] {
		switch (idx) {
			case 1:
				return [...this.state.CustomDataItem]?.find(v => v.dataID === this.state.selectedLevel[idx - 1])?.SubLevels!
			case 2:
				return [...this.state.CustomDataItem]?.find(v => v.dataID === this.state.selectedLevel[idx - 2])?.SubLevels?.find(v => v.dataID === this.state.selectedLevel[idx - 1])?.SubLevels!
			default:
				return [...this.state.CustomDataItem]
		}
	}

	// setDataLevel(idx: number, dataItem: CustomDataItem[]): void {
	// 	switch (idx) {
	// 		case 0:
	// 			this.setState({ CustomDataEdited: Object.assign({}, {...this.state.CustomDataEdited}, { dataItem }) });
	// 			break;
	// 		case 1:
	// 			this.setState({ CustomDataEdited: Object.assign({}, {...this.state.CustomDataEdited}, { dataItem }) });
	// 			break;
	// 		case 2:
	// 			this.setState({ CustomDataEdited: Object.assign({}, {...this.state.CustomDataEdited}, { dataItem }) });
	// 			break;
	// 		default:
	// 	}
	// }

	getDefDataLevel(idx: number): CustomDataLevelItem {
		switch (idx) {
			case 0:
				return this.state.CustomDataDefEdited?.Level1!
			case 1:
				return this.state.CustomDataDefEdited?.Level2!
			case 2:
				return this.state.CustomDataDefEdited?.Level3!
			default:
				return this.state.CustomDataDefEdited?.Level1!
		}
	}

	setDefDataLevel(idx: number, newDef: CustomDataLevelItem): void {
		switch (idx) {
			case 0:
				this.setState({ CustomDataDefEdited: Object.assign(this.state.CustomDataDefEdited, { Level1: newDef }) });
				break;
			case 1:
				this.setState({ CustomDataDefEdited: Object.assign(this.state.CustomDataDefEdited, { Level2: newDef }) });
				break;
			case 2:
				this.setState({ CustomDataDefEdited: Object.assign(this.state.CustomDataDefEdited, { Level3: newDef }) });
				break;
			default:
		}
	}

	render() {
		console.log(this.state)
		return (
			<Stack>
				<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
					<Text variant="xxLarge">
						Custom Data
					</Text>
				</Stack.Item>
				<DataDefSelector
					onModeChange={(defMode) => this.setState({ definitionMode: defMode })}
					definitionMode={this.state.definitionMode}
					CustomDataDefItem={this.state.CustomDataDefItem!}
				/>
				{this.state.definitionMode &&
					<>
						<ContentFrame>
							<CustomDataOptions
								// settings={this.props.settings}
								definitionMode={this.state.definitionMode!}
								editMode={this.state.editMode}
								CustomDataDefItem={this.state.CustomDataDefItem!}
								CustomDataDefEdited={this.state.CustomDataDefEdited!}
								onChangeDef={(newDef) => this.setState({ CustomDataDefEdited: newDef })}
								onSaveDef={(newDef) => this.uploadDefData(newDef)}
								onChangeEdit={(editMode) => this.setState({ editMode: editMode })}
							/>
						</ContentFrame>
						<ContentFrame>
							<CustomDataDefSettings
								settings={this.props.settings}
								editMode={this.state.editMode}
								CustomDataDefEdited={this.state.CustomDataDefEdited!}
								// onSaveDef={(newDef) => this.uploadDefData(newDef)}
								onChangeDef={(newDef) => this.setState({ CustomDataDefEdited: newDef })}
							/>
						</ContentFrame>
					</>
				}
				{[...Array(this.state.CustomDataDefEdited?.LevelCounts).keys()].map((val, idx) => {
					if (this.getDefDataLevel(idx)) {
						// console.log(val)
						return (
							this.state.definitionMode ?
								<ContentFrame key={val}>
									<CustomDataLevelDef
										dataLevel={idx}
										defDataLevelEdited={this.getDefDataLevel(idx)}
										editMode={this.state.editMode}
										onChangeDef={(defItem) => { console.log(this.state); this.setDefDataLevel(idx, defItem) }}
									/>
								</ContentFrame>
								:
								<ContentFrame key={val}>
									{(idx === 0 || this.state.selectedLevel[idx - 1] !== '') ?
										<CustomDataLevel
											dataLevel={idx}
											showButtons={this.state.CustomDataDefEdited?.LevelCounts === 1}
											parentId={idx > 0 ? this.state.selectedLevel[idx - 1] : ''}
											defDataLevelItem={this.getDefDataLevel(idx)}
											dataLevelItems={this.getDataLevel(idx)}
											onChangeData={(dataItem, parentId) => { this.uploadData(dataItem, parentId) }}
											onDeleteField={(id) => { this.deleteDataField(id, idx) }}
											onChangeSelection={(selectedId) => {
												console.log(selectedId)
												let selLvl: string[] = this.state.selectedLevel.map((val, selIdx) => {
													if (selIdx === idx) {
														return selectedId
													}
													else if (selIdx < idx) {
														return val
													}
													else if (selIdx === idx + 1) {
														return this.getDataLevel(idx).find(v => v.dataID === selectedId)?.SubLevels?.[0]?.dataID ?? ''
													}
													else {
														return ''
													}
												})
												console.log(selLvl)
												this.setState({
													selectedLevel: selLvl,
												})
											}}
										/>
										:
										<></>}
								</ContentFrame>
						)
					}
					else { return null }
				})}
			</Stack>
		)
	}
}