import { PictureLibrary } from "./PictureLibrary";

export interface PictureSrcLib {
	imgSrc: string;
	previewWidth: number;
	previewHeight: number;
	previewBigWidth: number;
	previewBigHeight: number;
	Libraries: PictureLibrary[];
	Path?: string;
	PicturesCount? : number;
}

export const ExampleLayoutList: PictureSrcLib[] = [{
	imgSrc: 'No items',
	previewWidth: 0,
	previewHeight: 0,
	previewBigWidth: 0,
	previewBigHeight: 0,
	Libraries: [],
	Path: ''
}]