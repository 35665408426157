import { GroupID } from "./GroupID";

export interface PresentationListItem {
	id: string,
	// index: number,
	// title: string,
	// searchText: string,
	// base64: string,
	fileSize?: string;
	fileSizeNum?: number;
	modifiedDate?: string;
	publishDate?: string;
	Width: number,
	Height: number,
	presentationName: string,
	pub: boolean,
	slideGroups?: GroupID[] | undefined,
}

export const examplePresentationList: PresentationListItem[] = [{
	id: '',
	Width: 0,
	Height: 0,
	presentationName: '',
	pub: false,
}]