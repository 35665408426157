import React, { useState, useEffect } from 'react';
import { ActionButton, Image, ImageFit, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import Axios, { CancelTokenSource } from 'axios';
import SlidesFiles from './SlidesFiles';
import SlidesSlides from './SlidesSlides';
import { GetPresentationList, GetSlidesList } from '../../helpers/GetFromRestApi';
import { exampleSlidesList, SlideListItem } from '../../interfaces/SlideListItem';
import { ContentFrame } from '../common/ContentFrame';
import { examplePresentationList, PresentationListItem } from '../../interfaces/PresentationListItem';
import { VersionSelector } from '../common/VersionSelector';
import { getTooltipFromString } from '../../helpers/Descriptions';
import { TenantContext, MessageContext } from '../../contexts/ContextWrapper';
import { checkValidToken } from '../../helpers/LogginHelper';
import { AuthContext } from '../../contexts/AuthContext';


let ourRequest: CancelTokenSource = {} as CancelTokenSource;

const Slides: React.FC = () => {

	const [presentationItems, setPresentationItems] = useState<PresentationListItem[]>([]);
	const [slideItems, setSlideItems] = useState<SlideListItem[]>([]);
	const [templateVersion, setTemplateVersion] = useState<string>('TEST');
	const [isLoadingPres, setIsLoadingPres] = useState<boolean>(false);
	const [isLoadingSlides, setIsLoadingSlides] = useState<boolean>(false);

	const { tenantContext } = React.useContext(TenantContext);
	const { messageContext, setMessageContext } = React.useContext(MessageContext);
	const { accessToken, refreshToken } = React.useContext(AuthContext);

	const getPresentations = async (version: string) => {
		ourRequest = Axios.CancelToken.source();
		setIsLoadingPres(true);
		setTemplateVersion(version);
		setSlideItems([]);
		let presentations: PresentationListItem[] = await GetPresentationList(
			accessToken!,
			tenantContext.tenantName,
			tenantContext.api,
			version,
			[],
			'ALL-SLIDES',
			ourRequest.token
		);
		if (!presentations) {
			presentations = examplePresentationList;
		}
		setPresentationItems(presentations);

		setIsLoadingPres(false);
	};

	const getSlides = async (presentation: string, version: string) => {
		ourRequest = Axios.CancelToken.source();
		setIsLoadingSlides(true);
		setTemplateVersion(version);
		let slides: SlideListItem[] = [];
		if (presentation !== '') {
			slides = await GetSlidesList(
				accessToken!,
				tenantContext.tenantName,
				tenantContext.api,
				version,
				[],
				'ALL-SLIDES',
				presentation,
				ourRequest.token
			);
		}
		if (!slides) {
			slides = exampleSlidesList;
		}
		setSlideItems(slides);
		setIsLoadingSlides(false);
	};

	useEffect(() => {
		getPresentations(templateVersion);
	}, [accessToken!, tenantContext.tenantName, tenantContext.api, templateVersion]);

	useEffect(() => {
		getPresentations(templateVersion);
		return () => { try { ourRequest.cancel(); } catch (error) { } }
	}, []);

	const settingGuideImage = require('../../assets/settings-guide.png');

	if (!checkValidToken(accessToken!)) { refreshToken() }
	
	return (
		<Stack>
			<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
				<Text variant="xxLarge">Slides</Text>
			</Stack.Item>
			<VersionSelector
				onVersionChange={getPresentations}
				version={templateVersion}
				testDescription={
					<>
						You're now in test mode. You can upload, download and delete presentations, but they will not be available to all users before you publish them.<br />
						To publish all presentations use the<ActionButton iconProps={{ iconName: 'PublishContent' }} >Publish All</ActionButton>button. To publish a single presentation use the<ActionButton iconProps={{ iconName: 'PublishContent' }} >Publish Presentation</ActionButton>button.<br />
						Users with an account in this portal are able to switch to Test version in the 365TemplateExtender addin. To reach the <b>Template Version</b> setting, click the bottom right menu and select <b>Settings</b> {getTooltipFromString(<><Image src={settingGuideImage} imageFit={ImageFit.contain} alt="" /></>)}
					</>
				}
				pubDescription={
					<>
						You're now in published mode. All slides are available for all users.
					</>
				}
			/>
			{isLoadingPres && <Spinner className='spinner' size={SpinnerSize.large} label='Loading presentations...' />}
			<ContentFrame hidden={isLoadingPres}>
				<SlidesFiles
					setMessage={(message) => setMessageContext({ ...messageContext, message: message.message, messageType: message.messageType, visible: message.visible })}
					getPresentations={getPresentations}
					getSlides={getSlides}
					presentationItems={presentationItems}
					accessToken={accessToken!}
					domain={tenantContext.tenantName}
					api={tenantContext.api}
					groupIds={tenantContext.groupIds}
					companyGroups={tenantContext.companyGroups}
					settings={tenantContext.settings}
					templateVersion={templateVersion}
				/>
			</ContentFrame>
			{isLoadingSlides && <Spinner className='spinner' size={SpinnerSize.large} label='Loading slides...' />}
			<ContentFrame hidden={isLoadingSlides || slideItems.length === 0}>
				<SlidesSlides
					refreshSlides={() => getSlides(slideItems[0].presentationName, templateVersion)}
					isLoadingSlides={isLoadingSlides}
					slideItems={slideItems}
					templateVersion={templateVersion}
				/>
			</ContentFrame>
		</Stack>
	);
};

export default Slides;
