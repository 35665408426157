// import { useNavigate } from "react-router-dom";
import { useUserFetch } from "./useUserFetch";
import { useGraphFetch } from "./useGraphFetch";

export const useSession = () => {

    // const { tenantContext, setTenantContext } = useContext(TenantContext)
    const { FetchUser } = useUserFetch()
    const { FetchFromGraph } = useGraphFetch()


    const LoginSession = async (onUserFetch: (api: string, tenantName:string)=> void, onError: (error: string)=> void, accessToken: string) => {
        console.log('LoginSession')
        const [myDefaultDomain, myVerifiedDomains] = await FetchFromGraph(accessToken)
        FetchUser(accessToken, myVerifiedDomains)
            .then(async (user) => {
                console.log(user)
                if (user.API && user.Domains) {
                    const api = user.API[0].APIName
                    const tenantName = user.Domains.find((item) => { return item.DomainName === myDefaultDomain })?.DomainName || user.Domains[0].DomainName
                    onUserFetch(api, tenantName)                    
                }
            })
            .catch((error) => {
                console.log(error)
                onError(error)
            })
    }

    return { LoginSession }
}

