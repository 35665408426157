import * as React from 'react';
import { CompoundButton, IButtonStyles, Image, Stack, Text } from '@fluentui/react';
import { ManifestItem } from '../../interfaces/ManifestItem';

export interface SettingsManifestPreviewProps {
	manifestItems: ManifestItem[]
	iconSrc: string
}

export interface SettingsManifestPreviewState {
}

export default class SettingsManifestPreview extends React.Component<SettingsManifestPreviewProps, SettingsManifestPreviewState> {
	constructor(props: SettingsManifestPreviewProps) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {

	}

	componentDidUpdate(_prevProps: SettingsManifestPreviewProps, _prevState: SettingsManifestPreviewState) {

	}

	hoverStyle: IButtonStyles = {
		root: {
			backgroundColor: '#FAFAFA',
			border: '1px solid #FAFAFA',
			padding: '5px',
			margin: '0px'
		},
		rootHovered: {
			backgroundColor: '#F5F5F5',
			borderRadius: '5px',
			border: '1px solid #C7C7C7',
		},
	}

	render() {
		return (
			<Stack tokens={{ childrenGap: '5px' }} >
				<Stack.Item><Text>Preview:</Text></Stack.Item>
				<Stack.Item align="start">
					<Stack styles={{ root: { backgroundColor: '#FAFAFA', minWidth: '120px', minHeight: '100px', borderBottom: '5px solid #F0F0F0' } }}>
						<Stack verticalAlign="space-between" styles={{ root: { margin: '5px 10px 5px 10px', padding: '0px 5px 0px 5px', borderLeft: '1px solid #D1D1D1', borderRight: '1px solid #D1D1D1' } }}  >
							<Stack.Item align='center' styles={{ root: { paddingTop: '0px' } }}>
								<CompoundButton
									styles={this.hoverStyle}
									onRenderText={() => {
										return (
											<Stack key='button' verticalAlign="space-between" styles={{ root: { maxWidth: '120px' } }}>
												<Stack.Item align='center'>
													<Image src={this.props.iconSrc} alt='Icon' width={32} height={32} />
												</Stack.Item>
												<Stack.Item align='center'>
													<Text styles={{ root: { fontFamily: 'Segoe UI, Helvetica', fontSize: '9pt', lineHeight: '0em' } }}>{this.props.manifestItems.find((value) => value.Title === 'Button Label')?.Text}</Text>
												</Stack.Item>
											</Stack>
										)
									}}
								/>
							</Stack.Item>
							<Stack.Item align='center' styles={{ root: { maxWidth: '125px', textAlign: 'center' } }}>
								<Text styles={{ root: { fontFamily: 'Segoe UI, Helvetica', fontSize: '8pt', lineHeight: '0em' } }}>{this.props.manifestItems.find((value) => value.Title === 'Group Label')?.Text}</Text>
							</Stack.Item>
						</Stack>
					</Stack>
				</Stack.Item>
				<Stack.Item align="start">
					<Stack styles={{ root: { padding: '10px', marginTop: '5px', maxWidth: '200px', borderRadius: '5px', border: '1px solid #C7C7C7', }}}>
						<Text styles={{ root: { fontFamily: 'Segoe UI, Helvetica', fontSize: '9pt', fontWeight: 'bold', marginBottom: '3px' } }}>{this.props.manifestItems.find((value) => value.Title === 'Button Label')?.Text}</Text>
						<Text styles={{ root: { fontFamily: 'Segoe UI, Helvetica', fontSize: '9pt' } }}>{this.props.manifestItems.find((value) => value.Title === 'Tooltip')?.Text}</Text>
						<Text styles={{ root: { fontFamily: 'Segoe UI, Helvetica', fontSize: '9pt' } }}>&nbsp;</Text>
						<Text styles={{ root: { fontFamily: 'Segoe UI, Helvetica', fontSize: '9pt' } }}>Your admin installed this add-in for you.</Text>
					</Stack>
				</Stack.Item>

			</Stack>
		)
	}

}
