import * as React from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from './contexts/ContextWrapper';
import MainPage from './MainPage';
import { useSettingsFetch } from './hooks/useSettingsFetch';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { CenterComponent } from './components/common/CenterComponent';
import NavigationWrapper from './components/navigation/NavigationWrapper';
import { AuthContext } from './contexts/AuthContext';
import Home from './Home';
import Academy from './components/academy/Academy';


const Body: React.FC = () => {
	const { api, domain } = useParams<'api' | 'domain'>();

	const [selectedPage, setSelectedPage] = React.useState<string>('home');

	const { userContext } = React.useContext(UserContext);
	const { isAuthenticated } = React.useContext(AuthContext);

	const navigate = useNavigate();
	const { UpdateSettings, isLoadingSettings } = useSettingsFetch()

	const changePage = React.useCallback((newPage: string) => {
		setSelectedPage(prevPage => {
			if (prevPage !== newPage && api && domain) {
				navigate(`/${api}/${domain}/${newPage}`);
			}
			return newPage;
		});
	}, [api, domain, navigate]);

	React.useEffect(() => {
		let isMounted = true;
		if (!isAuthenticated) {
			navigate('/login');
			return;
		}
		const validDomain = userContext.userData!?.Domains!?.findIndex(
			(d) => (d.APIName.toLowerCase() === api!.toLowerCase() && d.DomainName.toLowerCase() === domain!.toLowerCase())
		) >= 0;

		if (domain === 'academy' || domain === 'domains') {
			return;
		}

		if (api && domain && validDomain && isMounted ) {
			UpdateSettings(api, domain).catch(error => {
				console.error('Failed to update settings:', error);
				// Consider navigating to an error page or showing an error message
			});
		} else {
			navigate('/');
		}
		return () => { isMounted = false; };
	}, [api, domain, isAuthenticated]);

	if (isLoadingSettings) {
		return (
			<CenterComponent>
				<Spinner className='spinner' size={SpinnerSize.large} label='Loading data...' />
			</CenterComponent>
		);
	}

	if (domain === 'academy') {
		return (
			<Routes>
				<Route index element={<Academy/>} />
			</Routes>
		)
	}

	// if (domain === 'domains') {
	// 	return (
	// 		<Routes>
	// 			<Route index element={<Domains/>} />
	// 		</Routes>
	// 	)
	// }
 
	return (
		<NavigationWrapper
			setSelectedPage={changePage}
			selectedPage={selectedPage}
		>
			<Routes>
				<Route index element={<Home/>} />
				<Route path=":page/*" element={<MainPage setSelectedPage={setSelectedPage} />} />
			</Routes>
		</NavigationWrapper>
	);
}

export default Body;
