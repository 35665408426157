import * as React from 'react'
import { GetUseCustomData, GetUseUserInformation, UserInfoExtended } from '../helpers/SettingsCompany'
import { TenantContext } from '../contexts/ContextWrapper'
import { useUserData } from './useUserData'
import { CustomDataPlainListItem } from '../interfaces/CustomDataPlainList'
import { FindReplace } from '../interfaces/FindReplace';
import { useCustomData } from './useCustomData'

export const useReplace = () => {

    const { tenantContext } = React.useContext(TenantContext)

    const { userDataItems, isLoadingUserData } = useUserData()
    const { customDataItem, isLoadingCustomData } = useCustomData()

    const ReplaceInText = (text: string, onErrorEmpty: boolean): string => {
        let result = text;
        FindReplace.forEach((fr) => {
            result = result.replace(fr.find, fr.replace)
            // result = result.replace(new RegExp("/\b"+fr.find+"\b/", 'gi'), fr.replace)
        });
        if (onErrorEmpty && result.includes('¤¤null¤¤')) return ''
        if (result.includes('¤¤null¤¤')) return result.replace('¤¤null¤¤', '')
        return result;
    }

    const FindReplaceFromUserData = (userData: UserInfoExtended[]): FindReplace[] => {
        let findReplace: FindReplace[] = [];
        if (userData!?.length > 0) {
            userData.forEach((item) => {
                findReplace.push({ find: item.field, replace: item.value ? item.value : '¤¤null¤¤' })
            })
        }
        return findReplace;
    }

    const FindReplaceFromCustomData = (customData: CustomDataPlainListItem[]): FindReplace[] => {
        let findReplace: FindReplace[] = [];
        if (customData!?.length > 0) {
            customData.forEach((item) => {
                findReplace.push({ find: item.FieldName, replace: item.DataValue ? item.DataValue : '¤¤null¤¤' })
            })
        }
        return findReplace;
    }

    const FindReplace: FindReplace[] = React.useMemo(() => {
        let findReplace: FindReplace[] = []
        if (GetUseUserInformation(tenantContext.settings)) { findReplace = FindReplaceFromUserData(userDataItems) }
        if (GetUseCustomData(tenantContext.settings)) { findReplace = [...findReplace, ...FindReplaceFromCustomData(customDataItem)] }
        return findReplace
    }, [userDataItems, customDataItem, tenantContext.settings])

    return { ReplaceInText, FindReplace, isLoadingUserData, isLoadingCustomData }
}