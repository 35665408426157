import * as React from "react"
import { Pivot, PivotItem, Stack } from "@fluentui/react"
import { ContentFrame } from "../common/ContentFrame"
import { GetUseCustomData } from "../../helpers/SettingsCompany"
import { TenantContext } from "../../contexts/ContextWrapper"

export interface UserDataSelectorProps {
	onModeChange: (selectedKey: 'UserData' | 'CombinedData') => void
	selectedKey: 'UserData' | 'CombinedData'
}

export const UserDataSelector: React.FunctionComponent<UserDataSelectorProps> = (props) => {
	// const fontIconClass = mergeStyles({ FontSize: '32px', height: 32, width:32 })
const {tenantContext}=React.useContext(TenantContext)

	return (
		<ContentFrame>
			<>
				<Pivot
					className='Indent20'
					aria-label="Version Selector"
					onLinkClick={(item) => { props.onModeChange(item!.props.itemKey! as 'UserData' | 'CombinedData') }}
					selectedKey={props.selectedKey}
				>
					<PivotItem key='UserData' itemKey='UserData' headerText='User Data' />
					<PivotItem key='CombinedData' itemKey='CombinedData' headerText='Combined Data' />
				</Pivot>
				<Stack className='Indent20' >
					{props.selectedKey === 'UserData' ?
						<>
							<p style={{ fontSize: '14px' }}><b>User Data</b> is fetched from Microsoft Entra ID. It is possible to add more items by clicking <b>New Field</b></p>
							<p style={{ fontSize: '14px' }}>By pressing <b>Edit Mode</b> you can delete, rename or hide a field in the Office addin. Under <b>My Data</b> shows a preview of your personal data from Entra ID.</p>
						</>
						:
						<>
							<p style={{ fontSize: '14px' }}><b>Combined Data</b> combines multiple <b>User Data</b>{GetUseCustomData(tenantContext.settings) ? <> and <b>Custom Data</b></>: ''} items. The list of <b>Data Items</b> can be joined together with a <b>Delimiter</b>. If an Data Items value is missing it will be removed together with the delimiter. These are ideal for footers or address fields in Word templates. If you edit a <b>Combined Data</b> field the change will apply to all templates with it.</p>
							<p style={{ fontSize: '14px' }}>It is possible to use multiple data fields for each Data Item <i>e.g. "[aadTitle] [aadDepartment]"</i>. Characters outside of the brackets will be displayed as text<i>e.g. "Tel: [aadPhone]"</i>, but removed if the data field's value is missing. Under the list of Data Items you will find all your available <b>User Data</b>{GetUseCustomData(tenantContext.settings) ? <> and <b>Custom Data</b></>: ''}</p>
							<p style={{ fontSize: '14px' }}>By enable <b>HTML</b> each Data Item can be formatted. Under the list of Data Items you will find buttons for the most common HTML formatting.</p>
						</>
					}
				</Stack>
			</>
		</ContentFrame>
	)
}