import * as React from 'react';
import {
	CheckboxVisibility,
	CommandBar,
	DetailsListLayoutMode,
	IColumn,
	ICommandBarItemProps,
	Icon,
	Image,
	SelectionMode,
	ShimmeredDetailsList,
	Stack,
	TooltipHost,
} from '@fluentui/react';
import { Selection, } from '@fluentui/react/lib/DetailsList';
import { CustomDataLevelItem } from '../../interfaces/CustomDataLevelItem';
import { CustomDataListItem } from '../../interfaces/CustomDataListItem';
import { CustomDataItem } from '../../interfaces/CustomDataItem';
import { DialogEditCustomDetail } from '../dialogs/DialogEditCustomDetails';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import FloatingButton from '../common/FloatingButton';
import { DialogDelete } from '../dialogs/DialogDelete';
// import { DataLabelItem } from '../../interfaces/DataLabelItem';

export interface CustomDataLevelProps {
	dataLevel: number
	parentId: string
	defDataLevelItem: CustomDataLevelItem
	dataLevelItems: CustomDataItem[]
	showButtons: boolean
	onChangeData: (dataItem: CustomDataItem[], parentId: string) => void
	onDeleteField: (id: string) => void
	onChangeSelection: (selectedId: string) => void
}

export interface CustomDataLevelState {
	visible: boolean
	dataLevelEdited: CustomDataItem[]
	selectedItems: CustomDataItem[]
	selection: Selection
	columns: IColumn[]
	dialog: { showEdit: boolean, showNew: boolean, showDelete: boolean }
}

export default class CustomDataLevel extends React.Component<CustomDataLevelProps, CustomDataLevelState> {
	private _selection: Selection;

	constructor(props: CustomDataLevelProps) {
		super(props);
		this._selection = new Selection({
			onSelectionChanged: () => { this.setState({ selection: this._selection, }) },
		});
		this.state = {
			visible: false,
			dataLevelEdited: [],
			selectedItems: [],
			selection: this._selection,
			columns: [{ key: 'column1', name: '', minWidth: 16, }],
			dialog: { showEdit: false, showNew: false , showDelete: false }
		};
	}

	getColums(): IColumn[] | undefined {
		return [
			...[...this.props.defDataLevelItem.CustomDataDefs].map((field: CustomDataListItem) => {
				return {
					key: 'column_' + field.FieldName,
					name: field.FieldLabels.find((val) => val.langCode.toLowerCase() === 'default')?.dataLabel,
					fieldName: field.DataValue,
					minWidth: 100,
					maxWidth: 300,
					isResizable: true,
					onRender: (item: CustomDataItem) => {
						const listItem: CustomDataListItem = item.DataItems.find(v => v.FieldName.toLowerCase() === field.FieldName.toLowerCase())!
						if (listItem) {
							switch (listItem.DataType) {
								case 'png':
								case 'jpeg':
									return listItem.DataValue && (
										<TooltipHost
											content={(
												<Image
													styles={{ image: { maxWidth: 300, maxHeight: 300, objectFit: 'contain' } }}
													src={`data:image/${listItem.DataType};base64,${listItem.DataValue}`}
												/>
											)}
										>
											{/* <Icon  styles={{root: {fontSize: '16px'}}} iconName='PictureCenter' />	 */}
											<Icon {...getFileTypeIconProps({ extension: 'jpg', size: 20 })} />
										</TooltipHost>
									)
								case 'multiline':
									return listItem.DataValue?.split('\v').map((val, idx) => {
										return (<div key={idx}>{val}<br /></div>)
									})
								default:
									return listItem.DataValue
							}
						}
						else { return null }
					},
				} as IColumn
			}),
		]
	}

	getCommandItems(): ICommandBarItemProps[] {
		return [
			{
				key: 'new',
				text: 'New',
				iconProps: { iconName: 'Add' },
				onClick: () => this.addNewRow(),
			},
			{
				key: 'edit',
				text: 'Edit',
				disabled: this._selection.getItems().length === 0,
				iconProps: { iconName: 'Edit' },
				onClick: () => this.editRow(),
			},
			{
				key: 'delete',
				text: 'Delete',
				disabled: this._selection.getItems().length === 0,
				iconProps: { iconName: 'Delete' },
				onClick: () => this.deleteRow(),
			},
			// {
			// 	key: 'save',
			// 	text: 'Save',
			// 	disabled: JSON.stringify(this.props.dataLevelItems) === JSON.stringify(this.state.dataLevelEdited),
			// 	// this.props.dataLevelEdited. .filter((v, i, a) => a.indexOf(v) === i).length !== this.props.dataLevelEdited..length,
			// 	iconProps: { iconName: 'Save' },
			// 	onClick: () => { this.props.onChangeData(this.state.dataLevelEdited, this.props.parentId) },
			// },
			// {
			// 	key: 'revert',
			// 	text: 'Revert',
			// 	disabled: JSON.stringify(this.props.dataLevelItems) === JSON.stringify(this.state.dataLevelEdited),
			// 	// disabled: JSON.stringify(this.props.CustomDataDefItem) === JSON.stringify(this.props.CustomDataDefEdited),
			// 	iconProps: { iconName: 'RevToggleKey' },
			// 	onClick: () => { this.props.onChangeData(this.state.dataLevelEdited, this.props.parentId) },
			// },
		];
	}

	addNewRow() {
		this.setState({ dialog: { showEdit: true, showNew: true, showDelete: false } })
	}

	editRow() {
		this.setState({ dialog: { showEdit: true, showNew: false, showDelete: false } })
	}

	deleteRow() {
		this.setState({ dialog: { showEdit: false, showNew: false, showDelete: true } })
	}

	deleteSelectedRow() {
		const newData: CustomDataItem[] = this.state.dataLevelEdited.filter((_val, idx) => { return idx !== this._selection.getSelectedIndices()[0] })
		this.setState({ dataLevelEdited: newData })
		// this.props.onChangeData(newData, this.props.parentId)
		this.props.onDeleteField(this.state.dataLevelEdited[this._selection.getSelectedIndices()[0]].dataID!)
	}

	componentDidUpdate(prevProps: CustomDataLevelProps, _prevState: CustomDataLevelState) {
		if (prevProps.dataLevelItems !== this.props.dataLevelItems ||
			prevProps.parentId !== this.props.parentId) {

			console.log(this.props.dataLevelItems)
			// this._selection.setAllSelected(false)
			if (this.props.dataLevelItems === undefined) {
				this.setState({
					visible: false,
					selectedItems: []
				})
			}
			else {
				this.setState({
					visible: true,
					columns: this.getColums()!,
					dataLevelEdited: JSON.parse(JSON.stringify(this.props.dataLevelItems)),
					selectedItems: []
				})
			}
		}
		if (prevProps.dataLevelItems && this.props.dataLevelItems && prevProps.dataLevelItems[0]!?.dataID !== this.props.dataLevelItems[0]!?.dataID) {
			this._selection.setAllSelected(false)
		}
		if (this._selection.getSelectedCount() === 0) {
			this._selection.setIndexSelected(0, true, true)
		}
	}

	componentDidMount() {
		// this._selection.setAllSelected(false)
		if (this.props.dataLevelItems === undefined) {
			this.setState({
				visible: false,
				selectedItems: []
			})
		}
		else {
			this.setState({
				visible: true,
				columns: this.getColums()!,
				dataLevelEdited: JSON.parse(JSON.stringify(this.props.dataLevelItems)),
				selectedItems: []
			})
		}

	}

	render(): JSX.Element {
		console.log(this.state.dataLevelEdited)

		if (!this.state.visible) {
			return (<></>)
		}

		return (
			<Stack className='Width100'>
				<span className='Indent20'>
					{/* <h3>Custom data level {this.props.dataLevel + 1}</h3> */}
					<h3>{this.props.defDataLevelItem.LevelLabels.find(val => val.langCode.toLowerCase() === 'default')!?.dataLabel}</h3>
				</span>
				<CommandBar
					items={this.getCommandItems()}
					ariaLabel="Use left and right arrow keys to navigate between commands"
				/>
				{this.state.dataLevelEdited &&
					<ShimmeredDetailsList
						items={this.state.dataLevelEdited}
						columns={this.state.columns}
						enableShimmer={false}
						setKey="set"
						layoutMode={DetailsListLayoutMode.justified}
						selectionPreservedOnEmptyClick={true}
						selectionMode={SelectionMode.single}
						selection={this._selection}
						ariaLabelForSelectionColumn="Toggle selection"
						ariaLabelForSelectAllCheckbox="Toggle selection for all items"
						checkButtonAriaLabel="Row checkbox"
						checkboxVisibility={CheckboxVisibility.always}
						onActiveItemChanged={this._onActiveItemChanged}
					/>
				}
				<DialogEditCustomDetail
					isNew={this.state.dialog.showNew}
					hidden={!this.state.dialog.showEdit && !this.state.dialog.showNew}
					parentId={this.props.parentId}
					customDataItems={this.state.dialog.showNew ? [] : this.state.dataLevelEdited.filter((_val, idx) => { return idx === this._selection.getSelectedIndices()[0] })}
					customDataDefs={this.props.defDataLevelItem.CustomDataDefs}
					onChangeData={(dataItem, parentId) => { this.props.onChangeData(dataItem, parentId) }}
					onDismiss={(hidden) => { this.setState({ dialog: { showEdit: !hidden, showNew: !hidden, showDelete: false } }) }}
				/>
				<DialogDelete
					hidden={!this.state.dialog.showDelete}
					callbackDelete={() => { this.deleteSelectedRow(); this.setState({ dialog: { showEdit: false, showNew: false, showDelete: false } }) }}
					callbackCancel={() => { this.setState({ dialog: { showEdit: false, showNew: false, showDelete: false } }) }}
					title='Delete'
					description={this.state.dataLevelEdited[this._selection.getSelectedIndices()[0]]!?.SubLevels!?.length > 0 ?
						`${this.state.dataLevelEdited[this._selection.getSelectedIndices()[0]]!?.DataItems.find(i => i.LabelItem)!?.DataValue} has ${this.state.dataLevelEdited[this._selection.getSelectedIndices()[0]]!?.SubLevels[0]!?.LevelLabels[0]!?.dataLabel}. Are you sure you want to remove it?`
						:
						`Are you sure you want to remove ${this.state.dataLevelEdited[this._selection.getSelectedIndices()[0]]!?.DataItems.find(i => i.LabelItem)!?.DataValue}?`
					}
				/>
				<FloatingButton
					hidden={!this.props.showButtons}
					iconName='Add'
					tooltip='Add new field'
					right='200px'
					onClick={() => this.addNewRow()}
				/>
				<FloatingButton
					hidden={!this.props.showButtons || this._selection.getItems().length === 0}
					iconName='Edit'
					tooltip='Edit field'
					right='120px'
					onClick={() => this.editRow()}
				/>
				<FloatingButton
					hidden={!this.props.showButtons || this._selection.getItems().length === 0}
					iconName='Delete'
					tooltip='Delete field'
					right='40px'
					onClick={() => this.deleteSelectedRow()}
				/>
			</Stack>
		);
	}



	private _onActiveItemChanged = (item?: CustomDataItem): void => {
		// this.props.onChangeSelection(this._selection.getSelectedCount() ? (this._selection.getSelection()[0] as CustomDataItem)!.dataID : '')
		this.props.onChangeSelection(item?.dataID!)
		this.setState({
			selectedItems: item ? [item] : [],
			columns: this.getColums()!,
		})
	}

	// private _onActiveItemChanged = (item?: TemplateFolderItem): void => {
	// 	// console.log(this._selection.count)
	// 	this.setState({
	// 		selectedItem: item!,
	// 	})
	// 	this.props.getTemplates(item?.Path!, typeof item?.Root !== 'undefined' ? item?.Library! : '\\', item?.Root?.split('\\')[2].toUpperCase() || item?.Path.split('\\')[2].toUpperCase() || '')
	// }


	// private _onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
	// 	const { columns, items } = this.state;
	// 	const newColumns: IColumn[] = columns.slice();
	// 	const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
	// 	newColumns.forEach((newCol: IColumn) => {
	// 		if (newCol === currColumn) {
	// 			currColumn.isSortedDescending = !currColumn.isSortedDescending;
	// 			currColumn.isSorted = true;
	// 			// this.setState({
	// 			//   announcedMessage: `${currColumn.name} is sorted ${
	// 			// 	currColumn.isSortedDescending ? 'descending' : 'ascending'
	// 			//   }`,
	// 			// });
	// 		} else {
	// 			newCol.isSorted = false;
	// 			newCol.isSortedDescending = true;
	// 		}
	// 	});
	// 	const newItems = this._copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
	// 	this.setState({
	// 		columns: newColumns,
	// 		items: newItems,
	// 	});
	// };

	// _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
	// 	const key = columnKey as keyof T;
	// 	return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
	// }
}
