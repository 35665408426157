import React from 'react';
import { Stack, Text } from '@fluentui/react';
import SettingsCompany from '../../helpers/SettingsCompany';
import { ContentFrame } from '../common/ContentFrame';
import UserDataList from './UserDataList';
import { UserDataSelector } from './UserDataSelector';
import CombinedDataWrapper from './CombinedDataWrapper';

export interface UserDataProps {
	updateSettings(): Promise<void>;
	settings: SettingsCompany;
	accessToken: string;
	domain: string;
	api: string;
}

const UserData: React.FC<UserDataProps> = (props) => {
	//   const [settings] = useState(props.settings);
	const [selectedKey, setSelectedKey] = React.useState<'UserData' | 'CombinedData'>('UserData');

	if (!props.settings) {
		return <></>;
	}

	return (
		<Stack>
			<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
				<Text variant="xxLarge">User Data</Text>
			</Stack.Item>
			<UserDataSelector
				onModeChange={setSelectedKey}
				selectedKey={selectedKey}
			/>
			{selectedKey === 'UserData' ?
				<ContentFrame>
					<UserDataList />
				</ContentFrame>
				:
				<CombinedDataWrapper />
			}
		</Stack>
	);
};

export default UserData;
