import * as React from 'react';
import { Checkbox, DefaultButton, Stack, TextField, Toggle } from '@fluentui/react';
import Axios, { CancelTokenSource } from 'axios';
import SettingsCompany from '../../helpers/SettingsCompany';
import { UserAdminItem } from '../../interfaces/UserAdminItem';
import { ManifestItem } from '../../interfaces/ManifestItem';
import { GetManifestFile, GetManifestItems } from '../../helpers/GetFromRestApi';
import SettingsManifestPreview from './SettingsManifestPreview';
import { UploadFile } from '../../interfaces/UploadFile';
import { DialogUploadManifestIcon } from '../dialogs/DialogUploadManifestIcon';
import { uploadManifestIcon } from '../../helpers/PostToRestApi';
import { ExcelTheme, PowerPointTheme, WordTheme } from '../../helpers/Themes';

export interface SettingsManifestProps {
	updateSettings(): Promise<void>;
	setEditedSettings: (x: SettingsCompany) => void;
	editedSettings: SettingsCompany,
	userData: UserAdminItem,
	accessToken: string,
	// apiUri: string,
	domain: string
	api: string
	isSaved: boolean,
}

interface manifestPrograms {
	excel: { include: boolean, enabled: boolean }
	word: { include: boolean, enabled: boolean }
	powerpoint: { include: boolean, enabled: boolean }
}

export interface SettingsManifestState {
	manifestItems: ManifestItem[]
	updateVersion: boolean
	hideUploadDialog: boolean
	uploadConfirmed: boolean
	uploadItems: UploadFile[]
	programSettings: manifestPrograms
	// includeExcel: boolean
	// includeWord: boolean
	// includePowerPoint: boolean
}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class SettingsManifest extends React.Component<SettingsManifestProps, SettingsManifestState> {
	constructor(props: SettingsManifestProps) {
		super(props)
		this.state = {
			manifestItems: [],
			updateVersion: false,
			hideUploadDialog: true,
			uploadConfirmed: false,
			uploadItems: {} as UploadFile[],
			programSettings: {
				excel: { include: false, enabled: false },
				word: { include: false, enabled: false },
				powerpoint: { include: false, enabled: false },
			},
			// includeExcel: false,
			// includeWord: false,
			// includePowerPoint: false,
		};
		this.boundSetState = this.setState.bind(this)
	}
	updatingLogo: boolean = false

	boundSetState = (x: any) => { this.setState(x) }

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	componentDidMount() {
		this.getItems()
			.then(() => {
				this.checkProgramsInSettings()
			})
	}

	componentDidUpdate(_prevProps: SettingsManifestProps, _prevState: SettingsManifestState) {
		if (this.state.manifestItems.length === 0 ||
			_prevProps.editedSettings.Extensions !== this.props.editedSettings.Extensions ||
			_prevProps.isSaved !== this.props.isSaved) {
			this.getItems()
				.then(() => {
					this.checkProgramsInSettings()
				})
		}
		if (!_prevState.uploadConfirmed && this.state.uploadConfirmed) {
			this._uploadFile()
		}
	}

	getItems = async () => {
		ourRequest = Axios.CancelToken.source()
		await GetManifestItems(this.props.accessToken, this.props.domain, this.props.api, ourRequest.token)
			.then((items) => {
				this.setState({ manifestItems: items })
			})
			.catch(() => {
				this.setState({ manifestItems: [{ Title: 'No items', Text: '', CheckText: '', Mandatory: false, Locked: true }] })
			})
	}

	checkProgramsInSettings = () => {
		let includeExcel: boolean = false
		let includeWord: boolean = false
		let includePowerPoint: boolean = false
		let enabledExcel: boolean = false
		let enabledWord: boolean = false
		let enabledPowerPoint: boolean = false
		this.props.editedSettings.Extensions!?.forEach((extension) => {
			if (extension.ExcelDesktop || extension.ExcelOnline) { includeExcel = true }
			if (extension.WordDesktop || extension.WordOnline) { includeWord = true }
			if (extension.PowerPointDesktop || extension.PowerPointOnline) { includePowerPoint = true }
			if (extension.ActivateExcel) { enabledExcel = true }
			if (extension.ActivateWord) { enabledWord = true }
			if (extension.ActivatePowerPoint) { enabledPowerPoint = true }
		})
		this.setState({
			programSettings: {
				excel: { include: includeExcel, enabled: enabledExcel },
				word: { include: includeWord, enabled: enabledWord },
				powerpoint: { include: includePowerPoint, enabled: enabledPowerPoint },
			}
		})
	}

	async getManifest() {
		ourRequest = Axios.CancelToken.source()
		// let selectedFile: ExtendedTemplateListItem = this._selection.getSelection()[0] as ExtendedTemplateListItem
		let xmlData = await GetManifestFile(this.props.accessToken, this.props.domain, this.props.api, this.state.programSettings.excel.include, this.state.programSettings.powerpoint.include, this.state.programSettings.word.include, this.state.updateVersion, ourRequest.token)
		let anchor: HTMLAnchorElement = document.createElement("a");

		anchor.href = 'data:text/xml;charset=utf-8,' + encodeURIComponent((xmlData));
		anchor.download = 'manifest.xml';
		anchor.click(); //Downloaded file
	}

	downloadEnabled = (): boolean => {
		if (!this.props.isSaved) {
			return false
		}
		else {
			return this.state.manifestItems.every((item) => {
				if (item.Mandatory && item.Text === '') {
					return false
				}
				else { return true }
			})
		}
	}

	onChangeManifestField = (item: ManifestItem): void => {
		let manifestitems: ManifestItem[] = this.state.manifestItems
		manifestitems[manifestitems!.findIndex(val => val.Title === item.Title)] = item
		this.props.setEditedSettings(Object.assign(this.props.editedSettings, { Manifest: manifestitems }))
	}

	render() {
		return (
			this.state.manifestItems.length > 0 ?
				<>
					<Stack tokens={{ childrenGap: 10 }} >
						<Stack.Item>
							<Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign='center'>
								<img
									src="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/word_32x1.svg"
									width="32"
									height="32"
									alt="Word product icon"
								/>
								<Checkbox
									ariaLabel='Include in Word'
									theme={WordTheme}
									disabled={!this.state.programSettings.word.enabled}
									checked={this.state.programSettings.word.include}
									onChange={(_ev, checked) => this.setState({ programSettings: { ...this.state.programSettings, word: { ...this.state.programSettings.word, include: checked! } } })}
								/>
								<div style={{ width: '10px' }} />
								<img
									src="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/powerpoint_32x1.svg"
									width="32"
									height="32"
									alt="PowerPoint product icon"
								/>
								<Checkbox
									ariaLabel='Include in PowerPoint'
									theme={PowerPointTheme}
									disabled={!this.state.programSettings.powerpoint.enabled}
									checked={this.state.programSettings.powerpoint.include}
									onChange={(_ev, checked) => this.setState({ programSettings: { ...this.state.programSettings, powerpoint: { ...this.state.programSettings.powerpoint, include: checked! } } })}
								/>
								<div style={{ width: '10px' }} />
								<img
									src="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/excel_32x1.svg"
									width="32"
									height="32"
									alt="Excel product icon"
								/>
								<Checkbox
									ariaLabel='Include in Excel'
									theme={ExcelTheme}
									disabled={!this.state.programSettings.excel.enabled}
									checked={this.state.programSettings.excel.include}
									onChange={(_ev, checked) => this.setState({ programSettings: { ...this.state.programSettings, excel: { ...this.state.programSettings.excel, include: checked! } } })}
								/>
							</Stack>
						</Stack.Item>
						<Stack.Item>
							<Toggle
								label='Update manifest version'
								inlineLabel
								onText="Yes"
								offText="No"
								checked={this.state.updateVersion}
								onChange={(_ev, checked) => this.setState({ updateVersion: checked! })}
							/>
						</Stack.Item>
						<Stack.Item>
							<DefaultButton
								key='download'
								text='Download manifest'
								primary
								iconProps={{ iconName: 'Download' }}
								disabled={!this.downloadEnabled()}
								onClick={() => {
									this.getManifest()
										.then(() => {
											setTimeout(() => {
												this.getItems()
											}, 1000)
										})
								}}
							/>
						</Stack.Item>
					</Stack>
					<Stack horizontal tokens={{ childrenGap: 20 }} >
						<Stack.Item grow styles={{ root: { maxWidth: '500px' } }} >
							<Stack tokens={{ childrenGap: 5 }} >

								{this.state.manifestItems
									.map((item: ManifestItem, idx: number, _arr: ManifestItem[]) => {
										return (
											<Stack.Item key={idx}>
												<TextField
													label={item.Title}
													value={item.Text}
													required={item.Mandatory}
													disabled={item.Locked}
													onChange={(_ev, value) => this.onChangeManifestField(Object.assign(item, item.Text = value!))}
													errorMessage={item.Mandatory && !item.Text ? 'Field must be filled out' : ''}
												/>
											</Stack.Item>
										)
									})}
								<Stack.Item align='start' styles={{ root: { paddingTop: '20px' } }}>
									<DefaultButton
										key='upload'
										text='Upload Custom Icon'
										primary
										iconProps={{ iconName: 'Upload' }}
										onClick={() => { this._uploadPrompt() }}
									/>
								</Stack.Item>
							</Stack>
						</Stack.Item>
						<Stack.Item>
							<SettingsManifestPreview
								// iconSrc='https://365templateextender.azurewebsites.net/assets/icon-32.png'
								iconSrc={this.props.editedSettings.RibbonIcon32 + '?random=' + new Date().getTime()}
								manifestItems={this.state.manifestItems}
							/>
						</Stack.Item>
					</Stack >
					<DialogUploadManifestIcon setParentState={this.boundSetState} hidden={this.state.hideUploadDialog} hideState='hideUploadDialog' confirmState='uploadConfirmed' uploadItemState='uploadItems' />
				</>
				: null
		)
	}
	private _uploadPrompt() {
		this.setState({ hideUploadDialog: false })
	}

	private _uploadFile(): void {
		this.state.uploadItems.forEach((value, _index, _array) => {
			uploadManifestIcon(
				this.props.accessToken,
				this.props.domain,
				this.props.api,
				value.data
			)
				.then(async (response) => {
					setTimeout(() => {
						this.setState({ uploadConfirmed: false })
						this.props.updateSettings()
					}, 500)

					if (response.status === 200) {

					}
					else if (response.status === 405) {

					}
					else {

					}
				});
		})
	}
}
