import { IconButton, TooltipHost } from "@fluentui/react"
import * as React from "react"

interface HtmlButtonsProps {
    InsertTextAtSelection: (startText: string, endText?: string) => void
    disabled?: boolean
}

const HtmlButtons: React.FunctionComponent<HtmlButtonsProps> = ({ InsertTextAtSelection, disabled = false }) => {
    return (
        <>
            <TooltipHost content='Bold'>
                <IconButton
                    iconProps={{ iconName: 'Bold' }}
                    disabled={disabled}
                    onClick={() => InsertTextAtSelection('<b>', '</b>')}
                />
            </TooltipHost>

            <TooltipHost content='Italic'>
                <IconButton
                    iconProps={{ iconName: 'Italic' }}
                    disabled={disabled}
                    onClick={() => InsertTextAtSelection('<i>', '</i>')}
                />
            </TooltipHost>

            <TooltipHost content='Underline'>
                <IconButton
                    iconProps={{ iconName: 'Underline' }}
                    disabled={disabled}
                    onClick={() => InsertTextAtSelection('<u>', '</u>')}
                />
            </TooltipHost>

            <TooltipHost content='Text format'>
                <IconButton
                    iconProps={{ iconName: 'EditStyle' }}
                    disabled={disabled}
                    onClick={() => InsertTextAtSelection('<span style="">', '</span>')}
                />
            </TooltipHost>

            <TooltipHost content='Blank space'>
                <IconButton
                    iconProps={{ iconName: 'MaximumValue' }}
                    disabled={disabled}
                    onClick={() => InsertTextAtSelection('&nbsp;')}
                />
            </TooltipHost>
            <TooltipHost content='Line feed'>
                <IconButton
                    iconProps={{ iconName: 'ReturnKey' }}
                    disabled={disabled}
                    onClick={() => InsertTextAtSelection('<br>')}
                />
            </TooltipHost>
        </>
    )
}

export default HtmlButtons