import React from 'react';
import { getTheme, Stack } from '@fluentui/react';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';
import { PlotItem } from '../../interfaces/PlotItem';

export interface StatisticsBarChartProps {
	title: string;
	plotItems: PlotItem[];
	horizontal: boolean;
}

const theme = getTheme();

const StatisticsBarChart: React.FC<StatisticsBarChartProps> = (props) => {
	if (props.plotItems.length === 0) {
		return <></>;
	}

	const data: PlotItem[] = props.plotItems;

	return (
		<Stack style={{ width: '100%'}}>
			<span className='Indent20'>
				<h3>{props.title}</h3>
			</span>
			<VictoryChart domainPadding={{ x: 15 }} padding={{ left: 120, right: 50 }} height={data.length * 30 + 0}>
				<VictoryAxis style={{ axis: { strokeWidth: 0 } }} />
				<VictoryBar
					horizontal={props.horizontal}
					barRatio={0.9}
					style={{
						data: {
							fill: theme.palette.themePrimary,
							width: 25,
						},
					}}
					data={data}
					labels={({ datum }) => `${datum.y}`}
				/>
			</VictoryChart>
		</Stack>
	);
};

export default StatisticsBarChart;
