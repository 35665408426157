import * as React from 'react';
import {
	ICommandBarItemProps,
	Facepile,
	IFacepilePersona,
	PersonaSize,
	OverflowButtonType,
	IButtonProps,
	Label,
	Stack,
	Persona,
	IFacepileProps,
	ChoiceGroup,
	DefaultButton,
	IChoiceGroupOption,
	IChoiceGroupOptionProps,
} from '@fluentui/react';
import { CancelTokenSource } from 'axios';
import { getGroupMembers, getUserPhoto } from '../../helpers/GetFromGraph';
import { GroupDetails } from '../../interfaces/GroupDetails';
import { GraphUser } from '../../interfaces/GraphUser';

export interface GroupsContentProps {
	changeVersion(version: string): void,
	accessToken: string,
	allGroups: GroupDetails[],
	companyGroups: GroupDetails[],
	selectedGroup: GroupDetails,
	isUsersTenant: boolean,
	templateVersion: string,
}

export interface GroupsContentState {
	// selectedGroup: GroupDetails,
	members: IFacepilePersona[]
}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class GroupsContent extends React.Component<GroupsContentProps, GroupsContentState> {

	constructor(props: GroupsContentProps) {
		super(props);
		this.state = {
			// selectedGroup: {} as GroupDetails,
			members: [],
		};
	}

	commandItems = (): ICommandBarItemProps[] => [
		// {
		// 	key: 'upload',
		// 	text: 'Upload',
		// 	disabled: (this.props.templateVersion === 'PUB'),
		// 	iconProps: { iconName: 'Upload' },
		// 	onClick: () => this._uploadPrompt(),
		// },
		// {
		// 	key: 'download',
		// 	text: 'Download',
		// 	disabled: false,
		// 	iconProps: { iconName: 'Download' },
		// 	onClick: () => this._downloadFile(),
		// },
		// {
		// 	key: 'edit',
		// 	text: 'Edit',
		// 	disabled: true,
		// 	iconProps: { iconName: 'Edit' },
		// 	onClick: () => console.log('Edit'),
		// },
		// {
		// 	key: 'delete',
		// 	text: 'Delete',
		// 	iconProps: { iconName: 'Delete' },
		// 	onClick: () => this._deletePrompt(),
		// },
		// {
		// 	key: 'save',
		// 	text: 'Save',
		// 	disabled: true,
		// 	iconProps: { iconName: 'Save' },
		// 	onClick: () => console.log('Save'),
		// },
		// {
		// 	key: 'publish',
		// 	text: 'Publish',
		// 	disabled: true,
		// 	iconProps: { iconName: 'PublishContent' },
		// 	onClick: () => console.log('Publish'),
		// },
	];

	getMembers = async () => {
		const users: GraphUser[] = await getGroupMembers(this.props.accessToken, this.props.selectedGroup?.id)
		let members: IFacepilePersona[] = users?.map((userItem: GraphUser): IFacepilePersona => {
			return { personaName: userItem.displayName, data: userItem.id } as IFacepilePersona
		});
		if (members?.length > 10) {
			let firstMembers: IFacepilePersona[] = members.splice(0, 10)
			firstMembers = await Promise.all(firstMembers.map(async (item): Promise<IFacepilePersona> => {
				const photo: string = await getUserPhoto(this.props.accessToken, item.data)
				return { ...item, imageUrl: 'data:image/jpg;base64,' + photo } as IFacepilePersona
			}))
			members = firstMembers.concat(members)
		}
		else if (members?.length > 0) {
			members = await Promise.all(members?.map(async (item): Promise<IFacepilePersona> => {
				const photo: string = await getUserPhoto(this.props.accessToken, item.data)
				return { ...item, imageUrl: 'data:image/jpg;base64,' + photo } as IFacepilePersona
			}))
		}
		this.setState({
			members: members
		})
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	componentDidUpdate(_prevProps: Readonly<GroupsContentProps>, _prevState: Readonly<GroupsContentState>, _snapshot?: any): void {
		if (_prevProps.selectedGroup !== this.props.selectedGroup) {
			if (this.props.isUsersTenant) {
				this.getMembers()
			}
		}
	}

	componentDidMount(): void {
		
	}

	onRenderField = (opt: IChoiceGroupOption | IChoiceGroupOptionProps |undefined) => {
		return (
			<DefaultButton
				primary={opt!.key === this.props.templateVersion}
				disabled={opt!.disabled}
				tabIndex={-1}
				aria-hidden
				onClick={() => this.props.changeVersion(opt!.key!)}
			>
				{opt!.text}
			</DefaultButton>
		);
	};

	versionOptions: IChoiceGroupOption[] = [
		{ key: 'TEST', id: 'TEST', text: 'Test', onRenderField:  this.onRenderField },
		{ key: 'PUB', id: 'PUB', text: 'Published', onRenderField:this.onRenderField}
	];


	render(): JSX.Element {

		const groupMemberList = (): JSX.Element => {
			if (!!this.props.selectedGroup.id && this.props.isUsersTenant && this.state.members?.length > 0) {

				// const myGroups: GroupInfo[] = this.props.groupIds.filter((group: GroupInfo) => {
				// 	return this.props.folderGroupIds?.filter((foldergroup) => {
				// 		return group?.groupID === foldergroup.groupID
				// 	}).length! > 0
				// })
				const facepileProps: IFacepileProps = {
					personas: this.state.members,
					personaSize: PersonaSize.size40,
					maxDisplayablePersonas: 10,
					overflowButtonType: OverflowButtonType.descriptive,
					overflowButtonProps: { ariaLabel: 'More' } as IButtonProps,
					ariaDescription: 'To move through the items use left and right arrow keys.',
					ariaLabel: 'List of group´s members',
					onRenderPersona: (item: IFacepilePersona | undefined) => {
						return (
							<Persona key={item?.personaName} text={item?.personaName} size={PersonaSize.size40} imageUrl={item?.imageUrl} hidePersonaDetails={true} />
						)
					}
				}

				return (
					<Stack style={{ textIndent: '0px', marginLeft: '20px', }} tokens={{ childrenGap: '10px' }}>
						<Label>Members: {this.state.members.length}</Label>
						<Stack.Item className="ms-bgColor-white ms-hiddenMdDown">
							<Facepile
								{...facepileProps}
								maxDisplayablePersonas={10}
							/>
						</Stack.Item>
						<Stack.Item className="ms-bgColor-white ms-hiddenLgUp">
							<Facepile
								{...facepileProps}
								maxDisplayablePersonas={5}
							/>
						</Stack.Item>
					</Stack>
				)
			}
			else { return <></> }
		}

		return (
			<Stack className='Width100' style={{ marginBottom: 50 }}>
				<span className='Indent20'>
					<h3>Group content</h3>
					{groupMemberList()}
					<ChoiceGroup
						aria-label="Icon version:"
						options={this.versionOptions}
						styles={{ flexContainer: { display: 'flex', flexDirection: 'row', marginTop: 20 } }}
					// onChange={this.onChangeVersion}
					/>
				</span>
			</Stack>
		);
	}
}
