import * as React from 'react';
import { Icon, TooltipHost } from 'office-ui-fabric-react';
import Parser from 'html-react-parser';

interface description {
	key: string;
	icon?: string
	desc: string | JSX.Element;
}

const descriptions: description[] = [
	{ key: 'functions_microsoft_365_groups', icon: 'Info', desc: (<>Turn on this function to enable content for Microsoft 365 groups.<br />Users that are members of a group that have uploaded content will have access to the group's templates, pictures and icons.</>) },
	{ key: 'extensions_imagevault_secret', icon: 'Info', desc: (<>Turn on this function to enable content for Microsoft 365 groups.<br />Users that are members of a group that have uploaded content will have access to the group's templates, pictures and icons.</>) },
]

export default React.memo(function getDescription(key: string): any {
	try {
		return descriptions.find(item => item.key === key.toLowerCase())?.desc
	} catch (error) {
		console.log('ERROR' + key)
		return ''
	}
})

export function getTooltip(key: string): JSX.Element {
	try {
		const desc: description = descriptions.find(item => item.key === key.toLowerCase().replace(/ /g, '_'))!
		return (
			<TooltipHost content={desc.desc}>
				<Icon iconName={desc.icon} aria-label={`${desc.icon} tooltip`} />
			</TooltipHost>
		) //descriptions.find(item => item.key === key.toLowerCase())?.desc
	} catch (error) {
		console.log('ERROR' + key)
		return (<></>)
	}
}

export function getTooltipFromString(desc: string | JSX.Element): JSX.Element {
	try {
		if (desc) {
			return (
				<TooltipHost content={typeof desc === 'string' ? <>{Parser(`${desc}`)}</> : desc}>
					<Icon iconName={'Info'} aria-label={`Info tooltip`} />
				</TooltipHost>
			)
		}
		else {
			return <></>
		}
	} catch (error) {
		return <></>
	}
}