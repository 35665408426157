import { EmbeddedPictureItem } from "./EmbeddedPictureItem";

export interface EmailSignature {
	SignatureName: string
    DefaultSignature: boolean
    ReplySignature: boolean
    HTML: string
    EmbeddedPictures: EmbeddedPictureItem[]
}

export const exampleEmailSignature: EmailSignature = {
    SignatureName: '',
    DefaultSignature: false,
    ReplySignature: false,
    HTML: '',
    EmbeddedPictures: []
}