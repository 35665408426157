import * as React from 'react';
import {
	CommandBar,
	ICommandBarItemProps,
	SelectionMode,
	ShimmeredDetailsList,
	IColumn,
	CheckboxVisibility,
	OverflowButtonType,
	PersonaSize,
	Persona,
	Stack,
	Icon,
} from '@fluentui/react';
import { Facepile, IFacepilePersona } from 'office-ui-fabric-react/lib/Facepile';
import { DetailsListLayoutMode, Selection, } from 'office-ui-fabric-react/lib/DetailsList';
import { hasValues } from '../../helpers/MiscFunctions';
import { CancelTokenSource } from 'axios';
import SettingsCompany, { GetUseGroups } from '../../helpers/SettingsCompany';
import { TemplateFolderItem } from '../../interfaces/TemplateFolderItem';
import { GroupInfo } from '../../interfaces/GroupInfo';
import { GroupDetails } from '../../interfaces/GroupDetails';
import { ContentFolder } from '../../interfaces/ContentFolder';

export interface WordContentFoldersProps {
	getFolders(version: string): void;
	setPathId(pathId: string): void
	onCommandItemClick(key: string, selectedItem: ContentFolder): void
	folderItems: ContentFolder[]
	selectedPathId: string
	groupIds: GroupInfo[]
	companyGroups: GroupDetails[]
	settings: SettingsCompany
	templateVersion: string
}

export interface WordContentFoldersState {
	items: ContentFolder[];
	columns: IColumn[];
	selectedItem: ContentFolder;
	selection: Selection;
}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class WordContentFolders extends React.Component<WordContentFoldersProps, WordContentFoldersState> {
	private _selection: Selection;

	constructor(props: WordContentFoldersProps) {
		super(props);
		this._selection = new Selection({
			onSelectionChanged: () => this.setState({ selection: this._selection }),
		});
		this.state = {
			items: [],
			columns: [{ key: 'column1', name: '', minWidth: 16, }],
			selectedItem: {} as ContentFolder,
			selection: this._selection,
			// newFolderItem: {} as ContentFolder,

		};
	}

	commandItems = (): ICommandBarItemProps[] => [
		...(this.props.templateVersion === 'TEST' ? [{
			key: 'newFolder',
			text: 'New Folder',
			// disabled: (this.props.templateVersion === 'PUB'),
			iconProps: { iconName: 'FabricNewFolder' },
			onClick: () => this.props.onCommandItemClick('newFolder', this.state.selectedItem),
		},
		{
			key: 'edit',
			text: 'Edit',
			disabled: (!this.state.selectedItem.Path),
			iconProps: { iconName: 'Edit' },
			onClick: () => this.props.onCommandItemClick('edit', this.state.selectedItem),
		},
		{
			key: 'delete',
			text: 'Delete',
			disabled: (!this.state.selectedItem.Path || !this.state.selectedItem.isEmpty),
			iconProps: { iconName: 'Delete' },
			onClick: () => this.props.onCommandItemClick('delete', this.state.selectedItem),
		},
		{
			key: 'publishAll',
			text: 'Publish All',
			// disabled: true,
			iconProps: { iconName: 'PublishContent' },
			onClick: () => this.props.onCommandItemClick('publishAll', this.state.selectedItem),
		}] : []),
	];

	getColums(): IColumn[] {
		return [
			{
				key: 'column0',
				name: 'Icon',
				fieldName: 'icon',
				// iconName: 'FabricFolder',				
				isIconOnly: true,
				minWidth: 16,
				maxWidth: 16,
				isResizable: false,
				onRender: (item: ContentFolder) => { return <Icon styles={{root: {fontSize: '16px'}}} iconName={item.FullPath === this.state.selectedItem.FullPath ? 'FabricOpenFolderHorizontal': 'FabricFolder'}/>; },
			},
			{
				key: 'column1',
				name: 'Folder',
				fieldName: 'FullPath',
				minWidth: 250,
				maxWidth: 400,
				isResizable: true,
				// onColumnClick: this._onColumnClick,
				onRender: (item: ContentFolder) => { return !item.FullPath ? '\\' : item.FullPath },
			},
			{
				key: 'column2',
				name: '# Contents',
				fieldName: 'ContentCount',
				minWidth: 100, maxWidth: 200,
				isResizable: true,
				// onColumnClick:this._onColumnClick,
			},
			...(GetUseGroups(this.props.settings)) ? [{
				key: 'column3',
				name: 'Groups',
				fieldName: 'templateGroups',
				minWidth: 150,
				maxWidth: 250,
				isResizable: true,
				// onColumnClick: this._onColumnClick,
				onRender: (item: TemplateFolderItem) => {
					if (item.templateGroups && !!this.props.groupIds) {
						const myGroups: GroupInfo[] = this.props.groupIds.filter((group: GroupInfo) => {
							return item.templateGroups?.filter((foldergroup) => {
								return group?.groupID === foldergroup.groupID
							}).length! > 0
						})
						// const groups: IFacepilePersona[] = item.templateGroups?.map((groupItem: GroupID) => {return {personaName: groupItem.groupID, label: '',  }});
						const groups: IFacepilePersona[] = myGroups.map((groupItem: GroupInfo) => { return { personaName: groupItem.groupName, label: '', } });
						return (
							<Facepile
								personas={groups}
								personaSize={PersonaSize.size24}
								maxDisplayablePersonas={3}
								overflowButtonType={OverflowButtonType.descriptive}
								overflowButtonProps={{ ariaLabel: 'More' }}
								ariaDescription={'To move through the items use left and right arrow keys.'}
								ariaLabel={'List of folder´s groups'}
								onRenderPersona={(item: IFacepilePersona | undefined) => {
									return (
										<Persona key={item?.personaName} text={item?.personaName} size={PersonaSize.size24} hidePersonaDetails={true} />
									)
								}}
							/>
						)
					}
					else { return null }
				},
			}] : []
		]
	}

	onActiveItemChanged = (item?: ContentFolder): void => {
		this.setState({
			selectedItem: item!,
		})
		this.props.setPathId(item?.PathID!)
	}

	componentDidUpdate(prevProps: Readonly<WordContentFoldersProps>, _prevState: Readonly<WordContentFoldersState>, _snapshot?: any): void {
		if (prevProps.folderItems !== this.props.folderItems) {
			const selected: ContentFolder = this.props.folderItems.find((item) => { return item.PathID === this.props.selectedPathId })!
			this.setState({
				columns: this.getColums(),
				items: this.props.folderItems,
				selectedItem: !!selected ? selected : this.props.folderItems[0]
			});
		}
		else if (_prevState.selectedItem !== this.state.selectedItem){
			this.setState({
				columns: this.getColums(),
			});
		}
		if (this._selection.getSelectedCount() === 0) {
			this._selection.setIndexSelected(0, true, true)
		}
	}

	componentDidMount(): void {
		const selected: ContentFolder = this.props.folderItems.find((item) => { return item.PathID === this.props.selectedPathId })!
		this.setState({
			columns: this.getColums(),
			items: this.props.folderItems,
			selectedItem: !!selected ? selected : this.props.folderItems[0]
		});
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	render(): JSX.Element {
		console.log(this.state.items)
		if (!this.state.items) {
			return (<></>)
		}

		return (
			<Stack className='Width100'>
				<span className='Indent20'>
					<h3>{this.props.templateVersion === 'TEST' ? 'Folders in Test' : 'Published Folders'}</h3>
				</span>

				{this.props.templateVersion === 'TEST' && (
					<CommandBar
						items={this.commandItems()}
						ariaLabel="Use left and right arrow keys to navigate between commands"
					/>
				)}
				<ShimmeredDetailsList
					items={this.state.items}
					columns={this.state.columns}
					enableShimmer={!hasValues(this.state.items)}
					setKey="set"
					layoutMode={DetailsListLayoutMode.justified}
					selectionMode={this.state.items.length ? SelectionMode.single : SelectionMode.none}
					selection={(this._selection)}
					selectionPreservedOnEmptyClick={true}
					ariaLabelForSelectionColumn="Toggle selection"
					ariaLabelForSelectAllCheckbox="Toggle selection for all items"
					checkButtonAriaLabel="Row checkbox"
					checkboxVisibility={CheckboxVisibility.always}
					onActiveItemChanged={this.onActiveItemChanged}
				/>
			</Stack>
		);
	}
}