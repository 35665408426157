import * as React from 'react';
import ReactDOMServer from 'react-dom/server'
import {
	CheckboxVisibility,
	CommandBar,
	IColumn,
	ICommandBarItemProps,
	IconButton,
	Image,
	IStackItemStyles,
	mergeStyleSets,
	MessageBarType,
	Modal,
	ProgressIndicator,
	SelectionMode,
	Separator,
	ShimmeredDetailsList,
	ShimmerElementsGroup,
	Stack,
} from '@fluentui/react';
import { DetailsListLayoutMode, Selection } from 'office-ui-fabric-react/lib/DetailsList';
import PictureItem from './PictureItem';
import { deletePicture, updatePictures, uploadPicture } from '../../helpers/PostToRestApi';
import { GetPicture } from '../../helpers/GetFromRestApi';
import PicturePreviewPanel from './PicturePreviewPanel';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { sortArray } from '../../helpers/MiscFunctions';
import { Shimmer, ShimmerElementType } from 'office-ui-fabric-react';
import SettingsCompany, { GetMaxPictureCount } from '../../helpers/SettingsCompany';
import { DialogUploadPictures } from '../dialogs/DialogUploadPictures';
import { DialogUpdatePictureMeta } from '../dialogs/DialogUpdatePictureMeta';
import { PictureListItem } from '../../interfaces/PictureListItem';
import { UploadFile } from '../../interfaces/UploadFile';
import { PictureSrcLib } from '../../interfaces/PictureSrcLib';
import { MessageObject } from '../../interfaces/MessageObject';
import { DialogDelete } from '../dialogs/OldDialogDelete';
import { DialogOkOnly } from '../dialogs/OldDialogOkOnly';
import { MessageContextType } from '../../interfaces/ContextType';

export interface PicturesDetailsProps {
	setMessage: (x: MessageContextType) => void;
	getLayoutItems(reset: boolean): void;
	getPictures(): void;
	pictureItems: PictureListItem[];
	accessToken: string,
	// apiUri: string,
	domain: string
	api: string
	selectedSize: PictureSrcLib;
	settings: SettingsCompany;
	totalPicCount: number;
}

export interface PicturesDetailsState {
	sortedItems: PictureListItem[];
	// sortedItems: PictureListItem[];
	columns: IColumn[];
	showList: boolean;
	showPanel: boolean;
	showPreviewModal: boolean;
	selectedIndices: number[]
	selectedItem: PictureListItem;
	selectedItemBase64: string;
	selection: Selection;
	previewPanelOn: boolean;
	hideDeleteDialog: boolean;
	hideEditDialog: boolean;
	hideUploadDialog: boolean;
	hideNoSelectDialog: boolean;
	hideFileExist: boolean;
	hideUploadFull: boolean;
	deleteConfirmed: boolean;
	uploadConfirmed: boolean;
	uploadItems: UploadFile[];
}

const classNames = mergeStyleSets({
	filePictureHeaderPicture: {
		padding: 0,
		fontSize: '16px',
	},
	filePictureCell: {
		textAlign: 'center',
		selectors: {
			'&:before': {
				content: '.',
				display: 'inline-block',
				verticalAlign: 'middle',
				height: '100%',
				width: '0px',
				visibility: 'hidden',
			},
		},
	},
	filePictureImg: {
		verticalAlign: 'middle',
		maxHeight: '16px',
		maxWidth: '16px',
	},
	controlWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	exampleToggle: {
		display: 'inline-block',
		marginBottom: '10px',
		marginRight: '30px',
	},
	selectionDetails: {
		marginBottom: '20px',
	},
});

let infoMessage: HTMLDivElement | null = null;

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class PicturesDetails extends React.Component<PicturesDetailsProps, PicturesDetailsState> {
	private _selection: Selection;

	constructor(props: PicturesDetailsProps) {
		super(props);
		this._selection = new Selection({
			onSelectionChanged: () => {
				const indices = this._selection !== undefined ? this._selection.getSelectedIndices() : []
				this.setState({ selection: this._selection, selectedIndices: indices })
			},
		});
		this.state = {
			sortedItems: [],
			columns: [{ key: 'column1', name: '', minWidth: 16, }],
			showList: false,
			showPanel: false,
			showPreviewModal: false,
			selectedIndices: [],
			selectedItem: {} as PictureListItem,
			selectedItemBase64: '',
			selection: this._selection,
			previewPanelOn: false,
			hideDeleteDialog: true,
			hideEditDialog: true,
			hideUploadDialog: true,
			hideNoSelectDialog: true,
			hideFileExist: true,
			hideUploadFull: true,
			deleteConfirmed: false,
			uploadConfirmed: false,
			uploadItems: [],
		};
		this.boundSetState = this.setState.bind(this)
	}

	deleting: Boolean = false
	uploading: Boolean = false

	boundSetState = (x: any) => { this.setState(x) }

	getCommandBarItems(): ICommandBarItemProps[] {
		return [
			{
				key: 'upload',
				text: 'Upload',
				// disabled: !this.state.showList,
				iconProps: { iconName: 'Upload' },
				onClick: () => this._uploadPrompt(),
			},
			{
				key: 'download',
				text: 'Download',
				disabled: !this.state.showList,
				iconProps: { iconName: 'Download' },
				onClick: () => this._downloadFile(),
			},
			{
				key: 'edit',
				text: 'Edit',
				disabled: !this.state.showList,
				iconProps: { iconName: 'Edit' },
				onClick: () => this._editPrompt(),
			},
			{
				key: 'delete',
				text: 'Delete',
				disabled: !this.state.showList,
				iconProps: { iconName: 'Delete' },
				onClick: () => this._deletePrompt(),
			},
			// {
			// 	key: 'publish',
			// 	text: 'Publish',
			// 	disabled: true,
			// 	iconProps: { iconName: 'PublishContent' },
			// 	onClick: () => console.log('Publish'),
			// },
			...((this.state.showPanel) ?
				[{
					key: 'autoHidePanel',
					text: 'Show panel on click',
					label: 'Show panel on click',
					iconProps: { iconName: 'CheckboxComposite' },
					disabled: !this.state.showList,
					onClick: () => this.setState({ showPanel: false, previewPanelOn: false }),
				}]
				:
				[{
					key: 'autoShowPanel',
					text: 'Show panel on click',
					label: 'Show panel on click',
					iconProps: { iconName: 'Checkbox' },
					disabled: !this.state.showList,
					onClick: () => this.setState({ showPanel: true, previewPanelOn: true }),
				}]),
			{
				key: 'view',
				text: this.state.showList ? 'List view' : 'Preview',
				ariaLabel: this.state.showList ? 'List view' : 'Preview',
				iconProps: { iconName: this.state.showList ? 'GroupedList' : 'Tiles' },
				subMenuProps: {
					items: [
						{
							key: 'list',
							text: 'List view',
							label: 'List view',
							iconProps: { iconName: 'GroupedList' },
							onClick: () => this.setState({ showList: true }),
						},
						{
							key: 'tile',
							text: 'Preview',
							label: 'Preview',
							iconProps: { iconName: 'Tiles' },
							onClick: () => this.setState({ showList: false }),
						},
					]
				}
			},
		]
	}

	getColums(): IColumn[] {
		return [
			{
				key: 'column1',
				name: 'Picture',
				fieldName: 'id',
				className: classNames.filePictureCell,
				iconClassName: classNames.filePictureHeaderPicture,
				ariaLabel: 'Column operations for File type, Press to sort on File type',
				iconName: 'FileImage',
				isIconOnly: true,
				minWidth: 32,
				maxWidth: 32,
				isResizable: false,
				onColumnClick: this._onColumnClick,
				onRender: (item: PictureListItem) => {
					return (
						<Stack style={{ height: '24px' }} >
							<Image
								src={'data:image/' + (item.imgType === '.png' ? 'png' : 'jpeg') + ';base64,' + item.previewBase64}
								alt={item.imgDescription}
								width='100%'
								height='100%'
							/>
						</Stack>
					)
				},
			},
			{ key: 'column2', name: 'Name', ariaLabel: 'Name', fieldName: 'imgName', minWidth: 150, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick, },
			{ key: 'column3', name: 'Description (Alt Text)', ariaLabel: 'Description (Alt Text)', fieldName: 'imgDescription', minWidth: 150, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick, },
			{ key: 'column4', name: 'Category', fieldName: 'libraryName', minWidth: 150, maxWidth: 300, isResizable: true, onColumnClick: this._onColumnClick, },
			{ key: 'column5', name: 'W', fieldName: 'imageWidth', minWidth: 50, maxWidth: 50, onColumnClick: this._onColumnClick, },
			{ key: 'column6', name: 'H', fieldName: 'imageHeight', minWidth: 50, maxWidth: 50, onColumnClick: this._onColumnClick, },
			{ key: 'column7', name: 'Type', fieldName: 'imgType', minWidth: 35, maxWidth: 35, onColumnClick: this._onColumnClick, },
			{ key: 'column8', name: 'Size', fieldName: 'fileSizeNum', minWidth: 50, maxWidth: 50, onColumnClick: this._onColumnClick, onRender: (item: PictureListItem) => { return <span>{item.fileSize}</span> } },
			{ key: 'column9', name: 'Date modified', fieldName: 'modifiedDate', minWidth: 150, maxWidth: 200, onColumnClick: this._onColumnClick, },
		]
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	componentDidUpdate(prevProps: Readonly<PicturesDetailsProps>, _prevState: Readonly<PicturesDetailsState>, _snapshot?: any): void {

		if (prevProps.pictureItems !== this.props.pictureItems) {
			this.setState({
				columns: this.getColums(),
				sortedItems: this.props.pictureItems,
			});
		}

		if (this.state.showPanel && (this.state.selectedIndices.length > _prevState.selectedIndices.length || this.state.selectedItem !== _prevState.selectedItem)) {
			console.log('panelOn')
			this.setState({ previewPanelOn: true })
		}
		else if (this.state.showPanel && this.state.selectedIndices.length < _prevState.selectedIndices.length) {
			console.log('panelOff')
			this.setState({ previewPanelOn: false })
		}


		if (this.state.deleteConfirmed && !this.deleting) {
			this._deleteSelectedPicture()
		}
		if (this.state.uploadConfirmed && !this.uploading) {
			console.log(this.state.uploadItems)
			this._uploadPictures()
		}
	}

	componentDidMount(): void {
		this.setState({
			columns: this.getColums(),
			sortedItems: this.props.pictureItems,
		});
	}

	render(): JSX.Element {

		let PicturesRender: JSX.Element = (<></>)

		let LoadingProgress: JSX.Element = (<></>)

		if (!this.props.pictureItems)
			LoadingProgress = (
				<Stack style={{ padding: '20px' }}>
					<ProgressIndicator
						label="Fetching picture gallery"
						description=''
					/>
				</Stack>
			)

		const shimmerGroup = (<ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, width: this.props.selectedSize.previewWidth, height: this.props.selectedSize.previewHeight }]} />)

		function shimmerItems(): JSX.Element[] {
			let items = []
			for (let i = 0; i < 20; i++) {
				items.push(<Stack.Item styles={{ root: { padding: '5px' } }}><Shimmer customElementsGroup={shimmerGroup} /></Stack.Item>);
			}
			return items
		}

		// console.log(infoMessage)
		// console.log(infoMessage?.innerHTML === '')

		if (this.state.showList) {
			PicturesRender = (
				<ShimmeredDetailsList
					items={this.state.sortedItems}
					columns={this.state.columns}
					enableShimmer={!this.state.sortedItems}
					// setKey="set"
					layoutMode={DetailsListLayoutMode.justified}
					selectionMode={SelectionMode.multiple}
					selection={(this._selection)}
					selectionPreservedOnEmptyClick={false}
					ariaLabelForSelectionColumn="Toggle selection"
					ariaLabelForSelectAllCheckbox="Toggle selection for all items"
					checkButtonAriaLabel="Row checkbox"
					checkboxVisibility={CheckboxVisibility.always}
					onActiveItemChanged={this._onActiveItemChanged}
					onItemInvoked={this._onItemInvoked}
				// onDidUpdate={() => {this.state.selection.count && this.setState({previewPanelOn: this.state.showPanel})}}
				/>
			)
		}
		else {
			let itemLib: string;

			PicturesRender = (
				<>
					<Stack horizontal wrap tokens={{ childrenGap: 15 }} styles={{ root: { margin: '20px' } }}>
						{this.state.sortedItems ?
							this.state.sortedItems.map((item) => {
								let categoryHeader: JSX.Element = (<></>)

								if (itemLib !== item.libraryName || item.libraryName === '') {
									itemLib = item.libraryName
									categoryHeader = (
										<Stack.Item styles={this.separatorStyle}>
											<Separator>{itemLib}</Separator>
										</Stack.Item>
									)
								}
								return (
									<React.Fragment key={item.id}>
										{categoryHeader}
										<Stack.Item styles={{ root: { padding: '5px' } }}>
											<PictureItem
												setParentState={this.boundSetState}
												item={item}
												accessToken={this.props.accessToken}
												domain={this.props.domain}
												api={this.props.api}
											// showPanel={this.state.showPanel}
											/>
										</Stack.Item>
									</React.Fragment>
								)
							})
							:
							shimmerItems()
						}
					</Stack>
					{this.state.showPreviewModal ?
						<Modal
							titleAriaId='Image preview'
							isOpen={this.state.showPreviewModal}
							onDismiss={(_event) => this.setState({ showPreviewModal: false })}
							isBlocking={false}
							allowTouchBodyScroll={true}
						>
							<Stack grow horizontal styles={{ root: { padding: '12px 12px 14px 24px' } }}>
								<Stack.Item grow align='stretch'>
									<Stack>
										<Stack.Item style={{ fontSize: 24, fontWeight: 'bold', marginBottom: '10px' }}>{this.state.selectedItem.imgName}</Stack.Item>
										<Stack.Item>{this.state.selectedItem.imgDescription}</Stack.Item>
									</Stack>
								</Stack.Item>
								<Stack.Item align='start'>
									<IconButton
										iconProps={{ iconName: 'Cancel' }}
										styles={{ root: { marginLeft: 'auto', marginRight: '2px', marginTop: '4px' } }}
										ariaLabel="Close popup modal"
										onClick={() => this.setState({ showPreviewModal: false })}
									/>
								</Stack.Item>
							</Stack>
							<Stack style={{ padding: '20px' }}>
								{this.state.selectedItemBase64 !== '' ?
									<Image src={'data:image/' + (this.state.selectedItem.imgType === '.png' ? 'png' : 'jpeg') + ';base64,' + this.state.selectedItemBase64} width='100%' height='100%' alt={this.state.selectedItem.imgDescription} onClick={(_event) => { this.setState({ showPreviewModal: false }) }} />
									: null}
							</Stack>
						</Modal>
						: null}
				</>

			)
		}

		return (
			<Stack className='Width100'>
				<span className='Indent20'><h3>Picture Details</h3></span>
				<CommandBar
					items={this.getCommandBarItems()}
					ariaLabel="Use left and right arrow keys to navigate between commands"
				/>
				<Stack style={{ paddingLeft: '20px', paddingRight: '20px' }}>
					{LoadingProgress}
					<div ref={element => { infoMessage = element }}></div>
				</Stack>
				{PicturesRender}
				<DialogDelete
					setParentState={this.boundSetState}
					hidden={this.state.hideDeleteDialog}
					hideState='hideDeleteDialog'
					confirmState='deleteConfirmed'
					filename={this._selection.count === 1 ? ` file ${this.state.selectedItem.imgName}` : ` ${this._selection.count} files`}
				/>
				<DialogUploadPictures
					setParentState={this.boundSetState}
					hidden={this.state.hideUploadDialog}
					hideState='hideUploadDialog'
					confirmState='uploadConfirmed'
					uploadItemState='uploadItems'
					libraries={this.props.selectedSize.Libraries}
				/>
				<DialogUpdatePictureMeta
					setParentState={this.boundSetState}
					getPictures={this.props.getPictures}
					hidden={this.state.hideEditDialog}
					hideState='hideEditDialog'
					accessToken={this.props.accessToken}
					domain={this.props.domain}
					api={this.props.api}
					selectedItem={this.state.selectedItem}
				/>
				<DialogOkOnly
					setParentState={this.boundSetState}
					hidden={this.state.hideNoSelectDialog}
					hideState='hideNoSelectDialog'
					title='Nothing selected'
					text='You have to select a file to use this option.'
				/>
				<DialogOkOnly
					setParentState={this.boundSetState}
					hidden={this.state.hideFileExist}
					hideState='hideFileExist'
					title='File exist'
					text='This filename already exist.'
				/>
				<DialogOkOnly
					setParentState={this.boundSetState}
					hidden={this.state.hideUploadFull}
					hideState='hideUploadFull'
					title='Picture library is full'
					text='You have to delete pictures or get more space to upload more pictures.'
				/>
				{(this.state.previewPanelOn && this.state.selectedItem.id) && (
					<PicturePreviewPanel
						onDismiss={() => {
							console.log('dismiss');
							this.setState({ previewPanelOn: false })
						}}
						pictureItem={this.state.selectedItem}
						accessToken={this.props.accessToken}
						domain={this.props.domain}
						api={this.props.api}
					/>
				)}
			</Stack>
		);
	}

	private separatorStyle: IStackItemStyles = {
		root: {
			width: '100%',
			marginTop: '10px',
			marginBottom: '10px',
		},
	};

	private _onActiveItemChanged = (item?: PictureListItem, _idx?: number, _ev?: any): void => {
		// console.log('change')
		this.setState({ selectedItem: item!, })
	}

	private _onItemInvoked = (item?: PictureListItem, _idx?: number, _ev?: Event): void => {
		// console.log('invoke')
		this.setState({ selectedItem: item!, previewPanelOn: this.state.showPanel })
	};

	private _editPrompt() {
		if (this._selection.count > 0) {
			this.setState({ hideEditDialog: false })
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}
	}

	private _uploadPrompt() {
		if (this.props.totalPicCount < GetMaxPictureCount(this.props.settings)) {
			this.setState({ hideUploadDialog: false })
		}
		else {
			this.setState({ hideUploadFull: false })
		}
	}

	private async _uploadPictures() {
		this.uploading = true
		let successMsg: string[] = []
		let errorMsg: string[] = []
		let i: number = 1

		if (infoMessage) { infoMessage.innerHTML = ReactDOMServer.renderToString(<ProgressIndicator label="Uploading pictures" description={`Uploading ${this.state.uploadItems.length} picture(s)`} />) }

		await Promise.all(this.state.uploadItems.map(async (file) => {
			ourRequest = Axios.CancelToken.source()
			await uploadPicture(
				this.props.accessToken,
				this.props.domain,
				this.props.api,
				file.filename,
				file.data,
				this.props.selectedSize.Path!,
				file.library!,
				this.props.selectedSize.previewWidth,
				this.props.selectedSize.previewHeight,
				this.props.selectedSize.previewBigWidth,
				this.props.selectedSize.previewBigHeight,
				ourRequest.token,
			)
				.then(async (response: AxiosResponse) => {
					console.log(response)

					switch (response.status) {
						case 200:
							successMsg.push(response.data)
							break;
						default:
							response.data !== '' ? errorMsg.push(response.data) : errorMsg.push(`${response.status} ${response.statusText}`)
							break;
					}
				})
				.finally(async () => {
					if (infoMessage) { infoMessage.innerHTML = ReactDOMServer.renderToString(<ProgressIndicator percentComplete={(i / this.state.uploadItems.length)} label="Uploading pictures" description={`Uploading picture ${i++} of ${this.state.uploadItems.length} picture(s)`} />) }
				});
		})).then(async () => {
			if (infoMessage) { infoMessage.innerHTML = ReactDOMServer.renderToString(<ProgressIndicator label="Uploading pictures" description={`Updating picture gallery`} />) }

			ourRequest = Axios.CancelToken.source()
			await updatePictures(this.props.accessToken, this.props.domain, this.props.api, ourRequest.token)
				.then((response: AxiosResponse) => {
					let msg: MessageObject = { message: 'No files uploaded!', messageType: MessageBarType.error, visible: true }
					if (response.status !== 200) {
						msg = { message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true }
					}
					else if (errorMsg.length > 0) {
						msg = { message: errorMsg.length + errorMsg.join('\r\n'), messageType: MessageBarType.error, visible: true }
					}
					else if (successMsg.length > 0){
						msg = {message: successMsg.length + ' images upploaded successfully!', messageType: MessageBarType.success, visible: true}
					}
					setTimeout(() => {
						this.setState({
							sortedItems: [],
							uploadConfirmed: false,
							hideUploadDialog: true,
							uploadItems: [],
						})
						this.props.getLayoutItems(false)
						this.props.setMessage(msg)
					}, 500)
				})
				.finally(async () => {
					if (infoMessage) { infoMessage.innerHTML = '' }
					this.uploading = false
				})
		});

	}

	private _deletePrompt() {
		if (this._selection.count > 0) {
			this.setState({ hideDeleteDialog: false })
		}
		else {
			this.setState({ hideNoSelectDialog: false })
		}
	}

	private async _deleteSelectedPicture() {
		this.deleting = true
		let successMsg: string[] = []
		let errorMsg: string[] = []
		// let i: number = 1

		if (infoMessage) { infoMessage.innerHTML = ReactDOMServer.renderToString(<ProgressIndicator label="Deleting pictures" description={`Deleting ${this.state.uploadItems.length} picture(s)`} />) }

		await Promise.all(this._selection.getSelection().map(async (file) => {
			let pictureFile: PictureListItem = file as PictureListItem
			// let deleteNode = document.createElement('div')
			ourRequest = Axios.CancelToken.source()
			await deletePicture(
				this.props.accessToken,
				this.props.domain,
				this.props.api,
				pictureFile.imgPath,
				ourRequest.token
			)
				.then(async (response: AxiosResponse) => {
					console.log(response)
					switch (response.status) {
						case 200:
							successMsg.push(response.data)
							break;
						default:
							response.data !== '' ? errorMsg.push(response.data) : errorMsg.push(`${response.status} ${response.statusText}`)
							break;
					}
				});
		})).then(async () => {
			if (infoMessage) { infoMessage.innerHTML = ReactDOMServer.renderToString(<ProgressIndicator label="Deleting pictures" description={`Updating picture gallery`} />) }

			ourRequest = Axios.CancelToken.source()
			await updatePictures(this.props.accessToken, this.props.domain, this.props.api, ourRequest.token)
				.then((response: AxiosResponse) => {
					let msg: MessageObject = { message: 'No files deleted!', messageType: MessageBarType.error, visible: true }
					if (response.status !== 200) {
						msg = { message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true }
					}
					else if (errorMsg.length > 0) {
						msg = { message: errorMsg.length + errorMsg.join('\r\n'), messageType: MessageBarType.error, visible: true }
					}
					else if (successMsg.length > 0){
						msg = {message: successMsg.length + ' images deleted successfully!', messageType: MessageBarType.success, visible: true}
					}				
					setTimeout(() => {
						this.setState({
							sortedItems: [],
							selectedItem: {} as PictureListItem,
							hideDeleteDialog: true,
							deleteConfirmed: false,
						})
						this.props.setMessage(msg)
						this.props.getLayoutItems(false)
					}, 500)
				})
				.finally(async () => {
					if (infoMessage) { infoMessage.innerHTML = '' }

					this.deleting = false
				})
		});
	}

	private async _downloadFile() {
		try {
			this._selection.getSelection().forEach(async file => {
				if (typeof file !== 'undefined') {
					let selectedFile: PictureListItem = file as PictureListItem
					let fileBase64 = await GetPicture(this.props.accessToken, this.props.domain, this.props.api, selectedFile.id)
					let anchor: HTMLAnchorElement = document.createElement("a");
					anchor.href = 'data:image/' + (selectedFile.imgType === '.png' ? 'png' : 'jpeg') + ';base64,' + fileBase64;
					anchor.download = selectedFile.imgName + selectedFile.imgType
					anchor.click(); //Downloaded file
				}
			})
		} catch (error) {
			this.setState({ hideNoSelectDialog: false })
		}
	}

	private _onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
		const { columns, sortedItems: pictureItems } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
				// this.setState({
				//   announcedMessage: `${currColumn.name} is sorted ${
				// 	currColumn.isSortedDescending ? 'descending' : 'ascending'
				//   }`,
				// });
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		const newItems = sortArray(pictureItems, currColumn.fieldName!, currColumn.isSortedDescending);
		this.setState({
			columns: newColumns,
			sortedItems: newItems,
		});
	};
}
