import * as React from 'react';
import {
	CommandBar,
	ICommandBarItemProps,
	SelectionMode,
	ShimmeredDetailsList,
	IColumn,
	CheckboxVisibility,
	FontIcon,
	IconButton,
	ProgressIndicator,
	TooltipHost,
} from '@fluentui/react';
import { DetailsListLayoutMode, Selection } from 'office-ui-fabric-react/lib/DetailsList';
import { sortArray } from '../../helpers/MiscFunctions';
import { createNewDomain, deleteDomain } from '../../helpers/PostToAdminApi';
import { MessageBarType, Stack } from 'office-ui-fabric-react';
import SettingsCompany from '../../helpers/SettingsCompany';
import { UserAdminItem } from '../../interfaces/UserAdminItem';
import { DomainItem } from '../../interfaces/DomainItem';
import { NewDomainItem } from '../../interfaces/NewDomainItem';
import { DialogNewDomain } from '../dialogs/DialogNewDomain';
import { DialogOkOnly } from '../dialogs/OldDialogOkOnly';
import { DomainItemExtended } from '../../interfaces/DomainItemExtended';
import { DomainContent } from '../../interfaces/DomainContent';
import { GetDomainContent } from '../../helpers/GetFromRestApi';
import { DialogDelete } from '../dialogs/OldDialogDelete';
import { MessageContextType } from '../../interfaces/ContextType';
import Axios, { CancelTokenSource } from 'axios';
// import Axios, { CancelTokenSource } from 'axios';


export interface DomainsListProps {
	setMessage: (x: MessageContextType) => void;
	updateUsers(): void
	accessToken: string,
	domain: string,
	api: string,
	userData: UserAdminItem,
	domains: DomainItem[]
}

export interface DomainsListState {
	selectedItem: DomainItem
	domainItems: DomainItemExtended[]
	domainContent: DomainContent[]
	selection: Selection
	columns: IColumn[]
	newDomainItem: NewDomainItem
	deleteDomain: { name: string, api: string }
	uploadingNew: boolean
	hideNewDomainDialog: boolean
	hideDeleteDialog: boolean
	deleteConfirmed: boolean
	hideDomainExist: boolean
	newDomainConfirmed: boolean
	loadingContent: boolean
}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class DomainsList extends React.Component<DomainsListProps, DomainsListState> {

	private _selection: Selection;

	constructor(props: DomainsListProps) {
		super(props);
		this._selection = new Selection({
			onSelectionChanged: () => this.setState({ selection: this._selection }),
		});
		this.state = {
			// items: {} as DomainItem,
			domainItems: [],
			domainContent: [],
			// sortedDomainItems: {} as DomainItemExtended[],
			selectedItem: {} as DomainItem,
			selection: this._selection,
			columns: [],
			// domainToken: '',
			// apiName: '',

			newDomainItem: {} as NewDomainItem,
			deleteDomain: { name: '', api: '' },
			uploadingNew: false,
			hideDeleteDialog: true,
			hideNewDomainDialog: true,
			hideDomainExist: true,
			deleteConfirmed: false,
			newDomainConfirmed: false,
			loadingContent: false,
		};
		this.boundSetState = this.setState.bind(this)
	}

	// uploadingNew: Boolean = false

	boundSetState = (x: any) => { this.setState(x) }

	async getDomainContent() {
		ourRequest = Axios.CancelToken.source()
		this.setState({ loadingContent: true })
		let [dev, test, prod] = await Promise.all([
			GetDomainContent(this.props.accessToken, 'dev', ourRequest.token) || [],
			GetDomainContent(this.props.accessToken, 'test', ourRequest.token) || [],
			GetDomainContent(this.props.accessToken, 'prod', ourRequest.token) || [],]
		)
		console.log(dev)
		console.log(test)
		console.log(prod)
		console.log([...dev, ...test, ...prod])
		this.setState({
			domainContent: [...dev, ...test, ...prod],
			loadingContent: false,
			columns: this.getColumns(),
		})
	}

	getDeleteDisabled(item: DomainItemExtended, apiName: string): boolean {
		const content: DomainContent = this.state.domainContent.find((val) => val.DomainName === item.DomainName && val.APIName.toLowerCase() === apiName)!
		if (!content) { return true }
		return (content?.hasIcons || content?.hasPictures || content?.hasSlides || content?.hasTemplates || content?.hasEmailSignatures)
	}

	getColumns(): IColumn[] {
		return [
			{
				key: 'column1',
				name: 'Domain',
				fieldName: 'DomainName',
				minWidth: 200,
				maxWidth: 300,
				isSorted: true,
				isSortedDescending: false,
				isResizable: true,
				onColumnClick: this._onColumnClick,
			},
			{
				key: 'dev',
				name: 'Dev',
				fieldName: 'existInDevApi',
				minWidth: 100,
				maxWidth: 100,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				onRender: this.renderApiColumn()
			},
			{
				key: 'test',
				name: 'Test',
				fieldName: 'existInTestApi',
				minWidth: 100,
				maxWidth: 100,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				onRender: this.renderApiColumn()
			},
			{
				key: 'prod',
				name: 'Prod',
				fieldName: 'existInProdApi',
				minWidth: 100,
				maxWidth: 100,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				onRender: this.renderApiColumn()
			},
		];
	}

	renderApiColumn(): ((item: DomainItemExtended, index?: number | undefined, column?: IColumn | undefined) => JSX.Element) {
		return (item, _index, column) => {
			const content: DomainContent = this.state.domainContent.find((val) => val.DomainName === item.DomainName && val.APIName.toLowerCase() === column?.key!)!
			return (
				<>
					{(
						(item.existInDevApi && column?.key! === 'dev') ||
						(item.existInTestApi && column?.key! === 'test') ||
						(item.existInProdApi && column?.key! === 'prod')) &&
						<>
							{this.state.domainContent.length > 0 ?
								<>
									{!this.getDeleteDisabled(item, column?.key!) ?
										<>
											<FontIcon iconName="CheckMark" />
											<TooltipHost
												content='Delete domain'
											>
												<IconButton
													iconProps={{ iconName: 'Delete' }}
													onClick={() => {
														this.setState({
															deleteDomain: { name: item.DomainName, api: column?.name! },
															hideDeleteDialog: false
														})
													}
													}
												/>
											</TooltipHost>
										</>
										:
										<TooltipHost
											content={this.state.domainContent.length > 0 && content ? (
												<ul>
													{content.hasTemplates && <li>Templates</li>}
													{content.hasIcons && <li>Icons</li>}
													{content.hasPictures && <li>Pictures</li>}
													{content.hasSlides && <li>Slides</li>}
													{content.hasEmailSignatures && <li>Email signatures</li>}
												</ul>
											) : <></>}
										>
											<FontIcon iconName="CheckMark" />
										</TooltipHost>
									}
								</>
								:
								<FontIcon iconName="CheckMark" />
							}
						</>
					}
				</>
			)
		}
	}

	getDomainItemExtended(domains: DomainItem[]): DomainItemExtended[] {
		return [...new Set(domains!?.map(item => item.DomainName))].map((domain) => {
			return {
				DomainName: domain,
				existInDevApi: !!domains.find((val) => val.DomainName.toLowerCase() === domain.toLowerCase() && val.APIName.toLowerCase() === 'dev'),
				existInTestApi: !!domains.find((val) => val.DomainName.toLowerCase() === domain.toLowerCase() && val.APIName.toLowerCase() === 'test'),
				existInProdApi: !!domains.find((val) => val.DomainName.toLowerCase() === domain.toLowerCase() && val.APIName.toLowerCase() === 'prod'),
			}
		})
	}

	componentDidUpdate(prevProps: DomainsListProps, prevState: DomainsListState) {
		if (!prevState.newDomainConfirmed && this.state.newDomainConfirmed) {
			this.setState({ uploadingNew: true })
			this._newDomain()
		}
		if (!prevState.deleteConfirmed && this.state.deleteConfirmed) {
			this._deleteDomain()
		}
		if (prevProps.accessToken !== this.props.accessToken ||
			prevProps.domains !== this.props.domains ||
			prevProps.api !== this.props.api ||
			(prevState.uploadingNew && !this.state.uploadingNew)
		) {
			console.log('UPDATING')
			this.getDomainContent()
			this.setState({
				domainItems: this.getDomainItemExtended(this.props.domains),
				columns: this.getColumns()
			})
		}
	}

	componentDidMount() {
		if (!!this.props.domains) {
			this.getDomainContent()
			this.setState({
				domainItems: this.getDomainItemExtended(this.props.domains),
				columns: this.getColumns()
			})
		}
	}

	componentWillUnmount(): void {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}

	render(): JSX.Element {

		return (
			<Stack className='Width100'>
				<span className='Indent20'><h3>Domains</h3></span>
				<CommandBar
					items={this.commandItems}
					ariaLabel="Use left and right arrow keys to navigate between commands"
				/>
				{this.state.loadingContent &&
					<ProgressIndicator
						styles={{ root: { padding: '20px' } }}
						label="Collecting domain data"
						description=''
					/>
				}
				<ShimmeredDetailsList
					items={this.state.domainItems}
					columns={this.state.columns}
					enableShimmer={this.state.domainItems.length === 0}
					setKey="set"
					layoutMode={DetailsListLayoutMode.justified}
					selectionPreservedOnEmptyClick={true}
					selectionMode={SelectionMode.single}
					ariaLabelForSelectionColumn="Toggle selection"
					ariaLabelForSelectAllCheckbox="Toggle selection for all items"
					ariaLabelForShimmer="Content is being fetched"
					ariaLabelForGrid="Item details"
					checkButtonAriaLabel="Row checkbox"
					selection={(this._selection)}
					checkboxVisibility={CheckboxVisibility.always}
					onActiveItemChanged={(item) => this.setState({ selectedItem: item! })}
				/>
				<DialogNewDomain
					setParentState={this.boundSetState}
					hidden={this.state.hideNewDomainDialog}
					hideState='hideNewDomainDialog'
					confirmState='newDomainConfirmed'
					newDomainItem='newDomainItem'
					apis={this.props.userData.API}
					defaultApi={this.props.api}
				/>
				<DialogDelete setParentState={this.boundSetState} hidden={this.state.hideDeleteDialog} hideState='hideDeleteDialog' confirmState='deleteConfirmed' filename={` domain ${this.state.deleteDomain.name} from API ${this.state.deleteDomain.api}`} />
				<DialogOkOnly setParentState={this.boundSetState} hidden={this.state.hideDomainExist} hideState='hideDomainExist' title='Domain exist' text='This domain already exist.' />
			</Stack>
		);
	}

	private commandItems: ICommandBarItemProps[] = [
		{
			key: 'newField',
			text: 'New domain',
			iconProps: { iconName: 'Add' },
			onClick: () => this.setState({ hideNewDomainDialog: false }),
		},
	];

	private _deleteDomain() {
		deleteDomain(
			this.props.accessToken,
			this.state.deleteDomain.api.toLowerCase(),
			this.state.deleteDomain.name.toLowerCase(),
		)
			.then((response) => {
				setTimeout(() => {
					this.props.setMessage({ message: JSON.stringify(response.data), messageType: response.status === 200 ? MessageBarType.success : MessageBarType.error, visible: true })
					this.props.updateUsers()
					this.setState({
						deleteConfirmed: false,
						selectedItem: {} as DomainItem,
					})					
					this._selection.setAllSelected(false)
				}, 500)
			})
	}

	private _newDomain() {
		console.log(this.state.newDomainItem)
		// this.uploadingNew = true
		let mySettings: SettingsCompany = {} as SettingsCompany
		mySettings.Domain = this.state.newDomainItem.DomainName
		mySettings.CompanyName = this.state.newDomainItem.CompanyName
		mySettings.Caption = [this.state.newDomainItem.CompanyName, this.state.newDomainItem.CompanyName]
		mySettings.Extensions = []
		mySettings.Functions = []

		createNewDomain(
			this.props.accessToken,
			this.props.api,
			mySettings,
		)
			.then((response) => {
				if (response.status === 200) {
					this.setState({
						newDomainConfirmed: false,
						hideNewDomainDialog: true,
						newDomainItem: {} as NewDomainItem,
						uploadingNew: false
					})
					this.props.updateUsers()
					this.props.setMessage({ message: JSON.stringify(response.data), messageType: MessageBarType.success, visible: true })
				}
				else if (response.status === 405) {
					setTimeout(() => {
						this.setState({
							hideDomainExist: false,
							newDomainConfirmed: false,
							hideNewDomainDialog: true,
							newDomainItem: {} as NewDomainItem,
							uploadingNew: false
						})
						this.props.setMessage({ message: response.data.Message, messageType: MessageBarType.error, visible: true })
					}, 500)
				}
				else {
					setTimeout(() => {
						this.setState({
							newDomainConfirmed: false,
							hideNewDomainDialog: true,
							newDomainItem: {} as NewDomainItem,
							uploadingNew: false
						})
						this.props.setMessage({ message: response.data.Message, messageType: MessageBarType.error, visible: true })
					}, 500)
				}
			})
			.then(() => {
				// this.uploadingNew = false
			});
	}

	private _onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
		const { columns, domainItems } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
				// this.setState({
				//   announcedMessage: `${currColumn.name} is sorted ${
				// 	currColumn.isSortedDescending ? 'descending' : 'ascending'
				//   }`,
				// });
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		const newItems = sortArray(domainItems, currColumn.fieldName!, currColumn.isSortedDescending);
		this.setState({
			columns: newColumns,
			domainItems: newItems,
		});
	};
}
