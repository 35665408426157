import { useContext } from "react";
import { GraphContext } from "../contexts/ContextWrapper";
import { getCompanyData, getTenant, getProfilePhoto, getProfileData } from "../helpers/GetFromGraph";
import { GraphOrganizationData } from "../interfaces/GraphOrganizationData";
import { GraphUser } from "../interfaces/GraphUser";
import { VerifiedDomain } from "../interfaces/VerifiedDomain";

export const useGraphFetch = () => {

    const { graphContext, setGraphContext } = useContext(GraphContext);

    const FetchFromGraph = async (accessToken: string): Promise<[string, VerifiedDomain[]]> => {
        let myUserDetails: GraphUser
        let myCompDetails: GraphOrganizationData
        let myVerifiedDomains: VerifiedDomain[]
        let myUserPhoto: string

        [myUserDetails, myCompDetails, myVerifiedDomains, myUserPhoto] = await Promise.all([
            getProfileData(accessToken),
            getCompanyData(accessToken),
            getTenant(accessToken),
            getProfilePhoto(accessToken),
        ])

        const myDefaultDomain = JSON.stringify(myVerifiedDomains.filter((item: { isDefault: boolean; }) => item.isDefault === true)[0]!?.name) || ''
        setGraphContext({
            ...graphContext,
            userDetails: myUserDetails,
            compDetails: myCompDetails,
            userDefaultDomain: myDefaultDomain,
            verifiedDomain: myVerifiedDomains,
            userPhoto: myUserPhoto,
        })
        return [myDefaultDomain, myVerifiedDomains]
    }

    return { FetchFromGraph }
}