import * as React from 'react';
import { FontWeights, Image, ImageFit, Stack, Text } from '@fluentui/react';

const headerStyle = {
	root: {
		fontWeight: FontWeights.semibold,
	}
};

const HeaderLogo: React.FC = ({ children }) => {

	const headerLogo = require('../../assets/rehnlogo-filled.png');

	return (
		<Stack horizontal tokens={{ childrenGap: 20 }} styles={{ root: { width: '100%' } }} verticalAlign='center'>
			<Stack.Item styles={{ root: { width: 56	 } }}>
			<Image src={headerLogo} imageFit={ImageFit.contain} style={{ height: 56, width: 56 }} alt="" />
			</Stack.Item>			
			<Stack.Item grow styles={{ root: { justifyContent: 'end' } }}>
				<Text className="ms-hiddenXlUp" variant="large" styles={headerStyle}>
					365|TemplateExtender Admin
				</Text>
				<Text className="ms-hiddenLgDown" variant="xxLarge" styles={headerStyle}>
					365|TemplateExtender Admin
				</Text>
			</Stack.Item >
			{children}
		</Stack>
	)
}

export default HeaderLogo;
